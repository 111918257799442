import React, {useContext} from 'react';
import { FabricContext } from "../../context/FabricContext";
import TextBold from "../tools/_textbold";
import TextItalic from "../tools/_textitalic"
import TextUnderline from "../tools/_textunderline"
import TextAlign from "../tools/_textalign"
import TextFontSize from "../tools/_textSize"
import TextFontColor from "../tools/_textcolor"
import TextFontStyle from "../tools/_textFontStyle"

const TextMenu = () => {
  const { canvas, activeObject } = useContext(FabricContext);
  return (
    <div>
        <div style={{background: "#fdfcfc", borderRadius: "5px", display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
          <div  className="subMenuTitle" style={{width: "100%"}}> Fonts </div>
            <div style={{display: "flex", justifyContent: "flex-start", flexWrap: "wrap", width:"100%"}}> 
              <TextFontStyle />
              <TextFontSize /> 
              <hr style={{ color: "#888686", height: "1px", width: "98%", padding:"0px", margin: "5px"}}/>
              <TextBold />
              <TextItalic />
              <TextUnderline /> 
              <TextBold /> 
              <hr style={{ color: "#888686", height: "1px", width: "98%", padding:"0px", margin: "5px"}}/>
              <TextAlign /> 
              <hr style={{ color: "#888686", height: "1px", width: "98%", padding:"0px", margin: "5px"}}/>
              <TextFontColor /> 
              
            </div>
        </div>
    </div>
  )
}

export default TextMenu
import React from 'react';
import {useState} from 'react';
import { Link, useNavigate} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ProgressBar } from 'react-loader-spinner';
import Error from './Error';
import useAuth from './hooks/useAuth';

import axios from '../api/axios';
const REGISTER_URL = '/register';
const LOGIN_URL = '/auth';


const Register = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState();

  const [pwd, setPwd] = useState('');
  const [errorPwd, setErrorPwd] = useState();
  const [name, setName] = useState('');

  const [errMsg, setErrMsg] = useState('');
  const DEBUG = false;
  const [isProcessing, setIsProcessing] = useState(false);
  

  function isValidEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
  }

  const handleEmailChange = event => {  
      setEmail(event.target.value);
      setErrorEmail();
  };

  const handlePwdChange = event => {
    setPwd(event.target.value);
};

  const handleSubmit  = async (event) => {
      event.preventDefault();
      setIsProcessing(true);
      //setError(null);
      setErrorPwd(null);
      setErrMsg('');
      if (isValidEmail(email)) {
        DEBUG && console.log('The email is valid');
        setErrorEmail();
      } else {
        setErrorEmail('* Email is invalid');
        setIsProcessing(false);
        return;
      }

      try {
        const response = await axios.post(REGISTER_URL,
            JSON.stringify({ "user": email, 
                             "pwd": pwd,
                             "fullname": name}),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );
        //successful Registration
        // login 
        if (login(email, pwd)) {
          
        } else {
          setErrMsg('02');
        }
        
      } catch (error) {
        DEBUG && console.log("Error connecting to server ...", error.response);
        if (!error.response) { // undefined
          setErrMsg('01');
        } else if (error.response?.status === 400) {
          setErrMsg('03');
        } else if (error.response?.status === 401) {
          setErrMsg('02');
        } else if (error.response?.status === 409) {
          setErrMsg('04'); //user already exist
        } else {
          setErrMsg("00");
        }
        //setIsProcessing(false);
      } 
  };

  const login = async (user, passwd) => {
    setIsProcessing(true);
    try {
      const response = await axios.post(LOGIN_URL,
          JSON.stringify({ "user": user, 
                           "pwd": passwd}),
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          }
      );
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      const userID = response?.data?.userID;
      const fullname = response?.data?.fullname;
      const address = response?.data?.address;
      const contactnumbers = response?.data.contactnum;
      const gender = response?.data.gender;
      const birthdate = response?.data.birthdate;
      setAuth({userID, email, roles, accessToken,fullname, address, contactnumbers, gender, birthdate });
      setIsProcessing(false);
      navigate("/profile");
      return true;
    } catch (error) {
      DEBUG && console.log("Error connecting to server ...", error.response);
      if (!error.response) { // undefined
        setErrMsg('01');
      } else if (error.response?.status === 400) {
        setErrMsg('03');
      } else if (error.response?.status === 401) {
        setErrMsg('02');
      } else if (error.response?.status === 409) {
        setErrMsg('04'); //user already exist
      } else {
        setErrMsg("00");
      }
      return false;
      //setIsProcessing(false);
    } 
  }

  return (
    <div style={{width: "400px", height: "450px", margin: "auto"}}> 
        <div style={{width: "300px", height: "350px", borderWidth: "1px", borderColor: "#bbbbca", background: "#f1f1f1", margin: "30px", padding: "10px",
                    borderStyle: "solid", borderRadius: "20px", textAlign: "center", boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                    
        <div style={{height: "50px", width: "100%", paddingTop:"10px", color:"rgb(3, 67, 145)"}}> NEW USER REGISTRATION FORM </div>
        <form onSubmit={handleSubmit}> 
            <label> 
              <div style={{width: "200px", textAlign:"left", color:"rgb(3, 67, 145)", fontSize:"12px"}}> Name: </div>
              <div style={{width: "200px", height:"40px", textAlign:"left"}}>
                  <input id="name" name="name" style={{borderRadius:"1px", borderWidth:"1px", borderColor:"#e9e5e5", color:"#424242", width:"200px", height:"30px"}} 
                      value={name} required
                      onChange={e =>  {
                        setName(e.target.value);
                        }
                      }
                  /> 
              </div>
            </label>

            <label> 
              <div style={{width: "200px", textAlign:"left", color:"rgb(3, 67, 145)", fontSize:"12px"}}> Email Address: </div>
              <div style={{width: "200px", height:"40px", textAlign:"left"}}>
                  <input id="email" name="email" style={{borderRadius:"1px", borderWidth:"1px", borderColor:"#e9e5e5", color:"#424242", width:"200px", height:"30px"}} 
                      value={email} required
                      onChange={handleEmailChange}
                  /> {errorEmail && <span style={{color: 'red', fontSize:"11px"}}>{errorEmail}</span>}
              </div>
            </label>

            <label> 
              <div style={{width: "200px", textAlign:"left", color:"rgb(3, 67, 145)", fontSize:"12px"}}> Password: </div>
              <div style={{width: "200px", height:"40px", textAlign:"left"}}> 
                  <input id="pwd" name="pwd" type="password" style={{borderRadius:"1px", borderWidth:"1px", borderColor:"#e9e5e5", color:"#424242", width:"200px", height:"30px"}} 
                      value={pwd} required
                      onChange={handlePwdChange}
                  /> 
                  {errorPwd && <span style={{color: 'red', fontSize:"11px"}}>{errorPwd}</span>}
              </div>
            </label>
            <div style={{width: "100%", textAlign:"center", paddingTop: "20px"}} >
                <input type="submit" value="Register" style={{width: "80px", height:"35px", background:"#2e83f3", borderWidth:"0px", borderRadius:"8px", color:"white", fontSize:"12px" }}/> 
            </div>

            <div style={{paddingTop:"10px", fontSize:"13px"}}>Already have an account? <Link to="/login" className="login-link"> LOGIN here. </Link></div>
        </form>
                      
        </div>

        <Modal show={isProcessing} backdrop="static" keyboard={true} onHide={isProcessing} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header>
                <Modal.Title>Registering user ...</Modal.Title>
              </Modal.Header>
      
              <Modal.Body>
                  
                  { 
                    errMsg ?  <>
                                <div style={{backgroundColor: "#f55d5d", width:"100%", height: "50px", color: "white", fontSize:"14px", textAlign: "center", alignItems:"center", alignContent:"center", justifyContent:"center", margin: "auto" }}>
                                  <Error msg={errMsg}/>
                                </div> 
                                <div style={{textAlign: "center", height: "50px", padding: "10px"}}>
                                  <button className='button_blue' style={{padding: "3px", width: "90px"}} onClick={()=>{setIsProcessing(false)}}>OK</button>
                                </div>
                              </>
                           : 
                              <div style={{textAlign: "center"}}> 
                                <ProgressBar visible={true} height="80" width="80" color="#097fee" ariaLabel="progress-bar-loading" wrapperStyle={{}} wrapperClass="" />
                              </div>
                  }
              </Modal.Body>
      
              <Modal.Footer>
                
              </Modal.Footer>
        </Modal>

    </div>
  )
}

export default Register
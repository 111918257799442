import { useState, useEffect} from 'react';
import useAuth from './hooks/useAuth';
import axios from '../api/axios';
import useRefreshToken from './hooks/useRefreshToken';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ProgressBar } from 'react-loader-spinner';
import Error from './Error';

const Profile = () => {
  const { auth} = useAuth();
  const API_URL = "/profile/update";
  const refresh = useRefreshToken();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [fullname, setFullName] = useState("");
  const [housenum, setHousenum] = useState(auth?.address[0]?.housenum);
  const [street, setStreet] = useState(auth?.address[0]?.street);
  const [barangay, setBarangay] = useState(auth?.address[0]?.barangay);
  const [town, setTown] =useState(auth?.address[0]?.town);
  const [province, setProvince] = useState(auth?.address[0]?.province);
  const [zipcode, setZipcode] = useState(auth?.address[0]?.zipcode);
  const [region, setRegion] = useState(auth?.address[0]?.region);
  const [country, setCountry] = useState("Philippines");
  const [contactNum, SetContactNum] = useState("");
  const [gender, setGender] = useState("");
  const [bdayMonth, setBdayMonth] = useState("January");
  const [bdayDay, setBdayDay] = useState("1");
  const [bdayYear, setBdayYear] = useState("2010");

  const [errMsg, setErrMsg] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const DEBUG = false;

  const styles = {
    item_row: {
      backgroundColor: "white", width:"100%", display:"flex", flexDirection:"row", flexWrap: "wrap", 
      borderWidth:"1px", borderColor:"#cccccc", padding:"0px", 
    },
    item_column: {
      width: "50%", 
     // height: "50px", 
      padding:"5px", 
      borderWidth: "1px", 
      borderColor:"#f5f5f5", 
      borderStyle:"solid", 
      fontSize: "15px", 
      fontWeight:"400", 
      color:"#8a8888",
    },
    inputText: {
      padding: "10px",
      color: "red",
    },
  };

  const onOptionGenderChange = (e) => {
      setGender(e.target.value);
  }

  const onSave = async () => {
    DEBUG && console.log("updating profile ...");
    setErrMsg(null);
    setIsSaving(true);
    const JSON_final = JSON.stringify({ 
      "userID": auth.userID,
      "fullname": fullname,
      "contactnumbers" : contactNum,
      "birthdate": new Date(bdayMonth + " " + bdayDay + ", " + bdayYear),
      "gender" : gender,
      "address" : [{"housenum": housenum,
                    "street" : street,
                    "barangay" : barangay,
                    "town" : town,
                    "province" : province,
                    "zipcode": zipcode,
                    "region" : region,
                    "country" : country,
                    "default_": true
                  }]
    });
    DEBUG && console.log("JSON:", JSON_final);
  
    const bearer_token = `Bearer ${auth.accessToken}`;
    try {
      var response = await axios.post(API_URL, JSON_final,
         {
           headers: { 'Content-Type': 'application/json',
                      'Authorization': bearer_token },
             withCredentials: true
         });
      
      if (response.status === 201) { 
        // setAuth({
        //   "fullname": fullname, 
        //   "address": JSON_final.address, 
        //   "contactnumbers" : contactNum, 
        //   "gender" : gender, 
        //   "birthdate" : JSON_final.birthdate });
        await refresh(); //refresh token
         DEBUG && console.log("Successfully updated proflie ...");
         setIsSaving(false);
      }
    } catch (error) {
      DEBUG && console.log("Error connecting to server ...", error.response);
      if (!error.response) { // undefined
        setErrMsg('01');
      } else if (error.response?.status === 400) {
        setErrMsg('03');
      } else if (error.response?.status === 401) {
        setErrMsg('02');
      } else {
        setErrMsg("00");
      }
      setIsSaving(false);
    } 
      
  }

  const onChangePwd = () => {
    navigate("/profile_change_pwd");
  }

  useEffect(() => {
    //console.log("Auth: ", auth);
    setEmail(auth.email);
    setFullName(auth.fullname);
    SetContactNum(auth.contactnumbers);
    setGender(auth.gender);
    //setAddress( auth?.address[0]?.housenum + " " + auth?.address[0]?.street + ", " +
    //                    auth?.address[0]?.barangay + ", " + auth?.address[0]?.town + ", " +
    //                    auth?.address[0]?.province + " " + auth?.address[0]?.zipcode + ", Region " +
    //                    auth?.address[0]?.region)  ;
    setBdayYear(new Date(auth.birthdate).getFullYear());
    setBdayDay(new Date(auth.birthdate).getDate());
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    setBdayMonth(monthNames[new Date(auth.birthdate).getMonth()]);
    // eslint-disable-next-line
  }, []);


  return (
    <div className="container_80" style={{backgroundColor:"#e9f3fc", display: "flex", flexDirection: "row", flexWrap: "wrap",  
        justifyContent:"center", margin: "10px auto 10px auto", alignItems: "flexStart", boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>      
        
        <div style={{width: "100%", height:"50px"}}>
            <div style={{fontSize: "17px", fontWeight:"400", color: "#1A226D", margin: "auto", textAlign: "center", paddingTop: "10px"}}> Profile  </div>
        </div>

        {/* check if error */}
      { 
        errMsg ? <div style={{backgroundColor: "#f55d5d", width:"100%", height: "50px", color: "white", fontSize:"12px", textAlign: "center", alignItems:"center", alignContent:"center", justifyContent:"center", margin: "auto" }}>
                 <Error msg={errMsg}/>
                 </div> 
          : <>


        <div style={styles.item_row}> 
              <div style={{...styles.item_column, width: "30%", textAlign: "right"}} >
                    E-mail Address &nbsp; &nbsp;
              </div>
              <div style={{...styles.item_column, width: "70%", textAlign: "left"}}>
                    <input type="text" name="email" style={{width:"250px",textAlign:"left"}} value={email} disabled  
                          onChange={e =>  {
                                setEmail(e.target.value);
                              }
                          }
                    />
              </div>
        </div>
        <div style={styles.item_row}> 
              <div style={{...styles.item_column, width: "30%", textAlign: "right"}}>
                    Full Name &nbsp; &nbsp;
              </div>
              <div style={{...styles.item_column, width: "70%", textAlign: "left"}}>
                  <input type="text" name="email" style={{width:"250px",textAlign:"left"}} value={fullname} 
                        onChange={e =>  {
                          setFullName(e.target.value);
                        }
                    }
                 />
              </div>
        </div>
        <div style={styles.item_row}> 
              <div style={{...styles.item_column, width: "30%", textAlign: "right"}}>
                    Delivery Address&nbsp; &nbsp;
              </div>
              <div style={{...styles.item_column, width: "70%", textAlign: "left"}}>
                  House Number / Apartment <input type="text" name="email" style={{width:"150px",textAlign:"left"}} value={housenum} 
                                              onChange={e =>  {
                                                  setHousenum(e.target.value);
                                                }
                                              }
                                           /> <br />
                  Street <input type="text" name="email" style={{width:"150px",textAlign:"left"}} value={street} 
                            onChange={e =>  {
                                setStreet(e.target.value);
                              }
                            } 
                         /> <br />
                  Barangay <input type="text" name="email" style={{width:"150px",textAlign:"left"}} value={barangay} 
                              onChange={e =>  {
                                setBarangay(e.target.value);
                                }
                              } 
                           /> <br />
                  Town / City <input type="text" name="email" style={{width:"150px",textAlign:"left"}} value={town} 
                                  onChange={e =>  {
                                    setTown(e.target.value);
                                    }
                                  }    
                              /> <br />
                  Province <input type="text" name="email" style={{width:"150px",textAlign:"left"}} value={province} 
                                onChange={e =>  {
                                  setProvince(e.target.value);
                                  }
                                } 
                           /> <br />
                  Zipcode <input type="text" name="email" style={{width:"150px",textAlign:"left"}} value={zipcode} 
                               onChange={e =>  {
                                  setZipcode(e.target.value);
                                }
                              } 
                          /> <br />
                  Region <input type="text" name="email" style={{width:"150px",textAlign:"left"}} value={region} 
                              onChange={e =>  {
                                setRegion(e.target.value);
                              }
                            } 
                          /> <br />
              </div>
        </div>
        <div style={styles.item_row}> 
              <div style={{...styles.item_column, width: "30%", textAlign: "right"}}>
                    Contact Numbers&nbsp; &nbsp;
              </div>
              <div style={{...styles.item_column, width: "70%", textAlign: "left"}}>
              <input type="text" name="email" style={{width:"250px",textAlign:"left"}} value={contactNum} 
                    onChange={e =>  {
                      SetContactNum(e.target.value);
                      }
                    } 
              />  
              </div>
        </div>
        <div style={styles.item_row}> 
              <div style={{...styles.item_column, width: "30%", textAlign: "right"}}>
                    Gender&nbsp; &nbsp;
              </div>
              <div style={{...styles.item_column, width: "70%", textAlign: "left"}}>
                <label> <input type="radio" name="gender" value="Male" checked={gender==='Male'} onChange={onOptionGenderChange}/> <span className='mobile_font_size'> Male </span></label> &nbsp; 
                <label> <input type="radio" name="gender" value="Female" checked={gender==='Female'} onChange={onOptionGenderChange}/> <span className='mobile_font_size'> Female </span></label> &nbsp; 
                <label> <input type="radio" name="gender" value="Other" checked={gender==='Other'} onChange={onOptionGenderChange}/> <span className='mobile_font_size'> Other </span></label>
              </div>
        </div>
        <div style={styles.item_row}> 
              <div style={{...styles.item_column, width: "30%", textAlign: "right"}}>
                    Date of Birth&nbsp; &nbsp;
              </div>
              <div style={{...styles.item_column, width: "70%", textAlign: "left"}}>
                      <select className="mobile_size_40" style={{height: "30px", color: "gray"}} value={bdayMonth} 
                          onChange={e =>  {
                            setBdayMonth(e.target.value);
                            }
                          } 
                      >
                          <option value="January"> January </option>
                          <option value="February"> February </option>
                          <option value="March"> March </option>
                          <option value="April"> April </option>
                          <option value="May"> May </option>
                          <option value="June"> June </option>
                          <option value="July"> July </option>
                          <option value="August"> August </option>
                          <option value="September"> September </option>
                          <option value="October"> October </option>
                          <option value="November"> November </option>
                          <option value="December"> December </option>
                      </select>  &nbsp;
                      <select className="mobile_size_40" style={{height: "30px", color: "gray"}} value={bdayDay} 
                          onChange={e =>  {
                            setBdayDay(e.target.value);
                            }
                          } 
                      >
                          <option value="1"> 1 </option>
                          <option value="2"> 2 </option>
                          <option value="3"> 3 </option>
                          <option value="4"> 4 </option>
                          <option value="5"> 5 </option>
                          <option value="6"> 6 </option>
                          <option value="7"> 7 </option>
                          <option value="8"> 8 </option>
                          <option value="9"> 9 </option>
                          <option value="10"> 10 </option>
                          <option value="11"> 11 </option>
                          <option value="12"> 12 </option>
                          <option value="13"> 13 </option>
                          <option value="14"> 14 </option>
                          <option value="15"> 15 </option>
                          <option value="16"> 16 </option>
                          <option value="17"> 17 </option>
                          <option value="18"> 18 </option>
                          <option value="19"> 19 </option>
                          <option value="20"> 20 </option>
                          <option value="21"> 21 </option>
                          <option value="22"> 22 </option>
                          <option value="23"> 23 </option>
                          <option value="24"> 24 </option>
                          <option value="25"> 25 </option>
                          <option value="26"> 26 </option>
                          <option value="27"> 27 </option>
                          <option value="28"> 28 </option>
                          <option value="29"> 29 </option>
                          <option value="30"> 30 </option>
                          <option value="31"> 31 </option>
                      </select>  &nbsp;
                      <select className="mobile_size_40" style={{height: "30px", color: "gray"}} value={bdayYear} 
                          onChange={e =>  {
                            setBdayYear(e.target.value);
                            }
                          } 
                      >
                          {(() => {
                           const arr = [];
                           const year = new Date().getFullYear()-12;
                           for(var i=0; i<=70; i++) {
                            arr.push(
                              <option key={i} value={year-i}> {year-i} </option>
                            );
                           } 
                           return arr;
                        })()}
                      </select> 
              </div>
        </div>

        <div style={styles.item_row}> 
              <div style={{...styles.item_column, width: "30%", height: "50px", textAlign: "right"}}>
                    &nbsp; &nbsp;
              </div>
              <div style={{...styles.item_column, width: "70%", height: "50px", textAlign: "left"}}>
                  <button className='button_blue' style={{padding: "5px", width: "90px"}} onClick={onSave}>Save</button> &nbsp; &nbsp;  
                  <button className='button_blue' style={{padding: "5px", width: "180px"}} onClick={onChangePwd}>Change Password</button>
              </div>
        </div>

        

        <Modal show={isSaving} backdrop="static" keyboard={true} onHide={isSaving} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header>
                <Modal.Title>Updating profile ...</Modal.Title>
              </Modal.Header>
      
              <Modal.Body>
                  <div style={{textAlign: "center"}}> 
                    <ProgressBar visible={true} height="80" width="80" color="#097fee" ariaLabel="progress-bar-loading" wrapperStyle={{}} wrapperClass="" />
                  </div>
              </Modal.Body>
      
              <Modal.Footer>
                
              </Modal.Footer>
        </Modal>

        </>
}
 
    </div>
  )
}

export default Profile
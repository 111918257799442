import React, {useCallback, createContext, useState} from "react";
import { fabric }                 from "fabric";
import { initAligningGuidelines } from "../components/handlers/aligning_guidelines";

export const FabricContext = createContext([])

export const FabricContextProvider = ({ children }) => {
    const [canvas, setCanvas] = useState(null)
    const [activeObject, setActiveObject] = useState(null)
    const [fileName, setFileName] = useState("untitled");
    const [imageFiles, setImageFiles] = useState([{}]);
    const [size, setSize] = useState ( {
        _id: 0,
        width: 0,
        height: 0,
        unit: "inches", // inches, cm, meter, ft.
        type: "null",
        canvaswidth: 0,
        canvasheight: 0
    })
    const [undoData, setUndoData] = useState([]);
    const [redoData, setRedoData] = useState([]);
    const DEBUG = false;

    const [isLoading, setIsLoading] = useState(false);
  //  const [historyLocked, setHistoryLocked] = useState(false);

    const initCanvas = useCallback((el) => {
        const canvasOptions = {
            preserveObjectStacking: true,
            selection: true,
            defaultCursor: "default",
            backgroundColor: "#ffffff",

            
        }
        let c = new fabric.Canvas(el, canvasOptions)
        fabric.textureSize = 8192; //for applying filters
        initAligningGuidelines(c)
        c.renderAll()
        setCanvas(c)
    }, [])

    //const resizeCanvas = useCallback((el) => {
    //   console.log("resize from context...")
    //}, [])   

    const loadFromJSON = useCallback((el, json) => {
        let c = new fabric.Canvas(el)
        c.loadFromJSON(
            json,
            () => {
                c.renderAll.bind(c)
                c.setWidth(json.width)
                c.setHeight(json.height)
            },
            function(o, object) {
                fabric.log(o, object)
            })
        c.renderAll()
        setCanvas(c)
    }, [])

    fabric.Canvas.prototype.undo = function () {
        DEBUG && console.log("UNDO from context");
        if (undoData.length === 0) return;
        
        canvas?.loadFromJSON(undoData[undoData.length-2], canvas.renderAll.bind(canvas), function(o, object) {});
        setUndoData((previousArr) => (previousArr.slice(0, -1)));
        
    }

    fabric.Canvas.prototype.saveUndo = function () {
        setUndoData(oldArray => [...oldArray, canvas.toDatalessJSON()]); //push new value at the end of the array
        DEBUG && console.log("Undo data added!", undoData.length); 
       // }      
    }

    fabric.Canvas.prototype.clearUndo = function () {
        setUndoData([]); 
        DEBUG && console.log("clear undo!", undoData.length);     
    }

    fabric.Canvas.prototype.redo = function () {
        DEBUG && console.log("REDO from context");
        if (redoData.length === 0) return;
        
        canvas?.loadFromJSON(redoData[redoData.length-1], canvas.renderAll.bind(canvas), function(o, object) {});
        setRedoData((previousArr) => (previousArr.slice(0, -1)));    
    }

    fabric.Canvas.prototype.saveRedo = function () {
        setRedoData(oldArray => [...oldArray, canvas.toDatalessJSON()]); //push new value at the end of the array
        DEBUG && console.log("REDO data added!", redoData.length);  
    }

    fabric.Canvas.prototype.clearRedo = function () {
        setRedoData([]); 
        DEBUG && console.log("clear redo!", redoData.length);     
    }

    // corners when selected
    fabric.Object.prototype.borderColor = '#2929f8';
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = '#1bb0eb';
    fabric.Object.prototype.cornerStyle = 'square';

    const svgRotateIcon = encodeURIComponent(`
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
            <circle cx="9" cy="9" r="5" fill="white"/>
            <circle cx="9" cy="9" r="4.75" stroke="black" stroke-opacity="0.3" stroke-width="0.5"/>
        </g>
            <path d="M10.8047 11.1242L9.49934 11.1242L9.49934 9.81885" stroke="black" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.94856 6.72607L8.25391 6.72607L8.25391 8.03142" stroke="black" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.69517 6.92267C10.007 7.03301 10.2858 7.22054 10.5055 7.46776C10.7252 7.71497 10.8787 8.01382 10.9517 8.33642C11.0247 8.65902 11.0148 8.99485 10.9229 9.31258C10.831 9.63031 10.6601 9.91958 10.4262 10.1534L9.49701 11.0421M8.25792 6.72607L7.30937 7.73554C7.07543 7.96936 6.90454 8.25863 6.81264 8.57636C6.72073 8.89408 6.71081 9.22992 6.78381 9.55251C6.8568 9.87511 7.01032 10.174 7.23005 10.4212C7.44978 10.6684 7.72855 10.8559 8.04036 10.9663" stroke="black" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
        <filter id="filter0_d" x="0" y="0" width="18" height="18" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="2"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.137674 0 0 0 0 0.190937 0 0 0 0 0.270833 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        </defs>
        </svg>
        `)
    const rotateIcon = `data:image/svg+xml;utf8,${svgRotateIcon}`
    var img = document.createElement('img');
    img.src = rotateIcon;

    fabric.Object.prototype.controls.mtr = new fabric.Control({
        x: 0,
        y: -0.5,
        offsetY: -40,
        cursorStyle: 'crosshair',
        actionHandler: fabric.controlsUtils.rotationWithSnapping,
        actionName: 'rotate',
        render: renderIcon,
        cornerSize: 48,
        withConnection: true
      });

      fabric.Textbox.prototype.controls.mtr = new fabric.Control({
        x: 0,
        y: -0.5,
        offsetY: -40,
        cursorStyle: 'crosshair',
        actionHandler: fabric.controlsUtils.rotationWithSnapping,
        actionName: 'rotate',
        render: renderIcon,
        cornerSize: 48,
        withConnection: true
      });


      function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(img, -size / 2, -size / 2, size, size);
        ctx.restore();
      }


    return (
        <FabricContext.Provider
            value={{ canvas, initCanvas, fileName, setFileName, imageFiles, setImageFiles, size, setSize, loadFromJSON, activeObject, setActiveObject, 
                    undoData, setUndoData, redoData, setRedoData, isLoading, setIsLoading}}>
            {children}
        </FabricContext.Provider>
    )
}
import React, {useContext, useState, useEffect} from 'react';
import triangle from "../../images/editor/triangle.png";
import { FabricContext } from "../../context/FabricContext";
import { fabric } from 'fabric';


const FabricTriangle = () => {
    const { canvas } = useContext(FabricContext);
    const [mousePosition, setMousePosition] = useState({x:100, y: 100})
    const [isDrag, setisDrag] = useState(false);
    const [options, setOptions] = useState({width: 110,
      height: 110,
      fill: 'rgba(0,0,0,0)', //transparent
      stroke: 'black',
      strokeWidth: 3,
      strokeUniform: true,
      originX: 'center',
      originY: 'center',
    });

    const onAddTriangle = () => {
        if (isDrag) {    
          setOptions({...options,
            top: mousePosition.y,
            left: mousePosition.x,
          }) 
          setisDrag(false); 
        } 
        var triangle = new fabric.Triangle(options)
        canvas.add(triangle);

        if (!isDrag) {
          canvas.centerObject(triangle);
        }
        
        let count = canvas.getObjects().length;
        canvas.setActiveObject(canvas.item(count-1));
    }

    const onDrag = () => {
      canvas.on("dragover", function(options) {
        getMouseCoords(options);   
      }) 
     setisDrag(true);
    }
  
    const onDragEnd = () => {
      setOptions({...options,
          top: mousePosition.y,
          left: mousePosition.x,
        })
     
      onAddTriangle();
      canvas.off("dragover");
    }

    function getMouseCoords(event)    {
        var pointer = canvas.getPointer(event.e);
        var posX = pointer.x;
        var posY = pointer.y;
        setMousePosition({"x": posX, "y": posY });
    }

    useEffect(() => {
        setOptions({...options,
          top: mousePosition.y,
          left: mousePosition.x,
        })
    }, [mousePosition.x, mousePosition.y]);

  return (
    <div>
        <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onAddTriangle} onDragStart={onDrag} onDragEnd={onDragEnd}><img src={triangle} width="30px" alt="triangle"/> </div>
    </div>
  )
}

export default FabricTriangle
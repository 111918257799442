import React, {useContext} from 'react';
import iconTop from "../../images/editor/top.png";
import { FabricContext } from "../../context/FabricContext";

const Top = () => {
    const { canvas, activeObject } = useContext(FabricContext);
    const onTop = () => {
        console.log("Top ...");
        canvas.bringToFront(activeObject);
    }
  return (
    <div>
        <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} 
              onClick={onTop} ><img src={iconTop} width="24px" alt="Top"/> </div>
    </div>
  )
}

export default Top
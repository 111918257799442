import React from 'react'

const Shop = () => {
  const styles = {
    item_row: {
      backgroundColor: "white", width:"100%", display:"flex", flexDirection:"row", flexWrap: "wrap", 
      borderWidth:"1px", borderColor:"#cccccc", padding:"0px", 
    },
    item_column: {
      width: "50%", 
     // height: "50px", 
      padding:"5px", 
      borderWidth: "1px", 
      borderColor:"#f5f5f5", 
      borderStyle:"solid", 
      fontSize: "15px", 
      fontWeight:"400", 
      color:"#8a8888",
    },
    inputText: {
      padding: "10px",
      color: "red",
    },
  };
  return (
    <div style={{backgroundColor:"#e9f3fc", display: "flex", flexDirection: "row", flexWrap: "wrap", width: "1200px", 
        justifyContent:"center", margin: "10px auto 10px auto", alignItems: "flexStart", boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>      
        
        <div style={{width: "100%", height:"50px"}}>
            <div style={{fontSize: "17px", fontWeight:"400", color: "#1A226D", margin: "auto", textAlign: "center", paddingTop: "10px"}}> Shop  </div>
        </div>

        <div style={styles.item_row}> 
                              <div style={{...styles.item_column, width: "100%", textAlign: "center"}}>
                                  We're very sorry, this page is still under development.  <br />
                                  We're trying hard to make this available in the next few days. <br />
                                  <br />
                                  For inquiries, please message us at our Facebook page: <br />
                                  <a href="https://www.facebook.com/printbit" className="user_nav-link" target="_blank" rel="noreferrer">
                                    https://www.facebook.com/printbit
                                  </a>
                              </div>
                        </div>
    </div>
  )
}

export default Shop
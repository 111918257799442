import React, {useContext, useState, useEffect} from 'react';
import pentagon from "../../images/editor/pentagon.png";
import { FabricContext } from "../../context/FabricContext";
import { fabric } from 'fabric';




const FabricPentagon = () => {
    const { canvas } = useContext(FabricContext);
    const [mousePosition, setMousePosition] = useState({x:100, y: 100})
    const [isDrag, setisDrag] = useState(false);
    const [options, setOptions] = useState({ left: 20, 
      top: 0, 
      scaleX: 5, 
      scaleY: 5,  
      fill: 'rgba(0,0,0,0)', 
      stroke: 'black', 
      strokeWidth: 3, 
      strokeUniform: true,  
      originX: 'center',
      originY: 'center'}); 


    const onAddPentagon = () => {
        if (isDrag) {    
          setOptions({...options,
            top: mousePosition.y,
            left: mousePosition.x,
          }) 
          setisDrag(false); 
        } 
        var pentagonPath = new fabric.Path('M23.56 27.5H6.52L0.495 11.93 15 1.25l14.5 10.68z');    
        //var scale = 100/pentagonPath.width;
        pentagonPath.set(options);
        canvas.add(pentagonPath);

        if (!isDrag) {
          canvas.centerObject(pentagonPath);
        }
        let count = canvas.getObjects().length;
        canvas.setActiveObject(canvas.item(count-1));
    }

    const onDrag = () => {
      canvas.on("dragover", function(options) {
        getMouseCoords(options);   
      }) 
     setisDrag(true);
    }
  
    const onDragEnd = () => {
      setOptions({...options,
          top: mousePosition.y,
          left: mousePosition.x,
        })
     
      onAddPentagon();
      canvas.off("dragover");
    }

    function getMouseCoords(event)    {
        var pointer = canvas.getPointer(event.e);
        var posX = pointer.x;
        var posY = pointer.y;
        setMousePosition({"x": posX, "y": posY });
    }

    useEffect(() => {
        setOptions({...options,
          top: mousePosition.y,
          left: mousePosition.x,
        })
    }, [mousePosition.x, mousePosition.y]);

  return (
    <div>
        <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onAddPentagon} onDragStart={onDrag} onDragEnd={onDragEnd}><img src={pentagon} width="30px" alt="pentagon"/> </div>
    </div>
  )
}

export default FabricPentagon
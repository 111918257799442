import React, { useState, useRef, useContext, useEffect } from "react";
import { FabricContext } from "../../context/FabricContext";
import { fabric } from 'fabric';
import iconBrowse from '../../images/editor/browse.png';

const IconsLogosBrowse = () => {
    const hiddenFileInput = useRef(null);
    const { canvas} = useContext(FabricContext);
    const [mousePosition, setMousePosition] = useState({x:100, y: 100})
    const [isDrag, setisDrag] = useState(false);
    const [Options, setOptions] = useState({
        width: 300,
        left: 320,
        top: 289,
    });
    const SERVER_LOGOS = 'https://api.printbit.com';

    const icons_logos_stock = [
        { id: '1', label: 'PrintBit', url: "/logos/printbit.webp", thumbnail: "/logos/thumbnails/printbit.webp" },
        { id: '2', label: 'Province of Bohol', url: "/logos/bohol.webp", thumbnail: "/logos/thumbnails/bohol.webp" },
        { id: '3', label: 'City of Tagbilaran', url: "/logos/tagbilaran-city.webp", thumbnail: "/logos/thumbnails/tagbilaran-city.webp" },
        { id: '4', label: 'Barangay Taloto', url: "/logos/taloto.webp", thumbnail: "/logos/thumbnails/taloto.webp" },
        { id: '5', label: 'LGU Balilihan Bohol', url: "/logos/LGU-balilihan.webp", thumbnail: "/logos/thumbnails/LGU-balilihan.webp" },
        { id: '6', label: 'IMAP', url: "/logos/imap.webp", thumbnail: "/logos/thumbnails/imap.webp" },
        { id: '7', label: 'Bluewater Resorts', url: "/logos/bluewater.webp", thumbnail: "/logos/thumbnails/bluewater.webp" },
        { id: '8', label: 'Bohol Water', url: "/logos/bwui-bohol-water.webp", thumbnail: "/logos/thumbnails/bwui-bohol-water.webp" },
        { id: '9', label: 'Holy Name University', url: "/logos/hnu.webp", thumbnail: "/logos/thumbnails/hnu.webp" },
        { id: '10', label: 'Holy Spirit School', url: "/logos/hss.webp", thumbnail: "/logos/thumbnails/hss.webp" },
        { id: '11', label: 'Nyce Skin', url: "/logos/nyce-skin.webp", thumbnail: "/logos/thumbnails/nyce-skin.webp" },
        { id: '12', label: 'IVMG', url: "/logos/IVMG.webp", thumbnail: "/logos/thumbnails/IVMG.webp" },
        { id: '13', label: 'Bohol Peoples Lumber', url: "/logos/bohol-peoples-lumber.webp", thumbnail: "/logos/thumbnails/bohol-peoples-lumber.webp" },
        { id: '14', label: 'Facebook', url: "/logos/fb.webp", thumbnail: "/logos/thumbnails/fb.webp" },
        { id: '15', label: 'Instagram', url: "/logos/instagram.webp", thumbnail: "/logos/thumbnails/instagram.webp" },
        { id: '16', label: 'Youtube', url: "/logos/youtube.webp", thumbnail: "/logos/thumbnails/youtube.webp" },
    ]


    const handleClick = (event) => {
      hiddenFileInput.current.click();
      // console.log("CLICKED!")
    }

    const handleChange = (event) => {
         // const fileUploaded = event.target.files[0];
       // console.log("file ", event.target.files[0]);
       if (event.target.files[0] === undefined || event.target.files[0] === null) return;
       var imageFile = URL.createObjectURL(event.target.files[0]);
       console.log(imageFile);

       fabric.Image.fromURL(imageFile, function(oImg) {
           
           //oImg.scale(0.5).set('flipX', false);
           var image_width_final = canvas.width / 3;
           var image_width = oImg.width;
           oImg.scale(image_width_final/image_width);
           canvas.add(oImg).setActiveObject(oImg);
           canvas.centerObject(oImg);
          // console.log("width", canvas.width, oImg.width);
       });
      // event.target.files[0] = null;
    }

    function imageOnClick(param) {

      fabric.Image.fromURL(param, function(oImg) {
            
        var image_width_final = canvas.width / 3;
        var image_width = oImg.width;
        oImg.scale(image_width_final/image_width);


        if (isDrag) {
            var imgWidth = oImg.width / 2;  // to center object on the mouse position 
            setOptions({
                 width: oImg.width,
                 top: mousePosition.y,
                 left: mousePosition.x-imgWidth,
               }) 
              setisDrag(false); 
             } else {
             //  isDrag_ = false;
        }      

        oImg.set({left: Options.left, top: Options.top});
        
        canvas.add(oImg).setActiveObject(oImg);

        if (!isDrag) {
            canvas.centerObject(oImg);
        }
        
       // console.log("width", canvas.width, oImg.width);
      }, {crossOrigin: 'anonymous'});
    
    }

    function onDragImage(param)  {
      canvas.on("dragover", function(options) {
        getMouseCoords(options);   
      }) 
      setisDrag(true);
      // console.log(param);
    }

    function onDragEndImage(param) { 
      imageOnClick(param);
      canvas.off("dragover");
    }

    function getMouseCoords(event)
      {
        var pointer = canvas.getPointer(event.e);
        var posX = pointer.x;
        var posY = pointer.y;
        setMousePosition({"x": posX, "y": posY });
      //  console.log("getmouse", posX+", "+posY);    // Log to console
      }
    
      useEffect(() => {
       // var txtWidth = Options.width / 2; 
        setOptions({...Options,
          top: mousePosition.y,
          left: mousePosition.x,
        })
      //  setisDrag(true);
     }, [mousePosition.x, mousePosition.y]);

     useEffect(() => {
      console.log("LOGOS:", icons_logos_stock);
     }, []);

  return (
    <div style={{display: 'flex', flexWrap: 'wrap'}}> 
        <div style={{width: "100%", margin: "10px"}}> 
                <button className="buttonUpload" onClick={handleClick}>
                    <span style={{fontSize: "12px"}}> <img src={iconBrowse} width="20px" alt="circle"/> &nbsp; Browse Photo &nbsp;  </span>
                </button>
                <input type="file" style={{display: 'none'}}  onChange={handleChange} ref={hiddenFileInput} accept="image/png, image/jpeg, image/webp" 
                        onClick={(event)=> {
                            event.target.value = null
                            }
                        }
                />
        </div>
        <div> 
                {icons_logos_stock.map((images, i) => (
                     icons_logos_stock.length > 0 ? <img key={i} src={ SERVER_LOGOS + images.thumbnail} alt={images.label} width='100px' className="editorPhotos" 
                                                    onClick={() => imageOnClick( SERVER_LOGOS + images.url)} 
                                                    onDrag={() => onDragImage( SERVER_LOGOS + images.url)}
                                                    onDragEnd={() => onDragEndImage( SERVER_LOGOS + images.url)}
                                                /> : "" 
                ))}
        </div>
    </div>
  )
}

export default IconsLogosBrowse
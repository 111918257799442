import React from 'react'
import iconNew from "../../images/editor/new.png";

const New = ({showDialogNew}) => {

    const onNew = () => {
        showDialogNew(true);
     }
 
   return (
     <div>
       <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} 
                     onClick={onNew} ><img src={iconNew} width="24px" alt="Save"/> </div>
     </div>
   )
}

export default New
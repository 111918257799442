import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { DataProvider } from './context/DataContext';
import { FabricContextProvider } from "./context/FabricContext";
import { QueryClient, QueryClientProvider} from '@tanstack/react-query'
//import { QueryClient, QueryClientProvider } from "react-query";
//import { UndoRedoContextProvider } from "./context/UndoRedoContext";
const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
/*   <React.StrictMode> 
    <App />
   </React.StrictMode> */
   /*<QueryClientProvider client={queryClient}>*/
   <QueryClientProvider client={queryClient}>
   <BrowserRouter>
      <FabricContextProvider>
          <DataProvider>
            <AuthProvider>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </AuthProvider>
          </DataProvider>
      </FabricContextProvider>
    </BrowserRouter>
    </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

import React from 'react'
import { Link } from "react-router-dom";
import logo from '../images/printbit_logo_inline_small.png';

const EditorHeader = () => {
  return (
    <div><Link to="/"><img src={logo} alt="PrintBit" width="150px" style={{minWidth:"80px"}} /> </Link> </div>
  )
}

export default EditorHeader
import React from 'react';
import Opacity from './tools/Opacity';
import Border from './tools/Border';
import Crop from './tools/Crop';
import CropReset from './tools/CropReset';
import Filters from './tools/Filters';
import ClipPath from './tools/ClipPath';

const PhotoTools = () => {
  return (
    <div>
        <div style={{background: "#fdfcfc", borderRadius: "5px", display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
          <div  className="subMenuTitle" style={{width: "100%"}}> Photo Tools </div>
            <div style={{display: "flex", justifyContent: "flex-start", flexWrap: "wrap", width:"100%"}}> 
              <Opacity />
              <hr style={{ color: "#888686", height: "1px", width: "98%", padding:"0px", margin: "5px"}}/>
              <Border />
              <hr style={{ color: "#888686", height: "1px", width: "98%", padding:"0px", margin: "5px"}}/>
              <Crop /> <CropReset />
              <hr style={{ color: "#888686", height: "1px", width: "98%", padding:"0px", margin: "5px"}}/>
              <Filters />
              <hr style={{ color: "#888686", height: "1px", width: "98%", padding:"0px", margin: "5px"}}/>
              <ClipPath />
            </div>
        </div>
    </div>
  )
}

export default PhotoTools
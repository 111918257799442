import React, {useState, useContext, useEffect} from 'react';
import iconTextCongrats from "../../images/editor/texts/congrats.png";
import { FabricContext } from "../../context/FabricContext";
import { fabric } from 'fabric';

const TextCongrats = () => {
    const { canvas } = useContext(FabricContext)
    const [mousePosition, setMousePosition] = useState({x:100, y: 100})
    const [isDrag, setisDrag] = useState(false);
    const [text1Options, setText1Options] = useState({
        width: 320,
        top: 289,
        left: 299,
        fontSize: 50,
        fontWeight: "normal",
        fontStyle: "normal",
        textAlign: "right",
        fontFamily: "Achieve",
        textDecoration: "normal",
        fill: "#000000",
    })

    const onAddTextBox = () => {
        //console.log("ISDRAG", isDrag);
        if (isDrag) {
           var txtWidth = text1Options.width / 2;  // to center object on the mouse position 
           setText1Options({...text1Options,
                top: mousePosition.y,
                left: mousePosition.x-txtWidth,
              }) 
             setisDrag(false); 
            } else {
            //  isDrag_ = false;
        }      
       
        const textBox = new fabric.Textbox("Congratulations!", text1Options)
        canvas.add(textBox);
       
        if (!isDrag) {
            canvas.centerObject(textBox); 
        }

        var options2 = {
            width: 320,
            top: textBox.top + textBox.height,
            left: textBox.left,
            fontSize: 24,
            fontWeight: "normal",
            fontStyle: "normal",
            textAlign: "right",
            fontFamily: "Arial",
            textDecoration: "normal",
            fill: "#000000",
        };

        const textBox2 = new fabric.Textbox("YOU DID IT!  ", options2)
        canvas.add(textBox2);
        
        var sel = new fabric.ActiveSelection([textBox, textBox2], {
            canvas: canvas,
        });
        canvas.setActiveObject(sel);
        canvas.renderAll();
    }

    const onDragEndTextBox = (e) => {
        var txtWidth = text1Options.width / 2; 
        setText1Options({...text1Options,
            top: mousePosition.y,
            left: mousePosition.x - txtWidth,
          })
       // const textBox = new fabric.Textbox("Congratulations!", text1Options);
      //  canvas.add(textBox).setActiveObject(textBox);    
        onAddTextBox(true);
        canvas.off("dragover");
    }

    const onDragTextBox = (e) => {
        canvas.on("dragover", function(options) {
            getMouseCoords(options);   
          }) 
        setisDrag(true);
    }
  
    function getMouseCoords(event)
      {
        var pointer = canvas.getPointer(event.e);
        var posX = pointer.x;
        var posY = pointer.y;
        setMousePosition({"x": posX, "y": posY });
       // console.log("getmouse", posX+", "+posY);    // Log to console
      }

     useEffect(() => {
        var txtWidth = text1Options.width / 2; 
        setText1Options({...text1Options,
          top: mousePosition.y,
          left: mousePosition.x - txtWidth,
        })
      //  setisDrag(true);
     }, [mousePosition.x, mousePosition.y]);
 
  return (
    <div>
    <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"66px", width:"148px"}} 
                onClick={onAddTextBox} onDragStart={onDragTextBox} onDragEnd={onDragEndTextBox}><img src={iconTextCongrats} width="140px" alt="Textbox"/> </div>
</div>
  )
}

export default TextCongrats
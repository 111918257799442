import {useContext} from 'react';
import Modal from 'react-bootstrap/Modal';
import { ProgressBar } from 'react-loader-spinner';
import { FabricContext } from "../../context/FabricContext";

const DialogLoading = () => {
    //const [showDialog, setShowDialog] = useState(true);
    const { isLoading, setIsLoading} = useContext(FabricContext);
  return (
    <div>
        <Modal show={isLoading} backdrop="static" keyboard={true} onHide={isLoading} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header>
                <Modal.Title>Loading data ...</Modal.Title>
              </Modal.Header>
              <Modal.Body>                  
                  <div style={{textAlign: "center"}}> 
                        <ProgressBar visible={true} height="80" width="80%" color="#097fee" ariaLabel="progress-bar-loading" wrapperStyle={{}} wrapperClass="" />
                  </div> 
                                
                  <div style={{textAlign: "center", height: "50px", padding: "10px"}}>
                        <button className='button_blue' style={{padding: "3px", width: "90px"}} onClick={()=>{setIsLoading(false)}}>Close</button>
                  </div>
              </Modal.Body>
      
              <Modal.Footer>
                
              </Modal.Footer>
        </Modal>
    
    </div>
  )
}

export default DialogLoading
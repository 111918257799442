import React, {useContext, useState, useEffect} from 'react';
import { FabricContext } from "../../../context/FabricContext";
import { fabric } from 'fabric';
import iconSephia from "../../../images/editor/sephia.png";
import iconBrownie from "../../../images/editor/brownie.png";
import iconGrayScale from "../../../images/editor/grayscale.png";
import iconBlackWhite from "../../../images/editor/blackandwhite.png";
import iconVintage from "../../../images/editor/vintage.png";
import iconTechnicolor from "../../../images/editor/technicolor.png";
import iconPolaroid from "../../../images/editor/polaroid.png";
import iconKodachrome from "../../../images/editor/kodachrome.png";
import iconInvert from "../../../images/editor/invert.png";

const Filters = () => {
    const {activeObject, canvas } = useContext(FabricContext);
    const DEBUG = false;
    const [sephia, setSephia] = useState(false);
    const [brownie, setBrownie] = useState(false);
    const [grayscale, setGrayscale] = useState(false);
    const [blackWhite, setBlackWhite] = useState(false);
    const [vintage, setVintage] = useState(false);
    const [technicolor, setTechnicolor] = useState(false);
    const [polaroid, setPolaroid] = useState(false);
    const [kodachrome, setKodachrome] = useState(false);
    const [invert, setInvert] = useState(false);
    const [brightness, setBrightness] = useState(0);
    const [contrast, setContrast] = useState(0);
    const [hue, setHue] = useState(0);
    const [saturation, setSaturation] = useState(0);
    const [blur, setBlur] = useState(0);
    const [vibrance, setVibrance] = useState(0);

    const onApplyFilter = (filtername) => {
        canvas.enableGLFiltering = false;
        let imageTextureSize = activeObject?.width > activeObject?.height ? activeObject?.width : activeObject?.height;

        if (imageTextureSize > fabric.textureSize) {
            fabric.textureSize = imageTextureSize;
        }

        if (filtername==='Sephia') {
            !sephia ? activeObject.filters[3] = new fabric.Image.filters.Sepia() : activeObject.filters[3] = "";
            setSephia(!sephia);
            DEBUG && console.log("SEPHIA", activeObject.filters[3]);
        }

        if (filtername==='Brownie') {
            !brownie ? activeObject.filters[4] = new fabric.Image.filters.Brownie() : activeObject.filters[4] = "";
            setBrownie(!brownie);
        }

        if (filtername==='Grayscale') {
            !grayscale ? activeObject.filters[0] = new fabric.Image.filters.Grayscale() : activeObject.filters[0] = "";
            setGrayscale(!grayscale);
        }

        if (filtername==='BlackWhite') {
            !blackWhite ? activeObject.filters[19] = new fabric.Image.filters.BlackWhite() : activeObject.filters[19] = "";
            setBlackWhite(!blackWhite);
        }

        if (filtername==='Vintage') {
            !vintage ? activeObject.filters[9] = new fabric.Image.filters.Vintage() : activeObject.filters[9] = "";
            setVintage(!vintage);
        }
        if (filtername==='Technicolor') {
            !technicolor ? activeObject.filters[14] = new fabric.Image.filters.Technicolor() :  activeObject.filters[14] = "";
            setTechnicolor(!technicolor);
        }
        if (filtername==='Polaroid') {
            !polaroid ?  activeObject.filters[15] = new fabric.Image.filters.Polaroid() : activeObject.filters[15] = "";
            setPolaroid(!polaroid);
        }
        if (filtername==='Kodachrome') {
            !kodachrome ? activeObject.filters[18] = new fabric.Image.filters.Kodachrome() :  activeObject.filters[18] = "";
            setKodachrome(!kodachrome);
        }
        if (filtername==='Invert') {
            !invert ? activeObject.filters[1] = new fabric.Image.filters.Invert() : activeObject.filters[1] = "";
            setInvert(!invert);
        }
        DEBUG && console.log(fabric.textureSize);
        //activeObject.filters.push(sp);
        activeObject.applyFilters();
        canvas.renderAll();
    }

    const onApplyFilterValue = (index, prop, value) => {
        canvas.enableGLFiltering = false;
        if (prop==="brightness") {
            activeObject.filters[index] = new fabric.Image.filters.Brightness({brightness: parseFloat(value)})
            setBrightness(parseFloat(value));
            //console.log(prop, brightness);
        }
        if (prop==="contrast") {
            activeObject.filters[index] = new fabric.Image.filters.Contrast({contrast: parseFloat(value)})
            setContrast(parseFloat(value));
            //console.log(prop, brightness);
        }
        if (prop==="huerotation") {
            activeObject.filters[index] = new fabric.Image.filters.HueRotation({rotation: parseFloat(value)})
            setHue(parseFloat(value));
        }
        if (prop==="saturation") {
            activeObject.filters[index] = new fabric.Image.filters.Saturation({saturation: parseFloat(value)})
            setSaturation(parseFloat(value));
        }
        if (prop==="blur") {
            activeObject.filters[index] = new fabric.Image.filters.Blur({blur: parseFloat(value)})
            setBlur(parseFloat(value));
        }
        if (prop==="vibrance") {
            activeObject.filters[index] = new fabric.Image.filters.Vibrance({vibrance: parseFloat(value)})
            setVibrance(parseFloat(value));
        }

        var obj = canvas.getActiveObject();
       // obj.filters[index] = prop;
      //  if (obj.filters[index]) {
       //     obj.filters[index][prop] = parseFloat(value);
            obj.applyFilters();
            canvas.renderAll();
            DEBUG && console.log(index, prop, value, brightness);
      //  }    
    }

    const onFilterReset = () => {
        if (!activeObject) return;
        activeObject.filters[3] = "";
        setSephia(false);
        activeObject.filters[4] = "";
        setBrownie(false);
        activeObject.filters[0] = "";
        setGrayscale(false);
        activeObject.filters[19] = "";
        setBlackWhite(false);
        activeObject.filters[9] = "";
        setVintage(false);
        activeObject.filters[14] = "";
        setTechnicolor(false);
        activeObject.filters[15] = "";
        setPolaroid(false);
        activeObject.filters[18] = "";
        setKodachrome(false);
        activeObject.filters[1] = "";
        setInvert(false);
        activeObject.filters[5] = "";
        setBrightness(0);
        activeObject.filters[6]= "";
        setContrast(0);
        activeObject.filters[21]= ""
        setHue(0);
        activeObject.filters[7]= "";
        setSaturation(0);
        activeObject.filters[11]= "";
        setBlur(0);
        activeObject.filters[8]= "";
        setVibrance(0);
        activeObject.applyFilters();
        canvas.renderAll();

    }

    useEffect(() => {
       if (activeObject?.type!=="image") return;
       setSephia(activeObject?.filters[3]===undefined || activeObject?.filters[3]=== "" ? false : true);
       setBrownie(activeObject?.filters[4]===undefined || activeObject?.filters[4]=== "" ? false : true);
       setGrayscale(activeObject?.filters[0]===undefined || activeObject?.filters[0]=== "" ? false : true);
       setBlackWhite(activeObject?.filters[19]===undefined || activeObject?.filters[19]=== "" ? false : true);
       setVintage(activeObject?.filters[9]===undefined || activeObject?.filters[9]=== "" ? false : true);
       setTechnicolor(activeObject?.filters[14]===undefined || activeObject?.filters[14]=== "" ? false : true);
       setPolaroid(activeObject?.filters[15]===undefined || activeObject?.filters[15]=== "" ? false : true);
       setKodachrome(activeObject?.filters[18]===undefined || activeObject?.filters[18]=== "" ? false : true);
       setInvert(activeObject?.filters[1]===undefined || activeObject?.filters[1]=== "" ? false : true);
       setBrightness(activeObject?.filters[5]===undefined || activeObject?.filters[5]=== "" ? 0 : parseFloat(activeObject.filters[5].brightness));
       setContrast(activeObject?.filters[6]===undefined || activeObject?.filters[6]=== "" ? 0 : parseFloat(activeObject.filters[6].contrast));
       setHue(activeObject?.filters[21]===undefined || activeObject?.filters[21]=== "" ? 0 : parseFloat(activeObject.filters[21].rotation));
       setSaturation(activeObject?.filters[7]===undefined || activeObject?.filters[7]=== "" ? 0 : parseFloat(activeObject.filters[7].saturation));
       setBlur(activeObject?.filters[11]===undefined || activeObject?.filters[11]=== "" ? 0 : parseFloat(activeObject.filters[11].blur));
       setVibrance(activeObject?.filters[8]===undefined || activeObject?.filters[8]=== "" ? 0 : parseFloat(activeObject.filters[8].blur));
       DEBUG && console.log(activeObject?.filters[5]?.brightness);

    }, [activeObject]);


  return (
    <div style={{paddingTop:"0px", display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
        <div style={{width: "70px", margin: "5px", fontSize: "12px"}}>
            <img src={iconSephia} width="70px" alt="Sephia" style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", padding: "2px" }} />
            <label>
                <input type="checkbox" checked={sephia} onChange={() => {onApplyFilter('Sephia')}} /> Sephia
            </label>
        </div>
        <div style={{width: "70px", margin: "5px", fontSize: "12px"}}>
            <img src={iconBrownie} width="70px" alt="Brownie" style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", padding: "2px" }} />
            <label>
                <input type="checkbox" checked={brownie} onChange={() => {onApplyFilter('Brownie')}} /> Brownie
            </label>
        </div>
        <div style={{width: "70px", margin: "5px", fontSize: "12px"}}>
            <img src={iconGrayScale} width="70px" alt="Grayscale" style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", padding: "2px" }} />
            <label>
                <input type="checkbox" checked={grayscale} onChange={() => {onApplyFilter('Grayscale')}} /> Grayscale
            </label>
        </div>
        <div style={{width: "70px", margin: "5px", fontSize: "12px"}}>
            <img src={iconBlackWhite} width="70px" alt="Black and White" style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", padding: "2px" }} />
            <label>
                <input type="checkbox" checked={blackWhite} onChange={() => {onApplyFilter('BlackWhite')}} /> <span style={{fontSize: "9px"}}>  Black&White </span>
            </label>
        </div>
        <div style={{width: "70px", margin: "5px", fontSize: "12px"}}>
            <img src={iconVintage} width="70px" alt="Vintage" style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", padding: "2px" }} />
            <label>
                <input type="checkbox" checked={vintage} onChange={() => {onApplyFilter('Vintage')}} />  Vintage 
            </label>
        </div>
        <div style={{width: "70px", margin: "5px", fontSize: "12px"}}>
            <img src={iconTechnicolor} width="70px" alt="Technicolor" style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", padding: "2px" }} />
            <label>
                <input type="checkbox" checked={technicolor} onChange={() => {onApplyFilter('Technicolor')}} />  <span style={{fontSize: "9px"}}>Technicolor</span>
            </label>
        </div>
        <div style={{width: "70px", margin: "5px", fontSize: "12px"}}>
            <img src={iconPolaroid} width="70px" alt="Polaroid" style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", padding: "2px" }} />
            <label>
                <input type="checkbox" checked={polaroid} onChange={() => {onApplyFilter('Polaroid')}} />  Polaroid
            </label>
        </div>
        <div style={{width: "70px", margin: "5px", fontSize: "12px"}}>
            <img src={iconKodachrome} width="70px" alt="Kodachrome" style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", padding: "2px" }} />
            <label>
                <input type="checkbox" checked={kodachrome} onChange={() => {onApplyFilter('Kodachrome')}} /> <span style={{fontSize: "9px"}}> Kodachrome</span>
            </label>
        </div>
        <div style={{width: "70px", margin: "5px", fontSize: "12px"}}>
            <img src={iconInvert} width="70px" alt="Invert" style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", padding: "2px" }} />
            <label>
                <input type="checkbox" checked={invert} onChange={() => {onApplyFilter('Invert')}} /> Invert
            </label>
        </div> 
        <div style={{width: "70px", margin: "5px", fontSize: "12px", display: "flex", flexWrap: "wrap", justifyContent: "left"}}>
            <div > Brightness </div>
            <div> <input  type="range" id="borderwidth" name="borderwidth" step={0.1} min="-0.8" max="0.8" style={{ "width": "70px" }}
                        value={brightness} 
                        onChange={(e)=> {onApplyFilterValue(5, "brightness", e.target.value)}} 
                        className="btn-object-action"
                  /> 
            </div>
        </div> 
        <div style={{width: "70px", margin: "5px", fontSize: "12px", display: "flex", flexWrap: "wrap", justifyContent: "left"}}>
            <div > Contrast </div>
            <div> <input  type="range" id="borderwidth" name="borderwidth" step={0.1} min="-0.8" max="0.8" style={{ "width": "70px" }}
                        value={contrast}
                        onChange={(e)=> {onApplyFilterValue(6, "contrast", e.target.value)}} 
                        className="btn-object-action"
                  /> 
            </div>
        </div> 
        <div style={{width: "70px", margin: "5px", fontSize: "12px", display: "flex", flexWrap: "wrap", justifyContent: "left"}}>
            <div > Hue </div>
            <div> <input  type="range" id="borderwidth" name="borderwidth" step={0.1} min="-1" max="1" style={{ "width": "70px" }}
                        value={hue}
                        onChange={(e)=> {onApplyFilterValue(21, "huerotation", e.target.value)}} 
                        className="btn-object-action"
                  /> 
            </div>
        </div> 
        <div style={{width: "70px", margin: "5px", fontSize: "12px", display: "flex", flexWrap: "wrap", justifyContent: "left"}}>
            <div > Saturation </div>
            <div> <input  type="range" id="borderwidth" name="borderwidth" step={0.1} min="-1" max="1" style={{ "width": "70px" }}
                        value={saturation}
                        onChange={(e)=> {onApplyFilterValue(7, "saturation", e.target.value)}} 
                        className="btn-object-action"
                  /> 
            </div>
        </div> 
        <div style={{width: "70px", margin: "5px", fontSize: "12px", display: "flex", flexWrap: "wrap", justifyContent: "left"}}>
            <div > Blur </div>
            <div> <input  type="range" id="borderwidth" name="borderwidth" step={0.1} min="0" max="1" style={{ "width": "70px" }}
                        value={blur}
                        onChange={(e)=> {onApplyFilterValue(11, "blur", e.target.value)}} 
                        className="btn-object-action"
                  /> 
            </div>
        </div> 
        <div style={{width: "70px", margin: "5px", fontSize: "12px", display: "flex", flexWrap: "wrap", justifyContent: "left"}}>
            <div > Vibrance </div>
            <div> <input  type="range" id="borderwidth" name="borderwidth" step={0.1} min="-1" max="1" style={{ "width": "70px" }}
                        value={vibrance}
                        onChange={(e)=> {onApplyFilterValue(8, "vibrance", e.target.value)}} 
                        className="btn-object-action"
                  /> 
            </div>
        </div> 
        <div style={{paddingTop:"0px"}}>
            <button style={{fontSize:"11px", marginLeft: "5px", height:"24px", 
                    paddingTop:"0px", width:"80px", verticalAlign:"middle", borderColor:"#9e9d9d", borderStyle:"solid", borderWidth:"1px",}} onClick={onFilterReset} > Reset Filters </button>
        </div>
    </div>
  )
}

export default Filters
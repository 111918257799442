import { useNavigate } from 'react-router-dom';
import icon_editor from "../images/icons/editor.png";
import icon_tarp from "../images/icons/product_tarp.png";
import icon_sticker from "../images/icons/product_sticker.png";
import icon_embroidery from "../images/icons/product_embroidery.png";
import icon_sintra from "../images/icons/product_sintra.png";
import icon_business_card from "../images/icons/product_calling_card.png";
import icon_pvc_id from "../images/icons/product_pvc_id.png";
import icon_flyer from "../images/icons/product_flyer.png";
import icon_mug from "../images/icons/product_mug.png";
import icon_yearbook from "../images/icons/product_yearbook.png";
import icon_keycard_holder from "../images/icons/product_keycard_holder.png";
import icon_rfq from "../images/icons/icon_rfq.png";

const Products = () => {
    const navigate = useNavigate();

    const openEditor = (product_type) => {
        navigate("/editor");
    }

    const openNewOrderTarp = () => {
        navigate("/product_order_new_tarp");
    }

    const openTemplates = (product_type) => {
        navigate("/product_templates");
    }
    const openRfq = (product_type) => {
        navigate("/rfq");
    }

    return (
      <div className="row-products" style={{backgroundColor:"white"}}> 

            <div className="products" onClick={openEditor}> 
                <div className="title-products"> Online Editor </div>
                <img src={icon_editor} className="img-products-icons" alt="Online Editor"/>
                <div className="button-create-products" style={{width: "150px", height: "45px", bottom: "20px", left: "20px"}}> CREATE YOUR OWN DESIGN </div>
                <div className="background-div" ></div>
            </div>
            <div className="products" > 
                <div className="title-products">Tarpaulins</div> 
                <img src={icon_tarp} className="img-products-icons"  alt="Tarpaulins" onClick={openNewOrderTarp} />
                <div className="button-create-products" style={{width: "80px", height: "45px", bottom: "20px", left: "10px", padding: "10px 5px 10px 5px"}} 
                        onClick={() => openTemplates("tarp")}> TEMPLATES 
                </div>
                <div className="button-create-products" style={{width: "90px", height: "45px", bottom: "20px", left: "100px", padding: "10px 5px 10px 5px"}}
                        onClick={() => openEditor("tarp")}> CREATE</div>
                <div className="background-div" ></div>
            </div>
            <div className="products" > 
                <div className="title-products">Stickers</div>
                <img src={icon_sticker} className="img-products-icons"  alt="Stickers"/> 
                <div className="button-create-products" style={{width: "80px", height: "45px", bottom: "20px", left: "10px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openTemplates("stickers")}> TEMPLATES </div>
                <div className="button-create-products" style={{width: "90px", height: "45px", bottom: "20px", left: "100px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openEditor("stickers")}> CREATE </div>
                <div className="background-div" ></div>
            </div>
            <div className="products"> 
                <div className="title-products">Embroideries</div> 
                <img src={icon_embroidery} className="img-products-icons"  alt="Embroideries"/> 
                <div className="button-create-products" style={{width: "80px", height: "45px", bottom: "20px", left: "10px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openTemplates("stickers")}> TEMPLATES </div>
                <div className="button-create-products" style={{width: "90px", height: "45px", bottom: "20px", left: "100px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openEditor("stickers")}> CREATE </div>
                <div className="background-div" ></div>
            </div>

            <div className="products" > 
                <div className="title-products">Sintra Boards</div> 
                <img src={icon_sintra} className="img-products-icons"  alt="Sintraboards"/> 
                <div className="button-create-products" style={{width: "80px", height: "45px", bottom: "20px", left: "10px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openTemplates("stickers")}> TEMPLATES </div>
                <div className="button-create-products" style={{width: "90px", height: "45px", bottom: "20px", left: "100px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openEditor("stickers")}> CREATE </div>
                <div className="background-div" ></div>
            </div>
            <div className="products" > 
                <div className="title-products">Business Cards</div> 
                <img src={icon_business_card} className="img-products-icons"  alt="Business Cards"/> 
                <div className="button-create-products" style={{width: "80px", height: "45px", bottom: "20px", left: "10px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openTemplates("stickers")}> TEMPLATES </div>
                <div className="button-create-products" style={{width: "90px", height: "45px", bottom: "20px", left: "100px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openEditor("stickers")}> CREATE </div>
                <div className="background-div" ></div>
            </div>
            <div className="products" > 
                <div className="title-products">PVC IDs</div> 
                <img src={icon_pvc_id} className="img-products-icons"  alt="PVC IDs"/> 
                <div className="button-create-products" style={{width: "80px", height: "45px", bottom: "20px", left: "10px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openTemplates("stickers")}> TEMPLATES </div>
                <div className="button-create-products" style={{width: "90px", height: "45px", bottom: "20px", left: "100px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openEditor("stickers")}> CREATE </div>
                <div className="background-div" ></div>
            </div>
            <div className="products" > 
                <div className="title-products">Brochures / Flyers</div> 
                <img src={icon_flyer} className="img-products-icons"  alt="Brochures and Flyers"/> 
                <div className="button-create-products" style={{width: "80px", height: "45px", bottom: "20px", left: "10px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openTemplates("stickers")}> TEMPLATES </div>
                <div className="button-create-products" style={{width: "90px", height: "45px", bottom: "20px", left: "100px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openEditor("stickers")}> CREATE </div>
                <div className="background-div" ></div>
            </div>
            <div className="products" > 
                <div className="title-products">Mugs</div> 
                <img src={icon_mug} className="img-products-icons"  alt="Mugs"/> 
                <div className="button-create-products" style={{width: "80px", height: "45px", bottom: "20px", left: "10px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openTemplates("stickers")}> TEMPLATES </div>
                <div className="button-create-products" style={{width: "90px", height: "45px", bottom: "20px", left: "100px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openEditor("stickers")}> CREATE </div>
                <div className="background-div" ></div>
            </div>
            <div className="products" > 
                <div className="title-products">Yearbooks</div> 
                <img src={icon_yearbook} className="img-products-icons"  alt="Yearbooks"/> 
                <div className="button-create-products" style={{width: "80px", height: "45px", bottom: "20px", left: "10px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openTemplates("stickers")}> TEMPLATES </div>
                <div className="button-create-products" style={{width: "90px", height: "45px", bottom: "20px", left: "100px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openEditor("stickers")}> CREATE </div>
                <div className="background-div" ></div>
            </div>
            <div className="products" > 
                <div className="title-products">Keycard Holders</div> 
                <img src={icon_keycard_holder} className="img-products-icons"  alt="Keycard Holders"/> 
                <div className="button-create-products" style={{width: "80px", height: "45px", bottom: "20px", left: "10px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openTemplates("stickers")}> TEMPLATES </div>
                <div className="button-create-products" style={{width: "90px", height: "45px", bottom: "20px", left: "100px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openEditor("stickers")}> CREATE </div>
                <div className="background-div" ></div>
            </div>
            <div className="products" > 
                <div className="title-products">Your Own Design</div> 
                <img src={icon_rfq} className="img-products-icons"  alt="Request for Quotation"/> 
                <div className="button-create-products" style={{width: "170px", height: "45px", bottom: "20px", left: "15px", padding: "10px 5px 10px 5px"}}
                    onClick={() => openRfq()}> REQUEST FOR QUOTATION </div>
                <div className="background-div" ></div>
            </div>
      </div>
    );
}

export default Products;
import React, {useContext} from 'react';
import iconDown from "../../images/editor/down.png";
import { FabricContext } from "../../context/FabricContext";


const Down = () => {
    const { canvas, activeObject } = useContext(FabricContext);
    const onDown = () => {
        console.log("1 step lower...");
        canvas.sendBackwards(activeObject);
    }
  return (
    <div>
        <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} 
              onClick={onDown} ><img src={iconDown} width="24px" alt="Down"/> </div>
    </div>
  )
}

export default Down
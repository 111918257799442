import React, { createContext, useState } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [contentData, setContentData] = useState('');
  const [G_imageURL, setG_imageURL] = useState([]);

  //const updateData = (newData) => {
  //  setData(newData);
  //};

  return (
    <DataContext.Provider value={{ contentData, setContentData,
                                   G_imageURL,  setG_imageURL  }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
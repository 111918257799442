import { useState} from 'react';
import { useNavigate} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ProgressBar } from 'react-loader-spinner';
import Error from './Error';
import useAuth from './hooks/useAuth';
import axios from '../api/axios';

const ProfileChangePwd = () => {
  const navigate = useNavigate();
  const { auth} = useAuth();
  const [oldPasswd, setOldPasswd] = useState('');
  const [newPasswd1, setNewPasswd1] = useState('');
  const [newPasswd2, setNewPasswd2] = useState('');
  const API_URL = '/profile/update_pwd';

  const [errMsg, setErrMsg] = useState('');
  const DEBUG = false;
  const [isProcessing, setIsProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const styles = {
    item_row: {
      backgroundColor: "white", width:"100%", display:"flex", flexDirection:"row", flexWrap: "wrap", 
      borderWidth:"1px", borderColor:"#cccccc", padding:"0px", 
    },
    item_column: {
      width: "50%", 
     // height: "50px", 
      padding:"5px", 
      borderWidth: "1px", 
      borderColor:"#f5f5f5", 
      borderStyle:"solid", 
      fontSize: "15px", 
      fontWeight:"400", 
      color:"#8a8888",
    },
    inputText: {
      padding: "10px",
      color: "red",
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
    setShowDialog(true);
    setErrMsg('');
    setSuccess(false);
    
    if (newPasswd1!==newPasswd2) {
        DEBUG && console.log("New password don't match!");
        setErrMsg('06');  // password dont' match
        return ;
    } else {
        DEBUG && console.log("New password matched!");
    }
 
    try {
      const bearer_token = `Bearer ${auth.accessToken}`;
      const response = await axios.post(API_URL,
          JSON.stringify({ "user": auth.email, 
                           "pwd": oldPasswd,
                          "newpwd": newPasswd1}),
          {
              headers: { 'Content-Type': 'application/json',
                         'Authorization': bearer_token 
               },
              withCredentials: true
          }
      );
      setIsProcessing(false);
      setSuccess(true);
      DEBUG && console.log("Change password successful!");
      return true;
    } catch (error) {
      DEBUG && console.log("Error connecting to server ...", error.response);
      if (!error.response) { // undefined
        setErrMsg('01');
      } else if (error.response?.status === 400) {
        setErrMsg('03');
      } else if (error.response?.status === 401) { // password incorrect
        setErrMsg('05');
      } else if (error.response?.status === 409) { //user already exist
        setErrMsg('04'); 
      } else if (error.response?.status === 404) { //user not foud
        setErrMsg('02');
      } else {
        setErrMsg("00");
      }
      setIsProcessing(false);
      return false;
    } 
  }

  const onBackToProfile = () => {
    navigate("/profile");
  }


  return (
    <div className="container_80" style={{backgroundColor:"#e9f3fc", display: "flex", flexDirection: "row", flexWrap: "wrap",  
        justifyContent:"center", margin: "10px auto 10px auto", alignItems: "flexStart", boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>      
        
        <div style={{width: "100%", height:"50px"}}>
            <div style={{fontSize: "17px", fontWeight:"400", color: "#1A226D", margin: "auto", textAlign: "center", paddingTop: "10px"}}> Change Password  </div>
        </div>
        <div style={{width: "80%"}}>
        <form onSubmit={handleSubmit}> 
          
            <div style={styles.item_row}> 
                  <div style={{...styles.item_column, width: "30%", textAlign: "right"}} >
                        Old Password &nbsp; &nbsp;
                  </div>
                  <div style={{...styles.item_column, width: "70%", textAlign: "left"}}>
                        <input type="password"  name="passwd" style={{width:"250px",textAlign:"left"}} value={oldPasswd} required   
                              onChange={e =>  {
                                    setOldPasswd(e.target.value);
                                  }
                              }
                        />
                  </div>
            </div>                     
            <div style={styles.item_row}> 
                  <div style={{...styles.item_column, width: "30%", textAlign: "right"}} >
                        New Password &nbsp; &nbsp;
                  </div>
                  <div style={{...styles.item_column, width: "70%", textAlign: "left"}}>
                        <input type="password"  name="vpasswd1" style={{width:"250px",textAlign:"left"}} value={newPasswd1} required  
                              onChange={e =>  {
                                    setNewPasswd1(e.target.value);
                                  }
                              }
                        />
                  </div>
            </div>
              <div style={styles.item_row}> 
                    <div style={{...styles.item_column, width: "30%", textAlign: "right"}} >
                          Verify New Password &nbsp; &nbsp;
                    </div>
                    <div style={{...styles.item_column, width: "70%", textAlign: "left"}}>
                          <input type="password"  name="vpasswd2" style={{width:"250px",textAlign:"left"}} value={newPasswd2} required  
                                onChange={e =>  {
                                      setNewPasswd2(e.target.value);
                                    }
                                }
                          />
                    </div>
              </div>

            <div style={styles.item_row}> 
                  <div style={{...styles.item_column, width: "30%", height: "50px", textAlign: "right"}}>
                        &nbsp; &nbsp;
                  </div>
                  <div style={{...styles.item_column, width: "70%", height: "50px", textAlign: "left"}}>
                      <button className='button_blue' type="submit" style={{padding: "5px", width: "90px"}} >Save</button> &nbsp; &nbsp;  
                      <button className='button_blue' style={{padding: "5px", width: "180px"}} onClick={onBackToProfile}>Back to Profile</button>
                  </div>
            </div>
            
        </form>
        </div>
        <Modal show={showDialog} backdrop="static" keyboard={true} onHide={showDialog} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header>
                <Modal.Title>Changing password ...</Modal.Title>
              </Modal.Header>
      
              <Modal.Body>
                  
                  { 
                    errMsg ?  <>
                                <div style={{width:"100%", color: "red", fontSize:"18px", textAlign: "center", alignItems:"center", alignContent:"center", justifyContent:"center", margin: "auto" }}>
                                  <Error msg={errMsg}/>
                                </div> 
                                <div style={{textAlign: "center", height: "50px", padding: "10px"}}>
                                  <button className='button_blue' style={{padding: "3px", width: "90px"}} onClick={()=>{setShowDialog(false)}}>OK</button>
                                </div>
                              </>
                           : 
                              <>
                                { isProcessing && <div style={{textAlign: "center"}}> 
                                                   <ProgressBar visible={true} height="80" width="80" color="#097fee" ariaLabel="progress-bar-loading" wrapperStyle={{}} wrapperClass="" />
                                                </div> 
                                }
                                { success && 
                                    <div style={{backgroundColor: "#3570f1", width:"100%", height: "50px", color: "white", fontSize:"18px", textAlign: "center", alignItems:"center", alignContent:"center", justifyContent:"center", margin: "auto" }}>
                                      Successfull! 
                                    </div> 
                                }
                                <div style={{textAlign: "center", height: "50px", padding: "10px"}}>
                                  <button className='button_blue' style={{padding: "3px", width: "90px"}} onClick={()=>{setShowDialog(false)}}>OK</button>
                                </div>
                              </>
                  }
              </Modal.Body>
      
              <Modal.Footer>
                
              </Modal.Footer>
        </Modal>
    </div>
  )
}

export default ProfileChangePwd
import React, {useContext} from 'react';
import { FabricContext } from "../../../context/FabricContext";
import { fabric } from 'fabric';


const Crop = () => {
    const {activeObject, canvas } = useContext(FabricContext);
    const DEBUG = false;

    const onCrop = () => {
        const cropRectOptions =({
            id: "crop-rect",
            border: 10,
           // fill: 'rgba(0,0,0,0)', //transparent
            stroke: '#050785',
            strokeWidth: 3,
            strokeUniform: true,
            originX: 'left',
            originY: 'top',
            top: activeObject.top,
            left: activeObject.left,
            width: activeObject.getScaledWidth(),
            height: activeObject.getScaledHeight(),
            strokeDashArray: [5, 5],
            fill: "rgba(255, 255, 255, 1)",
            globalCompositeOperation: "overlay",
          });
        
        const image_to_crop = activeObject;
        DEBUG && console.log("CROPPING...");
        DEBUG && console.log(activeObject);

        var croprect = new fabric.Rect(cropRectOptions);

        canvas.add(croprect);
        canvas.setActiveObject(croprect);

       if ((croprect.setControlVisible("mtr", !1), croprect.cropX)) {
            DEBUG && console.log("control visible", croprect.cropX);
        }

        DEBUG && console.log("control visible", croprect.cropX);
        
        croprect.on("moving", function () {
            if (croprect.top < image_to_crop.top || croprect.left < image_to_crop.left) { 
               if (croprect.left < image_to_crop.left) { 
                    croprect.left =  image_to_crop.left; 
                }
               else { 
                    croprect.left = croprect.left; 
                }
               if ( croprect.top < image_to_crop.top ) {
                    croprect.top = image_to_crop.top;
                } else {
                    croprect.top = croprect.top;
                }
            }

            if (croprect.top + croprect.getScaledHeight() > image_to_crop.top + image_to_crop.getScaledHeight() ||
                croprect.left + croprect.getScaledWidth() > image_to_crop.left + image_to_crop.getScaledWidth())  {
                if (croprect.top + croprect.getScaledHeight() > image_to_crop.top + image_to_crop.getScaledHeight()) {
                    croprect.top = image_to_crop.top + image_to_crop.getScaledHeight() - croprect.getScaledHeight();
                } else {
                    croprect.top = croprect.top;
                }
                
                if (croprect.left + croprect.getScaledWidth() > image_to_crop.left + image_to_crop.getScaledWidth()) {
                    croprect.left = image_to_crop.left + image_to_crop.getScaledWidth() - croprect.getScaledWidth();
                } else {
                    croprect.left =  croprect.left;
                }
            }
        });

        croprect.on("deselected", function () {
            crop(image_to_crop, croprect);
          });

        //activeObject.setCoords();
        canvas.renderAll();
    }

    function crop(imgToCrop, cropRectangle) {
        var s = (cropRectangle.left - imgToCrop.left) / imgToCrop.scaleX,
            o = (cropRectangle.top - imgToCrop.top) / imgToCrop.scaleY,
            c = (cropRectangle.width * cropRectangle.scaleX) / imgToCrop.scaleX,
            l = (cropRectangle.height * cropRectangle.scaleY) / imgToCrop.scaleY;

          imgToCrop.set("cropX", s);
          imgToCrop.set("cropY", o);
          imgToCrop.set("width", c);
          imgToCrop.set("height", l);
          canvas.remove(cropRectangle);
         // canvas.remove(a),
          imgToCrop.set({
            top: imgToCrop.top + o * imgToCrop.scaleY,
            left: imgToCrop.left + s * imgToCrop.scaleX,
          });
          canvas.renderAll();
       DEBUG && console.log("Image to crop:", imgToCrop);
       DEBUG && console.log(s,o,c,l); 
    }
  
    return (
        <div style={{paddingTop:"0px"}}>
            <button style={{ fontSize:"11px", marginLeft: "5px", height:"24px", 
                    paddingTop:"0px", width:"50px", verticalAlign:"middle", borderColor:"#9e9d9d", borderStyle:"solid", borderWidth:"1px",}} onClick={onCrop} > Crop </button>
        </div>
  )
}

export default Crop
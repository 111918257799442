import { useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import axios from '../../api/axios';
import useAuth from '../../components/hooks/useAuth';
import Error from '../Error';
import { ProgressBar } from 'react-loader-spinner';
import { Button} from 'react-bootstrap';
import { FiTrash2 } from "react-icons/fi";


const CartEditTarp = () => {
    const [orderID, setOrderID] = useState();
    const [customSizeDisabled, setCustomSizeDisabled] = useState(true);
    const [customWidth, setCustomWidth] = useState(0);
    const [customHeight, setCustomHeight] = useState(0);
    const [unit, setUnit] = useState("ft.");
    const [tarpSize, setTarpSize] = useState("4,3,ft.");
    const [finalSize, setFinalSize] = useState({width: 4, height: 3, unit: "ft"});
    const [pricePerSqFt, setPricePerSqFt] = useState(0);
    const [priceLayout, setPriceLayout] = useState(0);
    const [printPrice, setPrintPrice] = useState(0);
    const [priceAddOn, setPriceAddOn] = useState(0);
    const [unitPrice, setUnitPrice] = useState(0);
    const [activity, setActivity] = useState("Birthday");
    const [theme, setTheme] = useState("None");
    const [content, setContent] = useState();
    const [pictures, setPictures] = useState([]); //photos that are currently uploaded
    const [picturesURLs, setPicturesURLs] = useState([]);  // all the blob files for the  photos to upload just for display in UI
    const [picturesForUpload, setPicturesForUpload] = useState([]); // the actual photos for upload
    const [notes, setNotes] = useState();
    const [dateTime, setDateTime] = useState();
    const [dateNeeded, setDateNeeded] = useState(new Date());
    const [time_, setTime_] = useState("4:00 PM");
    const [price, setPrice] = useState(0);
    const DEBUG = true;
    const [errMsg, setErrMsg] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isAddCartSuccess, setIsAddCartSuccess] = useState(false);
    const API_URL = "/cart/tarp_edit";
    const API_URL_UPLOAD = "/cart/upload_photos"
    const { auth } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    //const currentPath = location.pathname;
  
    //const getFormattedPrice = (price) => price ?  `₱ ${price?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : `₱ 0.00`;
    const getFormattedPrice = (price) => `₱ ${price?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;

    const styles = {
    item_row: {
      backgroundColor: "white", width:"100%", display:"flex", flexDirection:"row", flexWrap: "wrap", 
      borderWidth:"1px", borderColor:"#cccccc", padding:"0px", 
    },
    item_column: {
      width: "50%", 
     // height: "50px", 
      padding:"5px", 
      borderWidth: "1px", 
      borderColor:"#f5f5f5", 
      borderStyle:"solid", 
      fontSize: "15px", 
      fontWeight:"400", 
      color:"#8a8888",
    },
    inputText: {
      padding: "10px",
      color: "red",
    },
    };

    const tarp_default_sizes = [
      "3,2,ft.",
      "3,3,ft.",
      "4,2.5,ft.",
      "4,3,ft.",
      "5,3,ft.",
      "6,3,ft.",
      "8,3,ft.",
      "4,2,ft.",
      "4,4,ft.",
      "5,4,ft.",
      "6,4,ft.",
      "7,4,ft.",
      "8,4,ft.",
      "5,2,ft.",
      "2,5,ft.",
      "3.5,2.5,ft.",
      "3.5,2.5,ft.",
      "10,5,ft.",
    ]

    const handleSizeChange = (e) => {
      // setSelectedActivityOption(e.value);
      setTarpSize(e.target.value);
    }
  
    const onSizeOptionChange = e => {
      if (e.target.value==="preset") {
        setCustomSizeDisabled(true);
        return;
      } else {
        setCustomSizeDisabled(false);
      }  
    }
  
    const handleUnitChange = (e) => {
      setUnit(e.target.value);
    }
  
    const handleActivityChange = (e) => {
      setActivity(e.target.value);
    }
  
    const handleThemeChange = (e) => {
      setTheme(e.target.value);
    }
  
    const handleTimeChange = (e) => {
      setTime_(e.target.value);
    }
  
    const openItemToEdit = async () => {
        setIsLoading(true);
        if (!location.state) {
            //navigate("/cart");
            return;
        }
        DEBUG && console.log("items for edit", location);
        const item_to_edit = location.state.item_to_edit;
        const localThumbnails = location.state.localThumbnails;
        const tarp_size = item_to_edit.width + "," + item_to_edit.height + "," + item_to_edit.unit;
        setOrderID(item_to_edit._id);

        const tarp_size_found = tarp_default_sizes.includes(tarp_size);
        //if (tarp_size_found===true) console.log("tarp size found");
        if (tarp_size_found) {
          setTarpSize(tarp_size); //4,3,ft.
          setCustomSizeDisabled(true);
        } else {
          setCustomSizeDisabled(false);
          setCustomWidth(item_to_edit.width);
          setCustomHeight(item_to_edit.height);
          setUnit(item_to_edit.unit);
        }
        
        setActivity(item_to_edit.activity);
        setTheme(item_to_edit.theme);
        setContent(item_to_edit.content);
        setNotes(item_to_edit.notes);
        setDateNeeded(new Date(item_to_edit.dateNeeded))
        setPriceLayout(item_to_edit.priceLayout);
        setPriceAddOn(item_to_edit.priceAddOn);
        const time = new Date(item_to_edit.dateNeeded).getHours();
        //console.log("date", time);
        if (time===10) {
            setTime_("10:00 AM");
        } else if (time===11) {
            setTime_("11:00 AM");
        } else if (time===12) {
            setTime_("12:00 PM");
        } else if (time===13) {
            setTime_("1:00 PM");
        } else if (time===14) {
            setTime_("2:00 PM");
        } else if (time===15) {
            setTime_("3:00 PM");
        } else if (time===16) {
            setTime_("4:00 PM");
        } else if (time===17) {
            setTime_("5:00 PM");
        } else {
            setTime_("6:00 PM");
        }

        setPrice(item_to_edit.unitprice);
        setPicturesURLs([]);
        let blob_url = [];
        let found_ = false;  // to avoid duplicate entries in the thumbnails
        for (var i=0; i<item_to_edit.photos.length; i++) {
            found_ = false;
            for (var j=0; j<localThumbnails.length; j++) {
                if (item_to_edit.photos[i]===localThumbnails[j].filename) {
                    if (!found_) blob_url.push({"blob" : localThumbnails[j]?.blob, "filename": localThumbnails[j]?.filename});
                    found_ = true;
                }
            }

        }
        setPicturesURLs(blob_url);
        setIsLoading(false);
    }

      
    const getPrice = async () => {
      DEBUG && console.log("get price ...");
      setIsLoading(true);
      const JSON_final = JSON.stringify({  
        productCode: "TARP", 
        var_description: "Default"
      });
  
      try {
          var response = await axios.post("/price/tarp", JSON_final,
            {
              headers: { 'Content-Type': 'application/json'}
            }
          );
  
          if (response.status === 201) { 
            DEBUG && console.log("Price", response?.data);
            setPricePerSqFt(response?.data.price);
            setIsLoading(false);
            //return response?.data.price
          } 
      } catch (error) {
        DEBUG && console.log("Error connecting to server ...", error.response);
        if (!error.response) { // undefined
          setErrMsg('01');
        } else if (error.response?.status === 400) {
          setErrMsg('03');
        } else if (error.response?.status === 401) {
          setErrMsg('02');
        } else {
            setErrMsg("00");
        }
        setIsLoading(false);
    } 
    }
  
    const calculate_price = () => {
      if (errMsg || isLoading) {
        setPrice(0);
        return;
      }
      const price_per_sq_ft = pricePerSqFt;
      let width = 0;
      let height = 0;
      let unit_ = "ft.";
  
        if (customSizeDisabled) {
          let arrSize = tarpSize.split(','); //useState("4,3,ft.");
          width = arrSize[0];
          height = arrSize[1];
          unit_ = arrSize[2];
        } else {
          width = customWidth;
          height = customHeight;
          unit_ = unit;
        }
        
        setFinalSize({width: width, height: height, unit: unit_});
  
        let price_ = 0;
        let layout_price1 = 120;
        let layout_price2 = 150;
        let final_layout_price = layout_price1; 
        let total_sq_ft = 0;
  
        let price_add_on = 30;   
  
        if (unit_ === "ft.") {
          total_sq_ft = width * height; 
        } else if ( unit_ === "inches") {
          total_sq_ft  = ((width * height) / 144);
        } else if (unit_ === "meters") {
          total_sq_ft = ((width *  3.28084) * (height *  3.28084));
        } else if (unit_ === "cm") {
          total_sq_ft  = ((width *  0.0328 ) * (height *  0.0328 )) ;
        } else if (unit_ === "mm") {
          total_sq_ft = ((width * 0.00328084) * (height * 0.00328084)) ;
        } else {
          total_sq_ft = 0
        }   
  
        DEBUG && console.log("TOTAL sq ft:", total_sq_ft, width, height, unit_);
      price_ =  total_sq_ft * price_per_sq_ft; 
      setPrintPrice(price_);
    
      // less than 12 sq ft layout fee 
      if (total_sq_ft > 12 ) {
        final_layout_price = layout_price2;
      }

      setPriceLayout(final_layout_price);

      if (total_sq_ft === 0) {
        setPrice(0);
        return;
      }
      price_ = price_ + final_layout_price;

      //add on fee if less than 300 
      if (price_ >= 300) {
        setPriceAddOn(0);
        setUnitPrice(price_);   // unit price = print price + layout price 
        setPrice(price_);
      } else {
        setUnitPrice(price_ + price_add_on);   // unit price = print price + layout price + add on (if less than 300)
        setPriceAddOn(price_add_on);
        setPrice(price_  + price_add_on);
      }  
      //price_ = price_ + final_layout_price;
    }

    const onUpdate = async () => {
        DEBUG && console.log("updating cart ...");
        setErrMsg(null);
        setIsSaving(true);
        setIsAddCartSuccess(false);
        let bearer_token = "";
    
        let photos_filename=[];
    
        Object.keys(picturesForUpload).forEach(key => {
            //DEBUG && console.log("filename", imageFiles[key].name);
            photos_filename.push(picturesForUpload[key].name);
        })

        Object.keys(picturesURLs).forEach(key => {
            var found_ = false;
            for (var i=0; i<picturesForUpload.length; i++ ) {
                if (picturesURLs[key].filename===picturesForUpload[i].name) {
                    found_ = true;
                }
            }
            if (!found_) photos_filename.push(picturesURLs[key].filename);
            
        })
    
    
        const JSON_final = JSON.stringify({ 
          "orderID": orderID, 
          "userID": auth.userID,
          "width": finalSize.width,
          "filename": "For Layouting",
          "photos": photos_filename,
          "height": finalSize.height,
          "unit": finalSize.unit,
          "type": "Tarpaulin",
          "activity": activity,
          "theme": theme,
          "content": content,
          "notes": notes,
          "priceLayout": priceLayout,
          "pricePrint": printPrice,
          "priceAddOn": priceAddOn,  
          "unitprice": unitPrice,
          "quantity": 1,
          "dateNeeded": dateTime,
          "total": price,
    });
    DEBUG && console.log("JSON:", JSON_final);
      
        bearer_token = `Bearer ${auth.accessToken}`;
        //else {
        //  sessionID = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) =>
        //                        s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h)) // generate a session id compatible with mongodb ids
        //  localStorage.setItem('sessionID', sessionID);
        //}
    
        try {
          var response = await axios.post(API_URL, JSON_final,
             {
               headers: { 'Content-Type': 'application/json',
                          'Authorization': bearer_token },
                 withCredentials: true
             });
          
          if (response.status === 201) { 
             DEBUG && console.log("Successfully added to cart ...", response?.data?._id);
             setIsSaving(false);
             if (picturesForUpload.length > 0) {
                uploadImagestoServer(orderID, picturesForUpload);
             } else {
              setIsAddCartSuccess(true);
             }
          }
        } catch (error) {
          DEBUG && console.log("Error connecting to server ...", error.response);
          if (!error.response) { // undefined
            setErrMsg('01');
          } else if (error.response?.status === 400) {
            setErrMsg('03');
          } else if (error.response?.status === 401) {
            setErrMsg('02');
          } else {
            setErrMsg("00");
          }
          setIsSaving(false);
        } 
    }
    
    const handleUploadButton = (event) => {
      //event.preventDefault();
      setPictures([...event.target.files]);    
    }
    
    const handleButtonImageDel = (filename) => {
      //e.preventDefault();
      //alert(id);
      var tmpArray = [...picturesURLs]; // data for blob images for display only
      var tmpArray2 = [...picturesForUpload]; // actual local files for upload
      let id=-1;
      let id2=-1;
      for (var i=0; i<tmpArray.length; i++) {
          if (tmpArray[i]?.filename===filename) {
            id=i;
          }
      }
      //alert(id);
      if (id !== -1) {
        tmpArray.splice(id, 1);
        setPicturesURLs(tmpArray); 
      }
      console.log("tmparray2", tmpArray2);
      for (var j=0; j<tmpArray2.length; j++) {
        if (tmpArray2[j]?.name===filename) {
            id2=j;
          }
      }
      //alert(id2);
      if (id2 !== -1) {
         tmpArray2.splice(id2, 1);
         setPicturesForUpload(tmpArray2);
      }
    }
    
    //upload images to /userID/orders/orderid folder
    const uploadImagestoServer = async (orderid, imageFiles) => {
      setIsUploading(true);
      const formData = new FormData();
      formData.append('userID', auth.userID);
      formData.append('folderID', orderid);
      //DEBUG && console.log(orderid);
      let photos_filename=[];
    
      Object.keys(imageFiles).forEach(key => {
           formData.append(imageFiles[key].name, imageFiles[key]);
           //DEBUG && console.log("filename", imageFiles[key].name);
           photos_filename.push(imageFiles[key].name);
      })
    
      DEBUG && console.log("filename", photos_filename);
      // console.log("formdata", formData);
      const bearer_token = `Bearer ${auth.accessToken}`;
      try {
           const response = await axios.post(API_URL_UPLOAD, formData,
               {
                   headers: { 'content-type': 'multipart/form-data',
                              'Authorization': bearer_token },
                   withCredentials: true
               });
      //     console.log(response);
          if (response.status === 200)
            setIsUploading(false);
            setIsAddCartSuccess(true);
            console.log("Uploading files successful!")
      }  catch (error) {
        if (!error.response) { // undefined
          setErrMsg('01');
        } else if (error.response?.status === 400) {
          setErrMsg('03');
        } else if (error.response?.status === 401) {
          setErrMsg('02');
        } else {
          setErrMsg("00");
        }
          setIsUploading(false);
      }    
    }

    useEffect(() => {
        //DEBUG && console.log("Location state:", location.state);
          getPrice();
          //calculate_price();
          openItemToEdit();
      }, []);


    useEffect(() => {
        if (isLoading) return;
        calculate_price();
        DEBUG && console.log(tarpSize, customWidth, customHeight, unit, activity, theme, content, notes, dateTime, time_, price)
    
    },[tarpSize, customWidth, customHeight, unit, activity, theme, content, notes, dateNeeded, dateTime, time_, price, pricePerSqFt, customSizeDisabled, errMsg]);

    useEffect(() => {
        DEBUG && console.log("Picture URL:", picturesURLs, picturesForUpload);
      }, [picturesURLs]);

      useEffect(() => {
        //convert to universal time zone
        let date_ = `${dateNeeded.getMonth() + 1}/${dateNeeded.getDate()}/${dateNeeded.getFullYear()} ${time_}`;
        DEBUG && console.log("DATE:", date_);
        let dateUTC = new Date(date_).toISOString();
        setDateTime(dateUTC);
        DEBUG && console.log("date time: ", dateUTC)
      }, [time_, dateNeeded]);
    
    useEffect(() => {
        if (pictures.length < 1) return;
        const newImageURLs = [];
        const tempImageforUpload = [];
        console.log("pictures", pictures)
        pictures.forEach(image =>  {
            newImageURLs.push({"blob": URL.createObjectURL(image), "filename": image.name})
            tempImageforUpload.push(image);
        });
        setPicturesURLs(oldURLs => oldURLs.concat(newImageURLs)); // add the URLs to the existing values in array
        setPicturesForUpload(oldImage => oldImage.concat(tempImageforUpload));
        setPictures([]);
    } , [pictures]);

    useEffect(() => {
        if (isAddCartSuccess) {
            navigate("/cart");
        }
    },[isSaving, isUploading, isLoading, isAddCartSuccess])
  

  return (
    <div className="new-order-container" style={{backgroundColor:"#e9f3fc", display: "flex", flexDirection: "row", flexWrap: "wrap", 
        justifyContent:"center", margin: "10px auto 10px auto", alignItems: "flexStart", boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>  
      
      <div style={{width: "100%", height:"50px"}}>
            <div style={{fontSize: "17px", fontWeight:"400", color: "#1A226D", margin: "auto", textAlign: "center", paddingTop: "10px"}}> 
                Edit Tarpaulin Order  
            </div>
      </div>
      {/* check if error */}
      { 
        errMsg ? <div style={{backgroundColor: "#f55d5d", width:"100%", height: "50px", color: "white", fontSize:"12px", textAlign: "center", alignItems:"center", alignContent:"center", justifyContent:"center", margin: "auto" }}>
                 <Error msg={errMsg}/>
                 </div> 
          : <>
              {  isLoading ? 
                    <div>
                        <ProgressBar visible={true} height="80" width="80" color="#097fee" ariaLabel="progress-bar-loading" wrapperStyle={{}} wrapperClass="" /> 
                    </div> : 
                    <> 
                    <div style={styles.item_row}> 
                              <div style={{...styles.item_column, width: "30%", textAlign: "right"}}>
                                  Size 
                              </div>
                              <div style={{...styles.item_column, width: "70%", textAlign: "left"}}> 
                                  <label> <input type="radio" name="sizes" value="preset" checked={customSizeDisabled} onChange={onSizeOptionChange}/> <span className='mobile_font_size'> Preset Sizes </span></label> &nbsp; 
                                  <select onChange={handleSizeChange} style={{height: "30px", color: "gray"}} disabled={!customSizeDisabled} value={tarpSize}>
                                      <option value="3,2,ft."> 3x2 ft. </option>
                                      <option value="3,3,ft."> 3x3 ft. </option>
                                      <option value="4,2.5,ft."> 4x2.5 ft. </option>
                                      <option value="4,3,ft."> 4x3 ft. </option>
                                      <option value="5,3,ft."> 5x3 ft. </option>
                                      <option value="6,3,ft."> 6x3 ft. </option>
                                      <option value="8,3,ft."> 8x3 ft. </option>
                                      <option value="4,2,ft."> 4x2 ft. </option>
                                      <option value="4,4,ft."> 4x4 ft. </option>
                                      <option value="5,4,ft."> 5x4 ft. </option>
                                      <option value="6,4,ft."> 6x4 ft. </option>
                                      <option value="7,4,ft."> 7x4 ft. </option>
                                      <option value="8,4,ft."> 8x4 ft. </option>
                                      <option value="5,2,ft."> 5x2 ft. </option>
                                      <option value="2,5,ft."> 2x5 ft. </option>
                                      <option value="3.5,2.5,ft."> 3.5x2.5 ft. </option>
                                      <option value="3.5,2.5,ft."> 3.5x2.5 ft.</option>
                                      <option value="10,5,ft."> 10x5 ft. </option>
                                  </select>  
                                  <div>  
                                    <label> <input type="radio" name="sizes" value="custom" onChange={onSizeOptionChange}/> <span className='mobile_font_size'> Custom Size: </span></label> &nbsp; 
                                  </div>
                                  <div style={{display: "flex"}}>
                                      <div style={{padding: "3px", borderStyle:"solid", borderWidth:"1px", margin:"2px", borderRadius:"5px", borderColor:"#e9e8e8", width:"130px", textAlign: "right"}}> 
                                        <label> <span className='mobile_font_size'> Width </span><input type="text" name="width" style={{width:"70px", textAlign:"center"}} 
                                                        disabled={customSizeDisabled}
                                                        value={customWidth}
                                                        onChange={e =>  {
                                                          if (!isNaN(Number(e.target.value))) {
                                                            setCustomWidth(e.target.value);
                                                            }
                                                          }
                                                        } /> </label>
                                      </div>
                                      <div style={{padding: "3px", borderStyle:"solid", borderWidth:"1px", margin:"2px", borderRadius:"5px", borderColor:"#e9e8e8", width:"130px", textAlign: "right"}}> 
                                        <label><span className='mobile_font_size'>  Height </span><input type="text" name="width" style={{width:"70px",textAlign:"center"}} 
                                                        disabled={customSizeDisabled}
                                                        value={customHeight}
                                                        onChange={e =>  {
                                                          if (!isNaN(Number(e.target.value))) {
                                                            setCustomHeight(e.target.value);
                                                            }
                                                          }
                                                        } /> </label>
                                      </div>
                                      <div style={{padding: "3px", borderStyle:"solid", borderWidth:"1px", margin:"2px", borderRadius:"5px", borderColor:"#e9e8e8", width:"130px", textAlign: "right"}}> 
                                        <label> <span className='mobile_font_size'> Unit </span> 
                                        <select onChange={handleUnitChange} style={{height: "30px", color: "gray"}} defaultValue="ft." disabled={customSizeDisabled} >
                                            <option value="inches"> inches </option>
                                            <option value="ft."> feet </option>
                                            <option value="meters"> meters </option>
                                            <option value="cm"> cm </option>
                                            <option value="mm"> mm </option>
                                        </select> </label>
                                      </div>
                                  </div> 
                                </div>
                              </div>
                        <div style={styles.item_row}> 
                              <div style={{...styles.item_column, width: "30%", textAlign: "right"}}>
                                <span className='mobile_font_size'> Event / Activity / Purpose  </span>
                              </div>
                              <div style={{...styles.item_column, width: "70%", textAlign: "left"}}> 
                                  <select onChange={handleActivityChange} style={{width: "30%", height: "30px", color: "gray"}} value={activity} >
                                      <option value="Birthday">Birthday</option>
                                      <option value="Business">Business</option>
                                      <option value="Christening">Christening</option>
                                      <option value="Obituary">Obituary</option>
                                      <option value="Reunion">Reunion</option>
                                      <option value="Signage">Signage</option>
                                      <option value="Wedding">Wedding</option>
                                      <option value="Welcome">Welcome</option>
                                      <option value="Other Purposes">Other Purposes</option>
                                  </select>
                              </div>
                        </div>
                        <div style={styles.item_row}> 
                              <div style={{...styles.item_column, width: "30%", textAlign: "right"}}>
                                <span className='mobile_font_size'> Theme (if applicable)  </span>
                              </div>
                              <div style={{...styles.item_column, width: "70%", textAlign: "left"}}> 
                                  <select onChange={handleThemeChange} style={{width: "30%", height: "30px", color: "gray"}} value={theme} >
                                      <option value="None">None</option>
                                      <option value="Modern">Modern</option>
                                      <option value="Contemporary">Contemporary</option>
                                      <option value="60s 70s 80s">60s 70s 80s</option>
                                      <option value="Golden">Golden</option>
                                      <option value="Silver">Silver</option>
                                      <option value="Frozen">Frozen</option>
                                      <option value="Mickey">Mickey</option>
                                      <option value="Cocomelon">Cocomelon</option>
                                      <option value="Other">Other</option>
                                  </select>
                              </div>
                        </div>

                        <div style={styles.item_row}> 
                              <div style={{...styles.item_column, width: "30%", textAlign: "right"}}>
                                <span className='mobile_font_size'> Content  </span>
                              </div>
                              <div style={{...styles.item_column, width: "70%", textAlign: "left"}}>
                                  <textarea name="Content" rows={4} style={{width: "100%", color: "gray"}}  value={content} placeholder="Write the content here..."
                                    onChange={e =>  {
                                          setContent(e.target.value);
                                        }
                                      }
                                     
                                  />
                              </div>
                        </div>
                        
                        <div style={styles.item_row}> 
                              <div style={{...styles.item_column, width: "30%", textAlign: "right"}}>
                                  <span className='mobile_font_size'> Pictures  </span>
                              </div>
                              <div style={{...styles.item_column, width: "70%", textAlign: "left"}}>
                                  <input type="file" multiple  onChange={handleUploadButton}  />
                              </div>
                              <div style={{display:"flex",  flexDirection:"row", flexWrap:"wrap", width:"100%", textAlign: "center", justifyContent: "center"}}>
                                {picturesURLs.map((photos, index) => (
                                        <div key={index} style={{width:"120px", position:"relative", display:"inline-block"}}>
                                          <div style={{position:"absolute", right:"0", zIndex:"5"}}>
                                            <Button style={{backgroundColor:"transparent", borderBlockColor:"white", border:"0px", color: "#0890df"}} value={index} onClick={()=>handleButtonImageDel(photos.filename)}><FiTrash2 /></Button>
                                          </div>
                                          <img  src={photos.blob} alt="Image_Photo" width="120px" height="100px" style={{padding:"5px", borderRadius:"15px"}}/>  
                                        </div>
                                ))} 
                              </div>
                        </div>

                        <div style={styles.item_row}> 
                              <div style={{...styles.item_column, width: "30%", textAlign: "right"}}>
                                  <span className='mobile_font_size'> Notes for the designer </span>
                              </div>
                              <div style={{...styles.item_column, width: "70%", textAlign: "left"}}>
                                  <textarea name="Notes" rows={4} style={{width: "100%", color: "gray"}}  value={notes} placeholder="If you have additional request ... "
                                    onChange={e =>  {
                                        setNotes(e.target.value);
                                      }
                                    }
                                  />
                              </div>
                        </div>

                        <div style={styles.item_row}> 
                              <div style={{...styles.item_column, width: "30%", textAlign: "right"}}>
                                  <span className='mobile_font_size'> Date & Time Needed </span>
                              </div>
                              <div style={{...styles.item_column, width: "70%", textAlign: "left"}}>
                                  <DatePicker
                                    showIcon
                                    dateFormat="MM/dd/yyyy"
                                    selected={dateNeeded}
                                    minDate={new Date()}
                                    onChange={(date) => setDateNeeded(date)}
                                    className="customInput" />
                                  &nbsp;
                                  <select onChange={handleTimeChange} style={{width: "120px", height: "30px", color: "gray"}} value={time_} >
                                      <option value="10:00 AM">10:00 AM</option>
                                      <option value="11:00 AM">11:00 AM</option>
                                      <option value="12:00 PM">12:00 Noon</option>
                                      <option value="3:00 PM">3:00 PM</option>
                                      <option value="4:00 PM">4:00 PM</option>
                                      <option value="5:00 PM">5:00 PM</option>
                                      <option value="6:00 PM">6:00 PM</option>
                                  </select>
                              </div>
                        </div>

                        <div style={styles.item_row}> 
                              <div style={{...styles.item_column, width: "30%", textAlign: "right"}}>
                                  PRICE &nbsp; &nbsp;
                              </div>
                              <div style={{...styles.item_column, width: "70%", textAlign: "left", color: "red", fontWeight:"600", fontSize: "18px"}}>
                                  {getFormattedPrice(price)}
                              </div>
                        </div>

                        <div style={{...styles.item_row, height: "50px"}}> 
                              <div style={{...styles.item_column, width: "30%", textAlign: "right"}}>
                                  &nbsp; &nbsp;
                              </div>
                              <div style={{...styles.item_column, width: "70%", textAlign: "left", paddingTop: "8px"}}>
                                  <button className='button_blue' onClick={onUpdate}>Update Cart</button>
                              </div>
                        </div>         
                    </>
              }
            </>
      }

        <Modal show={isSaving || isUploading} backdrop="static" keyboard={true} onHide={isSaving || isUploading} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header>
                <Modal.Title>Updating cart order...</Modal.Title>
              </Modal.Header>
      
              <Modal.Body>
                  <div style={{textAlign: "center"}}> 
                    <ProgressBar visible={true} height="80" width="80" color="#097fee" ariaLabel="progress-bar-loading" wrapperStyle={{}} wrapperClass="" />
                  </div>
              </Modal.Body>
      
              <Modal.Footer>
                
              </Modal.Footer>
        </Modal>

    </div>
  )
}

export default CartEditTarp
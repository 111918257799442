import React, {useContext, useState, useEffect} from 'react';
import line from "../../images/editor/line.png";
import { FabricContext } from "../../context/FabricContext";
import { fabric } from 'fabric';



const FabricLine = () => {
    const { canvas } = useContext(FabricContext);
    const [mousePosition, setMousePosition] = useState({x:100, y: 100})
    const [isDrag, setisDrag] = useState(false);
    const [options, setOptions] = useState({stroke: 'black',
      strokeWidth: 3,
      strokeUniform: true,
      originX: 'center',
      originY: 'center',
    }); 

  const onAddLine = () => {
    if (isDrag) {    
      setOptions({...options,
         top: mousePosition.y,
         left: mousePosition.x,
       }) 
      setisDrag(false); 
    } 
       var line = new fabric.Line([100,100,200,100], options);
       canvas.add(line);

       if (!isDrag) {
          canvas.centerObject(line);
       }

       let count = canvas.getObjects().length;
       canvas.setActiveObject(canvas.item(count-1));
  }
    
  const onDrag = () => {
        canvas.on("dragover", function(options) {
          getMouseCoords(options);   
        }) 
       setisDrag(true);
  }
    
  const onDragEnd = () => {
        setOptions({...options,
            top: mousePosition.y,
            left: mousePosition.x,
          })
       
        onAddLine();
        canvas.off("dragover");
  }

  function getMouseCoords(event)    {
      var pointer = canvas.getPointer(event.e);
      var posX = pointer.x;
      var posY = pointer.y;
      setMousePosition({"x": posX, "y": posY });
  }

  useEffect(() => {
      setOptions({...options,
        top: mousePosition.y,
        left: mousePosition.x,
      })
  }, [mousePosition.x, mousePosition.y]);

  return (
    <div>
        <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onAddLine} onDragStart={onDrag} onDragEnd={onDragEnd}><img src={line} width="30px" alt="line"/> </div>
    </div>
  )
}

export default FabricLine
import React, {useContext} from 'react';
import iconInfo from "../../images/editor/info.png";
import { FabricContext } from "../../context/FabricContext"
import {getActiveStyle, setActiveStyle} from "../libs/utils";
import { fabric } from 'fabric';
import useAuth from '../hooks/useAuth';


const Log = () => {
    const { canvas, activeObject } = useContext(FabricContext);
    const { auth } = useAuth();

    const onLog = () => {
    //  var polygon = new fabric.Polygon(points, objectProps);
    //  canvas.add(polygon);
      //canvas.polygon.set({dirty: true});
     // canvas.renderAll();  
     if (activeObject) {
       // console.log("Bounding Rect", activeObject.getBoundingRect());
       // console.log("Get COords", activeObject.getCoords());
      //  console.log("getObjectSizeWithStroke", getObjectSizeWithStroke(activeObject));
      //  console.log("LEFT, TOP", activeObject.left, activeObject.top);
      //  console.log('OriginXY', activeObject.originX, activeObject.originY);
        //var activeObj = canvas.getActiveObject();
      //  console.log("Z-index", activeObject && canvas.getObjects().indexOf(activeObject));
       // setActiveStyle("dirty", "true", activeObject);
      
       // canvas.renderAll();
     }    
      console.log(auth);  
      console.log(canvas.getObjects());   
      //var dataURL = canvas.toDataURL({
      //  format: 'jpeg',
      //  quality: 0.5
     // });
      //console.log("data url", dataURL);
      //window.open(dataURL); 
    }
 
    function getObjectSizeWithStroke(object) {
      var stroke = new fabric.Point(
        object.strokeUniform ? 1 / object.scaleX : 1, 
        object.strokeUniform ? 1 / object.scaleY : 1
      ).multiply(object.strokeWidth);
      return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
    }

  return (
    <div>
      <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} 
                    onClick={onLog} ><img src={iconInfo} width="24px" alt="Info"/> </div>
    </div>
  )
}

export default Log
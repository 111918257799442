import React, {useContext} from 'react';
import { FabricContext } from "../../context/FabricContext";
import {getActiveStyle, setActiveProp, getActiveProp, setActiveStyle,} from "../libs/utils";
import iconRotate from "../../images/editor/rotate.png";
import iconRotateCCW from "../../images/editor/rotateCCW.png"

const _rotate = () => {
    const {canvas, activeObject } = useContext(FabricContext);

    const onRotateCW = (e) => {
        let angle = getActiveProp("angle", activeObject);     
        angle = angle==="" ? angle=0 : angle;
        let newAngle = angle > 359 ? 0 : angle + 10;
        setActiveProp('angle', newAngle, activeObject);
        activeObject.rotate(newAngle).setCoords();
        canvas.renderAll();
        console.log(getActiveProp("angle", activeObject));
    }

    const onRotateCCW = (e) => {
        let angle = getActiveProp("angle", activeObject);     
        angle = angle==="" ? angle=0 : angle;
        let newAngle = angle > 359 ? 0 : angle - 10;
        setActiveProp('angle', newAngle, activeObject);
        activeObject.rotate(newAngle).setCoords();
        canvas.renderAll();
        console.log(getActiveProp("angle", activeObject));
    }

  return (
    <div style={{display:"flex", flexDirection:"row"}}>
        <div style={{margin:"auto", paddingTop:"0px"}}>
                        <div className='buttonShapes' style={{color:"#010107", margin:"auto", paddingTop: "1px", height:"24px", width:"24px"}} 
                        onClick={onRotateCCW}><img src={iconRotateCCW} width="24px" alt="Rotate CCW"/> </div>
        </div>
         <div style={{margin:"auto", paddingTop:"0px"}}>
                        <div className='buttonShapes' style={{color:"#010107", margin:"auto", paddingTop: "1px", height:"24px", width:"24px"}} 
                        onClick={onRotateCW}><img src={iconRotate} width="24px" alt="Rotate CW"/> </div>
        </div>
    </div>
  )
}

export default _rotate
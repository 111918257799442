import { useEffect, useState, useContext} from 'react';
import "./modal.css";
import { ProgressBar } from 'react-loader-spinner'
import { FabricContext } from "../../context/FabricContext";
import axios from '../../api/axios';
import useAuth from '../hooks/useAuth';

const DialogSaveAsTemplate = ({setDialogSave}) => {
    const { canvas, fileName, setFileName, size, setSize, imageFiles } = useContext(FabricContext);
    const { auth } = useAuth();
    const [isSaving, setIsSaving] = useState(false);
    const [tmpfilename, setTMPFilename] = useState(null);
    const [category, setCategory] = useState("Banner");
    const [keywords, setKeywords] = useState();
    const [errMsg, setErrMsg] = useState('');
    const [asNewFile, setAsNewFile] = useState(false);
    const SERVER_IMAGES = "http://localhost:5000";
    const API_URL = '/templates_new';
    const API_URL_UPDATE = "/templates_update";
    const API_URL_UPLOAD = "/templates_images_upload";
    const DEBUG = true;
    

    const saveDesign = async () => {
        setErrMsg(null);
        setIsSaving(true);
        const randomID = Date.now().toString(36); //used for directory name at the server for all images related to the template

        if (tmpfilename==="" || tmpfilename === undefined) {
            setErrMsg("Please type the file name field.");
            setIsSaving(false);
            return;
        }

        if (keywords==="" || keywords === undefined) {
            setErrMsg("Please put keywords.");
            setIsSaving(false);
            return;
        }
        const zoom = canvas.getZoom();
        canvas.setZoom(1);
        canvas.setWidth(canvas.width * (1/zoom));     // 
        canvas.setHeight(canvas.height * (1 / zoom));
       
        canvas.renderAll();
        var json = [{}];
        json = canvas.toJSON();
        const tmpJson = {version: json.version, background: json.background, 
            backgroundImage: json.backgroundImage};
        
        var filesForUpload = [];

        // function to get the filenames for upload of the user images
        const nextJson = json.objects.map(fabricObject => {
            if (fabricObject.type === 'image') {
                DEBUG && console.log("SRC", fabricObject.src);                
                if (fabricObject.src.includes("blob")) {   //local images URL are blob (eg. blob:http://localhost:3000/2fb73506-3d26-44f9-9b45-dd82b138627d)
                    var blobFileURL = fabricObject.src;
                    //const tmparray = fabricObject.src.split("/"); //git rid of blob 
                    //const re = new RegExp(keyword, 'i');
                    const  filtered = imageFiles.filter(entry => 
                                        Object.values(entry).some(val => typeof val === "string"    
                                        && val.match(blobFileURL)));
                    const localImageFilename = filtered[0]?.localFilename.name;
                    if (filtered[0]?.localFilename!==undefined) {
                        filesForUpload.push(filtered[0]?.localFilename);
                    }
                    
                   // console.log("filetered:", localImageFilename);
                    return{ 
                        ...fabricObject, src: SERVER_IMAGES + "/templates_images_user/userImages/" + auth.userID + "/" + randomID + "/" + localImageFilename,
                    }
                } else 
                  return fabricObject;
                
            } else {
               // console.log(fabricObject);
                return fabricObject;
            }
        });
        DEBUG && console.log("NEXTJSON", nextJson);
        DEBUG && console.log("FILES4UPLOAD", filesForUpload);
        
        const finalJSON = {...tmpJson, "objects": nextJson };
        DEBUG && console.log("FInal JASON DB:", finalJSON);
        //console.log("Saving to DB ...", JSON.stringify(canvas.toJSON()));
        //console.log("Saving to DB ...", canvas.toJSON());
        var thumbnail = canvas.toDataURL({
            format: 'jpeg',
            quality: 0.5, 
            multiplier: .5
          });
        DEBUG && console.log("SIZE:", size);
        const bearer_token = `Bearer ${auth.accessToken}`;

        try {
            console.log("size ID:", size._id);
            var response;
            if (!size._id || size._id===undefined || asNewFile) {  // NEW 
                     console.log("Save as NEW design...");
                     const JSON_final = JSON.stringify({   "userID": auth.userID,
                        "filename": tmpfilename,
                        "category": category,
                        "keywords": keywords,
                        "width": size.width,
                        "height": size.height,
                        "unit": size.unit,
                        "type": size.type,
                        "canvaswidth": canvas?.width,
                        "canvasheight": canvas?.height,
                        "content": finalJSON, 
                        "thumbnail": thumbnail});

                    response = await axios.post(API_URL, JSON_final,
                     {
                        headers: { 'Content-Type': 'application/json',
                                    'Authorization': bearer_token },
                        withCredentials: true
                    });
    
                    if (response.status === 201) {
                        console.log("DESIGN ID after saving...", response.data._id);
                        console.log("JSON width: ", JSON_final.width);
                        setSize({...size, "_id": response.data._id})  // populate design ID for updating next time
                        //setTmpSize({"width":  JSON_final.width, "height":  JSON_final.height, "unit":  JSON_final.unit, "type":  JSON_final.type});
                    }
            } else {
                    console.log("Updating design...");
                    response = await axios.put(API_URL_UPDATE,
                        JSON.stringify({ "_id" : size._id,
                                        "userID": auth.userID,
                                        "filename": tmpfilename,
                                        "category": category,
                                        "keywords": keywords,
                                        "width": size?.width,
                                        "height": size?.height,
                                        "unit": size?.unit,
                                        "type": size?.type,
                                        "canvaswidth": canvas?.width,
                                        "canvasheight": canvas?.height,
                                        "content": finalJSON, 
                                        "thumbnail": thumbnail}),
                    {
                        headers: { 'Content-Type': 'application/json',
                                    'Authorization': bearer_token },
                        withCredentials: true
                    }
                    )
            }

           if (response.status === 201) {
                console.log("Saving to DB Successful!");
                console.log("FILES:", filesForUpload);
                if (filesForUpload.length > 0)  uploadImagestoServer(randomID, filesForUpload);  //if there are user files to upload
                setFileName(tmpfilename);   
                setDialogSave(false);
            }
            console.log(response);
        }  catch (err) {
           if (!err?.response) {
             setErrMsg('No Server Response');
           } else if (err.response?.status === 400) {
             setErrMsg('Missing data');
           } else if (err.response?.status === 401) {
             setErrMsg('Unauthorized');
            } else if (err.response?.status === 500) {
                setErrMsg('Internal Server Error! ');
           } else {
               setErrMsg('Server Error! Sorry about this. We are fixing it.');
           }
           console.log("Error message: ", err);
           setIsSaving(false);
        } 
        setIsSaving(false);
    }

    const uploadImagestoServer = async (randomID, imageFiles) => {
        const formData = new FormData();
        formData.append('userID', auth.userID);
        formData.append('folderID', randomID);
        DEBUG && console.log(randomID);
        Object.keys(imageFiles).forEach(key => {
            formData.append(imageFiles[key].name, imageFiles[key]);
        })

        console.log("formdata", formData);
        const bearer_token = `Bearer ${auth.accessToken}`;
        try {
            const response = await axios.post(API_URL_UPLOAD, formData,
                {
                    headers: { 'content-type': 'multipart/form-data',
                               'Authorization': bearer_token },
                    withCredentials: true
                });
            console.log(response);
            if (response.status === 200)
            console.log("Uploading files successful!")
        }  catch (err) {
            if (!err?.response) {
              //setErrMsg('No Server Response');
              console.log("No server response.")
            } else if (err.response?.status === 400) {
              //setErrMsg('Missing data');
              console.log("400 - Missing Data")
            } else if (err.response?.status === 401) {
             // setErrMsg('Unauthorized');
             console.log("Unauthorized")
            } else {
              //  setErrMsg('Not logged In');
              console.log("Not logged in")
            }
            console.log("Error message: ", err);
            //setIsSaving(false);
         }    
    }

    useEffect(() => {
        setTMPFilename(fileName);
        console.log(fileName);
      // eslint-disable-next-line
      },[]);

    useEffect(() => {
        DEBUG && console.log("as new file", asNewFile);
    },[asNewFile]);

  return (
    <div className="modalBackground">
        <div className="modalContainer" style={{height:"450px"}}>
            <div className="titleCloseBtn">
                <button onClick={() => { setDialogSave(false); }}> X </button>
            </div>
            <div className="title">
                <h4>Save Design as Template</h4>
            </div>
            <div className="body" style={{height:"300px", overflowY: "hidden"}}>
                <div style={{display:"flex",  flexDirection: "column", justifyContent: "left", alignItems:"left",  height:"250px", paddingRight:"5px", width:"100%"}}> 
                    <div style={{width: "100%", textAlign: "center", padding: "10px"}} > 
                        { isSaving ? <ProgressBar 
                                        visible={true}
                                        height="50"
                                        width="80"
                                        color="#097fee"
                                        ariaLabel="progress-bar-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="" /> : "" 
                        }  
                    </div>
                    <div style={{width: "100%", textAlign: "center", padding: "5px"}} > 
                            <span style={{color: "red"}}> {errMsg} </span>
                    </div>
                    <div style={{width: "100%", textAlign: "left", padding: "10px"}} > 
                        {
                            size._id ?  <label> <input type="checkbox" checked={asNewFile} onChange={() => {setAsNewFile(!asNewFile)}} /> Save as New File </label> : ""
                        }
                    </div>
                    <div style={{width: "100%", textAlign: "left", padding: "10px"}} > 
                        <input type="text"  style={{width: "525px", padding: "5px"}} placeholder="Enter File Name" value={tmpfilename} required
                        onChange={e =>  {
                                            setTMPFilename(e.target.value);
                                            setErrMsg(null);
                                        }
                                } 
                        />
                    </div>
                    <div style={{width: "100%", textAlign: "left", padding: "10px"}} > 
                        <label> 
                            Category &nbsp; 
                            <select name="selectedCategory" style={{width: "200px" }} value={category}
                                onChange={e => {
                                    //const options = [...e.target.selectedOptions];
                                    //const values = options.map(option => option.value);
                                    setCategory(e.target.value);
                                }}
                            >
                                <option value="Banner">Banner</option>
                                <option value="Brochure & Flyers">Brochure & Flyer</option>
                                <option value="Business Card">Business Card</option>
                                <option value="Embroidery">Embroidery</option>
                                <option value="ID">ID</option>
                                <option value="Invitation">Invitation</option>
                                <option value="Keycard Holder">Keycard Holder</option>
                                <option value="Mug">Mug</option>
                                <option value="Sticker">Sticker</option>
                                <option value="Sign">Sign</option>
                                <option value="Temp. Car Plate">Temp. Car Plate</option>
                                <option value="T-Shirt">T-Shirt</option>
                                <option value="Yearbook">Yearbook</option>  
                            </select>
                        </label>
                    </div>
                    <div style={{width: "100%", textAlign: "left", padding: "10px"}} >
                        <label> 
                            Keywords <input type="text" style={{width: "450px", padding:"5px"}} placeholder="Enter keywords" value={keywords} required 
                                        onChange={e =>  {
                                            setKeywords(e.target.value);
                                            setErrMsg(null);
                                            }
                                        } 
                                     />
                        </label>
                    </div>
                </div> 
                </div>
            <div className="footer" style={{height:"50px"}}>
                <button onClick={() => {setDialogSave(false);}} id="cancelBtn"> Cancel </button>
                <button onClick={saveDesign} disabled={isSaving} > Save </button>
            </div>
        </div>
    </div>    
  )
}

export default DialogSaveAsTemplate
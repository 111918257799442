import React, {useEffect, useState, useContext} from 'react';
import { FabricContext } from "../../context/FabricContext";
import iconTransparent from "../../images/editor/button_transparent.png";
import _flip from '../tools/_flip';
import _rotate from '../tools/_rotate';
import _gradient from '../tools/_gradient';

import {getActiveStyle, setActiveProp, getActiveProp, setActiveStyle,} from "../libs/utils";

const ShapesMenu = () => {
    const { canvas, activeObject } = useContext(FabricContext);
    const [options, setOptions] = useState({
        left: 100,
        top: 100,
        stroke: "black",
        hoverCursor: 'default',
        strokeWidth: 2,
        fill: 'rgba(0,0,0,0)',
        opacity: 1,
    })

    const updateStrokeColor = (e) => {
      setActiveProp("stroke", e.target.value, activeObject)
     // console.log(e.target.value);
     // console.log(activeObject);
    }

    const updateFillColor = (e) => {
        setActiveProp("fill", e.target.value, activeObject)
        setOptions({
            ...options,
            fill: e.target.value,
        })
       // console.log(e.target.value);
       // console.log(activeObject);
      }
    
      const updateStrokeWidth = (e) => {
        setActiveStyle('strokeWidth', parseInt(e.target.value, 10), activeObject);
        setOptions({
            ...options,
            strokeWidth: e.target.value,
        })
       // console.log(e.target.value);
       // console.log(activeObject);
      }

      const onTransparent = (e) => {
        setActiveStyle('fill', 'rgba(0,0,0,0)', activeObject);
        setOptions({
            ...options,
            fill: e.target.value,
        })
       // console.log(e.target.value);
       // console.log(activeObject);
      }

      const onUpdateOpacity= (e) => {
        setActiveStyle('opacity',  e.target.value/100, activeObject);
        setOptions({
            ...options,
            opacity: e.target.value/100,
        })
       // console.log(e.target.value);
       // console.log(activeObject);
       //console.log(e.target.__corner);
      }

    useEffect(() => {
        //setShowTools(activeObject ? activeObject.get("type") === "line" : false)
        if (activeObject) {
            const activeOptions = {
                stroke: getActiveStyle("stroke", activeObject),
                strokeWidth: getActiveStyle("strokeWidth", activeObject),
            }
            setOptions({ ...options, ...activeOptions })
        }

        
    }, [activeObject])

  return (
    <div>
        <div style={{background: "#fdfcfc", borderRadius: "5px", display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
          <div  className="subMenuTitle" style={{width: "100%"}}> Shape Tools </div>
                    <div style={{display:"flex", fontSize:"10px", width:"115px"}}>
                        
                         {/*stroke color */}
                        <div style={{fontSize:"10px", margin:"auto", height:"24px", paddingTop:"4px", width:"40px", verticalAlign:"middle", textAlign:"left"}}>Stroke: &nbsp; &nbsp;</div> 
                        <div> <input type="color" name="stroke" style={{ "width": "30px" }}
                           value={getActiveStyle("stroke", activeObject)}
                           onChange={updateStrokeColor} 
                           className="btn-object-action"/>
                        </div>

                        {/*stroke width */}
                        <div style={{margin:"auto", paddingTop:"4px"}}>
                            <input type="range" id="stokeWidth" name="strokeWidth" min="0" max="50" style={{ "width": "50px" }}
                            value={options.strokeWidth}
                            onChange={updateStrokeWidth} 
                            className="btn-object-action"/>
                        </div>

                        {/* <input type="number"
                           style={{ "width": "40px", height:"25px" }}
                           className="btn-object-action"
                           name="strokeWidth"
                           min="0"
                           value={options.strokeWidth}
                           onChange={updateStrokeWidth}
                        /> */}
                    </div>

                      {/*FILL Color */}
                    <div style={{display:"flex", fontSize:"10px", width:"60px"}}> 
                        <div style={{fontSize:"10px", margin:"auto", height:"24px", paddingTop:"4px", width:"35px", verticalAlign:"middle", textAlign:"center"}}>Fill: </div> 
                        <div><input type="color" name="fill" style={{ "width": "30px" }}
                           value={getActiveStyle("fill", activeObject)}
                           onChange={updateFillColor} 
                           className="btn-object-action"/>
                        </div>
                    </div>

                     {/*TRANSPARENCY */}
                    <div style={{display:"flex", fontSize:"10px", width:"30px"}}> 
                        <div className='buttonShapes' style={{color:"#010107", margin:"auto", paddingTop: "1px", height:"24px", width:"24px"}} 
                        onClick={onTransparent}><img src={iconTransparent} width="25px" alt="transparent"/> </div>
                    </div>

                     {/*OPACITY */}
                    <div style={{display:"flex", fontSize:"10px", width:"100px"}}> 
                        <div style={{fontSize:"10px", margin:"auto", height:"24px", paddingTop:"4px", width:"35px", verticalAlign:"middle", textAlign:"center"}}>Opacity: </div> 
                        <div style={{margin:"auto", paddingTop:"4px"}}><input type="range" id="opacity" name="opacity" min="0" max="100" style={{ "width": "50px" }}
                           value={getActiveStyle("opacity", activeObject)*100}
                           onChange={onUpdateOpacity} 
                           className="btn-object-action"/>
                        </div>
                    </div>
                    
                    {/*FLIP */}
                    <div style={{fontSize:"10px", width:"60px"}}> 
                        <_flip />
                    </div>

                    {/*ROTATE */}
                    <div style={{fontSize:"10px", width:"60px"}}> 
                        <_rotate />
                    </div>
                    
                    {/*GRADIENT*/}
                    <div style={{fontSize:"10px", width:"30px"}}> 
                        <_gradient />
                    </div>



         </div>
    </div>
  )
}

export default ShapesMenu
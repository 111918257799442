import { useRef, useState, useEffect} from 'react';
import useAuth from './hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ProgressBar } from 'react-loader-spinner';
import Error from './Error';

import axios from '../api/axios';
const LOGIN_URL = '/auth';


const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const emailRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  const [pwd, setPwd] = useState('');
  const [errorPwd, setErrorPwd] = useState(null);

  const [errMsg, setErrMsg] = useState('');
  const DEBUG = false;
  const [isProcessing, setIsProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showDialog, setShowDialog] = useState(false);


  function isValidEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = event => {
      setEmail(event.target.value);
  };

  const handlePwdChange = event => {
    setPwd(event.target.value);
  };

  const handleSubmit = async (event) => {
      event.preventDefault();
      setError(null);
      setErrorPwd(null);

      if (!isValidEmail(email)) {
        //console.log('The email is valid');
        setError('* Email is invalid');
        return;
      } 
      setShowDialog(true);
      setIsProcessing(true);
      setSuccess(false);
      try {
        const response = await axios.post(LOGIN_URL,
            JSON.stringify({ "user": email, pwd }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );
        //console.log(JSON.stringify(response?.data));
        //console.log("FROM URL:", location?.state);
        //console.log(JSON.stringify(response));
        const accessToken = response?.data?.accessToken;
        const roles = response?.data?.roles;
        const userID = response?.data?.userID;
        const fullname = response?.data?.fullname;
        const address = response?.data?.address;
        const contactnumbers = response?.data.contactnum;
        const gender = response?.data.gender;
        const birthdate = response?.data.birthdate;
        setAuth({userID, email, roles, accessToken,fullname, address, contactnumbers, gender, birthdate });
        setEmail('');
        setPwd('');
        
        
        //if (location.state.urlFrom) {
        //  DEBUG && console.log("URL from found!");
        //  navigate(location.state.urlFrom,  { state: location.state.JSON_final} );
        //} else {

        setSuccess(true);
        setIsProcessing(false);
        if (location?.state) {
          navigate(from, { replace: true });
        } else {
          navigate("/profile");
        }
        
        //}
      }  catch (error) {
       /*  if (!err?.response) {
          setErrMsg('No Server Response');
        } else if (err.response?.status === 400) {
            setErrMsg('Missing Username or Password');
        } else if (err.response?.status === 401) {
            setErrMsg('Unauthorized');
        } else {
            setErrMsg('Login Failed');
        }
          errRef.current.focus();
        } */
          DEBUG && console.log("Error connecting to server ...", error.response);
      if (!error.response) { // undefined
        setErrMsg('01');
      } else if (error.response?.status === 400) {
        setErrMsg('03');
      } else if (error.response?.status === 401) { // password incorrect
        setErrMsg('05');
      } else if (error.response?.status === 409) { //user already exist
        setErrMsg('04'); 
      } else if (error.response?.status === 404) { //user not foud
        setErrMsg('02');
      } else {
        setErrMsg("00");
      }
          setIsProcessing(false);
      }
  };

  useEffect(() => {
    emailRef.current.focus();
  }, [])

  useEffect(() => {
      setErrMsg('');
      setError('');
  }, [email, pwd])

  return (
    <> 
        <section>
          <div style={{width: "400px", height: "450px", margin: "auto"}}> 
              <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
              <div style={{width: "300px", height: "400px", borderWidth: "1px", borderColor: "#bbbbca", background: "#f1f1f1", margin: "30px", padding: "10px",
                                  borderStyle: "solid", borderRadius: "20px", textAlign: "center", boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                                  
                <div style={{height: "50px", width: "100%", paddingTop:"10px", color:"rgb(3, 67, 145)"}}> LOG IN TO YOUR ACCOUNT </div>
                    <form onSubmit={handleSubmit}> 
                          <label> 
                            <div style={{width: "200px", textAlign:"left", paddingTop: "10px", color:"rgb(3, 67, 145)", fontSize:"12px"}}> Email Address: </div>
                            <div style={{width: "200px", height:"50px", textAlign:"left"}}>
                                <input type="text" id="email" style={{borderRadius:"1px", borderWidth:"1px", borderColor:"#e9e5e5", color:"#424242", width:"200px", height:"30px"}} 
                                    value={email}
                                    onChange={handleChange}
                                    ref={emailRef}
                                    autoComplete="off"
                                    required
                                /> {error && <span style={{color: 'red', fontSize:"11px"}}>{error}</span>}
                            </div>
                          </label>

                          <label> 
                            <div style={{width: "200px", textAlign:"left", paddingTop: "10px", color:"rgb(3, 67, 145)", fontSize:"12px"}}> Password: </div>
                            <div style={{width: "200px", height:"50px", textAlign:"left"}}> 
                                <input id="password" type="password" style={{borderRadius:"1px", borderWidth:"1px", borderColor:"#e9e5e5", color:"#424242", width:"200px", height:"30px"}} 
                                    value={pwd}
                                    onChange={handlePwdChange}
                                    required
                                /> 
                                {errorPwd && <span style={{color: 'red', fontSize:"11px"}}>{errorPwd}</span>}
                            </div>
                          </label>
                          <div style={{width: "100%", textAlign:"center", paddingTop: "20px"}} >
                              <input type="submit" style={{width: "80px", height:"35px", background:"#2e83f3", borderWidth:"0px", borderRadius:"8px", color:"white", fontSize:"12px" }}/> 
                          </div>
                          <div style={{paddingTop:"10px", fontSize:"13px"}}>Forgot Password? <Link to="/login" className="login-link"> Click here to reset.</Link></div>
                          <div style={{paddingTop:"10px", fontSize:"13px"}}>No account yet? <Link to="/register" className="login-link"> REGISTER here. </Link></div>
                      </form>           
                 </div>
            </div>
            <Modal show={showDialog} backdrop="static" keyboard={true} onHide={showDialog} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header>
                <Modal.Title>Logging in ...</Modal.Title>
              </Modal.Header>
      
              <Modal.Body>
                  
                  { 
                    errMsg ?  <>
                                <div style={{width:"100%", color: "red", fontSize:"18px", textAlign: "center", alignItems:"center", alignContent:"center", justifyContent:"center", margin: "auto" }}>
                                  <Error msg={errMsg}/>
                                </div> 
                                <div style={{textAlign: "center", height: "50px", padding: "10px"}}>
                                  <button className='button_blue' style={{padding: "3px", width: "90px"}} onClick={()=>{setShowDialog(false)}}>OK</button>
                                </div>
                              </>
                           : 
                              <>
                                { isProcessing && <div style={{textAlign: "center"}}> 
                                                   <ProgressBar visible={true} height="80" width="80" color="#097fee" ariaLabel="progress-bar-loading" wrapperStyle={{}} wrapperClass="" />
                                                </div> 
                                }
                                { success && 
                                    <div style={{backgroundColor: "#3570f1", width:"100%", height: "50px", color: "white", fontSize:"18px", textAlign: "center", alignItems:"center", alignContent:"center", justifyContent:"center", margin: "auto" }}>
                                      Successfull! 
                                    </div> 
                                }
                                <div style={{textAlign: "center", height: "50px", padding: "10px"}}>
                                  <button className='button_blue' style={{padding: "3px", width: "90px"}} onClick={()=>{setShowDialog(false)}}>OK</button>
                                </div>
                              </>
                  }
              </Modal.Body>
      
              <Modal.Footer>
                
              </Modal.Footer>
        </Modal>
          </section>
    </>
  )
}

export default Login
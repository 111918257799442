import React, {useContext} from 'react'
import iconUp from "../../images/editor/up.png";
import { FabricContext } from "../../context/FabricContext";

const Up = () => {
  const { canvas, activeObject } = useContext(FabricContext);

    const onUp = () => {
        console.log("1 step higher...");
        canvas.bringForward(activeObject);
    }
  return (
    <div>
        <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} 
              onClick={onUp} ><img src={iconUp} width="24px" alt="Up"/> </div>
    </div>
  )
}

export default Up
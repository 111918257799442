import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import tarp_printer from '../images/carousel/tarp_printer.jpg';
import designer_desk from '../images/carousel/designer_desk.jpg';
import tarp_printer2 from '../images/carousel/tarp_printer2.jpg';
import designer_working from '../images/carousel/designer_working.jpg';
import fuji_versant from '../images/carousel/fuji_versant.jpg';
import printers from '../images/carousel/printers.jpg';
import embroidery_machine1 from '../images/carousel/embroidery_machine1.jpg';
import rolandprinter from '../images/carousel/rolandprinter.jpg';
import slogan from '../images/carousel/slogan.jpg';

import embroidery from '../images/carousel/embroidery2.jpg';
import vinyl_sticker from '../images/carousel/vinyl-stickers.jpg';
import tarpaulins from '../images/carousel/tarpaulins.jpg';
import sintraboards from '../images/carousel/sintraboards.jpg';

const CarouselMain = () => {
  return (
    <div style={{width: "80%", textAlign: "center", margin: "auto"}}>
        <Carousel>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={embroidery}
                    alt="Brother Embroidery Machines"
                />
                <Carousel.Caption style={{backgroundColor:  `rgba(116, 114, 114, 0.5)`}}>
                    <h3>Embroideries</h3>
                    <p>We're using Brother embroidery machines for high quality stitches</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={vinyl_sticker}
                    alt="Vinyl Stickers"
                />
                <Carousel.Caption style={{backgroundColor:  `rgba(116, 114, 114, 0.5)`}}>
                    <h3>Vinyl Stickers</h3>
                    <p>Printed with Roland vinyl sticker printer</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={tarpaulins}
                    alt="Tarpaulins"
                />
                <Carousel.Caption style={{backgroundColor:  `rgba(116, 114, 114, 0.5)`}}>
                    <h3>Tarpaulin Banners</h3>
                    <p>Perfect for business signs, birthdays and other occasions</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={sintraboards}
                    alt="Sintraboards"
                />
                <Carousel.Caption style={{backgroundColor:  `rgba(116, 114, 114, 0.5)`}}>
                    <h3>Sintraboards</h3>
                    <p>Perfect for menus, business signs, plate numbers, standees</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    </div>
  )
}

export default CarouselMain
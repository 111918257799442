import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, ToggleButtonGroup, ToggleButton, Form, Button} from 'react-bootstrap';
import Select from 'react-select';
import { FiTrash2 } from "react-icons/fi";
import { DataContext } from '../context/DataContext';
import { useNavigate } from "react-router-dom";


const Studio = () => {

  const [selectedThemeOption, setSelectedThemeOption] = useState([1,2,3,4,5,6]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [uploadedPhoto, setUploadedPhoto] = useState([]); //photos that are currently uploaded
  const [imageURLs, setImageURLs] = useState([]);  // all the URLS of the uploaded photos
  const [txtContent, setTxtContent] = useState(''); // textarea content

  const { setContentData, setG_imageURL } = useContext(DataContext);

   const activity_options = [
    { value: '1', label: 'Birthday' },
    { value: '2', label: 'Christening' },
    { value: '3', label: 'Reunion' },
    { value: '4', label: 'Signage' },
    { value: '5', label: 'Other Purposes' },
  ]

  const themes = [
    { id: '1', category: 1, label: 'Modern', src: '/images/modern1_web.png' },
    { id: '2', category: 2, label: 'Contemporary', src: '/images/contemporary1_web.png' },
    { id: '3', category: 3, label: '60s 70s 80s', src: '/images/retro1_web.png' },
    { id: '4', category: 4, label: 'Golden', src: '/images/golden1_web.png' },
    { id: '5', category: 5, label: 'Silver', src: '/images/silver1_web.png' },
    { id: '6', category: 6, label: 'Cartoon Character', src: '/images/cartoon1_web.png' },
  ]

  const size = [
    { id: '1', label: "Smallest (2x3 feet)"},
    { id: '2', label: "Small (2x4 feet)"},
    { id: '3', label: "Standard (3x4 feet)"},
    { id: '4', label: "Regular (3x5 feet)"},
    { id: '5', label: "Medium (3x6 feet)"},
    { id: '6', label: "Large (4x5 feet)"},
    { id: '7', label: "Custom Size"}
  ]

  const handleActivitySelect = (e) => {
   // setSelectedActivityOption(e.value);
  }

  const handleUploadButton = (event) => {
    //event.preventDefault();
    setUploadedPhoto([...event.target.files]);    
  }

  useEffect(() => {

    var loopData = [];
    var i ;
    for(i=0; i < selectedThemeOption.length; i++){
        var n;
        for (n=0; n < themes.length; n++) {
          if (selectedThemeOption[i]===themes[n].category) {
            const obj = {'label':themes[n].label, 'src':themes[n].src};
            loopData = [...loopData, obj] ;
            setSelectedTheme(loopData);
          }
        }
    }
    if (selectedThemeOption.length ===0) {
      setSelectedTheme([]);
    }

    if (uploadedPhoto.length < 1) return;
    const newImageURLs = [];
    uploadedPhoto.forEach(image => newImageURLs.push(URL.createObjectURL(image)));
    setImageURLs(oldURLs => oldURLs.concat(newImageURLs)); // add the URLs to the existing values in array
    setUploadedPhoto([]);
    //console.log(newImageURLs.length);
  } , [selectedThemeOption, uploadedPhoto]);


  const handleChange = (e) => {
    //e.preventDefault();
    setSelectedThemeOption(e);
      
    //console.log(themes);
   // console.log(e);
  }

  const handleButtonImageDel = (id) => {
    //e.preventDefault();
   // alert(id);
    var tmpArray = [...imageURLs];
    if (id !== -1) {
      tmpArray.splice(id, 1);
      setImageURLs(tmpArray);
    }
  }

  const handleTxtContent = event => {
    // 👇️ access textarea value
    setTxtContent(event.target.value);
    console.log(event.target.value);
  };

  let navigate = useNavigate(); 

  const handleGenerateDesign = () => {
    const newData = txtContent;
    setContentData(newData);
    setG_imageURL(imageURLs);
    //setYorbsData("Yorbs");
    let path = `/editor`; 
    navigate(path);
  };
  
  return (
    <Container fluid>      
        <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
                &nbsp;
                <div style={{fontSize: "17px", fontWeight:"700",}}> Design Studio </div>
                <div style={{fontSize: "15px", fontWeight:"700",}}>Tarpaulin Banners</div>
                &nbsp;
            </Col>
        </Row>
        
        <Row>
            <Col xxl={4} xl={5} lg={5} md={6} sm={12} xs={12}> 
                  <div style={{textAlign: "right"}}>                
                   Activity / Event / Purpose
                  </div>
            </Col>
            <Col xxl={8} xl={7} lg={7} md={6} sm={12} xs={12} style={{textAlign: "left"}}>
                  <div style={{width: "300px"}} >
                        <Select options={activity_options} onChange={handleActivitySelect} />
                  </div>
            </Col>
        </Row>

        <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
              &nbsp;
              <div style={{backgroundColor:"#deedfa", margin:"auto", borderRadius:"10px"}}>
                THEMES
                <br />
                <ToggleButtonGroup type="checkbox" value={selectedThemeOption} onChange={handleChange}>
                    <ToggleButton id="tbg-btn-1" value={1}>
                       Modern
                    </ToggleButton> &nbsp;
                    <ToggleButton id="tbg-btn-2" value={2}>
                        Contemporary
                    </ToggleButton> &nbsp;
                    <ToggleButton id="tbg-btn-3" value={3}>
                        60s 70s 80s
                    </ToggleButton> &nbsp;
                    <ToggleButton id="tbg-btn-4" value={4}>
                        Golden
                    </ToggleButton> &nbsp;
                    <ToggleButton id="tbg-btn-5" value={5}>
                        Silver
                    </ToggleButton> &nbsp;
                    <ToggleButton id="tbg-btn-6" value={6}>
                        Cartoon Character
                    </ToggleButton> &nbsp;
                </ToggleButtonGroup>
                <br />
                <br />
                {selectedTheme.map((theme) => (
                    selectedTheme.length > 0 ? <img src={theme.src} alt={theme.label} width='200px' /> : "" 
                ))}
                <br />
                <br />
              </div>
            </Col>
        </Row>
        <Row>
            <Col xxl={4} xl={5} lg={5} md={6} sm={12} xs={12}> 
                  <div style={{textAlign: "right"}}>                
                   CONTENT 
                  </div>
            </Col>
            <Col xxl={8} xl={7} lg={7} md={6} sm={12} xs={12} style={{textAlign: "left"}}>
                  <div style={{width: "400px"}} >
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Text className="text-muted">
                          Type the content here ...
                        </Form.Text>
                        <Form.Control as="textarea" id="content" value={txtContent} onChange={handleTxtContent} rows={4} />
                      </Form.Group>
                      
                    </Form>   
                  </div>
            </Col>
        </Row>
        <Row>
            <Col xxl={4} xl={5} lg={5} md={6} sm={12} xs={12}> 
                  <div style={{textAlign: "right"}}>                
                   PICTURES 
                  </div>
            </Col>
            <Col xxl={8} xl={7} lg={7} md={6} sm={12} xs={12} style={{textAlign: "left"}}>
                <Form>
                  <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Text className="text-muted">
                          Upload your pictures here ... 
                    </Form.Text>
                    <Form.Control type="file" multiple  onChange={handleUploadButton} />
                  </Form.Group>
                </Form> 
              </Col>
        </Row>
        <Row>
            <Col xxl={3} xl={5} lg={5} md={6} sm={12} xs={12}> 
              &nbsp;
            </Col>
            <Col xxl={9} xl={7} lg={7} md={6} sm={12} xs={12} style={{textAlign: "left"}} >   
              <div style={{display:"flex",  flexDirection:"row", flexWrap:"wrap", width:"900px", textAlign: "center"}}>
                {imageURLs.map((photos, index) => (
                          <div style={{width:"120px", margin:"auto",  position:"relative", display:"inline-block"}}>
                               <div style={{position:"absolute", right:"0", zIndex:"5"}}>
                                  <Button style={{backgroundColor:"transparent", borderBlockColor:"white", border:"0px"}} value={index} onClick={()=>handleButtonImageDel(index)}><FiTrash2 /></Button>
                               </div>
                              <img  src={photos} alt="Image_Photo" width="120px" height="100px" style={{padding:"5px", borderRadius:"15px"}}/>  
                          </div>
                ))} 
              </div>
            </Col>
        </Row> 
        <Row>
            <Col xxl={4} xl={5} lg={5} md={6} sm={12} xs={12}> 
                  <div style={{textAlign: "right"}}>                
                   SIZE 
                  </div>
            </Col>
            <Col xxl={8} xl={7} lg={7} md={6} sm={12} xs={12} style={{textAlign: "left"}}>
                <Form>
                  <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Text className="text-muted">
                          Set the WIDTH and HEIGHT of the design 
                    </Form.Text>
                    <div style={{width: "300px"}} >
                        <Select options={size} onChange={handleActivitySelect} />
                  </div>
                  </Form.Group>
                </Form> 
              </Col>
        </Row>
        <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
              &nbsp;
             {/* <Button as={Link} to={{pathname: "/editor", state: {textContent: "Yorbs Grapo"}}}>Generate Design</Button> */}
             {/* <Link to={{
                pathname: '/editor',
                  state: { textContent: "Yorbs Gwapo!" }
                }}>
                Generate Design
             </Link> */}
              <Button onClick={handleGenerateDesign}>Generate Design</Button>
            </Col>
        </Row>

            &nbsp;
            <br />
            &nbsp;
    </Container>
  )
}

export default Studio
import React, {useState, useContext, useEffect} from 'react';
import iconSale50 from "../../images/editor/texts/sale50.png";
import { FabricContext } from "../../context/FabricContext";
import { fabric } from 'fabric';


const TextSale50 = () => {
    const { canvas } = useContext(FabricContext)
    const [mousePosition, setMousePosition] = useState({x:100, y: 100})
    const [isDrag, setisDrag] = useState(false);
    const [text1Options, setText1Options] = useState({
        width: 80,
        top: 289,
        left: 299,
        fontSize: 90,
        fontWeight: "bold",
        fontStyle: "normal",
        textAlign: "center",
        fontFamily: "Bebas Neue",
        textDecoration: "normal",
        fill: "#ff0505"
    })

    const onAddTextBox = () => {
        if (isDrag) {
            var txtWidth = text1Options.width / 2;  // to center object on the mouse position 
            setText1Options({...text1Options,
                 top: mousePosition.y,
                 left: mousePosition.x-txtWidth,
               }) 
              setisDrag(false); 
             } else {
             //  isDrag_ = false;
         }      

        const textBox = new fabric.Textbox("50", text1Options)
        canvas.add(textBox);

         if (!isDrag) {
            canvas.centerObject(textBox); 
        }

        var options2 = {
            width: 40,
            top: textBox.top+5,
            left: textBox.left + textBox.width-5,
            fontSize: 50,
            fontWeight: "bold",
            fontStyle: "normal",
            textAlign: "center",
            fontFamily: "Bebas Neue",
            textDecoration: "normal",
            fill: "black",
        };
        const textBox2 = new fabric.Textbox("%", options2)
        canvas.add(textBox2);

        var options3 = {
            width: 40,
            top: textBox.top + textBox2.height - 5,
            left: textBox.left + textBox.width-5,
            fontSize: 30,
            fontWeight: "bold",
            fontStyle: "normal",
            textAlign: "center",
            fontFamily: "Bebas Neue",
            textDecoration: "normal",
            fill: "black",
        };
        const textBox3 = new fabric.Textbox("OFF", options3)
        canvas.add(textBox3);

        var options4 = {
            width: 80,
            top: textBox.top - 4,
            left: textBox.left + 15,
            fontSize: 15,
            fontWeight: "normal",
            fontStyle: "normal",
            textAlign: "center",
            fontFamily: "Arial",
            textDecoration: "normal",
            fill: "black",
        };
        const textBox4 = new fabric.Textbox("UP TO", options4)
        canvas.add(textBox4);

        var sel = new fabric.ActiveSelection([textBox, textBox2, textBox3, textBox4], {
            canvas: canvas,
        });
        canvas.setActiveObject(sel);
        canvas.renderAll();

    }

    const onDragEndTextBox = (e) => {
        var txtWidth = text1Options.width / 2; 
        setText1Options({...text1Options,
            top: mousePosition.y,
            left: mousePosition.x - txtWidth,
          })
       // const textBox = new fabric.Textbox("Congratulations!", text1Options);
      //  canvas.add(textBox).setActiveObject(textBox);    
        onAddTextBox();
        canvas.off("dragover");
    }


    const onDragTextBox = (e) => {
        canvas.on("dragover", function(options) {
            getMouseCoords(options);   
          }) 
        setisDrag(true);
    }

    function getMouseCoords(event)
      {
        var pointer = canvas.getPointer(event.e);
        var posX = pointer.x;
        var posY = pointer.y;
        setMousePosition({"x": posX, "y": posY });
      //  console.log("getmouse", posX+", "+posY);    // Log to console
      }

     useEffect(() => {
        var txtWidth = text1Options.width / 2; 
        setText1Options({...text1Options,
          top: mousePosition.y,
          left: mousePosition.x - txtWidth,
        })
      //  setisDrag(true);
     }, [mousePosition.x, mousePosition.y]);


    return (
        <div>
                <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"66px", width:"148px"}} 
                       onClick={onAddTextBox} onDragStart={onDragTextBox} onDragEnd={onDragEndTextBox}><img src={iconSale50} width="88px" alt="Up to 50 % sale!"/> </div>
        </div>
    )
}
  
export default TextSale50
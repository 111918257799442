import React, {useContext, useState, useEffect} from 'react';
import { FabricContext } from "../../context/FabricContext";
import {getActiveStyle, setActiveStyle,} from "../libs/utils";


const _textSize = () => {
    const { activeObject } = useContext(FabricContext);
    const [options, setOptions] = useState({"fontSize": 10});

    useEffect(() => {
         if (activeObject) {
            const activeOptions = {
                fontSize: getActiveStyle("fontSize", activeObject),
            }
            setOptions({ ...options, ...activeOptions })
        }
    }, [activeObject])

    const updateFontSize = (e) => {
        setOptions({
            ...options,
            fontSize: e.target.value,
        })
        setActiveStyle("fontSize", parseInt(e.target.value, 10), activeObject)
    }


  return (
          <div style={{margin:"auto", padding:"3px"}}>
               <span style={{fontSize:"12px"}}> Size </span> &nbsp; <input type="number"
                    style={{ "width": "40px", fontSize:"12px" }}
                    className="btn-object-action"
                    name="fontSize"
                    min="1"
                    max="200"
                    value={options.fontSize}
                    onChange={updateFontSize}
                />          
          </div>
  )
}

export default _textSize
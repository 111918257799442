import React, {useState, useContext, useEffect} from 'react';
import iconLock from "../../images/editor/lock.png";
import iconUnLock from "../../images/editor/unlock.png";
import { FabricContext } from "../../context/FabricContext";


const Lock = () => {
    const { canvas, activeObject } = useContext(FabricContext);
    const [ icon, setIcon ] = useState(iconLock);


    const onLock = () => {  
        console.log("Locking object...");
        if (!activeObject) return; 
        if(activeObject.type === 'activeSelection'){   // group selection
            activeObject._objects.forEach(function(item) {
                if (icon===iconUnLock) { 
                    item.lockMovementX = true;
                    item.lockMovementY = true;
                    item.lockRotation = true;
                    item.lockScalingX = true;
                    item.lockScalingY = true;
                    setIcon(iconLock);
                } else {
                    item.lockMovementX = false;
                    item.lockMovementY = false;
                    item.lockRotation = false;
                    item.lockScalingX = false;
                    item.lockScalingY = false;
                    setIcon(iconUnLock);
                }
            });
            canvas.discardActiveObject().renderAll();
        } else {  // individual selection
            if (icon===iconUnLock) { 
                activeObject.lockMovementX = true;
                activeObject.lockMovementY = true;
                activeObject.lockRotation = true;
                activeObject.lockScalingX = true;
                activeObject.lockScalingY = true;
                setIcon(iconLock);
            } else {
                activeObject.lockMovementX = false;
                activeObject.lockMovementY = false;
                activeObject.lockRotation = false;
                activeObject.lockScalingX = false;
                activeObject.lockScalingY = false;
                setIcon(iconUnLock);
            }
            canvas.discardActiveObject().renderAll();
        }
               
    // console.log("selected", activeObject.lockMovementX) 
    }

    useEffect(() => {
        if (!activeObject) return; 
        if(activeObject.type === 'activeSelection'){  
            activeObject._objects.forEach(function(item) {
                if (item.lockMovementX || item.lockMovementY || item.lockRotation || item.lockScalingX || item.lockScalingY) { 
                    setIcon(iconLock);
                } else {
                    setIcon(iconUnLock);
                }
            })
        } else {
            if (activeObject.lockMovementX || activeObject.lockMovementY || activeObject.lockRotation || activeObject.lockScalingX || activeObject.lockScalingY) { 
                setIcon(iconLock);
            } else {
                setIcon(iconUnLock);
            }

        }

    }, [activeObject]);

  return (
    <div>
        <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} 
              onClick={onLock} ><img src={icon} width="24px" alt="Lock Object"/> </div>
    </div>
  )
}

export default Lock
import { useLocation } from "react-router-dom";

const Header = () => {
   const { pathname } = useLocation();
   if (pathname === "/editor") return null;
   if (pathname === "/editornew") return null;
    return (
        <div className="user_header">
        FREE DELIVERY within Tagbilaran City, Bohol for orders P 300.00 and above!   
        </div>
    );
}

export default Header;
import React, {useContext, useState, useEffect} from 'react';
import { fabric } from 'fabric';
import { FabricContext } from "../../context/FabricContext";
import {getActiveStyle, setActiveStyle,} from "../libs/utils";
import iconGradient from "../../images/editor/gradient.png";


const _gradient = () => {
  const { activeObject } = useContext(FabricContext);
  const [showMenu, setShowMenu] = useState(false);
  const [gradientColor1, setgradientColor1] = useState("white"); // useState(getActiveStyle("fill", activeObject).colorStops[0].color); //
  const [gradientColor2, setgradientColor2] = useState("white");
  const [gradientColor3, setgradientColor3] = useState("white");
  const [x1Value, setX1Value] = useState(0);


    const onGradient = (e) => {
        setShowMenu(!showMenu);
    }

    const onGradientColor1 = (e) => {
     // setActiveStyle("fill", activeObject).colorStops[0].color;
     setgradientColor1(e.target.value);
     var gradient = new fabric.Gradient({
        type: 'linear',
        gradientUnits: 'pixels', // or 'percentage'
        coords: { 
                  x1: x1Value, 
                  y1: 0, 
                  x2: 0, 
                  y2: activeObject.height },
        colorStops:[
          { offset: 0, color: e.target.value },
          { offset: .5, color: gradientColor2 },
          { offset: 1, color: gradientColor3 }
        ]
      })
      setActiveStyle('fill', gradient, activeObject);
      console.log("object height: ", activeObject.height);
    }

    const onGradientColor2 = (e) => {
      setgradientColor2(e.target.value);
      var gradient = new fabric.Gradient({
        type: 'linear',
        gradientUnits: 'pixels', // or 'percentage'
        coords: { x1: x1Value, y1: 0, x2: 0, y2: activeObject.height },
        colorStops:[
          { offset: 0, color:  gradientColor1},
          { offset: .5, color: e.target.value },
          { offset: 1, color:  gradientColor3 }
        ]
      })
      setActiveStyle('fill', gradient, activeObject);
    }

    const onGradientColor3 = (e) => {
      setgradientColor3(e.target.value);
      var gradient = new fabric.Gradient({
        type: 'linear',
        gradientUnits: 'pixels', // or 'percentage'
        coords: { x1: x1Value, y1: 0, x2: 0, y2: activeObject.height },
        colorStops:[
          { offset: 0, color:  gradientColor1},
          { offset: .5, color:  gradientColor2},
          { offset: 1, color:  e.target.value }
        ]
      })
      setActiveStyle('fill', gradient, activeObject);
    }

    const setGradientAngle = (e) => {
      if (activeObject && activeObject.fill.colorStops !== undefined ) {
        console.log(e.target.value);
        var gradient = new fabric.Gradient({
          type: 'linear',
          gradientUnits: 'pixels', // or 'percentage'
          coords: { x1: e.target.value, y1: e.target.value, x2: 0, y2: activeObject.height },
          colorStops:[
            { offset: 0, color:  gradientColor1},
            { offset: .5, color:  gradientColor2},
            { offset: 1, color:  gradientColor3 }
          ]
        })
        setActiveStyle('fill', gradient, activeObject);
        setX1Value(e.target.value);
      }
    }

   useEffect(() => {
      if (activeObject && activeObject.fill.colorStops !== undefined ) {
          setgradientColor1(getActiveStyle("fill", activeObject).colorStops[0].color);
          setgradientColor2(getActiveStyle("fill", activeObject).colorStops[1].color);
          setgradientColor3(getActiveStyle("fill", activeObject).colorStops[2].color);
          setX1Value(getActiveStyle("fill", activeObject).coords.x1);
          console.log(activeObject.fill.coords.x1);
      } else {
        setgradientColor1("white");  
        setgradientColor2("white");  
        setgradientColor3("white");  
        setX1Value(0);
      }
  },[activeObject] )
 
  return (
    <div style={{position:"relative"}}>
         <div style={{margin:"auto", paddingTop:"0px"}}>
                        <div className='buttonShapes' style={{color:"#010107", margin:"auto", paddingTop: "1px", height:"24px", width:"24px"}} 
                        onClick={onGradient}><img src={iconGradient} width="24px" alt="Rotate CW"/> </div>
        </div>
        { showMenu && 
            <div id="myDropdown" style={{position:"absolute",  backgroundColor: "#f1f1f1", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)", zIndex: 1, width:"130px", padding:"5px"}}>
                <input type="color" name="gradient1" style={{ "width": "30px" }} className="btn-object-action" 
                    value={gradientColor1} onChange={onGradientColor1}/> &nbsp; &nbsp; 
                <input type="color" name="gradient2" style={{ "width": "30px" }} className="btn-object-action" 
                    value={gradientColor2} onChange={onGradientColor2}/> &nbsp; &nbsp; 
                <input type="color" name="gradient3" style={{ "width": "30px" }} className="btn-object-action" 
                    value={gradientColor3} onChange={onGradientColor3}/> &nbsp; &nbsp; 
                <input type="range" id="gradAngle" name="gradAngle" min="0" max={activeObject?.height} style={{ "width": "120px" }} 
                    value={x1Value} onChange={setGradientAngle} className="btn-object-action"/>
            </div>
        }
    </div>
  )
}

export default _gradient
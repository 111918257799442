import axios from '../../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const DEBUG = false;

    const refresh = async () => {

        try { 
            const response = await axios.get('/refresh', {
                withCredentials: true
            });
            DEBUG && console.log("refreshToken");
            setAuth(prev => {
                //console.log(JSON.stringify(prev));
                //console.log(response.data.accessToken);
                return {
                    ...prev,
                    roles: response.data.roles,
                    accessToken: response.data.accessToken,
                    email: response.data.email,
                    userID: response.data.userID,
                    fullname: response.data.fullname,
                    address: response.data.address,
                    contactnumbers: response.data.contactnum,
                    gender: response.data.gender,
                    birthdate: response.data.birthdate
                }
            });
            return response.data.accessToken;
        } catch (err) {
            if (!err?.response) {
              //setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
               // setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
               console.error("Unauthorized");
               setAuth({});
            } else if (err.response?.status === 403) {
                console.error("Unauthorized");
                setAuth({});
            } else {
               // setErrMsg('Login Failed');
            }
        }
    }
    return refresh;
};

export default useRefreshToken;
import React, {useState, useContext} from 'react';
import circle from "../../images/editor/circle.png";
import { FabricContext } from "../../context/FabricContext";
import { fabric } from 'fabric';
import {getActiveStyle, setActiveStyle, getActiveProp} from "../libs/utils";

const FabricCircle = () => {
    const { canvas, activeObject } = useContext(FabricContext);
    const [options, setOptions] = useState({
      top: 400,
      left: 200,
      radius: 50,
      border: 10,
      fill: 'rgba(0,0,0,0)',
      stroke: 'black',
      strokeWidth: 3,
      strokeUniform: true,
      originX: 'center',
      originY: 'center',
    })
    const [mousePosition, setMousePosition] = useState({x:100, y: 100});

    const onAddCircle = () => {
       var circle = new fabric.Circle(options); 
       canvas.add(circle);

       canvas.centerObject(circle);
       let count = canvas.getObjects().length;
       canvas.setActiveObject(canvas.item(count-1));
       //console.log(canvas.getObjects().length);
    }

    const getMouseDropPosition = (e) =>  {
        //console.log(e.clientY);
     } 

    const onDragCircle = (e) => {
     // setMousePosition({"x": e.clientX, "y": e.clientY});
     // canvas.on("drop", function(options) {
     //   setMousePosition({"x": options.e.clientX, "y": options.e.clientY});
      //  console.log(options.e.clientX, options.e.clientY);
    //  })
    canvas.on("dragover", function(options) {
        getMouseCoords(options);   
      })
    }

    const onDragEndCirle = (e) => {
     // canvas.on("dragover", function(options) {
     //    getMouseCoords(options);   
     // })
    
      var circle = new fabric.Circle({
            top: mousePosition.y,
            left: mousePosition.x,
            radius: 50,
            border: 10,
            fill: 'rgba(0,0,0,0)',
            stroke: 'black',
            strokeWidth: 3,
            strokeUniform: true,
            originX: 'center',
            originY: 'center',
      }); 
       canvas.add(circle);

      let count = canvas.getObjects().length;
      canvas.setActiveObject(canvas.item(count-1));

    canvas.off("dragover");

    }

    function getMouseCoords(event)
      {
        var pointer = canvas.getPointer(event.e);
        var posX = pointer.x;
        var posY = pointer.y;
        setMousePosition({"x": posX, "y": posY });
        //console.log("getmouse", posX+", "+posY);    // Log to console
      }

   // useEffect(() => {

   // }, [mousePosition.x, mousePosition.x]);

  return (
    <div>
        <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onAddCircle} onDragStart={onDragCircle} onDragEnd={onDragEndCirle}><img src={circle} width="30px" alt="circle"/> </div>
    </div>
  )
}

export default FabricCircle
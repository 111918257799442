import React, {useContext} from 'react'; 
import iconDownload from "../../images/editor/download.png";
import { FabricContext } from "../../context/FabricContext";

const Download = ({showDialog}) => {
    // const { canvas, fileName } = useContext(FabricContext);
    
    const onDownload = () => {
    //   let a = document.createElement('a');
    //   var dataURL = canvas.toDataURL({
    //     format: 'png',
    //     quality: 1,
    //     multiplier: 5
    //   });

    //   dataURL = dataURL.replace(/^data:image\/[^;]*/, 'data:application/octet-stream')
    //   dataURL = dataURL.replace(
    //     /^data:application\/octet-stream/,
    //     'data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=Canvas.png',
    //   )

    //   a.href = dataURL
    //   a.download = fileName + '.png';
    //   a.click()

    //   console.log("downloading ...", dataURL);
    showDialog(true);
    }

  return (
    <div>
      <div className='buttonAddToCart' style={{margin:"0px", padding:"3px", height:"30px", width:"100px"}} 
                    onClick={onDownload} ><img src={iconDownload} width="24px" alt="Download"/> Download </div>
    </div>
  )
}

export default Download
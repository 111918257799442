import React, {useContext} from 'react';
import { FabricContext } from "../../context/FabricContext";
import {getActiveStyle, setActiveProp, getActiveProp, setActiveStyle,} from "../libs/utils";
import iconFlipY from "../../images/editor/flipY.png";
import iconFlipX from "../../images/editor/flipX.png";

const _flip = () => {
    const { activeObject } = useContext(FabricContext);

    const onFlipY = (e) => {
        setActiveProp('flipY',  !getActiveProp("flipY", activeObject), activeObject);
        var angle = getActiveStyle("angle", activeObject);
        var negangle = 0 - angle;
        setActiveStyle('angle', negangle, activeObject);
       // console.log(getActiveProp("angle", activeObject));
    }
    
    const onFlipX = (e) => {
        setActiveProp('flipX',  !getActiveProp("flipX", activeObject), activeObject);
        var angle = getActiveStyle("angle", activeObject);
        var negangle = 0 - angle;
        setActiveStyle('angle', negangle, activeObject);
       // console.log(getActiveProp("angle", activeObject));
    }

  return (
    <div style={{display:"flex", flexDirection:"row"}}>
         <div style={{margin:"auto", paddingTop:"0px"}}>
                        <div className='buttonShapes' style={{color:"#010107", margin:"auto", paddingTop: "1px", height:"24px", width:"24px"}} 
                        onClick={onFlipY}><img src={iconFlipY} width="24px" alt="Flip Vertical"/> </div>
        </div>
        <div style={{margin:"auto", paddingTop:"0px"}}>
                        <div className='buttonShapes' style={{color:"#010107", margin:"auto", paddingTop: "1px", height:"24px", width:"24px"}} 
                        onClick={onFlipX}><img src={iconFlipX} width="24px" alt="Flip Horizontal"/> </div>
        </div>
    </div>

  )
}

export default _flip
const Security = () => {
    return ( <>
        <div className="new-order-container" style={{backgroundColor:"#e9f3fc", display: "flex", flexDirection: "row", flexWrap: "wrap", 
        justifyContent:"center", margin: "10px auto 10px auto", alignItems: "flexStart", boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>  
      
      <div style={{width: "100%", height:"50px"}}>
            <div style={{fontSize: "17px", fontWeight:"400", color: "#1A226D", margin: "auto", textAlign: "center", paddingTop: "10px"}}> 
                Security & Privacy Policy  
            </div>
      </div>

      <div style={{backgroundColor: "white", width:"100%", display:"flex", flexDirection:"row", flexWrap: "wrap", 
                    borderWidth:"1px", borderColor:"#e9e8e8", padding:"15px 0px 15px 0px", fontSize: "15px", fontWeight:"400", color:"#8a8888", 
                    textAlign:"center", alignItems:"center", alignContent:"center", justifyContent:"center", margin: "auto" }}>   
                We believe that your privacy and confidentiality is very important. <br />
                Consequently, we have set a very high standard in safeguarding your personal information and <br />
                will protect the information you share with us. Our privacy applies to all information that we  <br />
                collect at this website, in telephone conversations, e-mail transactions, and through in-person order placement.  <br />
                The content of your documents will remain private. <br />
                <br />
                Your Responsibility <br />
                When using this website, you accept and agree to the terms and conditions of this security and privacy policy.  <br />
                To do a better job protecting your privacy needs, and to adapt to new laws, legal standards,  <br />
                and printing industry standards and customs, we will periodically review our Security and Privacy Policy.  <br />
                We reserve the right, at our sole discretion, to make changes, modifications, alterations, and updates to this  <br />
                policy at any time. If changes are made, we will not contact you. It is your responsibility to  <br />
                personally check the Security and Privacy Policy for changes, modifications, alterations, and updates. <br />
                <br />
                Customer Information <br />
                When you become our customer, you may be asked to provide us with certain information, <br />
                including your company name, your name, and your e-mail address. <br />
                This information will be used for unspecified updates and communications about your order,  <br />
                questionnaires to measure your satisfaction, and announcements of new products, services,  <br />
                and special pricing promotions. <br />
                <br />
                Security and Privacy <br />
                We will not share any of your company or personal information or data with any of our partners or vendors.  <br />
                It is our policy to protect your information from all types of loss, misuse, or alteration. <br />
                <br />
                Social Media <br />
                Our site includes social media features such as Facebook “Like” and “Share” buttons. <br />
                These features may collect your IP address, which page you are visiting on our site,  <br />
                and may set a cookie to enable the feature to function properly. Social media features are  <br />
                either hosted by a third party or hosted directly on our site. Your interactions with these  <br />
                features are governed by the privacy policy of the company providing it. <br />
                <br />
                Cookie Files<br />
                In order to provide you with the best website experience possible, we may store non-critical <br />
                information about you by embedding cookies on your computer hard drive.<br />
                Some of our strategic partners, such as those with links to our website, may use cookies, <br />
                but we are not responsible for the abuse or misuse of any information gathered through the use <br />
                of cookies by such third parties.<br />
                <br />
                Free Newsletters, Announcements, Contests, and Surveys <br />
                We may contact you from time to time to provide you with a free newsletter or to make you aware of  <br />
                special offers, pricing promotions, surveys, or contests. Your participation is strictly voluntary.  <br />
                You may request that your name be removed from our list at any time. Any information we may gather by  <br />
                these means will be used to provide better service and customer-care. We will not share  <br />
                any of this information with others. <br />
                <br />
                If you have any questions, comments, or feedback about our Security and Privacy Policy, please contact us.

       </div>

        </div>
        
        </>
    );
}

export default Security;
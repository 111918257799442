import React, {useState, useContext} from 'react';
import { FabricContext } from "../../../context/FabricContext";
import {getActiveStyle, setActiveStyle, getActiveProp} from "../../libs/utils";

const CropReset = () => {
    const {activeObject, canvas } = useContext(FabricContext);
    const DEBUG = false;
    const onCropReset = () => {
        DEBUG && console.log("RESETTING CROP...");
        DEBUG && console.log(getActiveProp('cropX', activeObject));
        DEBUG && console.log(activeObject);
        activeObject.set('cropX', 0);
        activeObject.set('cropY', 0);
        activeObject.set('width', activeObject.getOriginalSize().width);
        activeObject.set('height', activeObject.getOriginalSize().height);

        DEBUG && console.log (activeObject.getOriginalSize());
        //activeObject.setCoords();
        canvas.renderAll();
    }

  return (

        <div style={{paddingTop:"0px"}}>
            <button style={{fontSize:"11px", marginLeft: "5px", height:"24px", 
                    paddingTop:"0px", width:"80px", verticalAlign:"middle", borderColor:"#9e9d9d", borderStyle:"solid", borderWidth:"1px",}} onClick={onCropReset} > Reset Crop </button>
        </div>

  )
}

export default CropReset
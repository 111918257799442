import React from 'react';
import iconSave from "../../images/editor/save.png";

const Save = ({showDialogSave}) => {  //calling showDialog function in parent (editor2)

    const onSave = () => {
       showDialogSave(true);
    }

  return (
    <div>
      <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} 
                    onClick={onSave} ><img src={iconSave} width="24px" alt="Save"/> </div>
    </div>
  )
}

export default Save
import React, {useContext} from 'react';
import { FabricContext } from "../../context/FabricContext";
import iconDuplicate from "../../images/editor/duplicate.png"

const Duplicate = () => {
    const DEBUG = true;
    const { canvas, activeObject } = useContext(FabricContext);

    const onDuplicate = () => {
        if (!activeObject) return;

        canvas.getActiveObject().clone((cloned) => {
            //console.log(cloned);
            cloned.set({
               left: cloned.left + 20,
               top: cloned.top + 20,
               evented: true,
           });
      
           if (cloned.type === 'activeSelection') {
              cloned.canvas = canvas;
              cloned.forEachObject(function(obj) {
                  canvas.add(obj);
              })
              cloned.setCoords();
           } else {
              canvas.add(cloned);
           }
            canvas.setActiveObject(cloned);
            canvas.requestRenderAll();
            canvas.saveUndo();
          })
          
           DEBUG && console.log(canvas.getObjects());

    }

    return (
        <div>
            <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} 
                  onClick={onDuplicate} ><img src={iconDuplicate} width="24px" alt="Duplicate Object"/> </div>
        </div>
      )
}

export default Duplicate
import React, {useContext} from 'react'; 
import iconUndo from "../../images/editor/undo.png";
import { FabricContext } from "../../context/FabricContext";

const Undo = () => {
    const { canvas, undoData} = useContext(FabricContext);
    const DEBUG = true;
    //const { undoData, setUndoData, setUndoLock } = useContext(UndoRedoContext);

    const onUndo = () => {
        
        if (undoData.length > 0) {
          DEBUG && console.log("Undoing ...");
          canvas.saveRedo();
          canvas.undo();
          DEBUG && console.log("undodta length:", undoData.length);
        } else {
          DEBUG && console.log("No undo data", undoData.length);
        }
    }
  return (
    <div>
        <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} 
                  onClick={onUndo} ><img src={iconUndo} width="24px" alt="Undo"/> </div>
    </div>
  )
}

export default Undo
import React, {useContext, useState} from 'react';
import { FabricContext } from "../../../context/FabricContext";
import { fabric } from 'fabric';
import iconCircle from "../../../images/editor/circle2.png";
import iconHeart from "../../../images/editor/heart2.png";
import iconStar from "../../../images/editor/star2.png";
import iconRecRounded from "../../../images/editor/rect_rounded2.png"
import iconPentagon from "../../../images/editor/pentagon2.png";
import iconClip1 from "../../../images/editor/clip1.png";
import iconClip2 from "../../../images/editor/clip2.png";
import iconClip3 from "../../../images/editor/clip3.png";
import iconClip4 from "../../../images/editor/clip4.png";
import iconClip5 from "../../../images/editor/clip5.png";
import iconClip6 from "../../../images/editor/clip6.png";
import iconClip7 from "../../../images/editor/clip7.png";


const ClipPath = () => {
    const {activeObject, canvas } = useContext(FabricContext);
    const DEBUG = false;
    const [showMenu, setShowMenu] = useState(false);
    

    const onClipCircle = () => {
        onClearClip();
        var clipRadius =  activeObject.height < activeObject.width ? activeObject.height/2 : activeObject.width/2;
        var clipTop = 0 - clipRadius;
        var clipPath = new fabric.Circle({ radius: clipRadius, 
                                           top: clipTop,
                                           left: clipTop,
                                           stroke: "#f10f0f", strokeWidth: 5, border: 10});
        activeObject.clipPath = clipPath;
        canvas.renderAll();
    }

    const onClipHeart = () => {
        onClearClip();
        
        var heartPath = new fabric.Path('M 272.70141,238.71731 \
            C 206.46141,238.71731 152.70146,292.4773 152.70146,358.71731  \
            C 152.70146,493.47282 288.63461,528.80461 381.26391,662.02535 \
            C 468.83815,529.62199 609.82641,489.17075 609.82641,358.71731 \
            C 609.82641,292.47731 556.06651,238.7173 489.82641,238.71731  \
            C 441.77851,238.71731 400.42481,267.08774 381.26391,307.90481 \
            C 362.10311,267.08773 320.74941,238.7173 272.70141,238.71731  \
            z ');
        
        heartPath.set({left: 0, top: 0, scaleX: activeObject.scaleX, scaleY: activeObject.scaleY, originX: "center", originY: "center" });
        var clipWH =  activeObject.height < activeObject.width ? activeObject.height : activeObject.width;
        activeObject.height < activeObject.width ? heartPath.scaleToHeight(clipWH) : heartPath.scaleToWidth(clipWH)
        
        activeObject.clipPath = heartPath;
        canvas.renderAll();
        //DEBUG && console.log("heartPath", heartPath.width, heartPath.height, heartPath.originX, heartPath.originY, heartPath.left, heartPath.top, heartPath.scaleX);
        //DEBUG && console.log("activeObject", activeObject.width, activeObject.height, activeObject.originX, activeObject.originY, activeObject.scaleX, activeObject.scaleY);
    }

    const onClipStar = () => {
        onClearClip();
        var points = [
            { x: 349.9, y: 75, },
            { x: 379, y: 160.9,},
            { x: 469, y: 160.9,},
            { x: 397, y: 214.9,},
            { x: 423, y: 300.9,},
            { x: 350, y: 249.9,},
            { x: 276.9, y: 301,},
            { x: 303, y: 215,},
            { x: 231, y: 161,},
            { x: 321, y: 161,},
         ];

         var options = ({
            left: 0,
            top: 0,
            scaleX: 1,
            scaleY: 1,
            originX: 'center',
            originY: 'center',
            angle: 0
          });

          const star = new fabric.Polygon(points, options);

          var clipWH =  activeObject.height < activeObject.width ? activeObject.height : activeObject.width;
          activeObject.height < activeObject.width ? star.scaleToHeight(clipWH) : star.scaleToWidth(clipWH)

          activeObject.clipPath = star;
          canvas.renderAll();
    }

    const onRecRounded = () => {
        onClearClip();
        const options = { 
            top: 0,
            left: 0,
            width: 100,
            height: 100,
            rx: 15,
            ry: 15,
            originX: 'center',
            originY: 'center',
          };

          var rect = new fabric.Rect(options);
          var clipWH =  activeObject.height < activeObject.width ? activeObject.height : activeObject.width;
          activeObject.height < activeObject.width ? rect.scaleToHeight(clipWH) : rect.scaleToWidth(clipWH)

          activeObject.clipPath = rect;
          canvas.renderAll();
    }

    const onPentagon = () => {
        onClearClip();
        const options = { 
            left: 0, 
            top: 0,
            scaleX: 1, 
            scaleY: 1,   
            originX: 'center',
            originY: 'center'}
        var pentagonPath = new fabric.Path('M23.56 27.5H6.52L0.495 11.93 15 1.25l14.5 10.68z');    
        pentagonPath.set(options);

        var clipWH =  activeObject.height < activeObject.width ? activeObject.height : activeObject.width;
        activeObject.height < activeObject.width ? pentagonPath.scaleToHeight(clipWH) : pentagonPath.scaleToWidth(clipWH);

        activeObject.clipPath = pentagonPath;
        canvas.renderAll();
    }

    const onClip1 = () => {
        onClearClip();
        var clip1Path = new fabric.Path("M3970 7986 c-6 -8 -54 -43 -107 -77 -215 -139 -446 -221 -743 -264 \
                    -25 -3 -184 -10 -355 -15 -487 -15 -741 -53 -976 -148 -176 -70 -293 -167 \
                    -351 -288 -31 -67 -58 -176 -58 -240 0 -88 -62 -202 -138 -257 -66 -48 -120 \
                    -66 -225 -77 -220 -23 -356 -118 -458 -322 -120 -239 -172 -545 -189 -1116 \
                    -10 -321 -21 -420 -71 -592 -54 -188 -151 -387 -258 -529 -23 -30 -41 -55 -41 \
                    -56 0 -1 18 -26 41 -56 107 -142 204 -341 258 -529 50 -172 61 -271 71 -592 5 \
                    -163 16 -368 25 -455 42 -407 137 -685 285 -834 97 -97 182 -132 380 -157 186 \
                    -23 302 -144 321 -335 4 -45 13 -104 20 -132 48 -206 229 -353 539 -440 210 \
                    -58 418 -82 827 -95 171 -5 331 -12 355 -16 301 -43 537 -128 750 -271 56 -37 \
                    106 -72 113 -77 6 -5 47 17 108 58 164 111 353 197 540 245 144 37 282 51 600 \
                    61 307 9 482 24 648 56 423 79 653 232 712 474 8 36 20 102 25 147 5 45 19 \
                    101 31 126 57 118 166 191 302 203 304 25 471 187 574 555 65 230 86 424 105 \
                    959 10 280 23 372 76 550 42 140 152 365 232 475 l57 80 -57 80 c-75 103 -188 \
                    333 -227 460 -57 185 -72 288 -81 565 -11 327 -18 436 -35 591 -54 466 -189 \
                    754 -405 864 -62 31 -110 43 -220 55 -118 13 -201 50 -259 116 -59 67 -82 125 \
                    -97 244 -17 131 -33 184 -77 253 -130 202 -403 319 -872 373 -71 8 -272 19 \
                    -446 24 -397 12 -523 31 -749 112 -120 43 -263 117 -375 193 -53 36 -100 65 \
                    -104 65 -5 0 -14 -7 -21 -14z");

        clip1Path.set({left: 0, top: 0, scaleX: activeObject.scaleX, scaleY: activeObject.scaleY, originX: "center", originY: "center" });
        var clipWH =  activeObject.height < activeObject.width ? activeObject.height : activeObject.width;
        activeObject.height < activeObject.width ? clip1Path.scaleToHeight(clipWH) : clip1Path.scaleToWidth(clipWH)
                    
        activeObject.clipPath = clip1Path;
        canvas.renderAll();    
    }

    const onClip2 = () => {
        onClearClip();
        var clipPath = new fabric.Path("M4940 9969 c-285 -133 -656 -199 -1115 -199 -293 0 -382 9 -955 98 \
            -328 51 -530 72 -695 72 -215 -1 -322 -31 -415 -117 -133 -122 -197 -342 -216 \
            -733 -12 -250 -54 -361 -181 -481 -123 -115 -211 -145 -478 -159 -208 -12 \
            -273 -21 -410 -56 -197 -51 -316 -138 -368 -266 -36 -92 -47 -168 -47 -343 0 \
            -166 13 -300 60 -605 96 -629 110 -751 110 -996 0 -298 -31 -570 -91 -804 -30 \
            -114 -85 -271 -121 -342 -18 -37 -18 -37 8 -95 135 -304 204 -686 204 -1126 \
            -1 -192 -17 -381 -54 -627 -105 -693 -116 -786 -116 -980 0 -217 24 -331 90 \
            -422 97 -136 316 -212 685 -237 251 -18 256 -18 326 -40 124 -39 240 -135 309 \
            -256 45 -78 66 -179 74 -345 26 -551 148 -790 427 -839 158 -27 447 -10 839 \
            49 670 102 746 110 1015 110 465 0 856 -75 1151 -219 16 -8 33 -4 75 15 148 \
            70 356 129 569 163 369 60 744 56 1200 -13 436 -66 644 -96 739 -106 133 -13 \
            378 -13 461 1 181 29 302 151 366 369 36 124 52 241 63 455 11 209 25 272 81 \
            371 69 119 202 220 335 254 38 9 133 21 210 24 388 20 617 85 741 212 42 43 \
            57 67 78 131 74 221 65 387 -69 1263 -45 296 -55 413 -55 676 0 269 8 388 41 \
            579 36 206 90 393 160 544 23 50 25 41 -31 176 -74 180 -134 439 -160 694 -5 \
            57 -10 216 -10 355 0 262 9 376 55 676 108 707 126 887 107 1074 -25 239 -96 \
            348 -278 425 -139 59 -286 86 -549 101 -93 5 -199 17 -235 25 -180 44 -339 \
            198 -395 385 -10 34 -20 124 -25 235 -16 299 -47 460 -115 594 -92 183 -232 \
            251 -517 251 -190 0 -342 -18 -978 -115 -283 -43 -423 -55 -671 -55 -264 0 \
            -390 10 -594 46 -176 32 -329 74 -453 126 -126 52 -129 52 -182 27z")
        applyClipPath(clipPath);
    }

    const onClip3 = () => {
        onClearClip();
        var clipPath = new fabric.Path("M4470 6314 c-667 -40 -1302 -285 -1825 -705 l-123 -99 -535 0 -535 0 \
        -276 -291 -276 -291 0 -207 0 -206 -113 -87 c-434 -335 -683 -674 -760 -1037 \
        -27 -128 -28 -332 -3 -451 80 -374 321 -710 751 -1043 l120 -93 5 -211 5 -210 \
        270 -286 270 -286 536 -1 536 0 119 -96 c607 -490 1324 -733 2099 -711 727 21 \
        1385 271 1953 743 l76 64 537 0 536 0 276 292 277 292 0 206 0 206 113 86 \
        c641 492 888 1030 731 1587 -32 113 -137 323 -221 441 -91 127 -326 362 -490 \
        489 l-133 103 -1 206 0 207 -277 292 -277 292 -538 1 -537 0 -94 78 c-473 391 \
        -1043 635 -1652 707 -126 15 -433 25 -544 19z")
        applyClipPath(clipPath);
    }

    const onClip4 = () => {
        onClearClip();
        var clipPath = new fabric.Path("M1555 6464 c-240 -28 -365 -149 -365 -352 0 -67 12 -118 90 -391 49 \
        -173 88 -316 86 -318 -1 -2 -131 33 -287 78 -156 44 -307 83 -335 86 -71 8 \
        -241 -14 -343 -43 -175 -51 -297 -140 -351 -257 -94 -200 -41 -532 147 -927 \
        173 -362 210 -659 107 -862 -13 -26 -25 -51 -27 -55 -3 -10 -45 -59 -104 -123 \
        -29 -31 -53 -60 -53 -64 0 -5 28 -40 62 -80 84 -98 123 -161 153 -251 63 -185 \
        15 -458 -135 -775 -193 -409 -246 -750 -147 -946 88 -173 352 -289 657 -287 \
        63 0 121 13 363 82 159 46 290 81 292 79 2 -2 -37 -144 -86 -317 -97 -343 \
        -106 -401 -73 -511 22 -74 114 -167 199 -201 53 -21 76 -23 205 -24 135 0 155 \
        3 292 38 146 37 391 113 462 142 20 8 37 15 38 15 2 -1 35 11 73 26 360 137 \
        559 173 774 140 158 -24 360 -98 528 -194 51 -28 99 -52 108 -52 8 0 59 24 \
        112 54 285 158 545 224 776 198 123 -15 237 -44 412 -107 531 -191 753 -253 \
        940 -262 178 -8 277 22 367 113 61 61 83 115 86 219 3 88 1 99 -87 405 -49 \
        173 -88 316 -86 318 2 2 131 -32 287 -76 155 -44 305 -82 331 -86 64 -8 223 9 \
        322 35 181 46 325 148 379 268 90 200 35 529 -153 928 -110 234 -158 408 -160 \
        586 -3 174 41 285 169 432 38 44 70 84 70 89 0 4 -28 39 -62 76 -198 214 -228 \
        431 -111 795 20 61 53 144 74 186 159 318 240 641 210 839 -31 200 -155 325 \
        -392 394 -103 29 -272 51 -343 43 -28 -3 -179 -42 -335 -86 -157 -45 -286 -80 \
        -287 -78 -1 1 38 144 87 317 77 273 89 325 89 392 0 100 -26 172 -85 232 -141 \
        145 -363 155 -775 35 -58 -17 -121 -36 -140 -41 -18 -6 -144 -50 -278 -99 \
        -134 -49 -286 -100 -337 -114 -316 -82 -572 -42 -934 145 -73 39 -139 70 -146 \
        70 -7 0 -69 -29 -137 -65 -220 -115 -367 -164 -550 -186 -191 -22 -347 10 \
        -729 149 -281 102 -369 130 -558 177 -111 28 -300 59 -321 53 -3 0 -18 -3 -35 \
        -4z")

        applyClipPath(clipPath);
    }

    const onClip5 = () => {
        onClearClip();
        var clipPath = new fabric.Path("M1268 3981 c7 -47 -57 -125 -112 -138 -16 -3 -31 -14 -33 -24 -3 -10 -15 -21 -27 -25 -35 -11 -138 -75 -185 -116 -24 -21 -52 -38 -63 -38 -23 0 \
        -89 -42 -145 -92 -24 -21 -47 -38 -51 -38 -5 0 -22 -18 -37 -40 -15 -22 -35 -42 -44 -45 -21 -8 -126 -135 -111 -135 7 0 -13 -27 -44 -61 -31 -33 -54 -65 \
        -50 -70 3 -5 -8 -25 -25 -45 -17 -19 -31 -41 -31 -48 0 -7 -11 -33 -25 -57 -49 -86 -85 -164 -85 -184 0 -11 -11 -38 -25 -60 -13 -22 -25 -49 -25 -59 0 \
        -11 -11 -47 -24 -80 -54 -136 -89 -380 -87 -606 2 -190 58 -511 106 -603 8 -16 15 -38 15 -49 0 -11 6 -32 14 -46 25 -49 56 -119 56 -127 0 -5 9 -19 21 \
        -31 11 -12 34 -54 50 -94 68 -168 276 -407 509 -585 81 -62 269 -185 284 -185 6 0 30 -13 54 -29 23 -16 62 -33 85 -36 23 -4 54 -16 69 -27 15 -10 49 -23 75 \
        -28 27 -4 73 -15 103 -23 30 -8 79 -15 108 -15 29 -1 68 -6 85 -11 18 -6 67 -12 110 -14 91 -4 99 11 16 31 -30 7 -101 26 -159 42 -58 16 -126 31 -151 34 \
        -51 6 -143 38 -173 61 -35 27 -7 28 51 3 65 -29 156 -52 175 -46 7 3 -9 15 -36 26 -61 28 -54 40 11 18 91 -32 192 -44 333 -39 150 6 174 14 135 45 -14 \
        11 -37 23 -52 27 -15 4 -29 13 -31 20 -3 10 5 12 37 6 32 -5 42 -3 47 8 3 8 19 21 35 27 16 7 29 18 29 24 0 7 17 18 38 24 l37 11 -41 -3 c-22 -2 -49 1 \
        -60 7 -10 6 -53 12 -95 13 -76 1 -104 8 -94 23 2 5 66 12 142 16 152 8 193 20 193 59 0 23 0 24 -42 9 -54 -19 -242 -5 -261 19 -7 9 -19 18 -27 21 -11 4 -11 \
        6 3 6 10 1 25 -6 33 -14 9 -8 25 -15 37 -14 20 0 20 1 3 8 -10 4 31 8 92 9 60 0 140 8 177 17 81 18 86 19 79 1 -4 -9 9 -17 39 -25 32 -7 46 -17 50 -32 4 \
        -16 11 -19 26 -14 29 9 26 0 -10 -40 -39 -42 -118 -72 -148 -55 -32 17 -25 45 21 84 24 19 40 37 37 40 -11 12 -99 -80 -99 -103 0 -35 62 -57 119 -42 24 6 \
        46 9 49 7 2 -3 -10 -27 -27 -54 -34 -54 -39 -80 -16 -88 23 -9 18 -22 -13 -29 -35 -7 -60 -42 -37 -51 8 -4 23 0 32 7 14 12 16 12 11 -1 -2 -8 -14 -16 -26 \
        -18 -28 -4 -29 -22 -1 -22 11 0 17 -4 14 -10 -3 -5 -15 -10 -26 -10 -10 0 -19 -4 -19 -10 0 -5 8 -10 18 -10 10 0 28 -14 40 -30 13 -17 30 -30 38 -30 11 0 \
        13 -6 9 -19 -5 -18 -2 -19 29 -14 25 4 36 2 36 -7 0 -8 8 -10 23 -6 12 4 31 8 42 9 29 3 82 38 95 62 6 11 12 39 14 61 2 23 6 46 9 51 4 6 -1 10 -10 9 -9 0 \
        -18 5 -20 12 -3 8 5 12 27 10 39 -4 61 21 41 46 -17 21 -51 20 -66 -1 -11 -14 -17 -15 -58 -2 -44 12 -63 29 -34 29 6 0 49 23 95 50 46 28 86 50 88 50 3 0 \
        -6 -14 -20 -31 -30 -35 -36 -75 -10 -65 9 3 19 6 24 6 13 0 53 60 56 84 1 12 3 24 3 28 2 15 121 129 129 124 12 -7 74 58 65 69 -5 4 -3 5 3 2 13 -7 43 23 \
        70 71 10 17 21 32 25 32 10 0 -3 -43 -16 -55 -7 -5 -39 -46 -72 -90 -33 -44 -81 -98 -107 -118 -25 -21 -46 -46 -46 -56 0 -9 -5 -22 -12 -29 -18 -18 -1 \
        -27 27 -12 34 18 163 144 213 208 37 48 42 51 42 29 0 -53 8 -58 51 -30 22 14 42 23 45 20 10 -10 -17 -76 -44 -109 -26 -31 -26 -31 -4 -26 25 7 27 0 11 -35 \
        -8 -17 -17 -23 -33 -20 -28 6 -50 -20 -52 -59 -3 -48 22 -45 71 10 25 27 55 52 67 56 13 3 41 28 63 56 22 27 43 50 48 50 4 0 -3 -15 -17 -33 -33 -43 -33 \
        -62 -1 -22 22 26 25 27 25 10 0 -11 -9 -35 -21 -54 -11 -18 -18 -40 -15 -48 6 -14 -18 -66 -50 -111 -14 -19 -14 -22 -2 -22 9 0 31 11 50 23 19 13 45 26 59 \
        29 13 3 32 9 41 12 13 6 17 1 20 -20 3 -28 3 -28 38 18 34 45 73 73 96 70 7 -1 14 7 17 18 3 11 10 20 16 20 8 0 7 -9 -3 -27 -8 -16 -19 -47 -25 -70 -7 \
        -24 -16 -43 -21 -43 -5 0 -5 14 1 32 11 40 11 41 -1 33 -5 -3 -14 -24 -21 -47 -12 -48 -58 -98 -89 -98 -17 0 -20 4 -15 20 3 11 2 20 -3 20 -13 0 -21 -59 -9 \
        -63 6 -2 -10 -17 -33 -35 -45 -33 -45 -43 1 -14 14 9 41 27 60 40 19 12 39 20 46 17 7 -2 15 0 18 5 3 5 12 7 20 4 8 -3 44 15 80 40 36 25 70 46 75 46 5 0 \
        19 15 31 33 13 17 29 35 35 40 7 4 27 33 45 65 18 31 42 66 55 77 26 23 114 192 114 221 0 10 9 38 20 61 24 54 74 241 96 363 19 102 25 394 12 525 l-7 70 \
        15 -70 c11 -49 17 -128 17 -265 2 -173 -2 -213 -26 -350 -16 -85 -34 -168 -42 -185 -7 -16 -19 -50 -26 -75 -18 -66 -126 -299 -157 -340 -70 -92 -89 -127 \
        -94 -172 -3 -27 -3 -48 0 -48 3 0 16 10 29 22 35 33 38 15 4 -25 -17 -19 -31 -38 -31 -42 0 -18 47 36 103 120 111 164 216 379 247 505 6 25 15 54 20 65 20 \
        43 52 181 62 263 5 48 10 186 10 307 1 185 2 212 12 172 15 -65 24 -52 15 20 -30 215 -33 228 -66 228 -30 0 -64 103 -44 134 16 25 38 4 65 -64 59 -148 114 \
        -441 87 -468 -15 -15 -14 -52 1 -77 14 -23 -2 -235 -28 -365 -9 -44 -20 -98 -24 -120 -4 -22 -12 -51 -17 -65 -6 -14 -13 -50 -17 -80 -11 -80 -40 -162 \
        -101 -277 -62 -117 -60 -140 3 -30 118 207 212 513 237 774 8 78 19 159 26 177 10 28 10 40 -1 63 -14 30 -14 31 -25 143 -11 119 -49 277 -82 343 -17 33 \
        -26 107 -13 107 4 0 11 -12 16 -27 7 -26 8 -25 13 17 5 40 9 45 29 42 20 -3 22 0 16 22 -4 19 -2 28 11 32 9 4 14 13 11 20 -3 7 0 16 5 20 6 3 8 17 4 31 \
        -4 18 -1 29 12 39 17 13 17 15 1 25 -25 14 -22 31 7 59 31 29 34 79 6 104 -14 13 -30 16 -67 11 -71 -8 -92 7 -121 85 -13 36 -33 85 -45 110 -11 25 -28 61 \
        -36 80 -8 19 -17 29 -19 22 -5 -12 7 -43 44 -114 7 -16 10 -28 5 -28 -13 0 -72 136 -99 225 -4 11 -12 25 -18 30 -24 20 -26 47 -5 52 26 7 25 9 -8 74 -70 \
        137 -160 242 -345 407 -161 143 -503 327 -684 368 -38 9 -95 22 -128 30 -117 26 -442 44 -555 30 -25 -3 -67 -8 -95 -11 -27 -3 -59 -10 -70 -15 -37 -16 -69 \
        -19 -82 -9 -9 8 -35 2 -95 -24 -46 -19 -94 -38 -108 -42 -14 -4 -41 -16 -60 -26 -19 -10 -55 -28 -80 -39 -58 -26 -140 -74 -148 -87 -4 -5 -31 -23 -60 -38 \
        -30 -16 -87 -57 -128 -92 -40 -35 -76 -63 -79 -63 -3 0 -23 -11 -44 -24 -21 -14 -41 -22 -45 -19 -3 3 -12 0 -20 -6 -24 -20 -29 6 -8 37 23 31 62 68 62 58 \
        0 -4 -7 -20 -15 -36 -9 -17 -12 -30 -7 -30 4 0 25 32 46 70 20 39 43 70 50 70 7 0 25 9 38 20 14 11 38 18 54 17 23 -2 30 2 32 21 4 29 -9 45 -60 72 -65 34 \
        -128 3 -128 -61 0 -37 -33 -68 -72 -69 -42 0 -109 -29 -144 -62 -25 -23 -36 -27 -61 -22 -46 9 -154 -46 -225 -115 -32 -30 -58 -59 -58 -64 0 -5 -18 -31 \
        -40 -57 -22 -26 -40 -55 -40 -63 0 -9 -5 -19 -11 -23 -20 -12 0 47 34 101 43 68 92 135 119 163 12 12 19 22 14 22 -15 0 -66 -41 -66 -53 0 -7 -17 -35 -38 \
        -63 -69 -94 -82 -113 -82 -127 0 -7 -20 -37 -46 -67 -44 -52 -74 -102 -88 -147 -13 -41 19 -26 41 20 27 55 80 127 94 127 6 0 1 -15 -11 -32 -12 -18 -34 \
        -59 -50 -91 -15 -32 -52 -84 -81 -116 -52 -57 -88 -135 -89 -190 0 -13 -11 -55 -25 -94 -14 -39 -23 -77 -21 -84 3 -8 2 -21 -3 -31 -5 -9 -15 -52 -22 -95 \
        -6 -43 -16 -80 -20 -83 -5 -2 -5 -15 -1 -27 4 -12 6 -51 4 -87 -7 -133 -2 -303 9 -316 6 -8 9 -31 7 -51 -3 -21 4 -69 14 -108 10 -38 20 -83 22 -98 1 \
        -15 8 -33 14 -39 7 -7 12 -16 12 -22 0 -5 -6 -1 -14 10 -67 91 49 -223 153 -415 8 -14 25 -39 38 -54 12 -16 23 -35 23 -42 0 -7 11 -22 24 -32 13 -10 30 \
        -32 39 -49 37 -71 269 -304 374 -374 12 -8 56 -39 98 -69 43 -29 99 -62 124 -73 47 -21 91 -55 91 -70 0 -5 -14 0 -30 12 -17 12 -45 26 -63 31 -33 9 -156 \
        85 -192 119 -11 11 -51 33 -88 50 -48 22 -87 50 -130 94 -34 34 -75 71 -90 81 -37 24 -130 139 -199 245 -68 104 -179 317 -201 385 -49 154 -67 219 -67 244 \
        0 15 -4 31 -9 37 -15 16 -41 297 -42 459 -1 122 32 363 62 452 7 21 12 48 11 60 -1 12 7 37 17 55 10 18 24 51 31 73 52 168 297 595 341 595 12 0 7 -10 -46 \
        -80 -36 -48 -57 -81 -111 -175 -27 -47 -87 -173 -101 -213 -24 -72 -7 -59 21 16 36 94 141 276 213 368 32 42 76 101 98 132 22 31 74 85 115 120 41 35 104 \
        91 140 124 71 65 214 158 243 158 10 0 28 5 39 11 18 10 24 8 38 -11 12 -17 24 -21 40 -17 12 3 33 1 46 -5 44 -20 111 27 89 63 -4 6 11 26 34 45 69 59 50 \
        106 -49 122 -30 5 -62 12 -71 16 -13 6 -15 1 -11 -23z m1372 -235 c268 -55 456 -122 613 -219 89 -54 162 -115 152 -125 -2 -2 -19 7 -37 21 -51 37 -186 \
        117 -200 117 -6 0 -19 7 -28 15 -27 24 -370 138 -425 140 -27 2 -102 10 -165 18 -116 14 -182 15 -275 2 -41 -6 -53 -4 -65 10 -13 15 -12 19 5 32 17 12 49 \
        14 185 10 91 -3 199 -13 240 -21z m-585 -16 c3 -5 -2 -10 -12 -10 -21 0 -123 -37 -140 -52 -7 -5 -17 -7 -23 -3 -11 7 -62 -10 -123 -41 -33 -16 -47 -18 -47 \
        -5 0 17 226 101 271 101 10 0 21 5 24 10 3 6 15 10 25 10 10 0 22 -4 25 -10z m600 -115 c285 -47 539 -166 755 -353 106 -93 104 -91 98 -97 -3 -3 -26 12 \
        -51 33 -183 158 -441 291 -667 343 -52 12 -102 21 -110 21 -8 0 -68 7 -133 15 -97 12 -144 13 -265 3 -80 -6 -150 -14 -155 -17 -15 -9 -167 -12 -190 -4 l-22 \
        8 23 7 c12 4 71 18 130 31 91 20 137 23 302 24 129 1 226 -4 285 -14z m-1015 -45 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z \
        m-842 -497 c-6 -21 -25 -54 -43 -74 -18 -20 -35 -42 -37 -50 -3 -8 -8 -11 -12 -7 -4 3 2 24 13 47 12 22 21 46 21 53 0 13 49 68 61 68 4 0 3 -17 -3 -37z \
        m3087 -354 c43 -79 105 -212 105 -225 0 -2 -4 -4 -9 -4 -6 0 -29 42 -54 93 -24 50 -67 129 -95 174 -29 46 -52 85 -52 89 0 3 12 -4 28 -14 15 -11 50 -62 \
        77 -113z m-3545 56 c0 -12 -24 -55 -32 -55 -11 0 -10 4 4 35 9 21 28 35 28 20z m250 -54 c0 -10 -15 -60 -34 -111 -86 -229 -110 -382 -103 -633 7 -263 45 \
        -413 162 -652 80 -161 163 -272 305 -408 107 -102 120 -117 101 -117 -12 0 -255 242 -301 300 -43 54 -180 280 -180 297 0 7 -6 27 -14 45 -50 118 -82 234 \
        -106 383 -15 94 -15 340 -1 445 25 169 97 407 138 452 21 23 33 23 33 -1z m3206 -93 c36 -70 76 -179 69 -186 -10 -10 -23 12 -49 85 -14 40 -35 91 -46 \
        113 -11 22 -16 40 -10 40 5 0 22 -23 36 -52z m-3530 -65 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m3404 -22 c0 -5 3 -18 6 -28 6 -16 5 \
        -16 -9 2 -19 23 -21 35 -7 35 6 0 10 -4 10 -9z m-3416 -36 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4 -15z m-168 -142 c-10 -10 -19 \
        5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m134 -3 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m80 -497 c12 -99 25 -166 60 \
        -304 23 -87 23 -89 12 -89 -31 0 -101 324 -102 473 0 62 18 12 30 -80z m-40 -416 c-1 -21 -1 -21 -15 -3 -8 10 -12 23 -9 28 9 14 24 -2 24 -25z m127 -29 \
        c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-107 -43 c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m205 -138 c37 \
        -67 50 -122 19 -82 -16 20 -74 130 -74 140 0 3 4 5 10 5 5 0 25 -29 45 -63z m65 -123 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 \
        -16z m100 -134 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m480 -232 c124 -79 245 -133 430 -192 146 -46 192 -66 158 -66 -10 \
        0 -39 9 -66 19 -27 11 -62 19 -78 19 -58 -1 -317 109 -414 177 -84 59 -110 79 -110 88 0 6 5 6 13 0 6 -5 37 -25 67 -45z m903 -15 c-18 -2 -48 -2 -65 0 -18 \
        2 -4 4 32 4 36 0 50 -2 33 -4z m272 -23 c-43 -47 -214 -93 -255 -68 -25 16 -26 28 -1 28 11 0 27 3 37 7 13 5 15 3 9 -7 -6 -10 4 -11 50 -5 72 9 105 20 \
        105 38 0 11 59 37 69 30 2 -2 -4 -12 -14 -23z m751 4 c-3 -9 -6 -21 -6 -27 0 -8 -3 -8 -11 0 -13 13 -5 43 12 43 6 0 8 -7 5 -16z m-277 -60 c-31 -33 -36 \
        -23 -8 16 13 17 25 26 27 20 2 -6 -6 -22 -19 -36z m-253 -126 c-9 -47 -33 -78 -72 -94 -26 -11 -39 -12 -55 -4 -26 14 -11 35 31 44 20 4 30 12 30 24 0 27 29 62 52 62 18 0 20 -4 14 -32z m-1691 -8 c10 -11 14 -20 8 -20 -5 0 -18 9 -28 \
        20 -10 11 -14 20 -8 20 5 0 18 -9 28 -20z m2305 -35 c-7 -9 -15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z m-611 -117 c-87 -69 -226 -69 -199 0 10 25 \
        43 29 83 11 19 -8 31 -8 51 3 15 8 41 12 58 10 l31 -5 -24 -19z");

        applyClipPath(clipPath);
    }

    const onClip6 = () => {
        onClearClip();
        var clipPath = new fabric.Path(" M669 1073 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z \
        M1160 1060 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0 -4 -4 -4 -10z \
        M585 1010 c-33 -27 -65 -49 -71 -50 -7 0 -15 -7 -18 -16 -3 -8 -11 \
        -12 -17 -9 -7 5 -10 -4 -7 -26 l3 -33 -52 0 c-46 -1 -53 1 -53 18 0 20 0 20 \
        -15 0 -14 -19 -14 -19 -15 1 -1 12 -4 15 -7 7 -4 -10 -8 -10 -18 -2 -11 9 -14 \
        7 -12 -9 1 -12 8 -21 17 -21 12 -1 11 -4 -5 -17 -16 -13 -17 -15 -2 -10 9 4 \
        17 2 17 -3 0 -13 -32 -28 -47 -23 -7 3 -13 0 -13 -6 0 -6 8 -11 18 -12 10 0 \
        12 -3 5 -6 -7 -2 -13 -10 -13 -17 0 -7 -15 -27 -33 -44 -25 -24 -41 -32 -63 \
        -30 -33 2 -57 -9 -49 -23 4 -5 -6 -9 -21 -9 -19 0 -24 -3 -17 -12 16 -22 9 \
        -52 -15 -64 -35 -17 -43 -29 -36 -48 5 -12 10 -14 19 -6 17 14 47 1 43 -19 -2 \
        -8 -14 -21 -27 -28 -28 -14 -51 -46 -26 -37 8 3 15 10 15 14 0 5 16 7 35 6 19 \
        -2 36 2 38 8 2 6 8 8 12 4 5 -5 1 -16 -8 -26 -30 -33 -19 -69 16 -51 15 9 18 \
        8 11 -3 -10 -15 -13 -58 -4 -58 3 0 14 7 24 15 51 39 54 6 5 -57 -37 -48 -36 \
        -52 6 -27 19 12 35 25 35 30 0 5 5 9 11 9 5 0 7 -4 4 -10 -12 -19 11 -10 25 \
        10 8 11 18 20 23 20 5 0 22 11 39 25 38 32 59 33 29 1 -18 -19 -19 -25 -8 -29 \
        8 -3 25 1 37 10 21 15 21 14 8 -6 -7 -11 -16 -21 -19 -21 -4 0 -9 -7 -13 -15 \
        -4 -13 -2 -13 14 -5 27 15 32 12 18 -9 -6 -11 -19 -17 -27 -14 -13 4 -14 3 -2 \
        -5 8 -6 21 -8 30 -5 9 4 5 -6 -11 -24 -16 -16 -28 -36 -28 -44 0 -8 -5 -20 \
        -12 -27 -9 -9 -9 -12 0 -12 7 0 12 4 12 8 0 5 14 19 31 31 18 13 29 19 25 13 \
        -3 -6 -2 -13 4 -17 5 -3 10 -1 10 6 0 8 6 6 18 -4 10 -10 22 -14 25 -10 4 3 7 \
        1 7 -6 0 -8 -7 -11 -15 -7 -8 3 -15 1 -15 -3 0 -14 32 -23 43 -12 7 7 15 7 25 \
        -1 11 -10 13 -8 10 8 -2 15 3 19 22 19 19 0 26 -6 28 -25 2 -14 8 -29 14 -35 \
        6 -7 9 -3 6 10 -2 13 3 20 12 20 9 0 26 11 39 24 17 17 25 20 31 11 4 -7 13 \
        -10 18 -6 6 4 16 -3 22 -14 9 -16 17 -19 48 -14 20 3 42 6 47 6 6 1 27 -7 47 \
        -16 27 -13 35 -22 30 -34 -5 -15 -3 -15 10 -4 13 10 22 11 37 3 24 -13 34 -14 \
        27 -2 -4 5 0 12 6 15 7 3 3 3 -9 0 -14 -3 -23 -1 -23 6 0 6 5 8 10 5 6 -3 10 \
        -1 10 5 0 7 7 7 23 -2 23 -13 29 -15 52 -17 9 -1 12 -7 8 -18 -4 -11 -3 -14 5 \
        -9 7 4 12 2 12 -4 0 -6 5 -8 11 -5 6 4 8 13 5 21 -3 8 -1 14 4 14 6 0 10 -4 \
        10 -10 0 -5 7 -7 16 -3 14 5 14 2 -2 -23 -11 -15 -13 -25 -7 -21 7 5 21 3 32 \
        -2 16 -9 18 -8 13 11 -5 19 -2 20 24 15 27 -6 27 -6 7 5 -26 12 -29 28 -10 47 \
        10 11 9 12 -2 6 -11 -6 -13 -4 -8 10 4 9 2 24 -5 32 -9 11 -8 13 2 11 13 -3 \
        15 8 11 42 0 6 -5 10 -11 10 -5 0 -7 -8 -3 -17 5 -15 3 -14 -10 2 -10 13 -12 \
        22 -5 26 6 4 8 16 6 27 -4 14 -2 19 7 16 7 -3 16 0 20 6 5 8 12 5 22 -7 10 \
        -12 9 -8 -2 11 -10 17 -12 25 -4 21 9 -6 11 -4 6 8 -3 9 0 18 9 21 21 8 17 23 \
        -8 29 -27 7 -20 16 18 23 16 3 31 9 32 15 2 5 8 7 13 3 5 -3 9 -2 9 2 0 12 \
        -34 33 -55 34 -11 1 -15 4 -8 6 17 8 124 -26 117 -37 -3 -5 -11 -7 -17 -3 -7 \
        3 -1 -4 13 -15 32 -27 38 -26 33 2 -4 20 -46 56 -70 59 -5 0 -21 6 -38 11 \
        l-30 11 35 9 c19 4 43 8 52 8 14 0 15 3 5 14 -6 7 -15 11 -19 8 -4 -2 -5 3 -2 \
        11 7 19 -32 31 -52 15 -8 -6 -17 -9 -21 -5 -8 8 22 32 62 51 30 14 27 19 -20 \
        27 -16 3 -8 11 35 38 37 23 49 36 38 38 -9 2 -29 -5 -45 -15 -15 -10 -24 -13 \
        -20 -7 4 7 28 22 54 34 32 15 42 24 33 30 -7 4 -9 13 -5 21 6 11 5 12 -6 2 \
        -18 -18 -30 -16 -23 2 4 8 1 15 -5 15 -6 0 -8 7 -5 15 4 8 -1 17 -10 21 -9 3 \
        -13 11 -9 17 4 6 0 8 -10 4 -10 -3 -17 -2 -17 3 0 14 24 12 38 -2 9 -9 12 -7 \
        12 12 0 24 -2 25 -75 25 -58 0 -75 -3 -79 -15 -3 -11 -4 -10 -5 3 0 10 -7 17 \
        -13 16 -26 -3 -39 1 -33 12 4 6 13 8 21 5 7 -3 16 -1 19 4 4 6 -6 10 -20 10 \
        -15 0 -24 4 -20 10 3 6 15 10 26 10 11 0 17 5 14 10 -4 6 -15 8 -26 5 -20 -7 \
        -27 9 -9 20 22 13 10 27 -17 20 -49 -13 -93 -16 -93 -5 0 6 -9 6 -26 0 -36 \
        -14 -49 -13 -35 4 9 11 8 15 -5 20 -13 5 -14 9 -4 21 7 8 10 15 8 15 -5 0 -84 \
        -36 -120 -55 -20 -10 -28 -11 -28 -3 0 9 -3 9 -12 0 -7 -7 -19 -12 -27 -12 -8 \
        0 -23 -8 -34 -17 -19 -17 -19 -17 -12 6 8 25 5 25 -45 -9 -14 -10 -31 -15 -39 \
        -12 -8 3 -11 0 -7 -7 4 -6 2 -11 -3 -11 -18 0 -12 21 12 42 12 11 15 16 7 12 \
        -26 -12 -50 -36 -45 -44 3 -5 -6 -3 -20 3 l-25 12 28 20 c34 25 89 74 82 74 \
        -3 0 -32 -22 -65 -49z m325 -5 c-14 -8 -32 -14 -40 -14 -9 0 -4 6 10 14 14 8 \
        32 14 40 14 9 0 4 -6 -10 -14z m-20 -30 c0 -2 -10 -9 -22 -15 -22 -11 -22 -10 \
        -4 4 21 17 26 19 26 11z m200 -25 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 \
        10 11 10 2 0 4 -4 4 -10z m-579 -31 c-24 -20 -22 -22 19 -19 18 1 22 -1 14 -9 \
        -13 -14 -57 -23 -49 -10 4 5 1 9 -5 9 -6 0 -10 7 -8 16 2 8 7 14 11 12 5 -2 \
        13 5 19 15 5 10 13 16 16 12 4 -4 -4 -15 -17 -26z m699 -49 c0 -5 -5 -10 -11 \
        -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-825 -50 c-3 -5 -1 -10 \
        4 -10 6 0 11 5 11 10 0 6 5 10 11 10 5 0 7 -4 4 -10 -3 -5 1 -10 10 -10 9 0 \
        14 -4 10 -9 -3 -5 -18 -9 -34 -9 -21 1 -28 5 -26 17 5 22 4 21 11 21 3 0 2 -4 \
        -1 -10z m-40 -66 c11 1 25 5 31 9 6 3 11 0 11 -8 0 -8 -3 -13 -6 -12 -4 1 -17 \
        -8 -31 -20 -14 -14 -18 -23 -10 -23 10 0 9 -5 -4 -19 -9 -10 -27 -17 -39 -16 \
        -12 1 -29 3 -37 4 -11 1 -9 6 8 20 28 25 28 29 -4 25 l-26 -2 27 28 c20 21 32   \
        27 44 21 9 -5 25 -8 36 -7z m891 -139 c-27 -21 -52 -21 -34 0 7 8 22 14 33 14 \
        20 0 20 0 1 -14z m-1081 -35 c-10 -11 -23 -20 -28 -20 -6 0 0 11 13 25 27 29 \
        42 25 15 -5z m91 19 c3 -6 1 -16 -5 -22 -9 -9 -10 -7 -5 8 7 18 6 18 -20 -3 \
        -29 -23 -46 -28 -46 -14 0 8 50 41 62 42 4 0 10 -5 14 -11z m-128 -19 c-7 -11 \
        -17 -20 -22 -20 -5 0 -1 9 9 20 10 11 20 20 22 20 2 0 -2 -9 -9 -20z m42 -53 \
        c0 -14 -49 -42 -57 -34 -4 4 45 47 53 47 2 0 4 -6 4 -13z m1087 -80 c3 -4 -8 \
        -7 -25 -7 -18 0 -32 4 -32 9 0 11 46 8 57 -2z m-907 -62 c-7 -8 -17 -15 -23 \
        -15 -5 0 -2 9 8 20 21 23 35 19 15 -5z m825 -55 c3 -5 1 -10 -4 -10 -6 0 -11 \
        5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-195 -110 c0 -5 -2 -10 -4 -10 -3 0 \
        -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-370 -4 c0 -3 -4 -8 -10 -11 \
        -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m25 -26 c-3 -5 -12 -10 \
        -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m430 -50 c3 -5 2 -10 -4 -10 -5 0 \
        -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z \
        M306 741 c-4 -6 -13 -8 -21 -4 -8 3 -15 0 -15 -5 0 -6 10 -9 21 -7 25 5 35 13 27 21 -3 3 -9 1 -12 -5z \
        M296 694 c-3 -8 -2 -13 3 -9 5 3 11 -1 14 -7 3 -7 4 -3 4 10 -1 25 -12 29 -21 6z \
        M1160 990 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z \
        M1230 989 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10 -5 -10 -11z \
        M1160 960 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0 -4 -4 -4 -10z  \
        M1157 916 c-4 -10 -1 -13 9 -9 7 3 14 9 14 14 0 14 -17 10 -23 -5z \
        M1230 919 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10 -5 -10 -11z \
        M1265 920 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7 -4 -4 -10z \
        M410 901 c0 -16 28 -20 40 -5 8 9 9 14 2 14 -6 0 -13 -6 -15 -12 -4 -10 -6 -10 -6 0 -1 14 -21 17 -21 3z \
        M75 520 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0 -13 -4 -16 -10z \
        M1335 510 c3 -6 -4 -10 -16 -10 -22 0 -22 0 -3 -15 28 -21 42 -18 40 10 0 14 -7 25 -14 25 -7 0 -10 -4 -7 -10z \
        M7 423 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3 -9 2 -12 -2z \
        M290 310 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0 -16 -4 -16 -10z \
        M560 160 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z \
        M660 160 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z \
        M700 159 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10 -5 -10 -11z \
        M560 130 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0 -4 -4 -4 -10z \
        M630 130 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0 -4 -4 -4 -10z \
        M665 130 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0 -8 -4 -11 -10z \
        M735 130 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0 -8 -4 -11 -10z \
        M630 89 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10 -5 -10 -11z \
        M730 89 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10 -5 -10 -11z \
        ");

        applyClipPath(clipPath);
    }

    const onClip7 = () => {
        onClearClip();
        var clipPath = new fabric.Path("M2943 2530 c2 -26 -8 -29 -63 -16 -14 4 -19 4 -12 0 6 -3 12 -15 13 \
            -27 0 -12 3 -16 6 -9 8 20 33 14 33 -8 0 -15 -7 -20 -24 -20 -14 0 -28 -4 -31 \
            -10 -9 -14 -37 -12 -33 2 6 16 -19 41 -32 33 -6 -4 -10 8 -10 29 0 35 -1 35 \
            -32 29 -32 -6 -78 -43 -78 -63 0 -5 -7 -10 -15 -10 -7 0 -18 -7 -23 -16 -7 \
            -13 -17 -15 -38 -10 -16 4 -48 7 -71 6 -23 0 -44 4 -48 10 -3 6 -10 8 -15 5 \
            -5 -3 -11 -1 -15 5 -3 5 -21 10 -39 10 -25 0 -35 -5 -39 -20 -3 -13 -13 -20 \
            -29 -20 -34 0 -50 14 -42 35 6 15 4 17 -11 11 -21 -8 -105 13 -105 26 0 11 \
            -49 30 -77 30 -12 0 -28 -6 -36 -13 -28 -27 -57 -49 -64 -49 -8 0 -46 -36 \
            -139 -127 -53 -54 -74 -65 -74 -38 0 8 4 15 8 15 5 0 14 23 21 50 7 28 19 56 \
            25 63 7 7 15 26 19 43 5 27 3 31 -12 27 -9 -2 -42 7 -72 21 -58 26 -105 26 \
            -95 -1 3 -8 2 -12 -3 -9 -9 6 -56 -63 -53 -77 2 -5 -2 -6 -7 -3 -5 3 -12 -1 \
            -15 -10 -5 -13 -15 -15 -44 -10 -20 3 -45 6 -54 7 -10 0 -18 4 -18 10 0 13 \
            -28 11 -32 -3 -3 -7 -18 -13 -34 -14 -23 -1 -29 -6 -28 -23 0 -11 -5 -21 -11 \
            -21 -6 0 -29 -26 -50 -57 -21 -32 -46 -69 -55 -83 -10 -14 -28 -46 -40 -70 \
            -12 -24 -30 -56 -40 -70 -9 -14 -29 -44 -45 -67 -16 -25 -36 -43 -46 -43 -10 \
            0 -23 -9 -29 -20 -6 -11 -15 -20 -20 -20 -15 0 -12 48 4 57 16 9 76 120 76 \
            141 0 7 -4 10 -10 7 -5 -3 -10 3 -10 14 0 12 4 21 10 21 10 0 55 80 52 93 -1 \
            4 2 7 8 7 5 0 10 5 10 10 0 6 -11 10 -25 10 -14 0 -25 5 -25 11 0 5 -4 8 -9 4 \
            -5 -3 -12 -1 -16 4 -8 14 -45 4 -70 -19 -27 -25 -50 -27 -39 -2 4 9 9 35 12 \
            58 3 35 1 41 -21 51 -20 10 -27 8 -43 -10 -22 -26 -125 -207 -118 -207 3 0 -4 \
            -12 -15 -26 -12 -15 -21 -33 -21 -40 0 -7 -9 -22 -21 -33 -13 -11 -18 -24 -14 \
            -32 5 -7 4 -10 -2 -6 -6 4 -16 -7 -23 -23 -7 -17 -16 -30 -20 -30 -4 0 -19 \
            -25 -34 -55 -14 -30 -31 -55 -36 -55 -5 0 -12 -11 -16 -25 -3 -14 -12 -25 -20 \
            -25 -18 0 -18 10 3 78 9 32 16 60 15 65 -1 4 2 7 6 7 5 0 17 35 28 78 10 42 \
            23 80 28 83 4 4 6 11 2 17 -3 6 -2 13 4 17 5 3 10 18 10 33 0 21 -2 24 -10 12 \
            -8 -13 -11 -13 -24 0 -12 12 -13 22 -3 58 6 23 15 42 19 42 4 0 8 13 8 30 0 \
            22 -4 28 -15 24 -8 -4 -33 3 -55 15 -44 23 -70 27 -70 11 0 -5 -8 -10 -17 -10 \
            -16 0 -63 -55 -63 -74 0 -16 -55 -86 -68 -86 -7 0 -11 -4 -7 -10 3 -5 -7 -16 \
            -23 -24 -27 -14 -97 -89 -135 -146 -9 -13 -17 -21 -17 -16 0 15 -155 -209 \
            -167 -241 -4 -10 -13 -23 -21 -29 -8 -5 -15 -22 -15 -37 -1 -15 -9 -29 -18 \
            -32 -45 -15 16 -66 96 -81 34 -6 36 -8 29 -38 -3 -17 -12 -38 -19 -46 -7 -8 \
            -17 -34 -22 -57 -11 -42 -10 -43 12 -37 20 5 24 2 27 -22 2 -22 10 -30 38 -37 \
            19 -5 38 -13 41 -18 3 -5 19 4 36 20 17 16 29 32 25 35 -3 3 -1 6 5 6 5 0 18 \
            18 29 40 10 22 24 40 31 40 6 0 13 5 15 11 6 19 47 0 45 -21 -1 -10 -5 -16 -9 \
            -14 -4 3 -8 -1 -8 -8 0 -7 -12 -31 -26 -51 -14 -21 -24 -40 -22 -41 1 -2 13 \
            -9 26 -15 17 -9 20 -16 13 -30 -7 -12 -6 -22 1 -29 9 -9 6 -19 -11 -42 -12 \
            -17 -19 -36 -15 -42 4 -6 3 -8 -3 -5 -6 4 -17 -12 -25 -38 -13 -38 -13 -46 -1 \
            -58 13 -12 12 -19 -1 -48 -18 -38 -60 -109 -73 -124 -5 -5 -10 -13 -11 -18 -4 \
            -16 -62 -111 -62 -102 0 6 -5 3 -10 -6 -6 -11 -6 -28 1 -49 8 -23 8 -38 1 -53 \
            -19 -39 -19 -71 1 -75 10 -2 24 4 31 14 15 21 51 12 43 -11 -5 -11 -1 -12 19 \
            -5 14 6 36 10 50 10 13 0 24 5 24 11 0 5 4 8 9 4 5 -3 17 13 27 34 10 22 20 \
            38 24 36 4 -2 18 18 30 46 13 28 28 53 34 56 5 4 6 0 2 -8 -4 -8 0 -5 8 5 9 \
            11 16 23 16 28 0 4 7 8 15 8 8 0 15 -3 15 -7 0 -15 -26 -81 -37 -94 -10 -13 \
            -13 -27 -29 -120 -6 -34 -4 -37 26 -48 19 -6 34 -10 34 -9 20 42 32 58 43 58 \
            9 0 11 -8 7 -24 -5 -22 -3 -24 16 -19 21 6 22 3 18 -27 l-5 -33 31 35 c17 20 \
            46 54 65 78 28 35 37 41 60 35 14 -4 27 -8 29 -10 2 -1 -10 -22 -27 -46 -16 \
            -24 -27 -47 -25 -51 3 -5 1 -8 -4 -8 -8 0 -53 -81 -53 -97 0 -4 -8 -16 -17 \
            -26 -16 -18 -16 -20 3 -33 10 -8 26 -14 35 -14 9 0 21 -5 28 -12 6 -6 26 -13 \
            44 -15 18 -2 34 -8 36 -13 5 -13 50 18 57 40 4 14 85 105 85 96 0 -2 -14 -30 \
            -30 -62 -21 -42 -28 -67 -24 -87 5 -25 -26 -109 -47 -127 -3 -3 -8 -15 -12 \
            -28 -5 -21 -3 -23 23 -17 37 8 51 -22 21 -47 -12 -9 -21 -21 -21 -27 0 -6 -8 \
            -11 -17 -12 -22 -1 36 -21 60 -21 9 1 17 -3 17 -7 0 -5 12 -11 26 -14 18 -3 \
            29 1 37 14 14 23 33 25 41 4 3 -8 14 -15 24 -15 10 0 22 -3 26 -7 4 -4 28 -13 \
            53 -19 53 -13 77 -3 106 48 18 31 38 36 56 14 7 -8 24 -17 39 -20 15 -4 51 \
            -14 80 -23 l52 -16 35 34 c19 19 35 39 35 45 0 6 13 25 30 44 16 18 32 42 36 \
            52 3 10 9 18 14 18 5 0 7 6 4 14 -3 8 0 17 7 22 8 5 10 3 4 -7 -4 -8 6 2 22 \
            21 17 19 28 40 26 47 -3 7 -1 13 5 13 5 0 19 16 31 35 19 32 53 47 64 28 7 \
            -12 -8 -45 -18 -42 -6 1 -19 -16 -30 -37 -11 -22 -23 -42 -28 -45 -4 -3 -15 \
            -21 -25 -40 -10 -19 -26 -43 -35 -54 -40 -44 -76 -109 -66 -121 8 -10 51 -23 \
            94 -29 26 -4 65 -26 65 -37 0 -7 8 -2 18 11 9 13 42 49 73 81 59 60 99 89 99 \
            70 0 -5 11 -10 25 -10 14 0 25 -5 25 -10 0 -12 75 70 101 110 9 14 20 22 25 \
            17 4 -4 -2 -20 -14 -37 -12 -16 -33 -55 -47 -86 -24 -55 -25 -58 -8 -68 27 \
            -16 80 -16 93 -1 7 9 8 15 0 20 -20 12 -10 25 19 25 34 0 51 -18 30 -31 -8 -5 \
            -9 -9 -4 -9 6 0 -1 -9 -15 -19 l-24 -19 43 -12 c42 -11 43 -11 77 27 19 21 51 \
            56 72 78 22 22 48 54 60 71 l21 31 63 -15 c35 -9 69 -23 76 -31 8 -9 11 -11 7 \
            -4 -10 21 43 28 64 9 10 -9 38 -18 62 -20 24 -2 48 -4 52 -5 5 -1 5 -6 2 -11 \
            -3 -6 3 -10 14 -10 12 0 21 -5 21 -10 0 -6 16 4 35 23 19 19 35 37 35 42 0 4 \
            16 23 35 42 19 19 35 39 35 45 0 5 36 45 80 88 44 43 80 83 80 90 0 6 5 8 11 \
            5 6 -4 13 2 16 14 3 12 10 21 17 21 6 0 33 25 60 56 35 40 54 54 67 51 17 -5 \
            24 4 20 26 -1 5 3 6 8 2 6 -3 16 3 23 15 13 20 78 30 78 11 0 -5 9 -7 20 -4 \
            14 4 20 0 20 -11 0 -10 22 5 59 43 66 64 103 76 109 32 2 -13 0 -26 -5 -30 \
            -10 -8 -28 -39 -47 -85 -15 -37 -15 -37 16 -56 17 -10 37 -16 44 -13 8 3 14 0 \
            14 -6 0 -6 18 -14 40 -17 22 -4 40 -12 40 -18 0 -6 12 1 25 17 13 15 29 27 35 \
            27 6 0 17 15 25 32 8 18 32 52 54 76 22 23 37 42 34 42 -4 0 2 10 13 22 11 13 \
            26 35 34 51 7 15 17 27 22 27 4 0 17 15 28 32 23 39 53 77 97 125 18 19 35 44 \
            38 54 4 11 11 17 16 14 5 -4 9 0 9 8 0 8 17 32 38 53 20 22 53 59 72 84 19 24 \
            43 52 53 63 22 22 18 53 -6 49 -24 -4 -22 34 3 55 11 10 20 22 20 29 0 6 9 19 \
            20 29 11 10 20 22 20 27 0 4 14 27 30 50 17 22 30 50 30 61 0 17 -5 19 -40 13 \
            -29 -4 -40 -3 -40 6 0 7 -9 19 -21 26 -11 7 -18 19 -16 27 4 9 -9 15 -48 20 \
            -33 4 -55 12 -59 21 -3 9 -10 13 -15 10 -18 -12 -21 28 -3 48 22 25 47 95 37 \
            104 -7 7 -12 8 -40 11 -27 2 -17 30 41 109 15 21 15 22 0 16 -9 -3 -16 -12 \
            -16 -20 0 -19 -10 -17 -37 5 -29 24 -31 61 -3 61 11 0 20 5 20 11 0 7 9 9 24 \
            5 17 -4 26 -1 30 10 5 13 9 14 21 4 13 -10 15 -8 15 13 0 20 -4 24 -21 20 -11 \
            -3 -30 -1 -42 4 -57 26 -95 33 -118 23 -34 -15 -103 9 -107 38 -2 14 -17 25 \
            -48 36 l-45 15 -32 -33 c-44 -45 -106 -126 -144 -190 -10 -16 -28 -41 -41 -57 \
            -14 -17 -20 -34 -16 -41 4 -7 4 -10 0 -6 -8 7 -44 -37 -96 -114 -25 -38 -69 \
            -55 -56 -22 15 40 19 67 7 60 -6 -4 -11 -3 -11 3 0 13 23 64 31 71 14 11 76 \
            98 103 145 16 28 40 63 54 80 l24 29 -23 9 c-13 5 -32 13 -41 18 -10 5 -24 9 \
            -32 9 -8 0 -17 4 -20 8 -8 13 45 112 57 105 6 -4 8 0 4 10 -3 10 -2 17 3 17 5 \
            0 12 11 16 25 3 14 10 23 14 20 5 -3 11 -1 15 5 3 6 -3 10 -15 10 -27 0 -22 \
            21 9 36 23 12 22 12 -10 19 -19 4 -38 10 -43 13 -5 3 -25 6 -44 8 -20 2 -40 \
            10 -44 19 -10 17 -38 21 -38 5 0 -5 -7 -10 -15 -10 -8 0 -15 -5 -15 -12 0 -7 \
            -12 -23 -27 -36 -21 -17 -34 -21 -57 -16 -17 3 -35 10 -41 15 -5 5 -17 9 -27 \
            9 -10 0 -21 6 -24 13 -9 25 -35 14 -90 -40 -31 -29 -58 -53 -61 -53 -2 0 -12 \
            7 -20 16 -9 8 -24 13 -34 11 -11 -3 -19 -1 -19 4 0 17 -47 9 -65 -11 -11 -12 \
            -31 -20 -51 -20 -18 0 -36 -4 -39 -10 -10 -15 -35 -12 -35 5 0 8 4 15 9 15 5 \
            0 20 17 32 38 13 20 30 42 39 49 8 7 12 13 8 13 -7 0 17 32 50 66 6 7 12 24 \
            12 37 0 21 -6 25 -34 29 -31 3 -34 2 -33 -22z m27 -2144 c0 -2 -8 -10 -17 -17 \
            -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m-330 -136 c0 -5 -4 -10 -10 -10 -5 \
            0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-400 -145 c0 -8 -4 -15 -10 \
            -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z")
        applyClipPath(clipPath);
    }

    const applyClipPath = (_path) => {
        _path.set({left: 0, top: 0, scaleX: activeObject.scaleX, scaleY: activeObject.scaleY, originX: "center", originY: "center" });
        var clipWH =  activeObject.height < activeObject.width ? activeObject.height : activeObject.width;
        activeObject.height < activeObject.width ? _path.scaleToHeight(clipWH) : _path.scaleToWidth(clipWH)
                    
        activeObject.clipPath = _path;
        canvas.renderAll();    
    }

    const onClearClip = () => {
        activeObject.clipPath = "";
        canvas.renderAll();
    }

    const onShowMenu = () => {
        setShowMenu(!showMenu);
    }


  return (
    <div style={{paddingTop:"0px"}}>
        <button style={{ fontSize:"11px", marginLeft: "5px", height:"24px", 
            paddingTop:"0px", width:"100px", verticalAlign:"middle", borderColor:"#9e9d9d", borderStyle:"solid", borderWidth:"1px",}} onClick={onShowMenu} > Clipping Masks </button>
            { 
                showMenu && 
                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", position: 'absolute', width: "220px", height: "200px", borderWidth: "1px", borderColor: "black", backgroundColor: "#f8f6f6", 
                  borderRadius: "8px", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"}}>

                    <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onClipCircle} > <img src={iconCircle} width="30px" alt="circle" /> 
                    </div>
                    <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onClipHeart} > <img src={iconHeart} width="30px" alt="heart" /> 
                    </div>
                    <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onClipStar} > <img src={iconStar} width="30px" alt="star" /> 
                    </div>
                    <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onRecRounded} > <img src={iconRecRounded} width="30px" alt="Rounded Rectangle" /> 
                    </div>
                    <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onPentagon} > <img src={iconPentagon} width="30px" alt="pentagon" /> 
                    </div>
                    <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onClip1} > <img src={iconClip1} width="30px" alt="clip1" /> 
                    </div>
                    <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onClip2} > <img src={iconClip2} width="30px" alt="clip2" /> 
                    </div>
                    <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onClip3} > <img src={iconClip3} width="30px" alt="clip3" /> 
                    </div>
                    <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onClip4} > <img src={iconClip4} width="30px" alt="clip4" /> 
                    </div>
                    <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onClip5} > <img src={iconClip5} width="30px" alt="clip5" /> 
                    </div>
                    <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onClip6} > <img src={iconClip6} width="30px" alt="clip6" /> 
                    </div>
                    <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onClip7} > <img src={iconClip7} width="30px" alt="clip7" /> 
                    </div>
                </div>
            } 
        <button style={{ fontSize:"11px", marginLeft: "5px", height:"24px", 
            paddingTop:"0px", width:"120px", verticalAlign:"middle", borderColor:"#9e9d9d", borderStyle:"solid", borderWidth:"1px",}} onClick={onClearClip} > Clear Clipping Mask </button>
    </div>
  )
}

export default ClipPath
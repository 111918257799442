import React from 'react'
import PhotoBrowse from './PhotoBrowse';
import PhotoSearch from './PhotoSearch';
import PhotosList from './PhotosList';


const Photos = () => {
  return (
    <div>
        <div style={{display:"flex"}}>
            <PhotoBrowse /> 
            
        </div>
    </div>
  )
}

export default Photos
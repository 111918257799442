import { useEffect, useState, useContext} from 'react';
import { FabricContext } from "../../context/FabricContext";
import axios from '../../api/axios';
import useAuth from '../hooks/useAuth';
import iconAddCart from "../../images/editor/add_cart.png";

const CartAdd = () => {
  const { canvas, fileName, size, } = useContext(FabricContext);
  const { auth } = useAuth();
  const DEBUG = true;
  const [errMsg, setErrMsg] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const API_URL = "/cart/new";

  const onAddCart = async () => {
      DEBUG && console.log("adding to cart ...");
      setErrMsg(null);
      setIsSaving(true);
      const bearer_token = `Bearer ${auth.accessToken}`;
      var unitprice = 100; // need to get this from DB
      var quantity = 1;
      var total = unitprice * quantity;
      const JSON_final = JSON.stringify({  
                "userID": auth.userID,
                "designID": size._id,
                "filename": fileName,
                "width": size.width,
                "height": size.height,
                "unit": size.unit,
                "type": size.type,
                "unitprice": unitprice,
                "quantity": quantity,
                "total": total
      });

      try {

          var response = await axios.post(API_URL, JSON_final,
            {
               headers: { 'Content-Type': 'application/json',
                           'Authorization': bearer_token },
               withCredentials: true
           });
           if (response.status === 201) { 
              DEBUG && console.log("Successfully added to cart ...");

           }
        
        }  catch (err) {
          if (!err?.response) {
            setErrMsg('Error!');
          } else if (err.response?.status === 400) {
            setErrMsg('Missing data');
          } else if (err.response?.status === 401) {
            setErrMsg('Unauthorized');
          } else {
              setErrMsg(err);
          }
        }
  }

  useEffect(() => {
    
  // eslint-disable-next-line
  },[]);

  return (
    <div>
      <div className='buttonAddToCart' style={{margin:"0px", padding:"3px", height:"30px", width:"100px", fontSize:"12px", }} 
                    onClick={onAddCart} ><img src={iconAddCart} width="24px" alt="Add to Cart"/> Add to Cart</div>
    </div>
  )
}

export default CartAdd
import React, {useContext, useState, useEffect} from 'react';
import { FabricContext } from "../../context/FabricContext";
import {getActiveStyle, setActiveStyle,} from "../libs/utils";
import iconBold from "../../images/editor/bold.png";



const _textbold = () => {
  const { activeObject } = useContext(FabricContext);
  const [options, setOptions] = useState({});
  const [buttonStyle, setButtonStyle] = useState({color:"#010107", margin:"auto", paddingTop: "3px", height:"35px", width:"55px", borderColor: "#c4bfbf", borderWidth: "1px"});

  useEffect(() => {
    if (activeObject) {
      const activeOptions = {
          fontSize: getActiveStyle("fontSize", activeObject),
          fontWeight: getActiveStyle("fontWeight", activeObject),
          fontStyle: getActiveStyle("fontStyle", activeObject),
          textAlign: activeObject["textAlign"],
          fontFamily: getActiveStyle("fontFamily", activeObject),
          textDecoration: getActiveStyle("textDecoration", activeObject),
          fill: getActiveStyle("fill", activeObject),
      }
      setOptions({ ...options, ...activeOptions })

      if (activeOptions.fontWeight === "bold") {
        setButtonStyle({background:"#ecebeb", margin:"auto", paddingTop: "3px", height:"35px", width:"55px", borderColor: "#c4bfbf", borderWidth: "1px"})
      } else 
      {
        setButtonStyle({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"55px", borderColor: "#c4bfbf", borderWidth: "1px"})
      }
     // console.log("selected text:", activeObject.getSelectedText())
  }

  }, [activeObject])


  const onBold = (e) => {
    const value = options.fontWeight === "bold" ? "normal" : "bold"
    setOptions({
           ...options,
            fontWeight: value,
    })
    setActiveStyle("fontWeight", value, activeObject)

    if (value === "bold") {
      setButtonStyle({background:"#ecebeb", margin:"auto", paddingTop: "3px", height:"35px", width:"55px", borderColor: "#c4bfbf", borderWidth: "1px"})
    } else 
    {
      setButtonStyle({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"55px", borderColor: "#c4bfbf", borderWidth: "1px"})
    }
    //console.log(value); 
  }

  
  return (
        <div style={{margin:"auto", paddingTop:"3px"}}>
                        <div className='buttonShapes' style={buttonStyle} 
                        onClick={onBold}><img src={iconBold} width="24px" alt="Bold"/> </div>
        </div>
      )
}

export default _textbold
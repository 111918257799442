import { Helmet } from 'react-helmet';
import CarouselMain from "./CarouselMain";
//import FeaturedProducts from "./FeaturedProducts";
//import Testimonials from './Testimonials';
//import Customers from './Customers';
import PaymentChannels from './PaymentChannels';
import Products from './Products';

const Home = () => {
    return <>
        <Helmet>
            <title>{'PrintBit'}</title>
        </Helmet>
        <div>
            <Products />
            <CarouselMain/>
            {/* <FeaturedProducts /> */}
           {/*  &nbsp;
            <Testimonials /> */}
            &nbsp;
           {/*  <Customers />
            &nbsp; */}
            <PaymentChannels />
            &nbsp;

        </div>
    </>;
}

export default Home;
import React, {useContext, useEffect, useState} from 'react';
import star from "../../images/editor/star.png";
import { FabricContext } from "../../context/FabricContext";
import { fabric } from 'fabric';



const FabricStar = () => {
    const { canvas } = useContext(FabricContext);
    const [mousePosition, setMousePosition] = useState({x:100, y: 100})
    const [isDrag, setisDrag] = useState(false);
    const [options, setOptions] = useState({left: 100,
      top: 10,
      scaleX: 0.5,
      scaleY: 0.5,
      fill: "#ffff00", 
      strokeWidth: 3,
      stroke: "#ffff00",
      originX: 'center',
      originY: 'center',
      angle: 0
    });



    const onAddStar = () => {
        if (isDrag) {    
          setOptions({...options,
            top: mousePosition.y,
            left: mousePosition.x,
          }) 
          setisDrag(false); 
        } 
        var points = [
            { x: 349.9, y: 75, },
            { x: 379, y: 160.9,},
            { x: 469, y: 160.9,},
            { x: 397, y: 214.9,},
            { x: 423, y: 300.9,},
            { x: 350, y: 249.9,},
            { x: 276.9, y: 301,},
            { x: 303, y: 215,},
            { x: 231, y: 161,},
            { x: 321, y: 161,},
         ];

        const star = new fabric.Polygon(points, options);
        canvas.add(star);

        if (!isDrag) {
          canvas.centerObject(star);
        }
        
        let count = canvas.getObjects().length;
        canvas.setActiveObject(canvas.item(count-1));
        //console.log(scale);
    }

    const onDrag = () => {
      canvas.on("dragover", function(options) {
        getMouseCoords(options);   
      }) 
     setisDrag(true);
    }
  
    const onDragEnd = () => {
      setOptions({...options,
          top: mousePosition.y,
          left: mousePosition.x,
        })
     
      onAddStar();
      canvas.off("dragover");
    }

    function getMouseCoords(event)    {
        var pointer = canvas.getPointer(event.e);
        var posX = pointer.x;
        var posY = pointer.y;
        setMousePosition({"x": posX, "y": posY });
    }

    useEffect(() => {
        setOptions({...options,
          top: mousePosition.y,
          left: mousePosition.x,
        })
    }, [mousePosition.x, mousePosition.y]);

  return (
    <div>
        <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onAddStar} onDragStart={onDrag} onDragEnd={onDragEnd}><img src={star} width="30px" alt="star"/> </div>
    </div>
  )
}

export default FabricStar
import React, {  useState } from 'react';
import { Outlet } from "react-router-dom";
import Header from './Header';
import Header2 from './Header2';
import Footer from './Footer';
import NavigationMain from './NavigationMain';


const Layout = () => {
    
    return (
        <>
            <Header />
            <NavigationMain />
            <Header2 />
            <main className="App">
                <Outlet />
            </main>
            <Footer />
            
        </>
    )
}

export default Layout
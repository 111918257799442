import { useLocation } from "react-router-dom";

const Header2 = () => {
    const { pathname } = useLocation();
    if (pathname === "/editor") return null;
    if (pathname === "/editornew") return null;
    return (
        <div className="user_header" style={{backgroundColor: "darkred"}}>
             &nbsp;
        </div>
    );
}

export default Header2;
import React, { useState, useEffect, useRef, useContext, useLayoutEffect} from 'react';
import { Link } from "react-router-dom";
import useAuth from './hooks/useAuth';
import useLogout from "./hooks/useLogout";

import EditorHeader from './EditorHeader';
import EditorMainMenu from './EditorMainMenu';

import BGcolors from './backgrounds/bgcolors';
import PhotoTools from './photos/PhotoTools';
import ShapesMenu from './shapes/ShapesMenu';
import TextMenu from './texts/TextMenu';

import Log from "./tools/log" ;
import New from "./tools/New";
import Open from "./tools/open";
import Save from "./tools/save";
import SaveAsTemplate from './tools/SaveAsTemplate';
import Download from "./tools/download";
import CartAdd from "./cart/cartAdd";
import Cart from "./cart/CartEditorButton";

import Undo from "./tools/undo";
import Redo from "./tools/redo";
import Up from "./tools/up";
import Down from "./tools/down";
import Top from "./tools/top";
import Bottom from "./tools/bottom";
import Lock from "./tools/lock";
import Duplicate from './tools/duplicate';
import Delete from './tools/delete';


import { FabricContext } from "../context/FabricContext";
import FabricCanvas              from "./FabricCanvas";

//Dialog Boxes
import ModalOpen from "./modals/modalOpen";
import DialogSave from './modals/dialogSave';
import DialogNew from './modals/dialogNew';
import DialogSaveAsTemplate from './modals/dialogSaveAsTemplate';
import DialogDownload from './modals/dialogDownload';
import DialogLoading from './modals/DialogLoading';

const Editor = (props) => {
  const DEBUG = false;

  const { canvas, activeObject, fileName, setFileName, size, setSize, isLoading, setIsLoading} = useContext(FabricContext);
  const { auth } = useAuth();
  const logout = useLogout();

  const [headerWH, setHeaderWH] = useState({"width": 90, "height": 30});
  const [isMobile, setIsMobile] = useState(false);
  const [canvasContainerStyle, setCanvasContainerStyle ] = 
      useState({textAlign:'center', background:'white', /* overflowX:"scroll", overflowY:"scroll" */ });
  const [windowSize, setWindowSize] = useState({width: 0, height: 0})
  //const [canvasContainerStyle2, setCanvasContainerStyle2 ] = 
   //   useState({textAlign:'center', background:'white', overflowX:"scroll", overflowY:"scroll" });
  const [canvasSize, setCanvasSize] = useState({width: 800, height: 600});
  const [canvasContainerSize, setCanvasContainerSize] = useState({width: 0, height: 0});
  const [designSize, setDesignSize] = useState({"width": 48, "height": 36, "unit": "inches", "type":"Tarpaulin Banner"});
  const [menuLeftSize, setMenuLeftSize] = useState({"width": 0, "height": 0});
  const [menuRightSize, setMenuRightSize] = useState({"width": 0, "height": 0});
  const hasWindow = typeof window !== 'undefined';  // For window resizing hook
  const [fontsLoading, setFontsLoading] = useState(true);
  
  const headerRef = useRef();
  const menuLeftRef = useRef();
  const headerMainRef = useRef();
  const menuRightRef = useRef();
  const footerRef = useRef();
  
  const [showFabricObjectsMenu, setshowFabricObjectsMenu] = useState(false);
  const [showTextMenu, setShowTextMenu] = useState(false);
  const [showBGColorMenu, setshowBGColorMenu] = useState(true);
  const [showPhotoToolsMenu, setshowPhotoToolsMenu] = useState(false);

  // Dialog Boxes
  const [showFormOpen, setShowFormOpen] = useState(false);
  const [showDialogSave, setShowDialogSave] = useState(false);
  const [showDialogNew, setShowDialogNew] = useState(false);
  const [showDialogTemplateSaveAs, setShowDialogTemplateSaveAs] = useState(false);
  const [showDialogDownload, setShowDialogDownload] = useState(false);

  //console.log("window", window.innerWidth, window.innerHeight);
  //console.log("header", headerWH);
  //console.log(canvasContainerStyle);


  useLayoutEffect(() => {
    setHeaderWH({"width": headerRef.current?.offsetWidth, "height": headerRef.current?.offsetHeight});
    setCanvasContainerStyle({...canvasContainerStyle, "width": window.innerWidth, "height": window.innerHeight - headerRef.current?.offsetHeight - footerRef.current?.offsetHeight });
    //setCanvasContainerStyle2({...canvasContainerStyle2, "width": window.innerWidth, "height": window.innerHeight - headerRef.current?.offsetHeight - footerRef.current?.offsetHeight });
    DEBUG && console.log("Menu Right width", menuRightRef.current?.offsetwidth );
    //console.log("canvasContainerStyle2", canvasContainerStyle2 );
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    /* Load FONTS */
    setFontsLoading(true);
    var FontFaceObserver = require('fontfaceobserver');
    var font1 = new FontFaceObserver('Bebas Neue');
    var font2 = new FontFaceObserver('Amarillo');
    var font3 = new FontFaceObserver('Achieve');
    var font4 = new FontFaceObserver('Ananda');
    var font5 = new FontFaceObserver('Caveat');

    Promise.all([font1.load(), font2.load(), font3.load(), font4.load(), font5.load()])
      .then(function () {
        DEBUG && console.log('Fonts have loaded');
      setFontsLoading(false);
      })
      .catch (function(err) {
        DEBUG && console.warn('Some critical font are not available:', err);
        setFontsLoading(false);
      })
    ;
   // setHeaderWH({"width": headerRef.current?.offsetWidth, "height": headerRef.current?.offsetHeight});
   // setCanvasContainerStyle({...canvasContainerStyle, "width": window.innerWidth, "height": window.innerHeight - headerRef.current?.offsetHeight - footerRef.current?.offsetHeight });
    //setMenuLeftSize({"width": menuLeftRef.current?.offsetWidth});  
  }, []);


  useEffect(() => { 
    if (!menuLeftRef.current) return;
      const resizeObserver = new ResizeObserver(() => {
          // Do what you want to do when the size of the element changes
          setMenuLeftSize({"width": menuLeftRef.current?.offsetWidth});
          setMenuRightSize({"width": menuRightRef.current?.offsetWidth});
          setCanvasContainerSize({width: window.innerWidth-menuLeftRef.current?.offsetWidth-menuRightRef.current?.offsetWidth, height: window.innerHeight-headerWH.height-footerRef.current?.offsetHeight})
          setWindowSize({width: window.innerWidth, height: window.innerHeight});
          DEBUG && console.log("menuleft", menuLeftRef.current.offsetWidth);
          DEBUG && console.log("menuRigh", menuRightRef.current.offsetWidth);
          DEBUG && console.log("Window", window.innerWidth, window.innerHeight);
          DEBUG && console.log("canvas container size", window.innerWidth-menuLeftSize.width-menuRightSize.width, window.innerHeight-headerWH.height-footerRef.current?.offsetHeight);
      });
      resizeObserver.observe(menuLeftRef.current);
      return () => resizeObserver.disconnect(); // clean up 
    //   
    //   console.log("menuleft", menuLeftRef.current?.offsetWidth, menuLeftSize.width );
   }, [window.innerWidth, window.innerHeight, canvasSize]); 

   // Window Resizing hook
  useEffect(() => {
    if (hasWindow) {
          function handleResize() {
            DEBUG && console.log("window size changed:", window.innerWidth)
            setMenuLeftSize({"width": menuLeftRef.current?.offsetWidth});
            setMenuRightSize({"width": menuRightRef.current?.offsetWidth});
            setCanvasContainerSize({width: window.innerWidth-menuLeftRef.current?.offsetWidth-menuRightRef.current?.offsetWidth, height: window.innerHeight-headerWH.height-footerRef.current?.offsetHeight})
            setWindowSize({width: window.innerWidth, height: window.innerHeight});
          }
          window.addEventListener("resize", handleResize);
          return () => window.removeEventListener("resize", handleResize);
    }
    // eslint-disable-next-line
  }, [hasWindow]); 

    //to display menu related to the object
  useEffect(() => { 

  if (canvas?.getActiveObject()?.get('type') === "rect" || 
      canvas?.getActiveObject()?.get('type') === "circle" ||
      canvas?.getActiveObject()?.get('type') === "triangle" ||
      canvas?.getActiveObject()?.get('type') === "polygon" ||
      canvas?.getActiveObject()?.get('type') === "path" ||
      canvas?.getActiveObject()?.get('type') === "line" ) 
    {
      setshowFabricObjectsMenu(true);
      DEBUG && console.log(canvas?.getActiveObject()?.get('type'));
    } 
  else {
    setshowFabricObjectsMenu(false);
    DEBUG && console.log(canvas?.getActiveObject()?.get('type'));
  }

  if (canvas?.getActiveObject()?.get('type') === "textbox" ||
      canvas?.getActiveObject()?.get('type') === "text")
    {
      setShowTextMenu(true);
    } else  {
      setShowTextMenu(false);
  }

  activeObject===null ? setshowBGColorMenu(true) : setshowBGColorMenu(false)

  canvas?.getActiveObject()?.get('type') === "image" ? setshowPhotoToolsMenu(true) : setshowPhotoToolsMenu(false)
  DEBUG && console.log(activeObject);
     
  // console.log("ActiveObjects", activeObject);
  // eslint-disable-next-line
  }, [activeObject]);

  const unSelectObjects = (e) => {
    var strTarget = e.target.className
    if (strTarget === "inside-shadow" || strTarget === "canvas-wrapper" || strTarget === "canvas-holder") {
       canvas.discardActiveObject().renderAll();
    }  
   //console.log(e.target) ; 
  }

  //Dialog box functions
  const showDialogOpen = ( showFormOpen ) => {
    setShowFormOpen(showFormOpen);
  }
  const funcShowDialogNew = ( param1 ) => {
    setShowDialogNew(param1);
  }
  const funcShowDialog = ( param1 ) => {
    setShowDialogSave(param1);
  }

  const childCallSetCanvasSize = (param1, param2, param3, param4, param5, param6, jsonData) => {
    setIsLoading(true);
    setDesignSize({"width": param1, "height": param2, "unit": param3, "type": param4}); // ex. 3x2 feet tarpualin banner
    setCanvasSize({"width": param5, "height": param6})  // width and height in pixels when the design is saved in to db
    canvas.setZoom(1);
    canvas.setWidth(param5);
    canvas.setHeight(param6);
    canvas.renderAll();
    canvas.loadFromJSON(jsonData, canvas.renderAll.bind(canvas), function(o, object) {
    //console.log("object", designSize.width);
    setIsLoading(false);
    });
    canvas.clearUndo();
    canvas.clearRedo();
    canvas.saveUndo();
    canvas.setZoom(1);
    canvas.setWidth(param5);
    canvas.setHeight(param6);
    canvas.renderAll();
    DEBUG && console.log("zoom:", canvas?.getZoom());
    DEBUG && console.log("OPEN", param1, param2, param3, param4, param5, param6);
   
  }

  const childCallSetCanvasSize_New = (param1, param2, param3, param4 ) => {
    setDesignSize({"width": param1, "height": param2, "unit": param3, "type": param4}); // ex. 3x2 feet tarpualin banner
    // setCanvasSize({"width": param1, "height": param2});
    let calculatedWidth = 0;
    let calculatedHeight = 0;
    let width_ = 0;
    let height_ = 0;
    width_ = parseFloat(param1);
    height_ = parseFloat(param2);

    if (width_ > height_) {
      calculatedWidth = (window.innerWidth - menuLeftRef.current.offsetWidth - menuRightRef.current.offsetWidth) * 0.9;
      calculatedHeight = (calculatedWidth * height_) / width_ ;
      //console.log("width is greater than height", param1, param2);
    } else {
      calculatedHeight = (canvasContainerSize.height-50) * 0.9;
      calculatedWidth =  (calculatedHeight * width_) / height_;
      //console.log("height is greater than width", param1, param2);
    }
    
    setCanvasSize({"width": calculatedWidth, "height": calculatedHeight});
   // console.log("CANVAS SIZE:", calculatedWidth, calculatedWidth)
    canvas.setZoom(1);
    canvas.setWidth(calculatedWidth);
    canvas.setHeight(calculatedHeight);
   //canvas.setWidth(500);
   //canvas.setHeight(500);
   // canvas.setZoom(.9);
    canvas.renderAll();
    DEBUG && console.log("NEW", param1, param2, param3, param4, calculatedWidth, calculatedHeight);
   // setSize({"width": param1, "height": param2, "unit": param3, "type":param4, canvaswidth: calculatedWidth, canvasheight: calculatedWidth})
    canvas.saveUndo();
  }

  
  //console.log("canvasContainerStyle", canvasContainerStyle );

  const signOut = async () => {
    //navigate('/');
    await logout();    
  }

  //ONETIME RUN
  useEffect(() => {
      setSize({"width": 48, "height": 36, "unit": "inches", "type":"Tarpaulin Banner", canvaswidth: 0, canvasheight: 0});
      //fabric.textureSize = 8000;
      //localStorage.setItem('undoRedoLock', false);

  }, []);

   // Restore contents after browser refresh
  useEffect(() => {
    DEBUG && console.log("Canvas changed...")
    if (fontsLoading) {
      DEBUG && console.log("Fonts are still loading... Not loading canvas...")
      return;
    } 
    if (localStorage.getItem('canvasJSON') && localStorage.getItem('canvasDetails')) {
      DEBUG && console.log("loading data from localstorage");

        const _canvasDetails = JSON.parse(localStorage.getItem('canvasDetails'));
        DEBUG && console.log("_canvasSize",_canvasDetails._id, _canvasDetails.width, _canvasDetails.height, _canvasDetails.type, _canvasDetails.unit);
       // childCallSetCanvasSize(_canvasDetails.width, _canvasDetails.height, _canvasDetails.unit, _canvasDetails.type, _canvasDetails.canvaswidth, 
       //                        _canvasDetails.canvasheight, JSON.parse(localStorage.getItem('canvasJSON')));
        setDesignSize({"width": _canvasDetails.width, "height": _canvasDetails.height, "unit": _canvasDetails.unit, "type": _canvasDetails.type}); // ex. 3x2 feet tarpualin banner
        setCanvasSize({"width": _canvasDetails.canvaswidth, "height": _canvasDetails.canvasheight})  // width and height in pixels when the design is saved in to db
        canvas?.setZoom(_canvasDetails.zoom);
        canvas?.setWidth(_canvasDetails.canvaswidth);
        canvas?.setHeight( _canvasDetails.canvasheight);
        canvas?.renderAll();
        canvas?.loadFromJSON(JSON.parse(localStorage.getItem('canvasJSON')), canvas.renderAll.bind(canvas), function(o, object) {
        //console.log("object", designSize.width);
          });
          setSize({_id: _canvasDetails._id, 
              width: _canvasDetails.width,
              height: _canvasDetails.height,
              unit: _canvasDetails.unit, // inches, cm, meter
              type: _canvasDetails.type,
              canvaswidth: _canvasDetails.canvaswidth,
              canvasheight: _canvasDetails.canvasheight
          })
          setFileName(_canvasDetails.filename);
      //canvas?.setZoom(_canvasDetails.zoom);
    }
  }, [canvas, fontsLoading]);


  useEffect(() => {
    if (canvas) {
      var _canvasDetails;
      if (canvas?.getZoom() ===1) { 
        _canvasDetails = {"_id": size._id, "width": size.width, "height": size.height, "unit": size.unit, "type": size.type, 
        "canvaswidth": canvas?.width, "canvasheight": canvas.height, "filename": fileName, "zoom": 1};
      } else {
        var zoom = canvas?.getZoom();
        var width = canvas.width * (1/zoom);
        var height = canvas.height * (1/zoom);

        _canvasDetails = {"_id": size._id, "width": size.width, "height": size.height, "unit": size.unit, "type": size.type, 
        "canvaswidth": width, "canvasheight": height, "filename": fileName, "zoom": 1};
      }
      
      localStorage.setItem('canvasJSON', JSON.stringify(canvas?.toJSON()));
      localStorage.setItem('canvasDetails', JSON.stringify(_canvasDetails));
      DEBUG && console.log("CANVAS DETAILS:", _canvasDetails);
      DEBUG && console.log("Localstorage updated!");
    }
  }, [activeObject]);

   //return fontsLoading ? null : (
    return  (
    <div style={{display:"flex", flexDirection:"column", height: windowSize.height }}>    
      <header ref={headerRef} className="header" style={{width: "100%", color:"white", padding: "0", margin: "0"}}>
        <div style={{display: "flex", alignItems:"flex-start"}}> 
            <div style={{width: "200px", height: "38px", padding: "0px", margin: "0px"}}> <EditorHeader/> </div> 
            <div style={{display: "flex", flex: "10 0 200px", alignItems:"center", justifyContent: "right", textAlign: "right", align: "right", padding:"3px"}}> 
              <New showDialogNew={funcShowDialogNew} />
              <Open showModal={showDialogOpen} /> 
              <Save showDialogSave={funcShowDialog}/> 
             {/*  <SaveAsTemplate showDialogTemplateSaveAs={setShowDialogTemplateSaveAs} /> */}
              {/* <Log />  */}
              <Cart /> &nbsp;
              { auth?.accessToken ? <Link to="/profile" className="login-link"> {auth.email}</Link> 
                                          : <Link to="/login" className="login-link"> Login </Link> }   
                      &nbsp; | &nbsp; <Link className="login-link" onClick={signOut}> Logout </Link> 
            </div>
        </div>
         
      </header>

      {/*MIDDLE SECTION */}
      <div style={{display: "flex", flexDirection: "row"}}> 
            {/*LEFT MENU section */}
            <div ref={menuLeftRef} className="aside menuSide" style={{textAlign:"center", height: window.innerHeight-headerWH.height-footerRef.current?.offsetHeight}}> 
                <EditorMainMenu mobile={isMobile} width={"800px"} height={window.innerHeight-headerWH.height-footerRef.current?.offsetHeight} 
                  changeDesignSize={childCallSetCanvasSize}
                />  {/*dynamic height */}
            </div> 

            {/*CENTER section*/} 
            <div className="main">
            
              <div ref={headerMainRef} className='headerMain' style={{display:"flex", justifyContent: "space-between", alignItems:"center", height:"40px"}}> 
                  {/*HEADER section*/} 
                  <div style={{display:"flex", justifyContent: "center", alignItems:"center", height:"40px", paddingRight:"5px", width:"75%"}}>
                          {fileName} | {designSize.width} x {designSize.height} {designSize.unit} &nbsp; &nbsp;   <Undo /> <Redo /> <Up /> <Down /> <Top /> <Bottom /> <Lock /> <Duplicate /> <Delete /> 
                  </div>
                  <div style={{display:"flex", justifyContent: "center", alignItems:"center", height:"40px", paddingRight:"5px", width:"25%"}}>
                      <CartAdd /> &nbsp; <Download showDialog={setShowDialogDownload}/> 
                  </div>

              </div>

              {/*CANVAS section*/}  

                      <div className="inside-shadow" 
                      style={{height:  canvasContainerSize.height-50, display:"flex", justifyContent:"center", alignItems:"top", padding:"0px"}} onClick = {unSelectObjects}>
                      
                            <div className="canvas-holder" style={{ position: "relative", display:"flex", justifyContent:"center", alignItems:"center",
                              overflowY: "scroll", overflowX: "scroll", width: canvasContainerSize.width-25, height: canvasContainerSize.height-50, padding:"5px"}} > 
                              
                                <FabricCanvas  width={canvasSize.width} height={canvasSize.height}/>
                            
                            </div>
                    
                    </div>

            </div>   

            {/*RIGHT MENU section*/} 
            <div ref={menuRightRef} className="aside  menuSideRight" style={{width: "250px",  height: window.innerHeight-headerWH.height-footerRef.current?.offsetHeight}}>      
                  { 
                    showBGColorMenu &&  <BGcolors />
                  }

                  {
                    showTextMenu  && <TextMenu />
                  }
                  {
                    showPhotoToolsMenu && <PhotoTools />
                  }
                  {
                    showFabricObjectsMenu && <ShapesMenu />
                  }

              </div>
        </div>
 
        {/*FOOTER section*/} 
        <div ref={footerRef} className='footer' style={{color:"white", fontSize:"12px"}}>
             Developed by: Yorbs  |  Customer Support: info@printbit.com  
        </div>

        {/* Dialog boxes controllers */}
        {showFormOpen && <ModalOpen setOpenModal={setShowFormOpen} changeDesignSize={childCallSetCanvasSize} />}  
        {showDialogSave && <DialogSave setDialogSave={setShowDialogSave} /> }  
        {showDialogNew && <DialogNew setDialogNew={setShowDialogNew} changeDesignSize={childCallSetCanvasSize_New}  /> }
        {showDialogTemplateSaveAs && <DialogSaveAsTemplate setDialogSave={setShowDialogTemplateSaveAs}/>} 
        {showDialogDownload && <DialogDownload showDialog={setShowDialogDownload} />}
        {isLoading && <DialogLoading />}

    </div>
  );
}

export default Editor;
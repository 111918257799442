import { useEffect, useState, useContext}  from 'react'
import { FabricContext } from "../../context/FabricContext";


const DialogNew = ({setDialogNew, changeDesignSize}) => {
    const { canvas, setFileName, setSize, setImageFiles } = useContext(FabricContext);
    const [errMsg, setErrMsg] = useState('');
    const [size1, setSize1] = useState("4,3,ft.,Tarpaulin Banner");
    const [customWidth, setCustomWidth] = useState(0);
    const [customHeight, setCustomHeight] = useState(0);
    const [customUnit, setCustomUnit] = useState(null);
    const [customDisabled, setCustomDisabled] = useState(true);

    const newDesign =  () => {
        if (size1==="custom") {
            if ((customWidth===0) || (customHeight===0)) {
              setErrMsg("Width and Height must be greater than 0");
              return;
            }
        }
        canvas.clear();
        canvas.setZoom(1);
        //initCanvas();
        const arrSize = size1.split(',');
        let width = arrSize[0];
        let height = arrSize[1];
        let unit = arrSize[2];
        let type = arrSize[3];
        if (size1==="custom") {
           width = customWidth;
           height = customHeight;
           unit = customUnit;
           type = "custom";
           if(unit===null) unit = "inches";
        }
        changeDesignSize(width, height, unit, type);
        //console.log(width, height, unit, type);
        setFileName('untitled');
        let _id = undefined;
        setSize({"_id":_id, "width":width, "height":height, "unit": unit, "type": type}); //update fabric context
        setImageFiles([]);
        setDialogNew(false);
    }

    const onOptionChange = e => {
        setSize1(e.target.value)
        if (e.target.value==="custom") {
          setCustomDisabled(false);
          return;
        } else {
          setCustomDisabled(true);
        }
        
    }

    const onUnitChange = e => {
        setCustomUnit(e.target.value);
    }

    useEffect(() => { 
       // console.log(size1);
        //const arrSize = size.split(',');
        //console.log(arrSize);
      },[size1]);

  return (
    <div className="modalBackground">
        <div className="modalContainer" style={{height:"450px"}}>
            <div className="titleCloseBtn">
                <button onClick={() => { setDialogNew(false); }}> X </button>
            </div>
            <div className="title">
                <h4>NEW DESIGN</h4>
                <span style={{color: "red", fontSize: "12px"}}> {errMsg} </span>
            </div>
            <div className="body" style={{height:"250px", }}>
             
              <div style={{width: "100%", backgroundColor: "#dddcdc"}} >  Tarpaulin Banners:  </div>
                <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "center", alignItems: "center", alignContent: "space-between", width: "90%", padding:"5px" }}>
                    <label> <input type="radio" name="new" value="3,2,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 3x2 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="3,3,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 3x3 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="4,3,ft.,Tarpaulin Banner" onChange={onOptionChange} defaultChecked /> 4x3 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="5,3,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 5x3 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="6,3,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 6x3 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="8,3,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 8x3 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="4,2,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 4x2 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="4,4,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 4x4 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="5,4,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 5x4 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="6,4,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 6x4 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="7,4,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 7x4 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="8,4,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 8x4 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="5,2,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 5x2 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="2,5,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 2x5 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="3.5,2.5,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 3.5x2.5 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="3.5,2.5,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 3.5x2.5 ft. </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="10,5,ft.,Tarpaulin Banner" onChange={onOptionChange}/> 10x5 ft. </label> &nbsp; &nbsp;
                </div>
              <div style={{width: "100%", backgroundColor: "#dddcdc"}} >  Business Cards:  </div>
                <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "center", alignItems: "center", alignContent: "space-between", width: "90%", padding:"5px"  }}>
                    <label> <input type="radio" name="new" value="3.5,2,inches,Business Card" onChange={onOptionChange}/> 3.5x2 inches (landscape) </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="2,3.5,inches,Business Card" onChange={onOptionChange}/> 2x3.5 inches (Portrait) </label> &nbsp; &nbsp;     
              </div>

              <div style={{width: "100%", backgroundColor: "#dddcdc"}} >  Invitations Cards:  </div>
                <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "center", alignItems: "center", alignContent: "space-between", width: "90%", padding:"5px"  }}>
                    <label> <input type="radio" name="new" value="21,29.7,cm,Invitation Card" onChange={onOptionChange}/> A4 (21x29.7 cm) </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="14.8,21,cm,Invitation Card" onChange={onOptionChange}/> A5 (14.8x21 cm) </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="5,7,inches,Invitation Card" onChange={onOptionChange}/> 5R (5x7 inches) </label> &nbsp; &nbsp;     
              </div>

              <div style={{width: "100%", backgroundColor: "#dddcdc"}} >  Posters:  </div>
                <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "center", alignItems: "center", alignContent: "space-between", width: "90%", padding:"5px"  }}>
                    <label> <input type="radio" name="new" value="21,29.7,cm,Poster" onChange={onOptionChange}/> A4 (21x29.7 cm) </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="29.7,42,cm,Poster" onChange={onOptionChange}/> A3 (29.7x42 cm) </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="12,18,inches,Poster" onChange={onOptionChange}/> 12x18 inches </label> &nbsp; &nbsp;     
              </div>

              <div style={{width: "100%", backgroundColor: "#dddcdc"}} >  Social Media Contents:  </div>
                <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "center", alignItems: "center", alignContent: "space-between", width: "90%", padding:"5px"  }}>
                    <label> <input type="radio" name="new" value="940,788,px,Facebook Post" onChange={onOptionChange}/> Facebook Post  </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="851,315,px,Facebook Cover" onChange={onOptionChange}/> Facebook Cover </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="1080,1920,px,Facebook Story" onChange={onOptionChange}/> Facebook Story </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="1200,628,px,Facebook Ad" onChange={onOptionChange}/> Facebook Ad </label> &nbsp; &nbsp;    
                    <label> <input type="radio" name="new" value="1080,1080,px,Instagram Post" onChange={onOptionChange}/> Instagram Post</label> &nbsp; &nbsp;
                    <label> <input type="radio" name="new" value="1080,1080,px,Instagram Ad" onChange={onOptionChange}/> Instagram Ad </label> &nbsp; &nbsp;     
                    <label> <input type="radio" name="new" value="1080,1920,px,Instagram Story" onChange={onOptionChange}/> Instagram Story </label> &nbsp; &nbsp;   
                    <label> <input type="radio" name="new" value="1280,720,px,Youtube Thumbnail" onChange={onOptionChange}/> Youtube Thumbnail</label> &nbsp; &nbsp;  
                    <label> <input type="radio" name="new" value="2560,1440,px,Youtube Channel" onChange={onOptionChange}/> Youtube Channel</label> &nbsp; &nbsp;       
              </div>
              <div style={{width: "100%", backgroundColor: "#dddcdc"}} > <label> <input type="radio" name="new" value="custom" onChange={onOptionChange} /> Custom Size :</label>  </div>
                <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "center", alignItems: "center", alignContent: "space-between", width: "100%", padding:"5px"  }}>
                    <label> Width <input type="text" name="width" style={{width:"70px"}} 
                                    value={customWidth} 
                                    disabled={customDisabled}
                                    onChange={e =>  {
                                      setCustomWidth(e.target.value);
                                      }
                                    } 
                                  />   
                    </label> &nbsp; &nbsp;
                    <label> Height <input type="text" name="width" style={{width:"70px"}}
                                    value={customHeight} 
                                    disabled={customDisabled}
                                    onChange={e =>  {
                                      setCustomHeight(e.target.value);
                                      }
                                    } 
                                   /> 
                    </label> &nbsp; &nbsp;
                    <label> <input type="radio" name="unit" value="inches" onChange={onUnitChange} disabled={customDisabled} defaultChecked /> inches </label> &nbsp; 
                    <label> <input type="radio" name="unit" value="feet" onChange={onUnitChange} disabled={customDisabled}/> feet </label> &nbsp;      
                    <label> <input type="radio" name="unit" value="cm" onChange={onUnitChange} disabled={customDisabled}/> cm </label> &nbsp;
                    <label> <input type="radio" name="unit" value="mm" onChange={onUnitChange} disabled={customDisabled}/> mm </label> &nbsp;
                    <label> <input type="radio" name="unit" value="px" onChange={onUnitChange} disabled={customDisabled}/> px </label> &nbsp;
                    <br /><br />
              </div>
            </div> 
            <div className="footer" style={{height:"50px"}}>
                <button onClick={() => {setDialogNew(false);}} id="cancelBtn"> Cancel </button>
                <button onClick={newDesign} > OK </button>
            </div>
        </div>
    </div>   
  )
}

export default DialogNew
import React, {
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useCallback,
    }                       from "react";
import { FabricContext }    from "../context/FabricContext";


const FabricCanvas = ({ jsonData = null, width, height}) => {
    const canvasEl = useRef(null);
    const { canvas, initCanvas, setActiveObject, loadFromJSON, historyLocked} = useContext(FabricContext);
    //const { undoData, setUndoData, undoLock, setUndoLock} = useContext(UndoRedoContext);

    useLayoutEffect(() => {
        if (jsonData) {
            loadFromJSON(canvasEl.current, jsonData)
        } else {
            initCanvas(canvasEl.current, {
                width: width,
                height: height,
            })  
        }
    }, [canvasEl, initCanvas, loadFromJSON, jsonData])

    const updateActiveObject = useCallback((e) => {
        if (!e) {
            return
        }
        setActiveObject(canvas.getActiveObject())

        // added for locking
        //console.log("selection:created", e);
          
        canvas.renderAll() 
    }, [canvas, setActiveObject])

    useEffect(() => {
        if (!canvas) {
            return
        }
        canvas.on("selection:cleared", updateActiveObject)
        
        canvas.on('selection:updated', ({ selected }) => {   // for locking
            setActiveObject(canvas.getActiveObject());
            //console.log ("target", canvas.getActiveObject().type);     
            canvas.renderAll();
            var activeO = canvas.getActiveObject();
           
            if (activeO.type === "activeSelection") {
                activeO._objects.forEach(function(item) {
                    if (item.lockMovementX || item.lockMovementY || item.lockRotation || item.lockScalingX || item.lockScalingY) {   // something (logic) is wrong here 
                        activeO.lockMovementX = true;
                        activeO.lockMovementY = true;
                        activeO.lockRotation = true;
                        activeO.lockScalingX = true;
                        activeO.lockScalingY = true;
                    }
                })   
            }

        })

        canvas.on("selection:created", ({ selected }) => {   // for locking
            setActiveObject(canvas.getActiveObject());
            //console.log ("target", canvas.getActiveObject().type);     
            canvas.renderAll();
            var activeO = canvas.getActiveObject();
           
            if (activeO.type === "activeSelection") {
                activeO._objects.forEach(function(item) {
                    if (item.lockMovementX || item.lockMovementY || item.lockRotation || item.lockScalingX || item.lockScalingY) {
                        activeO.lockMovementX = true;
                        activeO.lockMovementY = true;
                        activeO.lockRotation = true;
                        activeO.lockScalingX = true;
                        activeO.lockScalingY = true;
                    }
                })  
            }
        })

        canvas.on('mouse:wheel', function(opt) {
            var delta = opt.e.deltaY;
            var zoom = canvas.getZoom();
            zoom *= 0.9999 ** delta;
            if (zoom > 5) zoom = 5;
            if (zoom < 0.2) zoom = 0.2;
           // canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            canvas.setZoom(zoom);
           // console.log(width, height, zoom, delta);
           // console.log("size:", size);
            canvas.setWidth(width*zoom);
            canvas.setHeight(height*zoom);
            canvas.renderAll();
           // console.log("canvas width:", canvas.width);
            opt.e.preventDefault();
            opt.e.stopPropagation();
          });

        canvas.on('object:modified', function(opt) {
            canvas.saveUndo();
            //console.log("object:modified"); 
        });

        return () => {
            canvas.off("selection:created")
            canvas.off("selection:cleared")
            canvas.off("selection:updated")
            canvas.off("drop")
            canvas.off("object:modified")
            canvas.off("object:added")
        }
    }, [canvas, updateActiveObject, width, height])

    // useEffect(() => {
    //     if (!canvas) {
    //         return
    //     }
    //   //  let ratio = width/prevWidth;
    //    // if (ratio > 0) canvas.setZoom(ratio);

       
    //   // if (counter===0 && width > 0 ) {
    //   //      setOrigSize({"width": width, "height": height})
    //    //     setCounter(1);
    //   // } 

    //   //  canvas.setWidth(width);
    //    // canvas.setHeight(height);

    //     var scaleMultiplier = 0.0;
    //     scaleMultiplier = width / origsize.width;

    //     console.log("scale in fabric canvas: ", scaleMultiplier);

    //    // canvas.setZoom(scaleMultiplier);
    //    const zoomVal = canvas.getZoom();

    //     var obj = canvas.backgroundImage;
    //     if(obj){
    //        // obj.scaleX = obj.scaleX * scaleMultiplier;
    //        // obj.scaleY = obj.scaleY * scaleMultiplier;
    //        let scaleRatio = Math.max((canvas.width / zoomVal) / obj.width, (canvas.height / zoomVal) / obj.height);
    //        canvas.setBackgroundImage(obj, canvas.renderAll.bind(canvas), {
    //         scaleX: scaleRatio,
    //         scaleY: scaleRatio,
    //         left: canvas.width / 2,
    //         top: canvas.height / 2,
    //         originX: 'middle',
    //         originY: 'middle',
    //      });
    //     }

    //     /* var obj = canvas.backgroundImage;
    //     if(obj){
    //         obj.scaleX = obj.scaleX * scaleMultiplier;
    //         obj.scaleY = obj.scaleY * scaleMultiplier;
    //     } */

    //     var objects = canvas.getObjects();
    //     for (var i in objects) {
    //         objects[i].scaleX = objects[i].scaleX * scaleMultiplier;
    //         objects[i].scaleY = objects[i].scaleY * scaleMultiplier;
    //         objects[i].left = objects[i].left * scaleMultiplier;
    //         objects[i].top = objects[i].top * scaleMultiplier;
    //         objects[i].setCoords();
    //     }  
 
    //    // canvas.discardActiveObject();
    //    // canvas.setWidth(canvas.getWidth() * scaleMultiplier);
    //    // canvas.setHeight(canvas.getHeight() * scaleMultiplier);
    //     canvas.renderAll();
    //     canvas.calcOffset();

    //     //setPrevHeight(height);
    //     //setPrevWidth(width);
        
        
    //     //canvas.setZoom(canvas.getZoom()+1);
    //     //canvas.setWidth(originalWidth * canvas.getZoom());
    //     //canvas.setHeight(originalHeight * canvas.getZoom());

    //   //  console.log(width);
    //    // console.log(ratio);
    //    setOrigSize({"width": width, "height": height})
    //   // dbwidth = width;
    //    console.log("Orig width height", origsize.width, origsize.height);
    // }, [width, height ])

    useEffect(() => {
        // console.log("CANVAS LOADING... ");
        // if (localStorage.getItem('canvasJSON')) {
        //     console.log("canvas data found in localstorage... ");  
        //     if (canvas) {
        //         canvas.loadFromJSON(localStorage.getItem('canvasJSON'), canvas.renderAll.bind(canvas), function(o, object) {
        //             console.log("Loading data from localstorage...");  
        //         });
        //     }
        // }
            //   setSize(localStorage.getItem('canvasSize'));
    }, []);

    useEffect(() => { 
       // setOrigSize(width, height);
        //console.log("width height changed", width, height);

    }, [width, height])
 
    return (
        <div className="canvas-wrapper">
            <canvas ref={canvasEl}
                    id="fabric-canvas"
                    width={width}
                    height={height}
                    style={{ border: "1px solid #b8b4b4"}} />
        </div>
    )
}

export default FabricCanvas
import { useEffect, useState, useContext} from 'react';
import "./modal.css";
import { ProgressBar } from 'react-loader-spinner'
import { FabricContext } from "../../context/FabricContext";
import axios from '../../api/axios';
import useAuth from '../hooks/useAuth';
const DESIGN_URL = '/design_view';
const DESIGN_OPEN_URL = '/design_open';

const ModalOpen = ({ setOpenModal, changeDesignSize}) => {
    const { setFileName, setSize, setImageFiles } = useContext(FabricContext);
    const { auth } = useAuth();
    const [myDesigns, setMyDesigns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedValue,setSelectedValue] = useState(null); 
    const [selectedFileName, setSelectedFileName] = useState(null);

    const styles = { 
        container: { 
            display: "flex", 
            flexDirection: "row",
            justifyContent: "center", 
            alignItems: "center", 
        }, 
        heading: { 
            color: "green", 
            textAlign: "center", 
        }, 
        radioButton: { 
            padding: "12px 16px", 
            borderRadius: "8px", 
            margin: "3px", 
            border: "1px solid #b0bfcf", 
            background: "#fff", 
            display: "flex", 
            flexDirection: "row",
            alignItems: "center", 
            justifyContent: "center", 
            width: "150px", 
            cursor: "pointer", 
            transition: 
                "background-color 0.3s, color 0.3s", 
        }, 
        selected: { 
            background: "#007BFF", 
            color: "#fff", 
            borderColor: "#007BFF", 
        }, 
        list: { 
            listStyleType: "none", 
            listStyle: "none",
            padding: 0, 
            textAlign: "center", 
            display: "flex", 
            flexWrap: "wrap", 
            justifyContent: "space-around"
        }, 
    }; 

    const CustomRadioButton = ({ 
        id,
        label, 
        selected, 
        onSelect, 
    }) => ( 
        <li> 
            <button id={id}
                style={{ 
                    ...styles.radioButton, 
                    ...(selected 
                        ? styles.selected 
                        : {}), 
                }} 
                onClick={onSelect} 
            > 
                {label} 
            </button> 
        </li> 
    ); 

    const loadDesigns = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(DESIGN_URL,
                JSON.stringify({ "userID": auth.userID }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            //const designs = JSON.stringify(response?.data);
            const designs = response?.data;
            //console.log(designs);
            setMyDesigns(designs);
            setIsLoading(false);
        } catch (error) {
           // console.log("Error connecting to server ...", error);
            setIsLoading(false);
        } 
     }
    
     useEffect(() => {
        //console.log(selectedValue);
     },[selectedValue]);

     useEffect(() => {
        loadDesigns();
     // eslint-disable-next-line
     },[]);

     
     const openDesign = async () => {
        setIsLoading(true);
        //console.log("Design ID:", selectedValue)
        const bearer_token = `Bearer ${auth.accessToken}`;
        try {
            const response = await axios.post(DESIGN_OPEN_URL,
                JSON.stringify({ "userID": auth.userID, "designID": selectedValue }),
                {
                    headers: { 'Content-Type': 'application/json',
                              'Authorization': bearer_token },
                    withCredentials: true
                }
            );
            //const designs = JSON.stringify(response?.data);
            const _id = selectedValue;
            const content  = response?.data[0].content;
            const width = response?.data[0].width;
            const height = response?.data[0].height;
            const unit = response?.data[0].unit;
            const type = response?.data[0].type;
            const canvaswidth = response?.data[0].canvaswidth;
            const canvasheight = response?.data[0].canvasheight;
            var myJSON = JSON.stringify(content);
          // console.log(response?.data[0].content);
           //console.log(width, height, unit, type);
            setFileName(selectedFileName);
            setSize({_id, width, height, unit, type, canvaswidth, canvasheight}); //update fabric context
            changeDesignSize(width, height, unit, type, canvaswidth, canvasheight, myJSON);  // change the canvas size 
            setImageFiles([]);
            //canvas.setDimensions({"width": canvaswidth, "height": canvasheight});
            //canvas.loadFromJSON(myJSON, canvas.renderAll.bind(canvas));
           // setMyDesign(design);
        
        } catch (error) {
           // console.log("Error connecting to server ...", error);
            setIsLoading(false);
        } 
        setIsLoading(false);
        setOpenModal(false);
     }

    return (
    <div className="modalBackground">
        <div className="modalContainer">
            <div className="titleCloseBtn">
                <button onClick={() => { setOpenModal(false); }}> X </button>
            </div>
            <div className="title">
                <h4>MY CREATIONS</h4>
            </div>
            <div className="body">
                <div style={styles.container} > 
                 {
                    isLoading ? <ProgressBar 
                                visible={true}
                                height="80"
                                width="80"
                                color="#097fee"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{}}
                                wrapperClass="" /> : "" 
                 }
                    <ul style={styles.list}> 
                        { myDesigns.map((designs, i) => (
                                                        myDesigns.length > 0 ?  
                                                            <CustomRadioButton id={designs._id} key={i} label={designs.filename}
                                                                selected={selectedValue === designs._id} 
                                                                onSelect={() =>  {
                                                                        setSelectedValue(designs._id);
                                                                        setSelectedFileName(designs.filename);
                                                                        }
                                                                } 
                                                            /> 
                                                            : ""  
                                                        )
                                        )
                        }
                    </ul>
                </div>
            </div> 
            <div className="footer">
                <button onClick={() => {setOpenModal(false);}} id="cancelBtn"> Cancel </button>
                <button onClick={openDesign}> Open </button>
            </div>
        </div>
    </div>
  );
}

export default ModalOpen;
import React, {useContext, useState, useEffect} from 'react';
import { FabricContext } from "../../context/FabricContext";
import {setActiveStyle,} from "../libs/utils";
import iconCenter from "../../images/editor/center.png";
import iconLeft from "../../images/editor/left.png";
import iconRight from "../../images/editor/right.png";

const _textalign = () => {
    const {activeObject } = useContext(FabricContext);
    const [options, setOptions] = useState({textAlign: "left"});
    const [buttonCenterStyle, setButtonCenterStyle] = useState({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"70px", borderColor: "#c4bfbf", borderWidth: "1px"});
    const [buttonLeftStyle, setButtonLeftStyle] = useState({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"70px", borderColor: "#c4bfbf", borderWidth: "1px"});
    const [buttonRightStyle, setButtonRightStyle] = useState({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"70px", borderColor: "#c4bfbf", borderWidth: "1px"});

    useEffect(() => {
       // var tmp = activeObject["textAlign"];
        if (activeObject) {
           const activeOptions = {
              textAlign: activeObject["textAlign"],
           }
           if (activeOptions.textAlign === "center") {
                setButtonCenterStyle({background:"#ecebeb", margin:"auto", paddingTop: "3px", height:"35px", width:"70px", borderColor: "#c4bfbf", borderWidth: "1px"});
                setButtonLeftStyle({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"70px", borderColor: "#c4bfbf", borderWidth: "1px"});
                setButtonRightStyle({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"70px", borderColor: "#c4bfbf", borderWidth: "1px"});
            } else if (activeOptions.textAlign === "left")
             {
               setButtonCenterStyle({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"70px", borderColor: "#c4bfbf", borderWidth: "1px"});
               setButtonLeftStyle({background:"#ecebeb", margin:"auto", paddingTop: "3px", height:"35px", width:"70px", borderColor: "#c4bfbf", borderWidth: "1px"});
               setButtonRightStyle({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"70px", borderColor: "#c4bfbf", borderWidth: "1px"});
            } else {
               setButtonCenterStyle({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"70px", borderColor: "#c4bfbf", borderWidth: "1px"});
               setButtonLeftStyle({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"70px", borderColor: "#c4bfbf", borderWidth: "1px"});
               setButtonRightStyle({background:"#ecebeb", margin:"auto", paddingTop: "3px", height:"35px", width:"70px", borderColor: "#c4bfbf", borderWidth: "1px"});
            }
        }  

      // console.log(options)
    }, [activeObject, options])
    
    
    const onCenter = (e) => {
        setOptions({
            ...options,
            textAlign: "center",
        })
        setActiveStyle("textAlign", "center", activeObject)
        setButtonCenterStyle({background:"#ecebeb", margin:"auto", paddingTop: "3px", height:"35px", width:"70px", borderColor: "#c4bfbf", borderWidth: "1px"})
    }

    const onAlignLeft = (e) => {
        setOptions({
            ...options,
            textAlign: "left",
        })
        setActiveStyle("textAlign", "left", activeObject)
        setButtonLeftStyle({background:"#ecebeb", margin:"auto", paddingTop: "3px", height:"35px", width:"70px", borderColor: "#c4bfbf", borderWidth: "1px"})
    }

    const onAlignRight = (e) => {
        setOptions({
            ...options,
            textAlign: "right",
        })
        setActiveStyle("textAlign", "right", activeObject)
        setButtonRightStyle({background:"#ecebeb", margin:"auto", paddingTop: "3px", height:"35px", width:"70px", borderColor: "#c4bfbf", borderWidth: "1px"})
    }

  return (
    <div style={{display:"flex", flexDirection:"row", width: "100%"}}>
         <div style={{margin:"auto", paddingTop:"0px"}}>
                    <div className='buttonShapes' style={buttonLeftStyle} 
                    onClick={onAlignLeft}><img src={iconLeft} width="24px" alt="Left"/> </div>
         </div>

        <div style={{margin:"auto", paddingTop:"0px"}}>
                    <div className='buttonShapes' style={buttonCenterStyle} 
                    onClick={onCenter}><img src={iconCenter} width="24px" alt="Center"/> </div>
         </div>

         <div style={{margin:"auto", paddingTop:"0px"}}>
                    <div className='buttonShapes' style={buttonRightStyle} 
                    onClick={onAlignRight}><img src={iconRight} width="24px" alt="Right"/> </div>
         </div>
        
    </div>
  )
}

export default _textalign
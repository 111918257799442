import React, {useContext} from 'react';
import iconBottom from "../../images/editor/bottom.png";
import { FabricContext } from "../../context/FabricContext";

const Bottom = () => {
    const { canvas, activeObject } = useContext(FabricContext);
    const onBottom = () => {
        console.log("Bottom...");
        canvas.sendToBack(activeObject);
    }
  return (
    <div>
        <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} 
              onClick={onBottom} ><img src={iconBottom} width="24px" alt="Bottom"/> </div>
    </div>
  )
}

export default Bottom
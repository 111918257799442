const Terms = () => {
    return ( <>
        <div className="new-order-container" style={{backgroundColor:"#e9f3fc", display: "flex", flexDirection: "row", flexWrap: "wrap", 
        justifyContent:"center", margin: "10px auto 10px auto", alignItems: "flexStart", boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>  
      
      <div style={{width: "100%", height:"50px"}}>
            <div style={{fontSize: "17px", fontWeight:"400", color: "#1A226D", margin: "auto", textAlign: "center", paddingTop: "10px"}}> 
                Terms & Conditions  
            </div>
      </div>

      <div style={{backgroundColor: "white", width:"100%", display:"flex", flexDirection:"row", flexWrap: "wrap", 
                    borderWidth:"1px", borderColor:"#e9e8e8", padding:"15px 0px 15px 0px", fontSize: "15px", fontWeight:"400", color:"#8a8888", 
                    textAlign:"center", alignItems:"center", alignContent:"center", justifyContent:"center", margin: "auto" }}>   
                Welcome to our website. If you continue to browse and use this website, you are agreeing to <br />
                comply with and be bound by the following terms and conditions of use, which together with our <br />
                privacy policy govern PrintBit’s relationship with you in relation to this website. If you disagree <br />
                with any part of these terms and conditions, please do not use our website. <br />
                <br />
                The term ‘PrintBit’ or ‘us’ or ‘we’ refers to the owner of the website whose registered office is at  <br />
                Tagbilaran City, Bohol, Philippines. The term ‘you’ refers to the user or viewer of our website.<br />
                <br />
                The use of this website is subject to the following terms of use: <br />
                <br />
                The content of the pages of this website is for your general information and use only. It is subject to change without notice. <br />
                <br />
                This website uses cookies to monitor browsing preferences. <br />
                <br />
                Neither we nor any third parties provide any warranty or guarantee as to the accuracy, <br />
                timeliness, performance, completeness or suitability of the information and materials found or <br /> 
                offered on this website for any particular purpose. You acknowledge that such information and <br />
                materials may contain inaccuracies or errors and we expressly exclude liability for any such <br />
                inaccuracies or errors to the fullest extent permitted by law. <br />
                <br />
                Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. <br />
                It shall be your own responsibility to ensure that any products, services or information available through  <br />
                this website meet your specific requirements. <br />
                <br />
                This website contains material which is owned by or licensed to us. This material includes, but is not limited to, <br />
                the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, <br /> 
                which forms part of these terms and conditions. <br />
                <br />
                All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website. <br />
                <br />
                Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense. <br />
                <br />
                From time to time this website may also include links to other websites. These links are provided for your convenience to  <br />
                provide further information. They do not signify that we endorse the website(s).  <br />
                We have no responsibility for the content of the linked website(s). <br />
                <br />
                Your use of this website and any dispute arising out of such use of the website is subject to the laws of the Philippines. <br />
       </div>

        </div>
        
        </>
    );
}

export default Terms;
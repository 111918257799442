import React, {useContext} from 'react';
import iconCustomPolygon from "../../images/editor/custom_polygon.png";
import { FabricContext } from "../../context/FabricContext";
import { fabric } from 'fabric';

const CustomPolygon = () => {
    const { canvas, activeObject } = useContext(FabricContext);
     
    var _mode = "normal";
    var currentShape;
    var pos; 
    var objectProps = {
        "fill": '#f1eeee',
        "opacity": 1,
        "selectable": false,
        "stroke": '#ff0000',
        "strokeSize": 2,
        "evented": false
    }

    const onAddCustomPolygon= (e) => {
       _mode = "add"
        canvas.setCursor("crosshair");
        canvas.defaultCursor = "crosshair";
        canvas.selection = false;

        canvas.on("mouse:down", function(options) {
               pos = canvas.getPointer(options.e); 
               console.log("TOP", _mode)
               if (_mode==="add") {
                var polygon = new fabric.Polygon([
                    {
                    x: pos.x,
                    y: pos.y,
                    }, {
                        x: pos.x + 0.5,
                        y: pos.y + 0.5
                    }
                ], objectProps);
                currentShape=polygon;
                canvas.add(currentShape);
                currentShape.setCoords();
                canvas.renderAll();
                _mode= "edit"

               } else {
               var points_ = currentShape.points;
               //console.log("POINTS1", points_);
                points_.push({
                   x: pos.x, 
                   y: pos.y 
                });
                var polygon = new fabric.Polygon(points_, objectProps);
                canvas.add(polygon);
                canvas.remove(currentShape);
                currentShape = polygon;
                canvas.renderAll();
                //console.log("POINTS2", points_);
            }
         })
       
         canvas.on("mouse:dblclick", function(options) {
            currentShape.set({
            selectable: true,
            evented: true,
           });
           canvas.selection = true;
            _mode="normal";

            canvas.setActiveObject(currentShape);
            canvas.off("mouse:down");
            canvas.off("mouse:dblclick");
            canvas.defaultCursor = "default";
            //console.log("events removed!");
            currentShape.on('mousedblclick', function() {
                console.log('Polygon editting');
                Edit()
              });
         })     
    }

    function Edit() {
   
		var poly = canvas.getObjects()[0];
		canvas.setActiveObject(poly);
		poly.edit = !poly.edit;
    console.log("poly.edit", poly.edit)
    console.log(poly);
		if (poly.edit) {
            var lastControl = poly.points.length - 1;
            poly.cornerStyle = 'circle';
            poly.cornerColor = 'rgba(0,0,255,0.5)';
	          poly.controls = poly.points.reduce(
                                  function(acc, point, index) {
				                                                        acc['p' + index] = new fabric.Control({
                                                                                      positionHandler: polygonPositionHandler,
                                                                                      actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
                                                                                      actionName: 'modifyPolygon',
                                                                                      pointIndex: index
                                                                                    });
				                                                                    return acc;
			                                                        }, { });
      console.log("poly.controls", poly.controls)
		} else {
            poly.cornerColor = 'blue';
            poly.cornerStyle = 'rect';
			      poly.controls = fabric.Object.prototype.controls;
		}
		poly.hasBorders = !poly.edit;
    poly.setCoords();
		canvas.renderAll();
	}

    function anchorWrapper(anchorIndex, fn) {
        return function(eventData, transform, x, y) {
          var matrix_ =  transform.target.calcTransformMatrix();
          var pathoffsetX = transform.target.pathOffset.x;
          var pathoffsetY = transform.target.pathOffset.y;

          console.log("OFFSET", pathoffsetX, pathoffsetY)
          var fabricObject = transform.target,
              absolutePoint = fabric.util.transformPoint({
                  x: (fabricObject.points[anchorIndex].x - transform.target.pathOffset.x),
                  y: (fabricObject.points[anchorIndex].y - transform.target.pathOffset.y),
                 }, fabricObject.calcTransformMatrix()),
              actionPerformed = fn(eventData, transform, x, y), 
              newDim = fabricObject._setPositionDimensions({}),
              polygonBaseSize = getObjectSizeWithStroke(fabricObject),
              newX = (fabricObject.points[anchorIndex].x - transform.target.pathOffset.x ) / polygonBaseSize.x,
              newY = (fabricObject.points[anchorIndex].y - transform.target.pathOffset.y) / polygonBaseSize.y;
          fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);

         // console.log("absolutePoint", absolutePoint);
          console.log("pathoffsetX, Y", fabricObject.pathOffset.x, fabricObject.pathOffset.y)
        //  console.log("XY ", x,y );
        //  console.log("NewX", newX)
        //  console.log("newY", newY);
         // console.log("matrix", fabricObject.calcTransformMatrix())
         // console.log("x-offset",fabricObject.points[anchorIndex].x,  fabricObject.pathOffset.x, fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x)
          //console.log("y-offset",fabricObject.points[anchorIndex].y,  fabricObject.pathOffset.y, fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y)
 
          return actionPerformed;

        }
      }

    function actionHandler(eventData, transform, x, y) {
		  var polygon = transform.target,
		      currentControl = polygon.controls[polygon.__corner],
		      mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
          polygonBaseSize = getObjectSizeWithStroke(polygon),
				  size = polygon._getTransformedDimensions(0, 0),
				  finalPointPosition = {
					   x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
					   y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
				   };
		  polygon.points[currentControl.pointIndex] = finalPointPosition;
     // console.log("polygon.points", finalPointPosition);
    //  console.log("currentControl", currentControl);
		  return true;
	}

  function getObjectSizeWithStroke(object) {
		var stroke = new fabric.Point(
			object.strokeUniform ? 1 / object.scaleX : 1, 
			object.strokeUniform ? 1 / object.scaleY : 1
		).multiply(object.strokeWidth);
		return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
	}
  
    function polygonPositionHandler(dim, finalMatrix, fabricObject) {
      var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x), y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);
      //console.log("transformpoint", fabric.util.transformPoint({ x: x, y: y }, fabric.util.multiplyTransformMatrices(fabricObject.canvas.viewportTransform, fabricObject.calcTransformMatrix())))
      return fabric.util.transformPoint(
                          { x: x, y: y }, fabric.util.multiplyTransformMatrices(fabricObject.canvas.viewportTransform, fabricObject.calcTransformMatrix())
          );
    }
    
   /* useEffect(() => {
  
    } , []) */

  return (
    <div>
            <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                    ><img src={iconCustomPolygon} onClick={onAddCustomPolygon} width="30px" alt="custom Polygon"/> </div>
    </div>
  )
}

export default CustomPolygon
import React, { useState, useRef, useContext, useEffect } from "react";
import { FabricContext } from "../../context/FabricContext";
import { fabric } from 'fabric';
import iconBrowse from '../../images/editor/browse.png';

const BgImages = () => {
    const hiddenFileInput = useRef(null);
    const { canvas} = useContext(FabricContext);
    const [mousePosition, setMousePosition] = useState({x:100, y: 100})
    const [isDrag, setisDrag] = useState(false);
    const [Options, setOptions] = useState({
        width: 300,
        left: 320,
        top: 289,
    });

    const bgimages_stock = [
        { id: '1', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg1.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg1.webp" },
        { id: '2', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg2.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg2.webp" },
        { id: '3', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg3.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg3.webp" },
        { id: '4', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg4.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg4.webp" },
        { id: '5', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg5.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg5.webp" },
        { id: '6', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg6.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg6.webp" },
        { id: '7', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg7.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg7.webp" },
        { id: '8', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg8.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg8.webp" },
        { id: '9', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg9.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg9.webp" },
        { id: '10', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg10.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg10.webp" },
        { id: '11', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg11.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg11.webp" },
        { id: '12', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg12.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg12.webp" },
        { id: '13', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg13.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg13.webp" },
        { id: '14', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg14.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg14.webp" },
        { id: '15', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg15.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg15.webp" },
        { id: '16', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg16.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg16.webp" },
        { id: '17', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg17.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg17.webp" },
        { id: '18', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg18.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg18.webp" },
        { id: '19', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg19.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg19.webp" },
        { id: '20', label: 'Abstract color', url: "https://api.printbit.com/backgrounds/bg20.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg20.webp" },
        { id: '21', label: 'Unicorn', url: "https://api.printbit.com/backgrounds/bg21.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg21.webp" },
        { id: '22', label: 'Peach Fuzz', url: "https://api.printbit.com/backgrounds/bg22.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg22.webp" },
        { id: '23', label: 'Hearts, love', url: "https://api.printbit.com/backgrounds/bg23.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg23.webp" },
        { id: '24', label: 'Exercise', url: "https://api.printbit.com/backgrounds/bg24.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg24.webp" },
        { id: '25', label: 'Abstract', url: "https://api.printbit.com/backgrounds/bg25.webp", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg25.webp" },
        { id: '26', label: 'Birthday, Construction', url: "https://api.printbit.com/backgrounds/bg26.jpg", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg26.jpg" },
        { id: '27', label: 'Birthday, Generic', url: "https://api.printbit.com/backgrounds/bg27.jpg", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg27.jpg" },
        { id: '28', label: 'Birthday, Generic, Blue', url: "https://api.printbit.com/backgrounds/bg28.jpg", thumbnail: "https://api.printbit.com/backgrounds/thumbnails/bg28.jpg" },
    ]


    const handleClick = (event) => {
        hiddenFileInput.current.click();
       // console.log("CLICKED!")
    }

    const handleChange = (event) => {
    
    }

    function imageOnClick(param) {
        console.log(param, canvas.width, canvas.height);

        fabric.Image.fromURL(param, function(img) {
          //  img.set({
           //     width: canvas.width, 
          //      height: canvas.height, 
          //      originX: 'left', 
          //      originY: 'top'
         //   });
            //console.log("image", img.width, img.height);
            //console.log("zoom", canvas.getZoom())
            //img.set({width: canvas.width, height: canvas.height, originX: 'left', originY: 'top'});
            img.dirty = true;
            img.selectable = false;
            img.noScaleCache = false
          

           // console.log("reize",img.width, img.height);
            //img.scaleToWidth(canvas.width);
           // img.set({height: canvas.height});
           // img.scaleToHeight(canvas.height);
           // canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
           let zoomVal = canvas.getZoom();
           if (isNaN(zoomVal)) {
             zoomVal = 1;
           }
          // console.log("zoomVal", zoomVal, img.width, canvas.width)
          // let scaleRatio = Math.max(canvas.width / img.width, canvas.height / img.height);
         // let scaleRatio = Math.max((canvas.width) / img.width, (canvas.height) / img.height);
           canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
             // scaleX: scaleRatio,
             // scaleY: scaleRatio,
             //  left: canvas.width / 2,
             //  top: canvas.height / 2,
             //  originX: 'middle',
             // originY: 'middle',
             //scaleX: canvas.width / (img.width * zoomVal),
             //scaleY: canvas.height / (img.height * zoomVal)
             scaleX: canvas.width / ((img.width * zoomVal)*.98),
             scaleY: canvas.height / ((img.height * zoomVal)*.98)
             
           });
          // canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
          //      opacity: 1,
            //    width: canvas.width,
            //    height: canvas.height,
            // Needed to position backgroundImage at 0/0
             //   originX: 'left',
             //   originY: 'top'
             localStorage.setItem('canvasJSON', JSON.stringify(canvas?.toJSON()));
            }, {crossOrigin: 'anonymous'});
          
    
    }

    function onDragImage(param)  {

    }

    function onDragEndImage(param) { 

    }

    function getMouseCoords(event)
      {
        var pointer = canvas.getPointer(event.e);
        var posX = pointer.x;
        var posY = pointer.y;
        setMousePosition({"x": posX, "y": posY });
      //  console.log("getmouse", posX+", "+posY);    // Log to console
      }
    
      useEffect(() => {
       // var txtWidth = Options.width / 2; 
        setOptions({...Options,
          top: mousePosition.y,
          left: mousePosition.x,
        })
      //  setisDrag(true);
     }, [mousePosition.x, mousePosition.y]);


  return (

    <div style={{display: 'flex', flexWrap: 'wrap'}}> 
        <div style={{width: "100%", margin: "10px"}}> 
                <button className="buttonUpload" onClick={handleClick}>
                    <span style={{fontSize: "12px"}}> <img src={iconBrowse} width="20px" alt="circle"/> &nbsp; Browse Photo &nbsp;  </span>
                </button>
                <input type="file" style={{display: 'none'}}  onChange={handleChange} ref={hiddenFileInput} accept="image/png, image/jpeg, image/webp" 
                        onClick={(event)=> {
                            event.target.value = null
                            }
                        }
                />
        </div>
        <div> 
                {bgimages_stock.map((images, i) => (
                     bgimages_stock.length > 0 ? <img key={i} src={images.thumbnail} alt={images.label} width='100px' className="editorPhotos" 
                                                    onClick={() => imageOnClick(images.url)} 
                                                    onDrag={() => onDragImage(images.url)}
                                                    onDragEnd={() => onDragEndImage(images.url)}
                                                /> : "" 
                ))}
        </div>


    </div>
  )
}

export default BgImages
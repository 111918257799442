import React, {useContext, useState, useEffect} from 'react';
import { FabricContext } from "../../context/FabricContext";
import {getActiveStyle, setActiveStyle,} from "../libs/utils";
import iconUnderline from "../../images/editor/underline.png";


const _textunderline = () => {

    const { activeObject } = useContext(FabricContext);
    const [options, setOptions] = useState({});
    const [buttonStyle, setButtonStyle] = useState({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"55px", borderColor: "#c4bfbf", borderWidth: "1px"});
  
    useEffect(() => {
      if (activeObject) {
         const activeOptions = {
            fontSize: getActiveStyle("fontSize", activeObject),
            fontWeight: getActiveStyle("fontWeight", activeObject),
            fontStyle: getActiveStyle("fontStyle", activeObject),
            textAlign: activeObject["textAlign"],
            fontFamily: getActiveStyle("fontFamily", activeObject),
            textDecoration: getActiveStyle("textDecoration", activeObject),
            fill: getActiveStyle("fill", activeObject),
       }
      setOptions({ ...options, ...activeOptions })

      if (activeOptions.textDecoration === "underline") {
        setButtonStyle({background:"#ecebeb", margin:"auto", paddingTop: "3px", height:"35px", width:"55px", borderColor: "#c4bfbf", borderWidth: "1px"})
      } else 
       {
        setButtonStyle({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"55px", borderColor: "#c4bfbf", borderWidth: "1px"})
      }

    }
  
    }, [activeObject])
  
    const onUnderline = (e) => {
        const value = options.textDecoration === "underline" ? "" : "underline"
        setOptions({
            ...options,
            textDecoration: value,
        })
        setActiveStyle("textDecoration", value, activeObject)
        setActiveStyle("underline", !!value.length, activeObject)

        if (value === "underline") {
            setButtonStyle({background:"#ecebeb", margin:"auto", paddingTop: "3px", height:"35px", width:"55px", borderColor: "#c4bfbf", borderWidth: "1px"})
          } else 
           {
            setButtonStyle({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"55px", borderColor: "#c4bfbf", borderWidth: "1px"})
          }
    }
   
  
    return ( 
          <div style={{margin:"auto", paddingTop:"3px"}}>
                          <div className='buttonShapes' style={buttonStyle} 
                          onClick={onUnderline}><img src={iconUnderline} width="24px" alt="Underline"/> </div>
          </div>

    )
  }

export default _textunderline
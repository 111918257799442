import React, {useEffect, useState, useContext} from 'react';
import { FabricContext } from "../../../context/FabricContext";
import {getActiveStyle, setActiveStyle,} from "../../libs/utils";

const Border = () => {
    const DEBUG = false;
    const {activeObject } = useContext(FabricContext);
    const [options, setOptions] = useState({strokeWidth: 0});
    const presetColors = [
        { id: '1', label: 'Red', color: "#ff0000"},
        { id: '2', label: 'Yellow', color: "#ffff00"},
        { id: '3', label: 'Green', color: "#00ff00"},
        { id: '4', label: 'Aqua Blue', color: "#00ffff"},
        { id: '5', label: 'Blue', color: "#0000ff"},
        { id: '6', label: 'Pink', color: "#ff00ff"},
        { id: '7', label: 'White', color: "#ffffff"},
        { id: '8', label: 'Light Gray', color: "#e1e1e1"},
        { id: '9', label: 'Gray 1', color: "#acacac"},
        { id: '10', label: 'Gray 2', color: "#959595"},
        { id: '11', label: 'Red 2', color: "#d11b22"},
        { id: '12', label: 'Golden Yellow', color: "#ffae00"},
        { id: '13', label: 'Light Green', color: "#00a651"},
        { id: '14', label: 'Light Blue', color: "#00aeef"},
        { id: '15', label: 'Purple', color: "#6d07ad"},
        { id: '16', label: 'Gray 3', color: "#898989"},
        { id: '17', label: 'Gray 4', color: "#707070"},
        { id: '18', label: 'Gray 5', color: "#555555"},
        { id: '19', label: 'Dark Gray', color: "#363636"},
        { id: '20', label: 'Black', color: "#000000"},
        { id: '21', label: '#f7977a', color: "#f7977a"},
        { id: '22', label: '#f9ad81', color: "#f9ad81"},
        { id: '23', label: '#fff79a', color: "#fff79a"},
        { id: '24', label: '#c4df9b', color: "#c4df9b"},
        { id: '25', label: '#a2d39c', color: "#a2d39c"},
        { id: '26', label: '#6ecff6', color: "#6ecff6"},
        { id: '27', label: '#7ea7d8', color: "#7ea7d8"},
        { id: '28', label: '#8493ca', color: "#8493ca"},
        { id: '29', label: '#a187be', color: "#a187be"},
        { id: '30', label: '#bc8dbf', color: "#bc8dbf"},
        { id: '31', label: '#f49ac2', color: "#f49ac2"},
        { id: '32', label: '#f6989d', color: "#f6989d"},
        { id: '33', label: '#f26c4f', color: "#f26c4f"},
        { id: '34', label: '#f68e55', color: "#f68e55"},
        { id: '35', label: '#fbaf5c', color: "#fbaf5c"},
        { id: '36', label: '#fff467', color: "#fff467"},
        { id: '37', label: '#acd372', color: "#acd372"},
        { id: '38', label: '#7cc576', color: "#7cc576"},
        { id: '39', label: '#3bb878', color: "#3bb878"},
        { id: '40', label: '#1abbb4', color: "#1abbb4"},
        { id: '41', label: '#00bff1', color: "#00bff1"},
        { id: '42', label: '#438cca', color: "#438cca"},
        { id: '43', label: '#5574b9', color: "#5574b9"},
        { id: '44', label: '#855fa8', color: "#855fa8"},
    ];

    const onUpdateBorderWidth= (e) => {
        setActiveStyle('strokeWidth', parseInt(e.target.value, 10), activeObject);
        setOptions({
            ...options,
            strokeWidth: e.target.value,
        })
        DEBUG && console.log("border", parseInt(e.target.value, 10));
    }

    function applyColor (param) {

        setOptions({
            ...options,
            stroke: param,
        })
        
        setActiveStyle("stroke", param, activeObject);

    }

    useEffect(() => {
        //setShowTools(activeObject ? activeObject.get("type") === "line" : false)
        if (activeObject) {
            let strokeW =  getActiveStyle("strokeWidth", activeObject);
            if (strokeW === 0 || strokeW === null | strokeW ==="") {
                strokeW = 0;
            }
            const activeOptions = {
                stroke: getActiveStyle("stroke", activeObject),
                strokeWidth: strokeW
            }
            setOptions({ ...options, ...activeOptions })
            DEBUG && console.log("strokeWidth ", strokeW);
            DEBUG && console.log("border opacity", getActiveStyle("borderOpacityWhenMoving", activeObject))
        } 
        // eslint-disable-next-line
    }, [activeObject])

  return (
    <div>
        <div style={{display: "flex", justifyContent: "flex-start", width:"100%"}}>
            <div style={{fontSize:"11px", marginLeft:"5px", height:"24px", paddingTop:"4px", width:"65px", verticalAlign:"top", textAlign:"left"}}>Border </div> 
            <div style={{paddingTop:"4px", display: "flex", flexWrap: "wrap", justifyContent: "flex-start", width:"100%"}}>
                <input  type="range" id="borderwidth" name="borderwidth" min="0" max="50" style={{ "width": "150px" }}
                        value={options.strokeWidth}
                        onChange={onUpdateBorderWidth} 
                        className="btn-object-action"
                />
            </div>
        </div>
        <div style={{display: "flex", justifyContent: "flex-start", flexWrap:"wrap", width:"100%"}}> 
            {   presetColors.map((colors, i) => ( 
                                                    presetColors.length > 0 ? <div key={colors.id} className="presetColorButtons" style={{backgroundColor: colors.color}}
                                                                                    onClick={() => applyColor(colors.color)} >
                                                                                </div> : "" 
                                                        )
                                        )
                }
        </div>
    </div>
  )
}

export default Border
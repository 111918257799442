import { useEffect, useState, useContext} from 'react';
import "./modal.css";
import { ProgressBar } from 'react-loader-spinner'
import { FabricContext } from "../../context/FabricContext";
import axios from '../../api/axios';
import useAuth from '../hooks/useAuth';


const DialogSave = ({setDialogSave}) => {
    const { canvas, fileName, setFileName, size, setSize, imageFiles } = useContext(FabricContext);
    const { auth } = useAuth();
    const [isSaving, setIsSaving] = useState(false);
    const [tmpfilename, setTMPFilename] = useState(null);
    const [errMsg, setErrMsg] = useState('');
    const API_URL = '/design_new';
    const API_URL_UPDATE = "/design_update";
    const API_URL_UPLOAD = "/upload";
    const SERVER_IMAGES = "https://api.printbit.com";
    const API_URL_IMAGES = "/images_user/";

    const saveDesign = async () => {
        setErrMsg(null);
        setIsSaving(true);
        //console.log(tmpfilename);
        /* SAVE TO DB */
        const zoom = canvas.getZoom();
        canvas.setZoom(1);
        canvas.setWidth(canvas.width * (1/zoom));     // 
        canvas.setHeight(canvas.height * (1 / zoom));
       
        canvas.renderAll();
        var json = [{}];
        json = canvas.toJSON();
        //console.log("json", json.objects[0].src); // image src
        // if (json.objects.length > 0 ) {
        //     for (let i=0; i<=json.objects.length; i++) {
        //         if (json.objects[i]?.type === "image") {
        //             if (json.objects[i].src.includes("blob")) {
        //                 console.log("BLoB", json.objects[i]);
        //             }
        //            console.log(i, json.objects[i].src);  // image source data
        //         }
        //     }   
        // } 
        const tmpJson = {version: json.version, background: json.background, 
                          backgroundImage: json.backgroundImage};
        //var blobFileURL; 
        var filesForUpload = [];
        //console.log("TMPJSON", tmpJson);
        const nextJson = json.objects.map(fabricObject => {
            if (fabricObject.type === 'image') {
               // console.log("SRC", fabricObject.src);
                
                if (fabricObject.src.includes("blob")) {
                    var blobFileURL = fabricObject.src;
                    //const tmparray = fabricObject.src.split("/"); //git rid of blob and localhost
                    //const re = new RegExp(keyword, 'i');
                    const  filtered = imageFiles.filter(entry => 
                                        Object.values(entry).some(val => typeof val === "string"    
                                        && val.match(blobFileURL)));
                    const localImageFilename = filtered[0]?.localFilename.name;
                    if (filtered[0]?.localFilename!==undefined) {
                        filesForUpload.push(filtered[0]?.localFilename);
                    }
                    
                   // console.log("filetered:", localImageFilename);
                    return{ 
                        ...fabricObject, src:SERVER_IMAGES + API_URL_IMAGES + auth.userID + "/" + localImageFilename,
                    }
                } else 
                  return fabricObject;
                
            } else {
               // console.log(fabricObject);
                return fabricObject;
            }
        });

        //console.log("NEXTJSON", nextJson);
        //console.log("FILES4UPLOAD", filesForUpload);

        const finalJSON = {...tmpJson, "objects": nextJson };
       // console.log("FInal JASON DB:", finalJSON);
        //console.log("Saving to DB ...", JSON.stringify(canvas.toJSON()));
        //console.log("Saving to DB ...", canvas.toJSON());
        //console.log("SIZE:", size);
        const bearer_token = `Bearer ${auth.accessToken}`;
        try {
            //console.log("size ID:", size._id);
            var response;
            if (!size._id || size._id===undefined) {  // NEW 
                     console.log("Save as NEW design...");
                     const JSON_final = JSON.stringify({   "userID": auth.userID,
                     "filename": tmpfilename,
                     "width": size.width,
                     "height": size.height,
                     "unit": size.unit,
                     "type": size.type,
                     "canvaswidth": canvas?.width,
                     "canvasheight": canvas?.height,
                     "content": finalJSON});

                    response = await axios.post(API_URL, JSON_final,
                     {
                        headers: { 'Content-Type': 'application/json',
                                    'Authorization': bearer_token },
                        withCredentials: true
                    });
    
                    if (response.status === 201) {
                        //console.log("DESIGN ID after saving...", response.data._id);
                        //console.log("JSON width: ", JSON_final.width);
                        setSize({...size, "_id": response.data._id})  // populate design ID for updating next time
                        //setTmpSize({"width":  JSON_final.width, "height":  JSON_final.height, "unit":  JSON_final.unit, "type":  JSON_final.type});
                    }
            } else {
                    //console.log("Updating design...");
                    response = await axios.put(API_URL_UPDATE,
                        JSON.stringify({ "_id" : size._id,
                                        "userID": auth.userID,
                                        "filename": tmpfilename,
                                        "width": size?.width,
                                        "height": size?.height,
                                        "unit": size?.unit,
                                        "type": size?.type,
                                        "canvaswidth": canvas?.width,
                                        "canvasheight": canvas?.height,
                                        "content": finalJSON}),
                    {
                        headers: { 'Content-Type': 'application/json',
                                    'Authorization': bearer_token },
                        withCredentials: true
                    }
                    )
            }
           if (response.status === 201) {
                //console.log("Saving to DB Successful!");
                //console.log("FILES:", filesForUpload);
                if (filesForUpload.length > 0) uploadImagestoServer(filesForUpload);  //if there are files to upload
                setFileName(tmpfilename);   
            }
            //console.log(response);

        }  catch (err) {
           if (!err?.response) {
             setErrMsg('No Server Response');
           } else if (err.response?.status === 400) {
             setErrMsg('Missing data');
           } else if (err.response?.status === 401) {
             setErrMsg('Unauthorized');
           } else {
               setErrMsg('Not logged In');
           }
           //console.log("Error message: ", err);
           setIsSaving(false);
        } 
        setIsSaving(false);
        setDialogSave(false);
      
    }

    const uploadImagestoServer = async (imageFiles) => {
        const formData = new FormData();
        formData.append('userID', auth.userID);

        //remove duplicates in imageFiles before uploading
        const mapped = imageFiles.map((obj, index) => obj.name);
        const filteredFiles = imageFiles.filter((type, index) =>  mapped.indexOf(type.name) === index  )
        //console.log("names:", mapped, filteredFiles);
       //formData.append('file', imageFiles);  
        Object.keys(filteredFiles).forEach(key => {
            //formData.append(imageFiles.item(key).name, imageFiles.item(key))
            formData.append(filteredFiles[key].name, filteredFiles[key]);
        })

       //imageFiles.forEach(file => {
            //formData.append("file", file.image, file.image.name)
       //    formData.append('file[]', file);
       /// })

        //console.log("formdata", formData);
        const bearer_token = `Bearer ${auth.accessToken}`;
        try {
            const response = await axios.post(API_URL_UPLOAD, formData,
                {
                    headers: { 'content-type': 'multipart/form-data',
                               'Authorization': bearer_token },
                    withCredentials: true
                });
           // console.log(response);
           // if (response.status === 200)
           // console.log("Uploading files successful!")
        }  catch (err) {
            if (!err?.response) {
              //setErrMsg('No Server Response');
              //console.log("No server response.")
            } else if (err.response?.status === 400) {
              //setErrMsg('Missing data');
              //console.log("400 - Missing Data")
            } else if (err.response?.status === 401) {
             // setErrMsg('Unauthorized');
             //console.log("Unauthorized")
            } else {
              //  setErrMsg('Not logged In');
             // console.log("Not logged in")
            }
           // console.log("Error message: ", err);
            //setIsSaving(false);
         } 
         
         
    }
    
    useEffect(() => {
       setTMPFilename(fileName);
       //console.log(fileName);
     // eslint-disable-next-line
     },[]);

  return (
    <div className="modalBackground">
        <div className="modalContainer" style={{height:"350px"}}>
            <div className="titleCloseBtn">
                <button onClick={() => { setDialogSave(false); }}> X </button>
            </div>
            <div className="title">
                <h4>SAVE MY DESIGN</h4>
            </div>
            <div className="body" style={{height:"150px"}}>
                <span> {
                    isSaving ? <ProgressBar 
                                visible={true}
                                height="80"
                                width="80"
                                color="#097fee"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{}}
                                wrapperClass="" /> : "" 
                    }
                </span>
                 <span style={{color: "red"}}> {errMsg} </span>
                <input type="text"  style={{width: "550px", padding:"5px"}} placeholder="Enter File Name" value={tmpfilename}
                onChange={e =>  {
                                    setTMPFilename(e.target.value);
                                    setErrMsg(null);
                                }
                        } 
                />
            </div> 
            <div className="footer" style={{height:"50px"}}>
                <button onClick={() => {setDialogSave(false);}} id="cancelBtn"> Cancel </button>
                <button onClick={saveDesign} disabled={isSaving} > Save </button>
            </div>
        </div>
    </div>    
  )
}

export default DialogSave
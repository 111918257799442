import React, { useEffect} from 'react';
import './App.css';
import './fonts.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom"


import Layout from './components/Layout';
import Home from './components/Home';
import Studio from './components/Studio'
import Products from './components/Products';
import ProductOrderNewTarp from './components/ProductOrderNewTarp';
import Services from './components/Services';
import About from './components/About';
import Terms from './components/Terms';
import Security from './components/Security';
import Contact from './components/Contact';
import Editor from './components/Editor';
//import EditorOld from './components/Editor2';
import Login from './components/Login';
import Register from './components/Register';
import PersistLogin from './components/PersistLogin';
import RequireAuth from './components/RequireAuth';
import Cart from './components/cart/Cart';
import CheckOut from './components/cart/CheckOut';
import Orders from './components/Orders';
import Profile from './components/Profile';
import Shop from './components/Shop';
import CartEditTarp from './components/cart/CartEditTarp';
import ProfileChangePwd from './components/ProfileChangePwd';
import Templates from './components/Templates';
import Rfq from './components/Rfq';


const ROLES = {
  'User': 2001,
  'Editor': 1984,
  'Admin': 5150
}

function App() {

 useEffect(() => {
   document.title="PrintBit Online Printing Shop";
 }, []); 

  return ( 
    <>
      <Routes> 
        <Route path="/" element={<Layout />}>
          <Route path='/' element={<Home/>} />   
          <Route path='studio' element={<Studio/>} />    
          <Route path='products' element={<Products />} />         
          <Route path='shop' element={<Shop />} />
          <Route path='services' element={<Services />} />
          <Route path='aboutus' element={<About />} />
          <Route path='terms' element={<Terms />} />
          <Route path='security' element={<Security />} />
          <Route path="contact" element={<Contact />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="product_templates" element={<Templates />} />
          <Route path="rfq" element={<Rfq />} />

                  
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path='cart' element={<Cart/>} />
              <Route  path='editor' element={<Editor/>} />   
              <Route  path='checkout' element={<CheckOut/>} /> 
              <Route  path='orders' element={<Orders/>} /> 
              <Route  path='profile' element={<Profile/>} /> 
              <Route path='product_order_new_tarp' element={<ProductOrderNewTarp />} />
              <Route path='cart_edit_tarp' element={<CartEditTarp />} />
              <Route path='profile_change_pwd' element={<ProfileChangePwd />} />
            </Route>
          </Route>
        </Route>
      </Routes>   
    </>
  );
}

export default App;

import React, {useState, useContext, useEffect} from 'react';
import iconTextSubTitle from "../../images/editor/text_subtitle.png";
import { FabricContext } from "../../context/FabricContext";
import { fabric } from 'fabric';
import {getActiveStyle, setActiveStyle, getActiveProp} from "../libs/utils";

const TextBoxSubTitle = () => {
  const { canvas, activeObject } = useContext(FabricContext)
  const [mousePosition, setMousePosition] = useState({x:100, y: 100})
  const [options, setOptions] = useState({
      width: 400,
      top: 10,
      left: 10,
      fontSize: 35,
      fontWeight: "bold",
      fontStyle: "normal",
      textAlign: "center",
      fontFamily: "Arial",
      textDecoration: "normal",
      fill: "#000000",
  })

  const onAddTextBox = () => {
      const textBox = new fabric.Textbox("Add your text here", options)
      canvas.add(textBox).setActiveObject(textBox);
      canvas.centerObject(textBox);
  }

  function getMouseCoords(event)
      {
        var pointer = canvas.getPointer(event.e);
        var posX = pointer.x;
        var posY = pointer.y;
        setMousePosition({"x": posX, "y": posY });
        console.log("getmouse", posX+", "+posY);    // Log to console
      }


    const onDragTextBox = (e) => {
      canvas.on("dragover", function(options) {
        getMouseCoords(options);   
      })
    }

    const onDragEndTextBox = (e) => {

      setOptions({...options,
        top: mousePosition.y,
        left: mousePosition.x,
      })
      
      const textBox = new fabric.Textbox("Add your text here", options);
      canvas.add(textBox).setActiveObject(textBox);  
      canvas.off("dragover");
    }

    useEffect(() => {
      setOptions({...options,
        top: mousePosition.y,
        left: mousePosition.x,
      })
   }, [mousePosition.x, mousePosition.y]);



return (
  <div>
      <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"70px"}} 
                  onClick={onAddTextBox} onDragStart={onDragTextBox} onDragEnd={onDragEndTextBox}><img src={iconTextSubTitle} width="70px" alt="Textbox"/> </div>
  </div>
)
}

export default TextBoxSubTitle
import React, {useContext, useState, useEffect} from 'react';
import heart from "../../images/editor/heart.png";
import { FabricContext } from "../../context/FabricContext";
import { fabric } from 'fabric';


const FabricHeart = () => {
    const { canvas } = useContext(FabricContext);
    const [mousePosition, setMousePosition] = useState({x:100, y: 100})
    const [isDrag, setisDrag] = useState(false);
    const [options, setOptions] = useState({
        left: 20, 
        top: 0, 
        scaleX: .3, 
        scaleY: .3,  
        fill: '#ff0000', 
        stroke: '#ff0000', 
        strokeWidth: 3,  
        originX: 'center',
        originY: 'center',
    })

    const onAddHeart = () => {
        var heartPath = new fabric.Path('M 272.70141,238.71731 \
            C 206.46141,238.71731 152.70146,292.4773 152.70146,358.71731  \
            C 152.70146,493.47282 288.63461,528.80461 381.26391,662.02535 \
            C 468.83815,529.62199 609.82641,489.17075 609.82641,358.71731 \
            C 609.82641,292.47731 556.06651,238.7173 489.82641,238.71731  \
            C 441.77851,238.71731 400.42481,267.08774 381.26391,307.90481 \
            C 362.10311,267.08773 320.74941,238.7173 272.70141,238.71731  \
            z ');    
        if (isDrag) {
          
          setOptions({...options,
               top: mousePosition.y,
               left: mousePosition.x,
             }) 
            setisDrag(false); 
           } else {
           //  isDrag_ = false;
       }      

        heartPath.set(options);
        canvas.add(heartPath);

        if (!isDrag) {
          canvas.centerObject(heartPath);
        }

        let count = canvas.getObjects().length;
        canvas.setActiveObject(canvas.item(count-1));

        //console.log(heartPath);
    }

  const onDrag = () => {
    canvas.on("dragover", function(options) {
      getMouseCoords(options);   
    }) 
   setisDrag(true);
  }

  const onDragEnd = () => {
    setOptions({...options,
        top: mousePosition.y,
        left: mousePosition.x,
      })
   
    onAddHeart();
    canvas.off("dragover");

  }

  function getMouseCoords(event)    {
        var pointer = canvas.getPointer(event.e);
        var posX = pointer.x;
        var posY = pointer.y;
        setMousePosition({"x": posX, "y": posY });
  }

  useEffect(() => {
        setOptions({...options,
          top: mousePosition.y,
          left: mousePosition.x,
        })
  }, [mousePosition.x, mousePosition.y]);


  return (
    <div>
        <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={onAddHeart} onDragStart={onDrag} onDragEnd={onDragEnd}><img src={heart} width="30px" alt="heart"/> </div>
    </div>
  )
}

export default FabricHeart
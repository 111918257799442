import React, {useContext, useState, useEffect} from 'react';
import { FabricContext } from "../../context/FabricContext";
import {setActiveStyle, getActiveStyle, setActiveProp} from "../libs/utils";
import Select from 'react-select';
//import FontFaceObserver from 'fontfaceobserver';
//import '../../fonts.css';


const _textFontStyle = () => {
    const { activeObject } = useContext(FabricContext);
    const [options, setOptions] = useState({});
  //  const [fontsLoaded, setFontsLoaded] = useState(false);

    const fonts = [
        { id: '1', label: 'Accio-Dollaro'},
        { id: '1', label: 'Achieve'},
        { id: '1', label: 'Amarillo'},
        { id: '1', label: 'Ananda'},
        { id: '2', label: 'Arial'},
        { id: '2', label: 'Bebas Neue'},
        { id: '3', label: 'Bell MT Regular'},
        { id: '3', label: 'Better Together'},
        { id: '4', label: 'Book Antiqua Regular'},
        { id: '5', label: 'Calibri Regular'},
        { id: '5', label: 'Caveat'},
        { id: '7', label: 'Century Gothic Regular'},
        { id: '8', label: 'Century Regular'},
        { id: '9', label: 'Comic Sans MS Regular'},
        { id: '10', label: 'Courier New Regular'},
        { id: '11', label: 'Impact Regular'},
        { id: '12', label: 'Janda Manatee Bubble'},
        { id: '13', label: 'Janda Manatee Solid'},
        { id: '13', label: 'La Graziela Script'},
        { id: '14', label: 'Lucida Handwriting'},
        { id: '14', label: 'Lobster'},
        { id: '14', label: 'Madinah'},
        { id: '15', label: 'Mistral Regular'},
        { id: '16', label: 'Rockwell Regular'},
        { id: '16', label: 'Pacifico'},
        { id: '16', label: 'Sunshine'},
        { id: '17', label: 'Super Magic'},
        { id: '18', label: 'Tahoma Regular'},
        { id: '19', label: 'Times New Roman'},   
        { id: '20', label: 'Verdana'},
      ]

      
      //const isFontLoaded = FontFaceObserver([
      ///  { family: 'Accio-Dollaro' } // Same name you have in your CSS
     // ]);
     
     console.log("isediting", activeObject?.isEditing)

     useEffect(() => {
        if (activeObject) {
           const activeOptions = {
            fontFamily: getActiveStyle("fontFamily", activeObject),
           }
           setOptions({ ...options, ...activeOptions })
       }
       console.log(options);
    }, [activeObject])


      const handleActivitySelect = (e) => {   
        
    //    if (fontsLoaded) { 
            setOptions({
                ...options,
                fontFamily: e.label,
            })
            setActiveStyle("fontFamily", e.label, activeObject)
            console.log(e.label);
    //    } else {
          //  console.log("active object", activeObject)
   //     }
      }   


  return (
          <div style={{margin:"auto", padding:"2px"}}>
                <Select 
                    placeholder={options.fontFamily}
                    options={fonts} 
                    onChange={handleActivitySelect} 
                    value = {options.fontFamily}
                    styles={{ option: (provided, state) => ({ ...provided, fontFamily: state.label, fontSize: '13px' }),
                              control: (css) => ({...css, width: "150px", height: "30px", minHeight: '30px'}),
                              valueContainer: (provided, state) => ({...provided, height: '30px', padding: '0 0px' }),
                              input: (provided, state) => ({...provided, margin: '0px',}),
                              indicatorSeparator: state => ({display: 'none',}),
                              indicatorsContainer: (provided, state) => ({...provided, height: '30px',})
                            }} 
                />
        </div> 
  )
}

export default _textFontStyle
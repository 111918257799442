const About = () => {
    return (
        <div className="new-order-container" style={{backgroundColor:"#e9f3fc", display: "flex", flexDirection: "row", flexWrap: "wrap", 
            justifyContent:"center", margin: "10px auto 10px auto", alignItems: "flexStart", boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>  
          
          <div style={{width: "100%", height:"50px"}}>
                <div style={{fontSize: "17px", fontWeight:"400", color: "#1A226D", margin: "auto", textAlign: "center", paddingTop: "10px"}}> 
                    About Us  
                </div>
          </div>
          <div style={{backgroundColor: "white", width:"100%", display:"flex", flexDirection:"row", flexWrap: "wrap", 
                    borderWidth:"1px", borderColor:"#e9e8e8", padding:"15px 0px 15px 0px", fontSize: "15px", fontWeight:"400", color:"#8a8888", 
                    textAlign:"center", alignItems:"center", alignContent:"center", justifyContent:"center", margin: "auto" }}> 

                    <br />
                    Founded in December 8 of 2008, PrintBit Printing Shop started as an Internet Cafe and expanded <br />
                    the business to cater large format printing services in 2011. PrintBit is locally owned and <br />
                    now operates as a digital printing company based in Tagbilaran City, Bohol, Philippines. <br />
                    As a printing shop, We strive for excellence to meet all your printing needs. <br />
                    We are dedicated to cost-effective solutions and committed to providing the best services to our customers. <br />
                    Our goal is to have the very best and most up-to-date printing technology available on the market, <br />
                    and to employ the most experienced and innovative people in the industry. We will measure our success, <br />
                    not by our sales figures, but by the satisfaction and longevity of our clients.  <br />
                    <br />
                    We started printing of documents in the internet cafe with just an Epson desktop printer. <br />
                    In September of 2011, Roy, founder, loaned a large format printer to cater the growing demand of tarpaulin banners.<br />
                    The shop consisted of just 50 square meter space with only Roy and his wife, Nolie as employees. <br />
                    Through hard work and determination, Roy was able to grow the business and establish a reputation for a <br />
                    digital printing shop to produce quality print at affordable prices. <br />
                    The business grew through building relationships with customers and suppliers. From printing of documents, <br />
                    Printbit now caters an array of printing services such as printing of tarpaulins, vinyl stickers, sintraboards, <br />
                    business cards, PVC IDs, brochures, yearbooks, mugs, and also caters embroidery of logos for company uniforms. <br />
                    <br />
                    In May of 2020, during the covid lockdown, we stop serving at our physical shop. <br />
                    We started our online shop at our facebook page, PrintBit. We received orders thru our messenger and we deliver <br />
                    the orders within our city. In June of 2024, we launched our website, printbit.com. <br />
                    Roy built it from scratch. Our customers can now order thru our website. It has also an online editor <br />
                    that our customers can use. It contains design templates to choose and editable to cater the needs <br />
                    of our customers. This is our way of expanding our customer-base to the whole of the Philippines, and hopefully, the world.<br />
                    <br />
                    PrintBit continues to provide high quality printing services to local businesses, students and organizations in our area and <br />
                    with the launch of our website, we're hoping that we can serve more customers outside our area. Currently located at <br />
                    Tagbilaran City, Bohol, Philippines, that houses advanced technology and equipment to provide full service printing. <br />
                    With the leadership of Roy, PrintBit will continue to focus on providing quality services at affordable prices to <br />
                    our customers. We are always on the move and ahead as technology and demand changes.<br />
                    <br />
                    WHY CHOOSE US? <br />
                    Our Mission <br />
                    <br />
                    Everything we do and every decision we make will be for the purpose of improving service for our customers,  <br />
                    making the company more profitable, and improving the standard of living of our employees. <br />
                    To provide continued customer satisfaction by constantly updating our processes and computer systems, <br />
                    and become the leading printing shop in the market today.<br />
                    <br />
                    Our Philosophy<br />
                    Our primary objective is to make you happy. Here’s how:<br />
                    <br />
                    TRUST— We’ll tell you the truth, and we won’t exaggerate. <br />
                    PRICE — We’ll earn your loyalty by consistently being your lowest-cost provider of printing. <br />
                    SPEED — We hustle. We’ll finish your order quickly. We encourage our coworkers to work quickly and with a sense of urgency. <br />
                    QUALITY — We’ll carefully inspect your printed project more than once to ensure the highest quality. <br />
                    ENTHUSIASM & FRIENDLINESS — We only hire customer service representatives who have positive attitudes, a concern for others, <br />
                    and a high level of attention to detail. They are a well-trained, enthusiastic, and caring bunch of graphic arts professionals.<br />
                    <br />
          </div>
        </div>
    );
}

export default About;
import React, {useContext, useState, useEffect} from 'react';
import { FabricContext } from "../../context/FabricContext";
import {getActiveStyle, setActiveStyle} from "../libs/utils";



const _textcolor = () => {

    const { activeObject } = useContext(FabricContext);
    const [options, setOptions] = useState({});
    const presetColors = [
        { id: '1', label: 'Red', color: "#ff0000"},
        { id: '2', label: 'Yellow', color: "#ffff00"},
        { id: '3', label: 'Green', color: "#00ff00"},
        { id: '4', label: 'Aqua Blue', color: "#00ffff"},
        { id: '5', label: 'Blue', color: "#0000ff"},
        { id: '6', label: 'Pink', color: "#ff00ff"},
        { id: '7', label: 'White', color: "#ffffff"},
        { id: '8', label: 'Light Gray', color: "#e1e1e1"},
        { id: '9', label: 'Gray 1', color: "#acacac"},
        { id: '10', label: 'Gray 2', color: "#959595"},
        { id: '11', label: 'Red 2', color: "#d11b22"},
        { id: '12', label: 'Golden Yellow', color: "#ffae00"},
        { id: '13', label: 'Light Green', color: "#00a651"},
        { id: '14', label: 'Light Blue', color: "#00aeef"},
        { id: '15', label: 'Purple', color: "#6d07ad"},
        { id: '16', label: 'Gray 3', color: "#898989"},
        { id: '17', label: 'Gray 4', color: "#707070"},
        { id: '18', label: 'Gray 5', color: "#555555"},
        { id: '19', label: 'Dark Gray', color: "#363636"},
        { id: '20', label: 'Black', color: "#000000"},
        { id: '21', label: '#f7977a', color: "#f7977a"},
        { id: '21', label: '#f9ad81', color: "#f9ad81"},
        { id: '22', label: '#fff79a', color: "#fff79a"},
        { id: '23', label: '#c4df9b', color: "#c4df9b"},
        { id: '24', label: '#a2d39c', color: "#a2d39c"},
        { id: '25', label: '#6ecff6', color: "#6ecff6"},
        { id: '26', label: '#7ea7d8', color: "#7ea7d8"},
        { id: '27', label: '#8493ca', color: "#8493ca"},
        { id: '28', label: '#a187be', color: "#a187be"},
        { id: '29', label: '#bc8dbf', color: "#bc8dbf"},
        { id: '30', label: '#f49ac2', color: "#f49ac2"},
        { id: '31', label: '#f6989d', color: "#f6989d"},
        { id: '32', label: '#f26c4f', color: "#f26c4f"},
        { id: '33', label: '#f68e55', color: "#f68e55"},
        { id: '34', label: '#fbaf5c', color: "#fbaf5c"},
        { id: '35', label: '#fff467', color: "#fff467"},
        { id: '36', label: '#acd372', color: "#acd372"},
        { id: '37', label: '#7cc576', color: "#7cc576"},
        { id: '38', label: '#3bb878', color: "#3bb878"},
        { id: '39', label: '#1abbb4', color: "#1abbb4"},
        { id: '40', label: '#00bff1', color: "#00bff1"},
        { id: '41', label: '#438cca', color: "#438cca"},
        { id: '42', label: '#5574b9', color: "#5574b9"},
        { id: '43', label: '#855fa8', color: "#855fa8"},
    ];

    useEffect(() => {
        if (activeObject) {
           const activeOptions = {
            fill: getActiveStyle("fill", activeObject),
           }
           setOptions({ ...options, ...activeOptions })
       }
       console.log(options);
       // eslint-disable-next-line
    }, [activeObject])

    const updateColor = (e) => {
        setOptions({
            ...options,
            fill: e.target.value,
        })
        setActiveStyle("fill", e.target.value, activeObject)
    }

    function applyColor (param) {

        setOptions({
            ...options,
            fill: param,
        })
        if (!activeObject.isEditing) {
            setActiveStyle("styles", [], activeObject );
        }
        setActiveStyle("fill", param, activeObject);

    }

  return (
    <div style={{width: "100%"}}>
        <div style={{display: "flex", justifyContent: "flex-start", width:"60%", textAlign:"left", alignContent: "flex-start"}}> 
            <div style={{fontSize: "11px", margin:"auto", width: "70px", height:"20px"}}> Select Color </div> 
            <div style={{margin:"auto", paddingTop:"0px", width:"60px"}}>
            <input type="color" name="fill" style={{ "width": "50px" }}
                        value={options.fill}
                        onChange={updateColor}
                        className="btn-object-action"/>
            </div>
        </div>
        <div style={{display: "flex", justifyContent: "flex-start", flexWrap:"wrap", width:"100%"}}> 
             
            {   presetColors.map((colors, id) => ( 
                                                        presetColors.length > 0 ? <div key={id} className="presetColorButtons" style={{backgroundColor: colors.color}}
                                                                                        onClick={() => applyColor(colors.color)} 
                                                                                    ></div> : "" 
                                                    )
                                    )
            }
          </div>
    </div>
  )
}

export default _textcolor
import React, {useContext, useState, useEffect} from 'react';
import { FabricContext } from "../../context/FabricContext";
import {getActiveStyle, setActiveStyle,} from "../libs/utils";
import iconItalic from "../../images/editor/italic.png";


const _textitalic = () => {
  const { activeObject } = useContext(FabricContext);
  const [options, setOptions] = useState({});
  const [buttonStyle, setButtonStyle] = useState({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"50px", borderColor: "#c4bfbf", borderWidth: "1px"});

  useEffect(() => {
    if (activeObject) {
      const activeOptions = {
          fontSize: getActiveStyle("fontSize", activeObject),
          fontWeight: getActiveStyle("fontWeight", activeObject),
          fontStyle: getActiveStyle("fontStyle", activeObject),
          textAlign: activeObject["textAlign"],
          fontFamily: getActiveStyle("fontFamily", activeObject),
          textDecoration: getActiveStyle("textDecoration", activeObject),
          fill: getActiveStyle("fill", activeObject),
    }
    setOptions({ ...options, ...activeOptions })

    if (activeOptions.fontStyle === "italic") {
        setButtonStyle({background:"#ecebeb", margin:"auto", paddingTop: "3px", height:"35px", width:"55px", borderColor: "#c4bfbf", borderWidth: "1px"})
    } else 
    {
        setButtonStyle({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"55px", borderColor: "#c4bfbf", borderWidth: "1px"})
    }

  }

  }, [activeObject])

  const onItalic = (e) => {
    const value = options.fontStyle === "italic" ? "normal" : "italic"
    setOptions({
            ...options,
            fontStyle: value,
    })
    setActiveStyle("fontStyle", value, activeObject)

    if (value === "italic") {
        setButtonStyle({background:"#ecebeb", margin:"auto", paddingTop: "3px", height:"35px", width:"55px", borderColor: "#c4bfbf", borderWidth: "1px"})
     } else 
     {
       setButtonStyle({background:"none", margin:"auto", paddingTop: "3px", height:"35px", width:"55px", borderColor: "#c4bfbf", borderWidth: "1px"})
     }
  }


  return (
        <div style={{margin:"auto", paddingTop:"3px"}}>
                        <div className='buttonShapes' style={buttonStyle} 
                        onClick={onItalic}><img src={iconItalic} width="24px" alt="Italic"/> </div>
        </div>
  )
}

export default _textitalic
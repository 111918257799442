import { useEffect, useState} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ProgressBar } from 'react-loader-spinner'
import axios from '../../api/axios';
import useAuth from '../hooks/useAuth';
import logoGcash from '../../images/icons/GCash_logo.png';
import PBQRCODE from '../../images/icons/printbit-qr-code.png';


const CheckOut = () => {
    const DEBUG = true;
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [myCart, setMyCart] = useState([]);
    const [grandTotal, setGrandTotal] = useState(0);
    const [dialogGCASH, setDialogGCASH] = useState(false);
    const [randomMessage, setRandomMessage] = useState();
    const [address, setAddress] = useState();
    const [notes, setNotes] = useState();
    const [errorOccured, setErrorOccured] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const API_URL = "/order/new";


    const getFormattedPrice = (price) => `₱ ${price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;

    const openCart = async () => {
        setIsLoading(true);
        if (!location.state) {
            navigate("/cart");
        }
        DEBUG && console.log("MYCART", location?.state?.forCheckOut)
        setMyCart(location.state.forCheckOut);  
        setIsLoading(false);
    }

    const selectPaymentMethod =  () => {
        setDialogGCASH(true);
    }

    const handleClose = () => {
        setDialogGCASH(false);
        navigate("/orders");
    }

    const handleCloseError = () => {
        setErrorOccured(false);
    }

    const handleCloseProcessing = () => {
        setIsProcessing(false);
    }

    function getRandomSixDigit() {
        return Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;   
    }

    const onAddOrder = async () => {
        DEBUG && console.log("adding to orders db ...");
        setErrMsg(null);
        setIsProcessing(true);
        setErrorOccured(false);
        const orderDetails = myCart.map(contents => {
            return {
                cartID: contents._id,
                designID: contents.designID,
                quantity: contents.quantity,
                filename: contents.filename,
                _type: contents.type,
                width: contents.width,
                height: contents.height,
                unit: contents.unit,
                totalsqft: contents.unit ==="ft." ? contents.width * contents.height : (contents.width * contents.height) / 144,   //need more calculation for inches, cm, meters 
                priceLayout: contents.priceLayout,
                pricePrint: contents.pricePrint,
                priceAddon: contents.priceAddon,
                unitprice: contents.unitprice,
                total: contents.total,
            } 
        });
        DEBUG && console.log(orderDetails);
        const bearer_token = `Bearer ${auth.accessToken}`;
        const jsonData = JSON.stringify({  
            "orderchannel": "printbit.com",
            "userID": auth.userID,
            "customername": auth.fullname,
            "contactperson": auth.contactperson,
            "address": address,
            "contactnumber": auth.contactnumbers,
            "deadline": Date,
            "total": grandTotal,
            "notes": notes,
            "numberofitems": orderDetails.length,
            "orderdetails" : orderDetails
        });
        try {
            const response = await axios.post(API_URL, jsonData,
                {
                    headers: { 'Content-Type': 'application/json',
                              'Authorization': bearer_token },
                    withCredentials: true
                });
            setDialogGCASH(true); 
        } catch (error) {
            if (!error?.response) {
                setErrMsg('No Server Response!');
                setErrorOccured(true);
            } 
            else if (error.response?.status === 400) {
                setErrMsg('Missing data!');
                setErrorOccured(true);
            } 
            else if (error.response?.status === 401) {
                setErrMsg('Unauthorized!');
                setErrorOccured(true);
            } else {
                setErrMsg('Server Error! ' + error);
                setErrorOccured(true);
            }
            DEBUG && console.log("ERROR,", errMsg);
            DEBUG && console.log(jsonData);
        }
        setIsProcessing(false);
    }

    useEffect(() => {
        openCart();
        setRandomMessage(getRandomSixDigit());
        //DEBUG && console.log("location: ", location.state.forCheckOut);
        DEBUG && console.log(auth);
        if (auth.address[0]!==undefined) {
            setAddress( auth?.address[0]?.housenum + " " + auth?.address[0]?.street + ", " +
                        auth?.address[0]?.barangay + ", " + auth?.address[0]?.town + ", " +
                        auth?.address[0]?.province + " " + auth?.address[0]?.zipcode + ", Region " +
                        auth?.address[0]?.region)  ;
        }
     // eslint-disable-next-line
     },[]);

     useEffect(() => {

        let total = myCart.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.total;
        }, 0)
        setGrandTotal(total);
        //DEBUG && console.log(total);
     // eslint-disable-next-line
     },[myCart]);

  return (
    <div style={{backgroundColor:"#e9f3fc", display: "flex", flexDirection: "row", flexWrap: "wrap", width: "1200px", 
     justifyContent:"center", margin: "10px auto 10px auto", alignItems: "flexStart", boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>      
        
        <div style={{width: "100%", height:"50px"}}>
            <div style={{fontSize: "17px", fontWeight:"400", color: "#1A226D", margin: "auto", textAlign: "center", paddingTop: "10px"}}> Checkout  </div>
        </div>

        <div style={{backgroundColor: "white", width:"100%", height:"50px", display:"flex", flexDirection:"row", flexWrap: "nowrap", 
         borderWidth:"1px", borderColor:"#cccccc", padding:"10px 0px, 10px, 0px", }}> 

            <div style={{width: "150px", height: "50px", paddingTop:"0px", borderWidth: "1px", borderColor:"#e6e1e1", borderStyle:"solid"}}>
                <div style={{fontSize: "15px", fontWeight:"400", color:"#f30808", textAlign: "Left", padding: "10px"}}> 
                    <div>Delivery Address</div>
                </div>
            </div>

            <div style={{width: "1050px", height: "50px", padding:"10px", borderWidth: "1px", borderColor:"#e6e1e1", borderStyle:"solid", textAlign:"left"}}>
                <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888"}}> 
                    <div><span style={{color: "black", fontWeight:"600"}}>{auth.fullname} &nbsp; {auth.contactnumbers} </span> &nbsp; {address} &nbsp; </div>
                </div>
            </div>
        </div>
        
        <div style={{backgroundColor: "white", width:"100%", height:"50px", display:"flex", flexDirection:"row", flexWrap: "nowrap", 
         borderWidth:"1px", borderColor:"#cccccc", padding:"10px 0px, 10px, 0px", }}> 

            <div style={{width: "550px", height: "50px", paddingTop:"10px", borderWidth: "1px", borderColor:"#e6e1e1", borderStyle:"solid"}}>
                <div style={{fontSize: "15px", fontWeight:"600", color:"black"}}> 
                    <div>Ordered Products</div>
                </div>
            </div>
            <div style={{width: "200px", height: "50px", paddingTop:"10px", borderWidth: "1px", borderColor:"#e6e1e1", borderStyle:"solid"}}>
                <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888"}}> 
                    <div>Unit Price</div>
                </div>
            </div>
            <div style={{width: "200px", height: "50px", paddingTop:"10px", borderWidth: "1px", borderColor:"#e6e1e1", borderStyle:"solid"}}>
                <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888"}}> 
                    <div>Quantity</div>
                </div>
            </div>
            <div style={{width: "200px", height: "50px", paddingTop:"10px", borderWidth: "1px", borderColor:"#e6e1e1", borderStyle:"solid"}}>
                <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888"}}> 
                    <div>TOTAL</div>
                </div>
            </div>
            <div style={{width: "50px", height: "50px", paddingTop:"10px", borderWidth: "1px", borderColor:"#e6e1e1", borderStyle:"solid"}}>
                <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888"}}> 
                    <div>&nbsp;</div>
                </div>
            </div>
        </div>

        <div style={{backgroundColor: "white", width: "1200px"}}>
        { isLoading ? <div><ProgressBar 
                            visible={true}
                            height="80"
                            width="80"
                            color="#097fee"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{}}
                            wrapperClass="" /> 
                        </div> : 
                        myCart.map((cartItem, i) => ( 
                <div key={i} style={{backgroundColor: "white", width:"100%", display:"flex", flexDirection:"row", flexWrap: "nowrap", 
                    borderWidth:"1px", borderColor:"#cccccc", padding:"10px 0px, 10px, 0px", }}>    
                    <div style={{width: "550px", height: "80px", padding:"10px 0px 0px 10px", borderWidth: "0px 0px 1px 0px", borderColor:"#f5f0f0", borderStyle:"solid"}}>
                        <div style={{fontSize: "15px", fontWeight:"400", textAlign: "left"}}> 
                            <div style={{padding: "0px 0px 0px 3px", fontColor:"#0a0a0a", fontWeight:"500"}}>{cartItem.type}</div>
                            <div style={{padding: "0px 0px 0px 3px", fontSize:"12px", color:"#8a8888"}}>Size (w x h): {cartItem.width} x {cartItem.height} {cartItem.unit} - {cartItem.filename} </div>
                        </div>
                    </div> 
                    <div style={{width: "200px", height: "80px", paddingTop:"10px", borderWidth: "0px 0px 1px 0px", borderColor:"#f5f0f0", borderStyle:"solid"}}>
                        <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888"}}> 
                            <div>₱ {cartItem.unitprice}</div>
                        </div>
                    </div>   
                    <div style={{width: "200px", height: "80px", paddingTop:"10px", borderWidth: "0px 0px 1px 0px", borderColor:"#f5f0f0", borderStyle:"solid"}}>
                        <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888"}}> 
                            <div> {cartItem.quantity} </div>
                        </div>
                    </div>   
                    <div style={{width: "200px", height: "80px", paddingTop:"10px", borderWidth: "0px 0px 1px 0px", borderColor:"#f5f0f0", borderStyle:"solid"}}>
                        <div style={{fontSize: "15px", fontWeight:"500", color:"#0a0a0a"}}> 
                            <div>₱ {cartItem.total}</div>
                        </div>
                    </div>   
                    <div style={{width: "50px", height: "80px", paddingTop:"10px", borderWidth: "0px 0px 1px 0px", borderColor:"#f5f0f0", borderStyle:"solid", textAlign: "center"}}>

                    </div>   
                </div> 
                )
            )
        }
        </div>
        <div style={{backgroundColor: "white", width:"100%", height:"50px", display:"flex", flexDirection:"row", flexWrap: "nowrap", 
         borderWidth:"1px", borderColor:"#cccccc", padding:"0px", }}> 

            <div style={{width: "60%", height: "50px", paddingTop:"10px", }}>
                <div style={{fontSize: "20px", fontWeight:"600", color:"#f80909", textAlign:"right"}}> 
                    <div></div>
                </div>
            </div>
            <div style={{width: "30%", height: "50px", paddingTop:"10px", textAlign:"right"}}>
                <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888", display: "flex", flexDirection:"row", flexWrap: "nowrap", justifyContent:"flex-end"}}> 
                    <div style={{fontSize: "15px", fontWeight:"600", color:"#070707", textAlign:"right", padding: "5px 5px 0px 0px"}}>TOTAL </div>
                    <div  style={{fontSize: "20px", fontWeight:"600", color:"#f80909", textAlign:"right", padding: "0px 5px 0px 0px"}}>  {getFormattedPrice(grandTotal)} </div>
                </div>
            </div>
        </div>
        <div style={{backgroundColor: "white", width:"100%", height:"50px", display:"flex", flexDirection:"row", flexWrap: "nowrap", 
         borderWidth:"1px", borderColor:"#d6d5d5", borderStyle:"solid", padding:"0px 0px 0px 0px", }}> 

            <div style={{width: "30%", height: "50px", paddingTop:"10px", }}>
                <div style={{fontSize: "20px", fontWeight:"600", color:"#464646", textAlign:"right"}}> 
                    <div>PAYMENT METHOD</div>
                </div>
            </div>
            <div style={{width: "70%", height: "50px", paddingTop:"10px", textAlign:"right"}}>
                <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888", display: "flex", flexDirection:"row", flexWrap: "nowrap", justifyContent:"center"}}> 
                    <label> <input type="radio" name="payment" value="GCASH"/> GCASH  </label> &nbsp; &nbsp;
                   {/*  <label> <input type="radio" name="payment" value="GCASH"/> MAYA  </label> &nbsp; &nbsp;   &nbsp;  */}
                    <label> <input type="radio" name="payment" value="COD"/> Cash On Delivery  </label> &nbsp; &nbsp; 
                   {/*  <label> <input type="radio" name="payment" value="GCASH"/> PrintBit Coin (Crypto)  </label> &nbsp; &nbsp; 
                    <label> <input type="radio" name="payment" value="GCASH"/> PAYPAL  </label> &nbsp; &nbsp;  */}
                </div>  
            </div>
        </div>
        <div style={{backgroundColor: "white", width:"100%", height:"50px", display:"flex", flexDirection:"row", flexWrap: "nowrap", 
         borderWidth:"1px", borderColor:"#d6d5d5", borderStyle:"solid", padding:"0px 0px 0px 0px", }}> 
            <div style={{width: "100%", height: "80px", padding:"10px", }}>
                <div style={{fontSize: "20px", fontWeight:"600", color:"#464646", textAlign:"center"}}> 
                    <button style={{backgroundColor: "cornflowerblue", color: "white", borderRadius: "8px", fontSize: "20px", cursor: "pointer", borderWidth: "0px"}} onClick={() => navigate(-1)}> BACK TO CART </button> &nbsp; &nbsp;
                    <button style={{backgroundColor: "cornflowerblue", color: "white", borderRadius: "8px", fontSize: "20px", cursor: "pointer", borderWidth: "0px"}} 
                        onClick={onAddOrder} >  PLACE ORDER </button>
                </div>
            </div>
        </div>

        <Modal show={dialogGCASH} backdrop="static" keyboard={true} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header closeButton>
                <Modal.Title><img src={logoGcash}/></Modal.Title>
              </Modal.Header>
      
              <Modal.Body>
                <p style={{textAlign: "center"}}> <img src={PBQRCODE} width="300px" /> </p>
                <p style={{textAlign: "Left"}}>
                    AMOUNT: <span style={{fontSize: "22px", fontWeight:"600", color:"#f80909", textAlign:"right"}}>{getFormattedPrice(grandTotal)} </span> <br />
                    Number: 0917*****68 <br /> 
                    Name: ES*****O, R. <br />
                    Message: <span style={{fontSize: "18px", fontWeight:"500", color:"#f80909", textAlign:"right"}}>{randomMessage} </span>
                </p>
                <p style={{textAlign: "center", fontSize: "15px"}}>On your GCASH app, please scan the QR code and input the correct amount and don't forget to type the message for us to verify the payment easily. </p>
              </Modal.Body>
      
              <Modal.Footer>
                <Button style={{backgroundColor: "cornflowerblue"}} onClick={handleClose}>P A I D</Button>
              </Modal.Footer>
        </Modal>

        <Modal show={errorOccured} backdrop="static" keyboard={true} onHide={handleCloseError} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header closeButton>
                <Modal.Title>ERROR! </Modal.Title>
              </Modal.Header>
      
              <Modal.Body>
                <p style={{textAlign: "Center"}}>
                    {errMsg} <br />
                    Sorry for the inconvenience. We're fixing it. 
                </p>
              </Modal.Body>
      
              <Modal.Footer>
                <Button style={{backgroundColor: "cornflowerblue"}} onClick={handleCloseError}>Close</Button>
              </Modal.Footer>
        </Modal>

        <Modal show={isProcessing} backdrop="static" keyboard={true} onHide={handleCloseProcessing} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header closeButton>
              </Modal.Header>
      
              <Modal.Body>
                <p style={{textAlign: "Center"}}>
                    Processing ... 
                    <ProgressBar 
                            visible={true}
                            height="80"
                            width="80"
                            color="#097fee"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{}}
                            wrapperClass="" /> 
                </p>
              </Modal.Body>
      
        </Modal>
 
 
    </div>
  )
}

export default CheckOut
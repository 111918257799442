import React from 'react'
import { Link } from 'react-router-dom';
import iconCart from "../../images/icons/cart2.png";

const CartEditorButton = () => {
    const onCart = () => {
        console.log("viewing cart ...");
    }
  return (
    <div>
      <Link to="/cart" ><div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} 
                    onClick={onCart} ><img src={iconCart} width="24px" alt="Cart"/> </div></Link>
    </div>
  )
}

export default CartEditorButton
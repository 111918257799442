import { useState, useContext} from 'react';
import "./modal.css";
import { ProgressBar } from 'react-loader-spinner';
import { FabricContext } from "../../context/FabricContext";
import axios from '../../api/axios';
import useAuth from '../hooks/useAuth';

const DialogDownload = ({showDialog}) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const { canvas, fileName, size } = useContext(FabricContext);
    const { auth } = useAuth();
    const [dataURL, setDataURL] = useState();
    const [errMsg, setErrMsg] = useState();
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [imageType, setImageType] = useState("JPG");
    const API_URL_UPLOAD = "/design_user_upload";
    const DEBUG = false;

    const computeMultiplier = (_width, _height, _type, _unit) => {
        // tarps, banners = 72 dpi
        // stickers, etc = 300 dpi

        if (!_width || !_height) return 1;

        var dpi = 72;
        if (_type === "Tarpaulin Banner") {
            dpi = 72;
        } else {
            dpi = 300;
        }
         //
        const _canvaswidth = canvas.width;
        //const _canvasheight = canvas.height;
        var _targetResolutionWidth = 0;

        if (_unit === "ft.") {
            _targetResolutionWidth = _width * 12 * dpi;
            //const _targetResolutionHeight = _height * 12 * dpi;
        } else if (_unit === "inches") {
            _targetResolutionWidth = _width * dpi;
        } else if (_unit === "cm") {
            _targetResolutionWidth = (_width/2.54) * dpi;
        } else if (_unit === "mm") {
            _targetResolutionWidth = (_width/25.4) * dpi;
        } else { // pixels
            _targetResolutionWidth = _width;
        }

        return _targetResolutionWidth / _canvaswidth;
    }


    const onProcessing = () => {
        DEBUG && console.log("ID", size._id);
        if (!size._id) {
            setErrMsg("Please save it first.");
            return;
        }
        setIsProcessing(true);
        setUploadSuccess(false);
        setErrMsg();
        var _dataURL;
        var _imageType;
        var _multiplier = 1;

        imageType ==="PNG" ? _imageType="png" : _imageType="jpeg";
        _multiplier = computeMultiplier(size.width, size.height, size.type, size.unit);

        console.log("processing ...", _multiplier);
        setTimeout(function() {
            _dataURL = canvas.toDataURL({
                format: _imageType,
                quality: 1,
                multiplier: _multiplier
            });
            //DEBUG && console.log(_dataURL);
            setDataURL(_dataURL);
            if (_dataURL) onUpload(_dataURL, _imageType);
            setIsProcessing(false);
        }, 3000); 
    }

    const onDownload = () => {
        //dataURL = dataURL.replace(/^data:image\/[^;]*/, 'data:application/octet-stream')
       // dataURL = dataURL.replace(
        //  /^data:application\/octet-stream/,
       //   'data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=Canvas.png',
       // )
        var _imageType;
        imageType ==="PNG" ? _imageType="png" : _imageType="jpeg";
        
        DEBUG && console.log("downloading...");
        setIsDownloading(true);
        setTimeout(function() {
            let a = document.createElement('a');
            a.href = dataURL;
            a.download = fileName + "." + _imageType;
            a.click();
            setIsDownloading(false);
            a.remove();
        }, 3000);
    }

    function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:mime});
    }

    const onUpload = async (paramDataURL, imagetype) => {
        const blobimage = dataURLtoBlob(paramDataURL);
        //DEBUG && console.log("blob", blobimage);
        const bearer_token = `Bearer ${auth.accessToken}`;
        const formData = new FormData();
        formData.append('userID', auth.userID);
        //DEBUG && console.log('paramDataURL', paramDataURL);


        formData.append("myFile", blobimage, fileName + "." + imagetype);
        try {
            const response = await axios.post(API_URL_UPLOAD, formData,
                {
                    headers: { 'content-type': 'multipart/form-data',
                               'Authorization': bearer_token },
                    withCredentials: true
                });
            DEBUG && console.log(response);
            if (response.status === 200) {
                DEBUG && console.log("Uploading files successful!");
                setUploadSuccess(true);
            }
        }  catch (err) {
            if (!err?.response) {
              setErrMsg('ERROR: No Server Response');
              DEBUG && console.log("No server response.");
            } else if (err.response?.status === 400) {
              setErrMsg('ERROR: Missing data');
              DEBUG && console.log("400 - Missing Data")
            } else if (err.response?.status === 401) {
             setErrMsg('ERROR: Unauthorized');
             DEBUG && console.log("Unauthorized")
            } else {
              setErrMsg('System Error! We\'re fixing it. Sorry for the inconvenience.');
              DEBUG && console.log("System Error!")
            }

         }    
    }

    const onImageTypeChange = e => {
        setImageType(e.target.value);
        setUploadSuccess(false);
    }

  return (
    <div className="modalBackground">
        <div className="modalContainer" style={{height:"440px"}}>
            <div className="titleCloseBtn">
                <button onClick={() => { showDialog(false); }}> X </button>
            </div>
            <div className="title" style={{height: "30px"}}>
                <h4>DOWNLOAD MY DESIGN</h4>
            </div>
            <div className="body" style={{height:"300px", overflowY: "hidden"}}>
                <div style={{display:"flex",  flexDirection: "column", justifyContent: "left", alignItems:"left",  height:"300px", padding:"0px", width:"100%"}}> 
                    <div style={{color: 'red', height:"10px"}}>
                            { isProcessing ? 'Processing data ...' : ""}
                            { isDownloading ? 'Downloading design ...' : ""}
                            { errMsg }
                    </div>
                    <div style={{width: "100%", textAlign: "center", padding: "0px", height: "50px"}} > 
                        { isProcessing || isDownloading ?   <ProgressBar 
                                            visible={true}
                                            height="60"
                                            width="60"
                                            color="#097fee"
                                            ariaLabel="progress-bar-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="" /> : "" 
                        }  
                    </div>
                    <div style={{height: "120px", padding: "10px", backgroundColor:"#ebebeb", margin: "auto", width: "100%"}}>
                        <div> Select Image Type </div>
                        <div>    
                                <label> <input type="radio" name="imagetype" value="JPG" onChange={onImageTypeChange} defaultChecked disabled={isProcessing || isDownloading}/> JPG </label> &nbsp;      
                                <label> <input type="radio" name="imagetype" value="PNG" onChange={onImageTypeChange} disabled={isProcessing || isDownloading}/> PNG </label> &nbsp;    
                        </div>
                        <div style={{padding: "10px"}}>
                            <button onClick={onProcessing} disabled={isProcessing || isDownloading} style={{borderBlockWidth:"1px", borderRadius:"10px", borderColor:"#d4d3d3"}} >Process Design </button>
                        </div>
                        
                    </div>
                    <div style={{height: '100px', padding: "10px", margin: "auto"}}>
                          {uploadSuccess &&  <button onClick={onDownload} disabled={isProcessing || isDownloading} style={{borderBlockWidth:"1px", borderRadius:"10px", borderColor:"#d4d3d3", backgroundColor: "#dbdbdb"}}>Click Here to Download</button> } 
                    </div>
                </div>
            </div>
            <div className="footer" style={{height:"70px"}}>
                <button onClick={() => {showDialog(false);}} id="cancelBtn"> Close </button>
            </div>
        </div>
    </div>
  )
}

export default DialogDownload
import React, { useState, useContext, useEffect } from "react";
import { FabricContext } from "../../context/FabricContext";
//import { fabric } from 'fabric';
import axios from '../../api/axios';
import useAuth from '../hooks/useAuth';
//import {Buffer} from 'buffer';

const Templates = ({changeDesignSize}) => {
  const DEBUG = false;
  const { setImageFiles, setSize, setFileName, isLoading, setIsLoading} = useContext(FabricContext);
  const { auth } = useAuth();
  const API_URL = '/templates';
  const IMAGE_SERVER = 'https://api.printbit.com';
  //const API_URL_TEMPLATES_THUMBNAILS = '/template_thumbnail';
  const API_URL_TEMPLATE_OPEN = '/templates_open';
  const [templatesData, setTemplatesData] = useState([{}]);
  //const [selectedValue,setSelectedValue] = useState(null); 
  //const [selectedFileName, setSelectedFileName] = useState(null);
  const [errMsg, setErrMsg] = useState();
  const [isLoading_, setIsLoading_] = useState(false);
  //const [Options, setOptions] = useState({
   // width: 300,
   // left: 320,
  //  top: 289,
  //  srcFromAttribute: true
  //});
  
  const loadTemplates = async () => {
    setIsLoading_(true);
    const bearer_token = `Bearer ${auth.accessToken}`;
    //DEBUG && console.log(bearer_token);
        try { 
          const response = await axios
            .get(API_URL,
            { 
                headers: { 'content-type': 'multipart/form-data',
                           'Authorization': bearer_token },
                withCredentials: true,
            })
            .then ((response) => {
                setTemplatesData( response.data);
                DEBUG && console.log(response.data);
                setIsLoading_(false);
            });      
          } catch (err) {
            DEBUG && console.log("Error connecting to server ...", err);
            if (!err?.response) {
              setErrMsg('No Server Response');
              DEBUG && console.log("No server response.")
            } else if (err.response?.status === 400) {
              setErrMsg('Missing data');
              DEBUG && console.log("Missing Data")
            } else if (err.response?.status === 401) {
             setErrMsg('Unauthorized');
             DEBUG && console.log("Unauthorized")
            } else {
              setErrMsg('System error! Sorry about this, we\'re fixing it.');
              DEBUG && console.log("System error!")
            }
            DEBUG && console.log("Error message: ", err);
            //setIsSaving(false);
         } 
  }
  /* const loadTemplateThumbnail = async (thumbnail) => {
    const bearer_token = `Bearer ${auth.accessToken}`;
       const JSON_final = JSON.stringify({   "userID": auth.userID,
                     "thumbnail": thumbnail});
        DEBUG && console.log("JSON", JSON_final);
        try { 
          const response = await axios.post(API_URL_TEMPLATES_THUMBNAILS, JSON_final,
            {
                headers: { 'content-type': 'application/json',
                           'Authorization': bearer_token },
                withCredentials: true,
                responseType: 'blob'
            });

          const imageThumbnail = response.data;
          
          if (imageThumbnail) {
            //setThumbnail(URL.createObjectURL(imageThumbnail));
            //return URL.createObjectURL(imageThumbnail);
            //var tmb = Buffer.from(imageThumbnail, "binary").toString("base64");
            //setThumbnail(URL.createObjectURL(`data:image/jpeg;charset=utf-8;base64,${tmb}`));
            //setThumbnail(URL.createObjectURL(imageThumbnail));
            setThumbnail(URL.createObjectURL(imageThumbnail));
            DEBUG && console.log("base64", imageThumbnail);
          } else {
            setThumbnail("");
          }
        } catch (err) {
          DEBUG && console.log("Error connecting to server ...", err);
          if (!err?.response) {
            setErrMsg('No Server Response');
            DEBUG && console.log("No server response.")
          } else if (err.response?.status === 400) {
            setErrMsg('Missing data');
            DEBUG && console.log("Missing Data")
          } else if (err.response?.status === 401) {
           setErrMsg('Unauthorized');
           DEBUG && console.log("Unauthorized")
          } else {
            setErrMsg('System error! Sorry about this, we\'re fixing it.');
            DEBUG && console.log("System error!")
          }
          DEBUG && console.log("Error message: ", err);
          //setIsSaving(false);
       } 
  } */
  
  function imageOnClick(param) {
    openTemplate(param);
  }

  const openTemplate = async (designID) => {
    setIsLoading(true);
    const bearer_token = `Bearer ${auth.accessToken}`;
        try {
            const response = await axios.post(API_URL_TEMPLATE_OPEN,
                JSON.stringify({ "userID": auth.userID, "designID": designID }),
                {
                    headers: { 'Content-Type': 'application/json',
                              'Authorization': bearer_token },
                    withCredentials: true
                }
            );
            //const designs = JSON.stringify(response?.data);
            const _id = designID;
            const filename = response?.data[0].filename;
            const content  = response?.data[0].content;
            const width = response?.data[0].width;
            const height = response?.data[0].height;
            const unit = response?.data[0].unit;
            const type = response?.data[0].type;
            const canvaswidth = response?.data[0].canvaswidth;
            const canvasheight = response?.data[0].canvasheight;
            var myJSON = JSON.stringify(content);
            DEBUG && console.log(response?.data[0]);
            DEBUG && console.log(width, height, unit, type);
            setFileName(filename);
            setSize({_id: "",width, height, unit, type, canvaswidth, canvasheight}); //update fabric context
            changeDesignSize(width, height, unit, type, canvaswidth, canvasheight, myJSON);  // change the canvas size 
            setImageFiles([]);   
            setIsLoading(false);     
        } catch (error) {
            console.log("Error connecting to server ...", error);
            setIsLoading(false);
        } 
       
        //setOpenModal(false);
  }

  useEffect(() => {
    loadTemplates();
   // var thumb = loadTemplateThumbnail('thumbnails\\37a0360d-96c8-430b-a9cd-06575da01e25.jpeg');
   // if (thumb) {
      //var base64 = Buffer.from(thumb, "binary").toString("base64");
      //setThumbnail(thumb);
   //   console.log("BOLB", thumbnail);
   // }
    
  // eslint-disable-next-line
  },[]);

  useEffect(() => {
    //DEBUG && console.log(images_stock);
  }, [templatesData]);


  return (
    <div style={{display: 'flex', flexWrap: 'wrap'}}> 
      {  
      isLoading_ ?  
                  <div>loading... </div> 
                : 
                  <div> 
                      { templatesData.map((templates, i) => (
                              templatesData.length > 0 ? <img key={i} src={templates.thumbnail ? IMAGE_SERVER + "/templates_thumbnail/" + templates.thumbnail : IMAGE_SERVER + "/templates_thumbnail/noImage.jpg"} alt={templates.filename} width='100px' className="editorPhotos" 
                                                              onClick={() => imageOnClick(templates._id)} 
                                                              //onDrag={() => onDragImage(images.url)}
                                                            // onDragEnd={() => onDragEndImage(images.url)}
                                                          />  : ""
                                                        ))
                      }
                  </div>
      }
    </div>
  )
}

export default Templates

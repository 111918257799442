import React, {useState, useContext} from 'react';
import { FabricContext } from "../../../context/FabricContext";

import {getActiveStyle, setActiveStyle,} from "../../libs/utils";


const Opacity = () => {
    const DEBUG = false;
    const {activeObject } = useContext(FabricContext);
    const [options, setOptions] = useState({});

    const onUpdateOpacity= (e) => {
        setActiveStyle('opacity',  e.target.value/100, activeObject);
        setOptions({
            ...options,
            opacity: e.target.value/100,
        })
        DEBUG && console.log("opacity", e.target.value/100);
    }


  return (
    <div style={{display: "flex", justifyContent: "flex-start", width:"100%"}}>
        <div style={{fontSize:"11px", marginLeft: "5px", height:"24px", paddingTop:"4px", width:"50px", verticalAlign:"middle", textAlign:"left"}}>Opacity </div> 
        <div style={{paddingTop:"4px"}}>
            <input  type="range" id="opacity" name="opacity" min="0" max="100" style={{ "width": "150px" }}
                    value={getActiveStyle("opacity", activeObject)*100}
                    onChange={onUpdateOpacity} 
                    className="btn-object-action"
            />
        </div>

    </div>
  )
}

export default Opacity
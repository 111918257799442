import React, {useContext, useState, useEffect} from 'react';
import { FabricContext } from "../../context/FabricContext";

const BGcolors = () => {
    const { canvas} = useContext(FabricContext);
    const [options, setOptions] = useState({});
    const presetBGColors = [
        { id: '1', label: 'Red', color: "#ff0000"},
        { id: '2', label: 'Yellow', color: "#ffff00"},
        { id: '3', label: 'Green', color: "#00ff00"},
        { id: '4', label: 'Aqua Blue', color: "#00ffff"},
        { id: '5', label: 'Blue', color: "#0000ff"},
        { id: '6', label: 'Pink', color: "#ff00ff"},
        { id: '7', label: 'White', color: "#ffffff"},
        { id: '8', label: 'Light Gray', color: "#e1e1e1"},
        { id: '9', label: 'Gray 1', color: "#acacac"},
        { id: '10', label: 'Gray 2', color: "#959595"},
        { id: '11', label: 'Red 2', color: "#d11b22"},
        { id: '12', label: 'Golden Yellow', color: "#ffae00"},
        { id: '13', label: 'Light Green', color: "#00a651"},
        { id: '14', label: 'Light Blue', color: "#00aeef"},
        { id: '15', label: 'Purple', color: "#6d07ad"},
        { id: '16', label: 'Gray 3', color: "#898989"},
        { id: '17', label: 'Gray 4', color: "#707070"},
        { id: '18', label: 'Gray 5', color: "#555555"},
        { id: '19', label: 'Dark Gray', color: "#363636"},
        { id: '20', label: 'Black', color: "#000000"},
        { id: '21', label: '#f7977a', color: "#f7977a"},
        { id: '105', label: '#f9ad81', color: "#f9ad81"},
        { id: '22', label: '#fff79a', color: "#fff79a"},
        { id: '23', label: '#c4df9b', color: "#c4df9b"},
        { id: '24', label: '#a2d39c', color: "#a2d39c"},
        { id: '25', label: '#6ecff6', color: "#6ecff6"},
        { id: '26', label: '#7ea7d8', color: "#7ea7d8"},
        { id: '27', label: '#8493ca', color: "#8493ca"},
        { id: '28', label: '#a187be', color: "#a187be"},
        { id: '29', label: '#bc8dbf', color: "#bc8dbf"},
        { id: '30', label: '#f49ac2', color: "#f49ac2"},
        { id: '31', label: '#f6989d', color: "#f6989d"},
        { id: '32', label: '#f26c4f', color: "#f26c4f"},
        { id: '33', label: '#f68e55', color: "#f68e55"},
        { id: '34', label: '#fbaf5c', color: "#fbaf5c"},
        { id: '35', label: '#fff467', color: "#fff467"},
        { id: '36', label: '#acd372', color: "#acd372"},
        { id: '37', label: '#7cc576', color: "#7cc576"},
        { id: '38', label: '#3bb878', color: "#3bb878"},
        { id: '39', label: '#1abbb4', color: "#1abbb4"},
        { id: '40', label: '#00bff1', color: "#00bff1"},
        { id: '41', label: '#438cca', color: "#438cca"},
        { id: '42', label: '#5574b9', color: "#5574b9"},
        { id: '43', label: '#855fa8', color: "#855fa8"},
        { id: '44', label: '#f06ea9', color: "#f06ea9"},
        { id: '45', label: '#f26d7d', color: "#f26d7d"},
        { id: '46', label: '#ed1c24', color: "#ed1c24"},
        { id: '47', label: '#f26522', color: "#f26522"},
        { id: '48', label: '#f7941d', color: "#f7941d"},
        { id: '49', label: '#fff200', color: "#fff200"},
        { id: '50', label: '#8dc73f', color: "#8dc73f"},
        { id: '51', label: '#39b54a', color: "#39b54a"},
        { id: '52', label: '#00a651', color: "#00a651"},
        { id: '53', label: '#00a99d', color: "#00a99d"},
        { id: '54', label: '#00aeef', color: "#00aeef"},
        { id: '55', label: '#0072bc', color: "#0072bc"},
        { id: '56', label: '#0054a6', color: "#0054a6"},
        { id: '57', label: '#2e3192', color: "#2e3192"},
        { id: '58', label: '#662d91', color: "#662d91"},
        { id: '59', label: '#92278f', color: "#92278f"},
        { id: '60', label: '#9e0b0f', color: "#9e0b0f"},
        { id: '61', label: '#ed145b', color: "#ed145b"},
        { id: '62', label: '#a0410d', color: "#a0410d"},
        { id: '63', label: '#a36209', color: "#a36209"},
        { id: '64', label: '#aba000', color: "#aba000"},
        { id: '65', label: '#598527', color: "#598527"},
        { id: '66', label: '#1a7b30', color: "#1a7b30"},
        { id: '67', label: '#007236', color: "#007236"},
        { id: '68', label: '#00746b', color: "#00746b"},
        { id: '69', label: '#0076a3', color: "#0076a3"},
        { id: '70', label: '#004b80', color: "#004b80"},
        { id: '71', label: '#1b1464', color: "#1b1464"},
        { id: '72', label: '#440e62', color: "#440e62"},
        { id: '73', label: '#630460', color: "#630460"},
        { id: '74', label: '#9f005d', color: "#9f005d"},
        { id: '75', label: '#9e003a', color: "#9e003a"},
        { id: '76', label: '#7a0001', color: "#7a0001"},
        { id: '77', label: '#7b2e00', color: "#7b2e00"},
        { id: '78', label: '#744d24', color: "#744d24"},
        { id: '79', label: '#8d6339', color: "#8d6339"},
        { id: '80', label: '#c89c6d', color: "#c89c6d"},
        { id: '81', label: '#5f2b63', color: "#5f2b63"},
        { id: '82', label: '#b23554', color: "#b23554"},
        { id: '83', label: '#f27e56', color: "#f27e56"},
        { id: '84', label: '#fce766', color: "#fce766"},
        { id: '85', label: '#86dccd', color: "#86dccd"},
        { id: '86', label: '#e7fdcb', color: "#e7fdcb"},
        { id: '87', label: '#ffdc84', color: "#ffdc84"},
        { id: '88', label: '#f57677', color: "#f57677"},
        { id: '89', label: '#5fc2c7', color: "#5fc2c7"},
        { id: '90', label: '#98dfe5', color: "#98dfe5"},
        { id: '91', label: '#c2eff3', color: "#c2eff3"},
        { id: '92', label: '#ddfdfd', color: "#ddfdfd"},
        { id: '93', label: '#9ee9d3', color: "#9ee9d3"},
        { id: '94', label: '#2fc6c8', color: "#2fc6c8"},
        { id: '95', label: '#2d7a9d', color: "#2d7a9d"},
        { id: '96', label: '#48466d', color: "#48466d"},
        { id: '97', label: '#61c0bf', color: "#61c0bf"},
        { id: '98', label: '#bbded6', color: "#bbded6"},
        { id: '99', label: '#fae3d9', color: "#fae3d9"},
        { id: '100', label: '#ffb6b9', color: "#ffb6b9"},
        { id: '101', label: '#ffaaa5', color: "#ffaaa5"},
        { id: '102', label: '#ffd3b6', color: "#ffd3b6"},
        { id: '103', label: '#dcedc1', color: "#dcedc1"},
        { id: '104', label: '#a8e6cf', color: "#a8e6cf"},

    ];
    const updateBGColor = (e) => {
        setOptions({
            ...options,
            fill: e.target.value,
        })
        canvas.setBackgroundImage(null, canvas.renderAll.bind(canvas));
        canvas.setBackgroundColor(e.target.value, 
            canvas.renderAll.bind(canvas)
        );
        
    }

    function applyBackgroundColor (param) {

        setOptions({
            ...options,
            fill: param,
        })
        
        canvas.setBackgroundImage(null, canvas.renderAll.bind(canvas));
        canvas.setBackgroundColor(param, canvas.renderAll.bind(canvas));
        console.log("apply BG ", param)

    }

    useEffect(() => {
       if (canvas) {
          localStorage.setItem('canvasJSON', JSON.stringify(canvas?.toJSON()));
       }
    }, [options]);


    return (
        <div style={{background: "#fdfcfc", borderRadius: "5px", display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
  
          <div  className="subMenuTitle" style={{width: "100%"}}> Background Color </div>

          <div style={{display: "flex", justifyContent: "flex-start", width:"100%"}}> 
             <div style={{fontSize: "11px", margin:"auto", width: "90px", height:"20px"}}> Select Color </div> 
             <div style={{margin: "auto", width: "90%", textAlign: "left"}}> 
                <input type="color" name="fill" style={{ "width": "50px" }}
                        value={options.fill}
                        onChange={updateBGColor}
                        className="btn-object-action"/>
            </div>
          </div>
          <div style={{display: "flex", justifyContent: "flex-start", flexWrap:"wrap", width:"100%"}}> 
             
            {   presetBGColors.map((colors, i) => ( 
                                                        presetBGColors.length > 0 ? <div key={colors.id} className="presetColorButtons" style={{backgroundColor: colors.color}}
                                                                                        onClick={() => applyBackgroundColor(colors.color)} 
                                                                                    ></div> : "" 
                                                    )
                                    )
            }
          </div>
      </div>
    )
}

export default BGcolors
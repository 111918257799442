import React from 'react';
import iconOpen from "../../images/editor/open.png";

const Open = ({showModal}) => {
    //const { canvas } = useContext(FabricContext);
    //const hiddenFileInput = useRef(null);
    //const [file, setFile ] = useState(null);
    
    const onOpen = () => {
        console.log("Opening file ....")
        //hiddenFileInput.current.click();
        showModal(true);
       
    }

   /*  const handleChange = (event) => {

        if (event.target.files[0] === undefined || event.target.files[0] === null) return;
        var file = event.target.files[0];

        if(!file) return;

        var reader = new FileReader();
        reader.onload = function(f) {
            var data = f.target.result;
            canvas.loadFromJSON(
            JSON.parse(data),
            canvas.renderAll.bind(canvas));
        };
        reader.readAsText(file);

        fabric.util.clearFabricFontCache();
        var objs = canvas.getObjects().filter(function(o) {
              if (o.get('type') === 'textbox') {
                  return o.set('dirty', true);
              }
              canvas.renderAll();
        });
        console.log("OBJECTS", canvas.getObjects());
        canvas.renderAll();
        
    } */


  return (
    <div>
      <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} 
                    onClick={onOpen} ><img src={iconOpen} width="24px" alt="Open"/> </div>
        
        {/*<input type="file" style={{display: 'none'}}  onChange={handleChange} ref={hiddenFileInput} accept=".json" 
                        onClick={(event)=> {
                            event.target.value = null
                            }
                        }
                      /> */}
        
    </div>
  )
}

export default Open
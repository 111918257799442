import React, {useContext} from 'react'; 
import iconRedo from "../../images/editor/redo.png";
import { FabricContext } from "../../context/FabricContext";

const Redo = () => {
  const { canvas, redoData} = useContext(FabricContext);
  const DEBUG = true;

    const onRedo = () => {
        if (redoData.length > 0) {
          DEBUG && console.log("Redoing ...");
          canvas.saveUndo();
          canvas.redo();
          DEBUG && console.log("redodta length:", redoData.length);
        } else {
          DEBUG && console.log("No redo data", redoData.length);
        }
    }

  return (
    <div>
        <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} 
                  onClick={onRedo} ><img src={iconRedo} width="24px" alt="Redo"/> </div>
    </div>
  )
}

export default Redo
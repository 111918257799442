import React, { useState, useEffect, useContext} from 'react';
import { fabric } from 'fabric';
import { Button} from 'react-bootstrap';
import { AiOutlineAppstore, AiOutlineFontSize, AiOutlineFileImage, AiOutlineExpand } from "react-icons/ai";
import { IoShapesOutline } from "react-icons/io5";
import { TbDog } from "react-icons/tb";
import { BsBack } from "react-icons/bs";
import { RiDoubleQuotesR } from "react-icons/ri";

import { FabricContext } from "../context/FabricContext";
//import {getActiveStyle, setActiveProp, setActiveStyle}  from "./libs/utils"

import Templates from './templates/Templates';

import FabricCircle from './shapes/FabricCircle';
import FabricRect from './shapes/FabricRect';
import FabricLine from './shapes/FabricLine';
import FabricTriangle from './shapes/FabricTriangle';
import FabricStar from './shapes/FabricStar';
import FabricHeart from './shapes/FabricHeart';
import FabricRectRound from './shapes/FabricRectRound';
import FabricPentagon from './shapes/FabricPentagon';
import CustomPolygon from './shapes/CustomPolygon';
import { getActiveProp, setActiveProp } from './libs/utils';
import Log from './tools/log';

import FabricTextBox from './texts/FabricTextBox';
import TextBoxTitle from './texts/TextBoxTitle';
import TextBoxSubTitle from './texts/TextBoxSubTitle';
import TextCongrats from './texts/TextCongrats';
import TextHappyBirthday from './texts/TextHappyBirthday';
import TextChristianWorld from './texts/TextChristianWorld';
import TextSale50 from './texts/TextSale50';
import TextAwesomeBohol from './texts/TextAwesomeBohol';

import Photos from './photos/Photos';
import BgImages from './backgrounds/BgImages';
import IconsLogos from './icon_logos/IconsLogosBrowse';

const EditorMainMenu = ({mobile, width, height, changeDesignSize}) => {
  const DEBUG = false;
  const [innerDivStyle, setInnerDivStyle] = useState({minHeight:"100%"}); // default for PC
  //const [isCollapsed, setIsCollapsed] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState([false,"Photos"]);
  const [isMobile, setIsMobile] = useState(mobile);
  const { canvas, activeObject } = useContext(FabricContext);
  //const [width_, setWidth] = useState(width);
  height = !height ? 0 : height; 

  //Delete Icon
  const deleteIcon =
  "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
  
  const img = document.createElement('img');
  img.src = deleteIcon;
  const renderIcon = (ctx, left, top, styleOverride, fabricObject) => {
    var size = 15;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(img, -size / 2, -size / 2, size, size);
    ctx.restore();
  };

  const onDelete = () => {
    //console.log(activeObject.type, canvas.getActiveObject().isEditing);
    if (!activeObject) return;
    if (activeObject.type === 'textbox' && canvas.getActiveObject().isEditing) {
      return;
    }
    if (activeObject.lockMovementX) return;
    canvas.getActiveObjects().forEach((obj) => {
      canvas.remove(obj)
      canvas.saveUndo();
     })
   canvas.discardActiveObject().renderAll()
  };

  const onArrowUp = () => {
    //console.log("ArrowUP");
    if (activeObject) {
      setActiveProp("top", getActiveProp("top", activeObject)-1, activeObject);
    }
  }

  const onArrowDown = () => {
    //console.log("ArrowDOWN");
    if (activeObject) {
      setActiveProp("top", getActiveProp("top", activeObject)+1, activeObject);
    }
  }

  const onArrowLeft = () => {
    //console.log("ArrowLeft");
    if (activeObject) {
      setActiveProp("left", getActiveProp("left", activeObject)-1, activeObject);
    }
  }

  const onArrowRight = () => {
    //console.log("ArrowRight");
    if (activeObject) {
      setActiveProp("left", getActiveProp("left", activeObject)+1, activeObject);
    }
  }

  //KEYBOARD HANDLING
  document.onkeydown = function(e) {
    if (activeObject) {
      if (activeObject.lockMovementX) return;
    }

      switch (e.code) {
        case "Delete": // delete
          //console.log("Deleted");
          onDelete();
          break;
        case "ArrowUp": 
          onArrowUp();
          break;
        case "ArrowDown": 
          onArrowDown();
          break;
        case "ArrowLeft":
          onArrowLeft();
          break;
        case "ArrowRight":
          onArrowRight();
          break;
        default:
          break;
      }
      //console.log(e.code);
      canvas.renderAll();
  }

  fabric.Object.prototype.controls.deleteControl = new fabric.Control({
    //x: 0.4, // .5
    //y: -0.8, //-.5
   // offsetY: 16, // 16
    x: 0.5,
    y: -0.5,
    offsetY: -16,
    offsetX: 16,
    cursorStyle: 'pointer',
    mouseUpHandler: onDelete,
    render: renderIcon,
    cornerSize: 24,
  });

 // Duplicate Object
 const duplicateIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 55.699 55.699' width='100px' height='100px' xml:space='preserve'%3E%3Cpath style='fill:%23010002;' d='M51.51,18.001c-0.006-0.085-0.022-0.167-0.05-0.248c-0.012-0.034-0.02-0.067-0.035-0.1 c-0.049-0.106-0.109-0.206-0.194-0.291v-0.001l0,0c0,0-0.001-0.001-0.001-0.002L34.161,0.293c-0.086-0.087-0.188-0.148-0.295-0.197 c-0.027-0.013-0.057-0.02-0.086-0.03c-0.086-0.029-0.174-0.048-0.265-0.053C33.494,0.011,33.475,0,33.453,0H22.177 c-3.678,0-6.669,2.992-6.669,6.67v1.674h-4.663c-3.678,0-6.67,2.992-6.67,6.67V49.03c0,3.678,2.992,6.669,6.67,6.669h22.677 c3.677,0,6.669-2.991,6.669-6.669v-1.675h4.664c3.678,0,6.669-2.991,6.669-6.669V18.069C51.524,18.045,51.512,18.025,51.51,18.001z M34.454,3.414l13.655,13.655h-8.985c-2.575,0-4.67-2.095-4.67-4.67V3.414z M38.191,49.029c0,2.574-2.095,4.669-4.669,4.669H10.845 c-2.575,0-4.67-2.095-4.67-4.669V15.014c0-2.575,2.095-4.67,4.67-4.67h5.663h4.614v10.399c0,3.678,2.991,6.669,6.668,6.669h10.4 v18.942L38.191,49.029L38.191,49.029z M36.777,25.412h-8.986c-2.574,0-4.668-2.094-4.668-4.669v-8.985L36.777,25.412z M44.855,45.355h-4.664V26.412c0-0.023-0.012-0.044-0.014-0.067c-0.006-0.085-0.021-0.167-0.049-0.249 c-0.012-0.033-0.021-0.066-0.036-0.1c-0.048-0.105-0.109-0.205-0.194-0.29l0,0l0,0c0-0.001-0.001-0.002-0.001-0.002L22.829,8.637 c-0.087-0.086-0.188-0.147-0.295-0.196c-0.029-0.013-0.058-0.021-0.088-0.031c-0.086-0.03-0.172-0.048-0.263-0.053 c-0.021-0.002-0.04-0.013-0.062-0.013h-4.614V6.67c0-2.575,2.095-4.67,4.669-4.67h10.277v10.4c0,3.678,2.992,6.67,6.67,6.67h10.399 v21.616C49.524,43.26,47.429,45.355,44.855,45.355z'/%3E%3C/svg%3E%0A";
 var cloneImg = document.createElement('img');
 cloneImg.src = duplicateIcon;

 const renderDuplicateIcon = (ctx, left, top, styleOverride, fabricObject) => {
     var size = 15;
     ctx.save();
     ctx.translate(left, top);
     ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
     ctx.drawImage(cloneImg, -size / 2, -size / 2, size, size);
     ctx.restore();
   };

 const onDuplicate = () => {
    // editor?.canvas.clone(function(clonedObj));
    canvas.getActiveObject().clone((cloned) => {
      //console.log(cloned);
      cloned.set({
         left: cloned.left + 20,
         top: cloned.top + 20,
         evented: true,
     });

     if (cloned.type === 'activeSelection') {
        cloned.canvas = canvas;
        cloned.forEachObject(function(obj) {
            canvas.add(obj);
        })
        cloned.setCoords();
     } else {
        canvas.add(cloned);
     }
      canvas.setActiveObject(cloned);
      canvas.requestRenderAll();
      canvas.saveUndo();
    })
    
     DEBUG && console.log(canvas.getObjects());
 }


  // fabric.Object.prototype.controls.clone = new fabric.Control({
  //       x: 0.5,
  //       y: -0.5,
  //       offsetY: -16,
  //       offsetX: -16,
  //       cursorStyle: 'pointer',
  //       mouseUpHandler: onDuplicate,
  //       render: renderDuplicateIcon,
  //       cornerSize: 24,
  // });


   fabric.Textbox.prototype.controls.deleteControl = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: -16,
    offsetX: 16,
    cursorStyle: 'pointer',
    mouseUpHandler: onDelete,
    render: renderIcon,
    cornerSize: 24,
  })

  fabric.Textbox.prototype.controls.clone = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: -16,
    offsetX: -16,
    cursorStyle: 'pointer',
    mouseUpHandler: onDuplicate,
    render: renderDuplicateIcon,
    cornerSize: 24,
  });

  function setCollapsed (buttonLabel) {
    //const label = "Templates";
    let tempArray = [];
    if (buttonLabel===isCollapsed[1]) {
        tempArray = [!isCollapsed[0], buttonLabel];
      } else {
        tempArray = [false, buttonLabel];
    }
    setIsCollapsed(tempArray);
  }

  function collapseStyle (buttonLabel) {
    if (isCollapsed[1]===buttonLabel && !isCollapsed[0]) {
        return {backgroundColor:"#f5d5d5"};
    } else {
        return null;
    }
  }

  useEffect(() => { 
    if (!mobile) {
        setIsMobile(false);
        setInnerDivStyle({display:"flex", flexDirection: "column", alignContent: "flex-start", justifyContent: "flex-start", flex:"0 0 85px", backgroundColor:"rgb(223, 224, 224)", 
         height: height });
    } else {
        setIsMobile(true);
        setInnerDivStyle({display:"flex", flexWrap:"wrap", alignContent: "flex-start", justifyContent: "center", flex:"1 0 100px"});
    }
    //console.log(mobile);
  }, [mobile]); 

  
//isCollapsed[1]==="Templates" && !isCollapsed[0]  ? {backgroundColor:"#f5d5d5"} : (null)
  return (
    <div style={{display:"flex", flexDirection: "row", alignItems: "flex-start"}}> 
        <div style={innerDivStyle}> 
            <Button className='buttonsMenu btn-light' style={collapseStyle("Templates")}  
                 onClick={() => {setCollapsed("Templates")}}> 
                 <AiOutlineAppstore /> <span style={{fontSize:"12px"}}>Templates</span> 
            </Button>

            <Button className='buttonsMenu btn-light' style={collapseStyle("Texts")}  
                onClick={() => {setCollapsed("Texts")}}> <AiOutlineFontSize /> <br /> <span style={{fontSize:"12px"}}>Texts </span></Button>

            <Button className='buttonsMenu btn-light' style={collapseStyle("Photos")}
                onClick={() => {setCollapsed("Photos")}}> <AiOutlineFileImage /> <span style={{fontSize:"12px"}}>Photos </span></Button>

            <Button className='buttonsMenu btn-light' style={collapseStyle("Shapes")} 
                onClick={() => {setCollapsed("Shapes")}}> <IoShapesOutline /> <span style={{fontSize:"12px"}}>Shapes </span></Button>  

            <Button className='buttonsMenu btn-light' style={collapseStyle("Animals")} 
                onClick={() => {setCollapsed("Animals")}}> <TbDog /> <span style={{fontSize:"12px"}}>Animals </span></Button>

            <Button className='buttonsMenu btn-light' style={collapseStyle("Icons")} 
                onClick={() => {setCollapsed("Icons")}}> <AiOutlineExpand /> <br /> <span style={{fontSize:"10px"}}>Icons/Logos </span></Button>

            <Button className='buttonsMenu btn-light' style={collapseStyle("Backgrounds")}
                onClick={() => {setCollapsed("Backgrounds")}}> <BsBack /><span style={{fontSize:"10px"}}>Backgrounds </span></Button>

            <Button className='buttonsMenu btn-light' style={collapseStyle("Quotes")}
                onClick={() => {setCollapsed("Quotes")}}> <RiDoubleQuotesR /> <br /><span style={{fontSize:"12px"}}>Quotes </span></Button> 
        </div>
        
        {
            /* TEMPLATES */    
            !isCollapsed[0] && !isMobile && isCollapsed[1]==="Templates" && 
            <div className='subMenuContainer' style={{height: height, overflowY: 'scroll'}}> 
                 <div className="subMenuTitle"> 
                    {isCollapsed[1]} 
                </div>
                 <div style={{height:"100%"}}> 
                   <Templates changeDesignSize={changeDesignSize}/>
                 </div>
            </div>
        }

        {
            /* TEXTS */
            !isCollapsed[0] && !isMobile && isCollapsed[1]==="Texts" && 
            <div className='subMenuContainer'> 
                 <div className='subMenuTitle'> {isCollapsed[1]} </div>
                <TextBoxTitle />
                <TextBoxSubTitle />
                <FabricTextBox />
                <TextCongrats />
                <TextHappyBirthday />
                <TextChristianWorld />
                <TextSale50 />
                <TextAwesomeBohol />
            </div>
        }
 
        {
            /* PHOTOS */
            !isCollapsed[0] && !isMobile && isCollapsed[1]==="Photos" && 
            <div className='subMenuContainer' style={{height: height, overflowY: 'scroll'}}> 
                 <div className="subMenuTitle"> 
                    {isCollapsed[1]} 
                </div>
                 <div style={{height:"100%"}}> 
                    <Photos />
                 </div>
            </div>

        }

        {
            /* SHAPES */
            !isCollapsed[0] && !isMobile && isCollapsed[1]==="Shapes" && 
            <div className='subMenuContainer'> 
                 <div className='subMenuTitle'> {isCollapsed[1]} </div>
                {/* {
                    shapeMenuButtons.map((data, i) => 
                    <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"45px", width:"45px"}} 
                        onClick={data.handler}>{data.label}
                    </div>
                   ) 
                } */}
                <FabricCircle />
                <FabricRect />
                <FabricLine />
                <FabricTriangle />
                <FabricStar />
                <FabricHeart />
                <FabricRectRound />
                <FabricPentagon />
                <CustomPolygon />
                <Log />

            </div>
        }

        {
            /* ANIMALS */    
            !isCollapsed[0] && !isMobile && isCollapsed[1]==="Animals" && 
            <div className='subMenuContainer'> 
                 <div className="subMenuTitle"> 
                    {isCollapsed[1]} 
                </div>
                 <div style={{height:"100%"}}> 
                   some animals here soon ...
                 </div>
            </div>
        }
       
       {
            /* ICONS */    
            !isCollapsed[0] && !isMobile && isCollapsed[1]==="Icons" && 
            <div className='subMenuContainer'> 
                 <div className="subMenuTitle"> 
                    Icons & Logos
                </div>
                 <div style={{height:"100%"}}> 
                    <IconsLogos />
                 </div>
            </div>
        }
          
        {
            /* BACKGROUNDS */    
            !isCollapsed[0] && !isMobile && isCollapsed[1]==="Backgrounds" && 
            <div className='subMenuContainer' style={{height: height, overflowY: 'scroll'}}> 
                 <div className="subMenuTitle"> 
                    {isCollapsed[1]} 
                </div>
                 <div> 
                    <BgImages />
                 </div>
            </div>
        }

        {
            /* QUOTES */    
            !isCollapsed[0] && !isMobile && isCollapsed[1]==="Quotes" && 
            <div className='subMenuContainer'> 
                 <div className="subMenuTitle"> 
                    {isCollapsed[1]} 
                </div>
                 <div style={{height:"100%"}}> 
                   some quotes here soon ...
                 </div>
            </div>
        }

        {
            // MObile screen
           // isMobile &&  <div style={{background:"#504b4b"}}> M</div> 
        }
    </div>
  )
}

export default EditorMainMenu
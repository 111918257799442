import React from 'react';
import { Container } from 'react-bootstrap';
import iconCOD from "../images/icons/COD.png";

const PaymentChannels = () => {
  return (
    <Container>
    <div style={{width: "600px", textAlign: "center", backgroundColor:"rgb(3, 67, 145)", color:"white", margin:"auto", padding:"10px", fontSize:"18px"}}>
            PAYMENT CHANNELS
            &nbsp;
            
    </div>
    <br />
      <img src={iconCOD} alt="COD"/>
    </Container>
  )
}

export default PaymentChannels
import React, {useState, useContext, useEffect} from 'react';
import iconHappybday from "../../images/editor/texts/happybday.png";
import { FabricContext } from "../../context/FabricContext";
import { fabric } from 'fabric';

const TextHappyBirthday = () => {
    const { canvas } = useContext(FabricContext)
    const [mousePosition, setMousePosition] = useState({x:100, y: 100})
    const [isDrag, setisDrag] = useState(false);
    const [text1Options, setText1Options] = useState({
        width: 320,
        top: 289,
        left: 299,
        fontSize: 50,
        fontWeight: "bold",
        fontStyle: "normal",
        textAlign: "center",
        fontFamily: "Amarillo",
        textDecoration: "normal",
        fill: "#000000",
        styles: 
        { "0": {
            "0": {"fill": "#f70808"},
            "1": {"fill": "#f7ab08"},
            "2": {"fill": "#f7e308"},
            "3": {"fill": "#48f708"},
            "4": {"fill": "#08f7f3"},
            "5": {"fill": "#0870f7"},
            "6": {"fill": "#5008f7"},
            "7": {"fill": "#cf08f7"},
            "8": {"fill": "#f70870"}
             }
        }
    })


    const onAddTextBox = () => {
        if (isDrag) {
            var txtWidth = text1Options.width / 2;  // to center object on the mouse position 
            setText1Options({...text1Options,
                 top: mousePosition.y,
                 left: mousePosition.x-txtWidth,
               }) 
              setisDrag(false); 
             } else {
             //  isDrag_ = false;
         }      

        const textBox = new fabric.Textbox("Birthday!", text1Options)
        canvas.add(textBox);

        if (!isDrag) {
            canvas.centerObject(textBox); 
        }

        var options2 = {
            width: 320,
            top: textBox.top - textBox.height - 5,
            left: textBox.left,
            fontSize: 40,
            fontWeight: "bold",
            fontStyle: "normal",
            textAlign: "center",
            fontFamily: "Caveat",
            textDecoration: "normal",
            fill: "#c00707",
        };

        const textBox2 = new fabric.Textbox("H a p p y", options2)
        canvas.add(textBox2);

        fabric.util.clearFabricFontCache();
        textBox.set('dirty', true);
        
        var sel = new fabric.ActiveSelection([textBox, textBox2], {
            canvas: canvas,
        });
        canvas.setActiveObject(sel);
        canvas.renderAll();
    
    }

    const onDragEndTextBox = (e) => {
        var txtWidth = text1Options.width / 2; 
        setText1Options({...text1Options,
            top: mousePosition.y,
            left: mousePosition.x - txtWidth,
          })
       // const textBox = new fabric.Textbox("Congratulations!", text1Options);
      //  canvas.add(textBox).setActiveObject(textBox);    
        onAddTextBox();
        canvas.off("dragover");
    }

    const onDragTextBox = (e) => {
        canvas.on("dragover", function(options) {
            getMouseCoords(options);   
          }) 
        setisDrag(true);
    }

    function getMouseCoords(event)
      {
        var pointer = canvas.getPointer(event.e);
        var posX = pointer.x;
        var posY = pointer.y;
        setMousePosition({"x": posX, "y": posY });
      //  console.log("getmouse", posX+", "+posY);    // Log to console
      }

     useEffect(() => {
        var txtWidth = text1Options.width / 2; 
        setText1Options({...text1Options,
          top: mousePosition.y,
          left: mousePosition.x - txtWidth,
        })
      //  setisDrag(true);
       // eslint-disable-next-line
     }, [mousePosition.x, mousePosition.y]);


    return (
        <div>
            <div className='buttonShapes' style={{fontSize:"25px", color:"#010107", margin:"3px", padding: "0px", height:"66px", width:"148px"}} 
                    onClick={onAddTextBox} onDragStart={onDragTextBox} onDragEnd={onDragEndTextBox}><img src={iconHappybday} width="140px" alt="Happy Birthday!"/> </div>
        </div>
      )
    }
    
export default TextHappyBirthday



import React, { useState, useRef, useContext, useEffect } from "react";
import { FabricContext } from "../../context/FabricContext";
import { fabric } from 'fabric';
import iconBrowse from '../../images/editor/browse.png';

import axios from '../../api/axios';
import useAuth from '../hooks/useAuth';


const PhotoBrowse = () => {
    const DEBUG = false;
    const hiddenFileInput = useRef(null);
    const { canvas, imageFiles, setImageFiles, setIsLoading} = useContext(FabricContext);
    const [mousePosition, setMousePosition] = useState({x:100, y: 100})
    const [isDrag, setisDrag] = useState(false);
    const [Options, setOptions] = useState({
        width: 300,
        left: 320,
        top: 289,
        srcFromAttribute: true
    });
    
    const [images_stock, setImages_stock] = useState([{}]);
    const { auth } = useAuth();
    const API_URL = '/upload';
    const API_URL_IMAGES = '/images';
    const SERVER_PHOTOS = 'https://api.printbit.com';

    const [isLoading_, setIsLoading_] = useState(false);

    const handleClick = (event) => {
        hiddenFileInput.current.click();
       // console.log("CLICKED!")
    }
 
    const handleChange = (event) => {
       // const fileUploaded = event.target.files[0];
       // console.log("file ", event.target.files[0]);
        if (event.target.files[0] === undefined || event.target.files[0] === null) return;
        var imageFile = URL.createObjectURL(event.target.files[0]);
        DEBUG && console.log("imageFiles", imageFiles.length);
        if (imageFiles) {
          setImageFiles([...imageFiles , { "blobURL": imageFile,
                       "localFilename" : event.target.files[0]}]);
        } else {
          setImageFiles([{ "blobURL": imageFile,
                       "localFilename" : event.target.files[0]}]);
        }
        //console.log("event.file:", event.target.files[0]);
        
        

        fabric.Image.fromURL(imageFile, function(oImg) {
           // setImage1(oImg);
            //console.log("oIMG", oImg);
            //oImg.scale(0.5).set('flipX', false);
            var image_width_final = canvas.width / 3;
            var image_width = oImg.width;
            oImg.scale(image_width_final/image_width);
            oImg.srcFromAttribute=true;

            // added to prevent cropping on applying filters
            let imageTextureSize = oImg.width > oImg.height ? oImg.width : oImg.height;
            if (imageTextureSize > fabric.textureSize) {
                //canvas.textureSize = imageTextureSize;
                fabric.textureSize = imageTextureSize;
            }

            canvas.add(oImg).setActiveObject(oImg);
            canvas.centerObject(oImg);

            console.log("TEXTTURE", canvas?.textureSize)
           
          }, {crossOrigin: 'anonymous'});
       // event.target.files[0] = null;
      // uploadImagetoServer(imageFiles.localFilename);
    };

    // function blobToFile(theBlob, fileName){
    //   //A Blob() is almost a File() - it's just missing the two properties below which we will add
    //   theBlob.lastModifiedDate = new Date();
    //   theBlob.name = fileName;
    //   return theBlob;
    // }

    const uploadImagetoServer = async (imageFile) => {
        const formData = new FormData();
        formData.append('userID', auth.userID);

        //let blob = new Blob(image1, { type: "image/jpeg" });
        //var myFile = blobToFile(blob, "myimage.jpg");
        DEBUG && console.log("MyFile:", imageFile);
        formData.append('file', imageFile);  
        DEBUG && console.log("formdata", formData);
        const bearer_token = `Bearer ${auth.accessToken}`;
        try {
            const response = await axios.post(API_URL, formData,
                {
                    headers: { 'content-type': 'multipart/form-data',
                               'Authorization': bearer_token },
                    withCredentials: true
                });
            console.log(response);
        }  catch (err) {
            if (!err?.response) {
              //setErrMsg('No Server Response');
              DEBUG && console.log("No server response.")
            } else if (err.response?.status === 400) {
              //setErrMsg('Missing data');
              DEBUG && console.log("Missing Data")
            } else if (err.response?.status === 401) {
             // setErrMsg('Unauthorized');
             DEBUG && console.log("Unauthorized")
            } else {
              //  setErrMsg('Not logged In');
              DEBUG && console.log("Not logged in")
            }
            DEBUG && console.log("Error message: ", err);
            //setIsSaving(false);
         } 
    }

    function imageOnClick(param) {
      DEBUG && console.log(param);
      setIsLoading(true);
       // canvas.saveUndo();
        fabric.Image.fromURL(param, function(oImg) {
            
            var image_width_final = canvas.width / 3;
            var image_width = oImg.width;
            oImg.scale(image_width_final/image_width);

 
            if (isDrag) {
                var imgWidth = oImg.width / 2;  // to center object on the mouse position 
                setOptions({
                     width: oImg.width,
                     top: mousePosition.y,
                     left: mousePosition.x-imgWidth,
                   }) 
                  setisDrag(false); 
                 } else {
                 //  isDrag_ = false;
             }      

            oImg.set({left: Options.left, top: Options.top, srcFromAttribute: true});

            //needed for applying filters to big images
            let imageTextureSize = oImg.width > oImg.height ? oImg.width : oImg.height;
            if (imageTextureSize > fabric.textureSize) {
                //canvas.textureSize = imageTextureSize;
                fabric.textureSize = imageTextureSize;
            }

            
            canvas.add(oImg).setActiveObject(oImg); 
            canvas.saveUndo();

            if (!isDrag) {
                canvas.centerObject(oImg);
            }
            setIsLoading(false);
           // console.log("width", canvas.width, oImg.width);
        }, {crossOrigin: 'anonymous'});
       
    }

    function onDragImage(param)  {
        canvas.on("dragover", function(options) {
            getMouseCoords(options);   
          }) 
        setisDrag(true);
       // console.log(param);
    }

    function onDragEndImage(param) { 
        imageOnClick(param);
        canvas.off("dragover");
    }

    function getMouseCoords(event)
      {
        var pointer = canvas.getPointer(event.e);
        var posX = pointer.x;
        var posY = pointer.y;
        setMousePosition({"x": posX, "y": posY });
      //  console.log("getmouse", posX+", "+posY);    // Log to console
      }
    
      useEffect(() => {
       // var txtWidth = Options.width / 2; 
        setOptions({...Options,
          top: mousePosition.y,
          left: mousePosition.x,
        })
      //  setisDrag(true);
     }, [mousePosition.x, mousePosition.y]);

     useEffect(() => {
        loadImages();
     },[]);

     useEffect(() => {
      DEBUG && console.log("imagefile:", imageFiles);
     }, [imageFiles]);

     const loadImages = async () => {
        //const url = "http://localhost:5000/images";
        //try {
        //    const response = await fetch(url);
        //    const images = await response.json();
        //    setImages_stock(images);
        
        //} catch (error) {
        //  DEBUG && console.log("Error connecting to server ...", error);
        //}

        //need users to logged in before getting image data 
        setIsLoading_(true);
        const bearer_token = `Bearer ${auth.accessToken}`;
        try { 
          const response = await axios.get(API_URL_IMAGES,
            {
                headers: { 'content-type': 'multipart/form-data',
                           'Authorization': bearer_token },
                withCredentials: true
            });
          const images = response.data;
          setImages_stock(images);
          DEBUG && console.log("IMAGES", images);
          setIsLoading_(false);
        } catch (err) {
          DEBUG && console.log("Error connecting to server ...", err);
          if (!err?.response) {
            //setErrMsg('No Server Response');
            DEBUG && console.log("No server response.")
          } else if (err.response?.status === 400) {
            //setErrMsg('Missing data');
            DEBUG && console.log("Missing Data")
          } else if (err.response?.status === 401) {
           // setErrMsg('Unauthorized');
           DEBUG && console.log("Unauthorized")
          } else {
            //  setErrMsg('Not logged In');
            DEBUG && console.log("Not logged in")
          }
          DEBUG && console.log("Error message: ", err);
          //setIsSaving(false);
       } 
     }

  return (
    <div style={{display: 'flex', flexWrap: 'wrap'}}> 
        <div style={{width: "100%", margin: "10px"}}> 
                <button className="buttonUpload" onClick={handleClick}>
                    <span style={{fontSize: "12px"}}> <img src={iconBrowse} width="20px" alt="circle"/> &nbsp; Browse Photo &nbsp;  </span>
                </button>
                <input type="file" style={{display: 'none'}}  onChange={handleChange} ref={hiddenFileInput} accept="image/png, image/jpeg, image/webp" 
                        onClick={(event)=> {
                            event.target.value = null
                            }
                        }
                />
        </div>

        {  
          isLoading_ ?  
                  <div style={{width: "100%"}}>loading... </div> 
                : 
                <div> 
                        {images_stock.map((images, i) => (
                            images_stock.length > 0 ? (images.url!==undefined ? <img key={i} src={SERVER_PHOTOS + images.thumbnail} alt={images.label} width='100px' className="editorPhotos" 
                                                            onClick={() => imageOnClick(SERVER_PHOTOS + images.url)} 
                                                            onDrag={() => onDragImage(SERVER_PHOTOS + images.url)}
                                                            onDragEnd={() => onDragEndImage(SERVER_PHOTOS + images.url)}
                                                        /> : "")  
                                                        : ""
                                                      ))}
                </div>
        }
    </div>
  )
}

export default PhotoBrowse

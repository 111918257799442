import { useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ProgressBar } from 'react-loader-spinner'
import axios from '../../api/axios';
import useAuth from '../hooks/useAuth';
import editIcon from "../../images/icons/edit.png";
const API_CART_URL = '/cart';
const API_CART_DEL_URL = '/cart/del';
const API_CART_QTY_URL = '/cart/editqty'
const dayjs = require('dayjs');




const Cart = () => {
    const DEBUG = false;
    const navigate = useNavigate();
    const deleteIcon =
    "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
    const { auth } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [myCart, setMyCart] = useState([]);
    const [grandTotal, setGrandTotal] = useState(0);
    const [checkedState, setCheckedState] = useState([]);
    const [dialogOK, setDialogOK] = useState(false);
    const [forCheckOut, setForCheckOut] = useState([]);
    const [localThumbnails, setLocalThumbnails] = useState([]);
    //const [testImage, setTestImage] = useState([]);
    const getFormattedPrice = (price) => `₱ ${price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;

    const handleOnChange = (position) => {
        //DEBUG && console.log ("checkdstate", checkedState);
        const updatedCheckedState = checkedState.map((item, index) => 
                index === position ? !item : item       
        );

        setCheckedState(updatedCheckedState);
        getGrandTotal(updatedCheckedState);
       
    }

    const getGrandTotal = (_checkstate) => {
        const totalPrice = _checkstate.reduce(
            (sum, currentState, index) => {
              if (currentState === true) {
                return sum + myCart[index]?.total;
              }
              return sum;
            },
            0
          );
      
          setGrandTotal(totalPrice);
    }

    const openCart = async () => {
        setIsLoading(true);
        const bearer_token = `Bearer ${auth.accessToken}`;
        try {
            const response = await axios.post(API_CART_URL,
                JSON.stringify({ "userID": auth.userID}),
                {
                    headers: { 'Content-Type': 'application/json',
                              'Authorization': bearer_token },
                    withCredentials: true
                }
            );
            const cart = response?.data;
            DEBUG && console.log(cart);
            setMyCart(cart);
            setLocalThumbnails([]);
            
            if (checkedState.length===0) {
                setCheckedState(new Array(cart.length).fill(false));
            }
            
            setIsLoading(false);
        } catch (error) {
            DEBUG && console.log("Error connecting to server ...", error);
            setIsLoading(false);
        } 
    }

    const onDeleteItem = async (cartItemId) => {
        DEBUG && console.log("deleting ...", cartItemId);
        //return;
        const bearer_token = `Bearer ${auth.accessToken}`;
        try {
            const response = await axios.post(API_CART_DEL_URL,
                JSON.stringify({ "userID": auth.userID, "_id": cartItemId}),
                {
                    headers: { 'Content-Type': 'application/json',
                              'Authorization': bearer_token },
                    withCredentials: true
                }
            );
            openCart();
            //setGrandTotal(0);
        } catch (error) {
            DEBUG && console.log("Error connecting to server ...", error);
        }
    }

    const onEditItem = async (cartItemId) => {
        DEBUG && console.log("editing ...", cartItemId);
        let item_to_edit;
        for (var i=0; i<myCart?.length; i++) {
            if (myCart[i]._id===cartItemId) {
                item_to_edit = myCart[i];
            }
        }
        navigate("/cart_edit_tarp",  { state: { item_to_edit, localThumbnails} });
        DEBUG && console.log ("item to edit:", item_to_edit);
    }

    const updateQuantity =  async (e) => {
        DEBUG && console.log("updating qty ....", e.target.value, e.target.name);
        const bearer_token = `Bearer ${auth.accessToken}`;
        let _unitprice = 0;
        let _priceprint = 0;
        let _priceaddon = 0;
        let _pricelayout = 0;
        const unitPrice = myCart.map((cartItem, index)=> {
            if (cartItem._id===e.target.name) {
                _unitprice = cartItem.unitprice;
                _priceaddon = cartItem.priceAddon;
                _priceprint = cartItem.pricePrint;
                _pricelayout = cartItem.priceLayout;
                //return cartItem.unitprice;
            } else {
                //return 0;
            }
        })
        console.log("_priceaddon", _priceaddon);
        try {
            const response = await axios.post(API_CART_QTY_URL,
                JSON.stringify({ "userID": auth.userID, "_id": e.target.name, 
                                 "quantity": e.target.value,
                                 "unitprice": _unitprice,
                                 "priceAddon": _priceaddon,
                                 "pricePrint": _priceprint,
                                 "priceLayout": _pricelayout
                                }),
                {
                    headers: { 'Content-Type': 'application/json',
                              'Authorization': bearer_token },
                    withCredentials: true
                }
            );
           //updateTotal(e.target.name);  
           openCart();
           //updateForCheckOut();
           
           //setGrandTotal(0);
        }  catch (error) {
           DEBUG && console.log("Error connecting to server ...", error);
        }
    }

    //const updateTotal = (id) => {
        //console.log('index: ' + index);
        //console.log('property name: '+ e.target.name);
        //let newArr = [...myCart]; // copying the old datas array
     //   let newArr = myCart.map((cartItem, index) => {
     //       if (cartItem._id===id) {
     //           console.log("Total", cartItem.quantity);
     //       }
     //   })
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        //newArr[index] = e.target.value; // replace e.target.value with whatever you want to change it to  
        //setMyCart(newArr);
    //}
      
    const onCheckout = () => {
        //
        if (grandTotal===0) {
            setDialogOK(true);
            return;
        }
        navigate("/checkout",  { state: { forCheckOut } });
    }

    const handleClose = () => setDialogOK(false);

    const updateForCheckOut = () => {
        var checkedProduct=[];
        for (var i=0; i < checkedState.length; i++) {
            //DEBUG && console.log(i, checkedState[i]);
            if (checkedState[i]===true) {
                checkedProduct.push(myCart[i]);
            }
            setForCheckOut(checkedProduct);
            //DEBUG && console.log(checkedProduct);
        }
    }

    const getImageThumbnail = async (orderid_, folder_, image_) => {
        
        const filename_ = "/" + orderid_ + "/" + folder_ + "/" + image_;
        //DEBUG && console.log(image_);
        const bearer_token = `Bearer ${auth.accessToken}`;
       
        await axios.post(API_CART_URL + filename_,
                { "userID": auth.userID},
                {
                    headers: { 'Content-Type': 'application/json',
                              'Authorization': bearer_token },
                    withCredentials: true,
                    responseType: "blob"
                })
                .then((response) => {
                //DEBUG && console.log(data);
                const new_blob = new Blob( [ response.data ], { type: 'image/jpg' } );
                const url = URL.createObjectURL( new_blob );
                //DEBUG && console.log ("blob: ", new_blob);
                setLocalThumbnails(images => {

                    const id_ = orderid_;
                    let newdata=[];

                    newdata ={"id": orderid_, "filename": image_, "blob": url};  
                    return [...images, newdata];

                    // if (images?.length ===0) {
                    //      newdata ={"id": orderid_, "photos": [{ "filename": image_, "blob": url}]};  
                    //      return [...images, newdata];
                    // } else {
                    //    newdata = [{"filename": image_, "blob": url}];
                    //    return images?.map((image) => 
                    //             image.id === id_ ? {...image, photos: image?.photos?.concat(newdata)} : image
                    //      )
                    // }

                   /*  if (images?.length ===0) {
                          newdata ={"id": orderid_, "photos": [{ "filename": image_, "blob": url}]};  
                          DEBUG && console.log("new data 0:", newdata);
                          return [...images, newdata];
                    }
                    console.log("blob:", image_, url, id_, images?.length, images);
                    
                    for (var i=0; i<images?.length; i++) {
                        if (images[i]?.id === id_) {
                            DEBUG && console.log("same id:", id_, image_);
                            newdata = [{"filename": image_, "blob": url}];
                            //return [{...images[i], photos: images[i]?.photos?.concat(newdata)}] // problem here
                            if (images?.length===1) {
                                return [{...images[i], photos: images[i]?.photos?.concat(newdata)}]
                            } else {
                                //return (images_ => {
                                   // photos: images_?.photos?.concat(newdata)
                                    //}
                                //)
                                const data_ = [{...images[i], photos: images[i]?.photos?.concat(newdata)}]; // store current data
                                DEBUG && console.log("store current date", data_);
                                const updatedData = images?.splice(i,1);
                                DEBUG && console.log("remove from data", updatedData);
                                return updatedData.concat(data_)
                            } 
                            
                        } else {
                            console.log("NOT same id:", id_, image_);
                            
                        }
                    }
                    DEBUG && console.log("End of loop", images)
                    newdata = {"id": orderid_, "photos": [{ "filename": image_, "blob": url}]};
                    return [...images, newdata];
                    //return images; */

                });
                setIsLoading(false);  
                })
                .catch(function (error) {
                    console.log("Error:",  error);
        })
    }

    const update_local_thumbnails = () => {
       // setLocalThumbnails(thumbnails => myCart.map((cartItem, i) => {
           // for(var photo in cartItem.photos){
           //     return [{"photo": photo, "blob":"yorbs"}]
            //}
        myCart.map((cartItem, i) => {
            //setIsLoading(true);  
            var i;
           // var loopData = [];
            for(i=0; i < cartItem.photos.length; i++){
                //setIsLoading(true);  
               // const newdata ={ "photo": cartItem.photos[i], "blob": getImageThumbnail(cartItem._id, "/thumbs/", cartItem.photos[i])};
               getImageThumbnail(cartItem._id, "/thumbs/", cartItem.photos[i]);
               // loopData.push(newdata);
            }
           // return [...thumbnails, loopData];      
        })

      //for (var i=0; i < myCart.length; i++) {
      //      console.log(myCart[i].photos);
      //}

     // setMyCart(oldData => {
    //   const newData = [...oldData];
     //   for (var i=0; i < newData.length; i++) {

     //   }
    //    return newData;
    //  })
  
    }

    const getBlob = (file_) => {
       // DEBUG && console.log("getBlob:", file_, testImage[1]?.blob)
        //return testImage[3]?.blob;
        for (var i=0; i<localThumbnails?.length; i++) {
            if (file_===localThumbnails[i].filename) {
                return localThumbnails[i]?.blob;
            } 
        }   
    }

    useEffect(() => {
        openCart();
      //  getImageThumbnail();

     // eslint-disable-next-line
     },[]);

     useEffect(() => {
        getGrandTotal(checkedState);
        getFormattedPrice(grandTotal);
        updateForCheckOut();
     },[checkedState]);

     useEffect(() => {
        getGrandTotal(checkedState);
        getFormattedPrice(grandTotal);
        updateForCheckOut();
        update_local_thumbnails();
        DEBUG && console.log("Cart:", myCart);
     }, [myCart]);

     //useEffect(() => {
        //console.log("local thumbs: ", localThumbnails);
     //},[localThumbnails])


  return (
    <div style={{backgroundColor:"#e9f3fc", display: "flex", flexDirection: "row", flexWrap: "wrap", width: "1200px", 
        justifyContent:"center", margin: "10px auto 10px auto", alignItems: "flexStart", boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>      
        
        <div style={{width: "100%", height:"50px"}}>
            <div style={{fontSize: "17px", fontWeight:"400", color: "#1A226D", margin: "auto", textAlign: "center", paddingTop: "10px"}}> Shopping Cart  </div>
        </div>
        
        <div style={{backgroundColor: "white", width:"100%", height:"50px", display:"flex", flexDirection:"row", flexWrap: "nowrap", 
         borderWidth:"1px", borderColor:"#cccccc", padding:"10px 0px, 10px, 0px", }}> 

            <div style={{width: "550px", height: "50px", paddingTop:"10px", borderWidth: "1px", borderColor:"#e6e1e1", borderStyle:"solid"}}>
                <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888"}}> 
                    <div>Product</div>
                </div>
            </div>
            <div style={{width: "200px", height: "50px", paddingTop:"10px", borderWidth: "1px", borderColor:"#e6e1e1", borderStyle:"solid"}}>
                <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888"}}> 
                    <div>Unit Price</div>
                </div>
            </div>
            <div style={{width: "200px", height: "50px", paddingTop:"10px", borderWidth: "1px", borderColor:"#e6e1e1", borderStyle:"solid"}}>
                <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888"}}> 
                    <div>Quantity</div>
                </div>
            </div>
            <div style={{width: "200px", height: "50px", paddingTop:"10px", borderWidth: "1px", borderColor:"#e6e1e1", borderStyle:"solid"}}>
                <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888"}}> 
                    <div>TOTAL</div>
                </div>
            </div>
            <div style={{width: "50px", height: "50px", paddingTop:"10px", borderWidth: "1px", borderColor:"#e6e1e1", borderStyle:"solid"}}>
                <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888"}}> 
                    <div>&nbsp;</div>
                </div>
            </div>
        </div>

        <div style={{backgroundColor: "white", width: "1200px"}}>
        { isLoading ? <div><ProgressBar 
                            visible={true}
                            height="80"
                            width="80"
                            color="#097fee"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{}}
                            wrapperClass="" /> 
                        </div> :  
        myCart.map((cartItem, i) => ( 
                <div key={i} style={{backgroundColor: "white", width:"100%", display:"flex", flexDirection:"row", flexWrap: "nowrap", 
                    borderWidth:"1px", borderColor:"#cccccc", padding:"10px 0px, 10px, 0px", }}> 
                    <div style={{width: "50px", paddingTop:"10px", borderWidth: "0px 0px 1px 0px", borderColor:"#f5f0f0", borderStyle:"solid"}}>
                        <div style={{fontSize: "18px", fontWeight:"400", color:"#f70404"}}> 
                            <div style={{fontSize:"Large"}}> 
                                    <input type="checkbox" id="cartItem" name="cartItem" value={cartItem.total} style={{backgroundColor: "red"}} 
                                       checked={checkedState[i]}  onChange={() => handleOnChange(i)}/>
                            </div>
                        </div>
                    </div>   
                    <div style={{width: "500px", paddingTop:"10px", borderWidth: "0px 0px 1px 0px", borderColor:"#f5f0f0", borderStyle:"solid"}}>
                        <div style={{fontSize: "15px", fontWeight:"400", textAlign: "left"}}> 
                            <div style={{padding: "0px 0px 0px 3px", fontColor:"#0a0a0a", fontWeight:"500"}}>{cartItem.type}</div>
                            <div style={{padding: "0px 0px 0px 3px", fontSize:"12px", color:"#8a8888"}}>Size (w x h): {cartItem.width} x {cartItem.height} {cartItem.unit} - {cartItem.filename} </div>
                            {cartItem.activity ? 
                                <div style={{padding: "0px 0px 0px 3px", fontSize:"12px", color:"#8a8888"}}> {cartItem.activity}, {cartItem.theme} <br /> 
                                    {cartItem.content}, {cartItem.notes} <br />
                                       Date Needed: {(() => {
                                                             return dayjs(cartItem.dateNeeded).format('dddd, MMMM D, YYYY h:mm A');;
                                                        })()
                                                  } <br />
                                           {
                                            cartItem.photos.map((photo, j) => (                                               // var i;
                                               // var loopData = [];
                                               // for(i=0; i < testImage.length; i++){ 
                                              /*  testImage?.map((photo_, k)=> {
                                                    if (photo===photo_.filename) {
                                                        return <img key={j} src={photo_.blob} width="50px" height="50px"/>     
                                                    } else {
                                                        return <img key={k}  width="50px" height="50px"/> 
                                                    }
                                               }) */
                                               
                                               <img key={j} src={getBlob(photo)} width="50px" height="50px" style={{padding: "1px", borderRadius: "8px",}}/>     
  
                                                       
                                               //     } else return "no image"
                                               // }
                                                
                                            ))
                                           }
                                </div>
                                : ""
                            }
                        </div>
                    </div> 
                    <div style={{width: "200px", paddingTop:"10px", borderWidth: "0px 0px 1px 0px", borderColor:"#f5f0f0", borderStyle:"solid"}}>
                        <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888"}}> 
                            <div>₱ {cartItem.unitprice}</div>
                        </div>
                    </div>   
                    <div style={{width: "200px", paddingTop:"10px", borderWidth: "0px 0px 1px 0px", borderColor:"#f5f0f0", borderStyle:"solid"}}>
                        <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888"}}> 
                            <div><input type="number"
                                    style={{ "width": "70px", fontSize:"15px" }}
                                    className="btn-object-action"
                                    name={cartItem._id}
                                    min="1"
                                    max="100000"
                                    value={cartItem.quantity}
                                    onChange={updateQuantity}
                                 />          
                            </div>
                        </div>
                    </div>   
                    <div style={{width: "200px", paddingTop:"10px", borderWidth: "0px 0px 1px 0px", borderColor:"#f5f0f0", borderStyle:"solid"}}>
                        <div style={{fontSize: "15px", fontWeight:"500", color:"#0a0a0a"}}> 
                            <div>₱ {cartItem.total}</div>
                        </div>
                    </div>   
                    <div style={{width: "50px", paddingTop:"10px", borderWidth: "0px 0px 1px 0px", borderColor:"#f5f0f0", borderStyle:"solid", alignItems:"center"}}>
                        <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"50px", textAlign: "center"}} 
                            onClick={() => onDeleteItem(cartItem._id) }>
                            <img src={deleteIcon} width="20px" height="20px" />
                        </div>
                        
                        <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"50px", textAlign: "center"}} 
                            onClick={() => onEditItem(cartItem._id) }>
                            <img src={editIcon} width="20px" height="20px" />
                        </div>
                    </div>  
                </div> 
                
            ))
        }
        </div>

        <div style={{backgroundColor: "white", width:"100%", height:"50px", display:"flex", flexDirection:"row", flexWrap: "nowrap", 
         borderWidth:"1px", borderColor:"#cccccc", padding:"10px 0px, 10px, 0px", }}> 

            <div style={{width: "60%", height: "50px", paddingTop:"10px", }}>
                <div style={{fontSize: "20px", fontWeight:"600", color:"#f80909", textAlign:"right"}}> 
                    <div></div>
                </div>
            </div>
            <div style={{width: "30%", height: "50px", paddingTop:"10px", textAlign:"right"}}>
                <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888", display: "flex", flexDirection:"row", flexWrap: "nowrap", justifyContent:"flex-end"}}> 
                    <div style={{fontSize: "15px", fontWeight:"600", color:"#070707", textAlign:"right", padding: "5px 5px 0px 0px"}}>TOTAL </div>
                    <div  style={{fontSize: "20px", fontWeight:"600", color:"#f80909", textAlign:"right", padding: "0px 5px 0px 0px"}}>  {getFormattedPrice(grandTotal)} </div>
                </div>
            </div>
            <div style={{width: "10%", height: "50px", paddingTop:"10px", }}>
                <div style={{fontSize: "15px", fontWeight:"400", color:"#8a8888"}}> 
                    <div><button style={{backgroundColor: "cornflowerblue", color: "white", borderRadius: "8px", fontSize: "20px", cursor: "pointer", borderWidth: "0px"}}
                            onClick={onCheckout}>CHECKOUT</button></div>
                </div>
            </div>
        </div>
        <Modal show={dialogOK} backdrop="static" keyboard={true} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
      
              <Modal.Body>
                <p style={{textAlign: "center"}}>Please select an item to checkout.</p>
              </Modal.Body>
      
              <Modal.Footer>
                <Button style={{backgroundColor: "cornflowerblue"}} onClick={handleClose}>Close</Button>
              </Modal.Footer>
        </Modal>
 

        
    </div>
    
  )
}

export default Cart
import { useState, useEffect} from 'react';

const Error = (props) => {
  const [errmsg, setErrMsg] = useState("");

  const default_msg = "Sorry for the inconvenience. We're fixing it. Please try to refresh the page. If still not working, please try again later. Thanks! "

  const evaluate_error_codes = () => {
    switch (props.msg) {
        case '00':
            return "SERVER ERROR! " + default_msg
        case '01': 
            return "ERROR CONNECTING TO SERVER! " + default_msg
        case '02':
            return "UNAUTHORIZED! User not found! " // 404
        case '03':
            return "MISSING DATA! Please contact support!"
        case '04':
              return "E-mail already exist! Please try a different e-mail account. " // 409
        case '05':
              return "Password is incorrect. "  //401
        case '06':
              return "New password don't match."
        default:
            return null;
    }
  } 

  useEffect(() => {
    setErrMsg(evaluate_error_codes());
  }, [errmsg]);

  return (
    <div>
        {errmsg}
    </div>
  )
}

export default Error
import { useEffect, useState} from 'react';
//import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ProgressBar } from 'react-loader-spinner'
import axios from '../api/axios';
import useAuth from '../components/hooks/useAuth';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery} from '@tanstack/react-query'
const API_ORDERS_URL = '/order/all';



const Orders = () => {
    const DEBUG = false;
    const { auth } = useAuth();
    const { ref, inView } = useInView()
    
    const getOrdersFromServer = async ({ pageParam }) => {
        //console.log("getOrders From Server");
        const bearer_token = `Bearer ${auth.accessToken}`;
       // try {
            const response = await axios.post(API_ORDERS_URL + "?page=" + pageParam,
                JSON.stringify({ "userID": auth.userID}),
                {
                    headers: { 'Content-Type': 'application/json',
                              'Authorization': bearer_token },
                    withCredentials: true
                }
            );
            //const orders = response?.data;
            DEBUG && console.log("JSON", response.data, pageParam);
            //return orders;
            return response.data;
      //  } catch (error) {
      //      DEBUG && console.log("Error connecting to server ...", error);
       //     return error;
      //  } 
    }

  //  const {
  //      data: orders,
  //      error,
  //      isLoading,
  //  } = useQuery("ordersData", getOrdersFromServer);

  //const { isLoading, error, data, isFetching } = useQuery({
  //  queryKey: ['orderData'],
  //  queryFn: () => getOrdersFromServer(),
  //});

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: ["orders"],
        queryFn: getOrdersFromServer,
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
    });
    
    const getFormattedPrice = (price) => `₱ ${price?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;

    useEffect(() => {
        //console.log("orders", data);
        //console.log("error", error);
        ///console.log("hasNextPage", hasNextPage);
        //console.log("isFetching", isFetching);
        //console.log("isFetchingNextPage", isFetchingNextPage);
        //console.log("status", status);
     },[isFetching]);

    useEffect(() => {
       //setMyOrders(orders);
     // eslint-disable-next-line
        if (inView) {
            //console.log("inVIEW");
            fetchNextPage();
        }
    }, [fetchNextPage, inView])



  return (
    <div style={{backgroundColor:"#e9f3fc", display: "flex", flexDirection: "row", flexWrap: "wrap", width: "1200px", 
        justifyContent:"center", margin: "10px auto 10px auto", alignItems: "flexStart", boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>            
        <div style={{width: "100%", height:"50px"}}>
            <div style={{fontSize: "17px", fontWeight:"400", color: "#1A226D", margin: "auto", textAlign: "center", paddingTop: "10px"}}> Orders  </div>
        </div>
        <div style={{width: "100%",  display: "flex", flexDirection: "row", flexWrap: "nowrap", backgroundColor: "white"}}>
            <div style={{width: "15%", backgroundColor: "white"}}>
                <button style={{width:"98%", height: "30px", borderWidth: "0px", borderStyle:"none", margin:"2px", padding:"2px", color: "#7c7b7b", fontSize:"13px"}}>All</button>
                <button style={{width:"98%", height: "30px", borderWidth: "0px", borderStyle:"none", margin:"2px", padding:"2px", color: "#7c7b7b", fontSize:"13px"}}>To Pay </button>
                <button style={{width:"98%", height: "30px", borderWidth: "0px", borderStyle:"none", margin:"2px", padding:"2px", color: "#7c7b7b", fontSize:"13px"}}>Printing In Progress</button>
                <button style={{width:"98%", height: "30px", borderWidth: "0px", borderStyle:"none", margin:"2px", padding:"2px", color: "#7c7b7b", fontSize:"13px"}}>To Ship</button>
                <button style={{width:"98%", height: "30px", borderWidth: "0px", borderStyle:"none", margin:"2px", padding:"2px", color: "#7c7b7b", fontSize:"13px"}}>To Receive</button>
                <button style={{width:"98%", height: "30px", borderWidth: "0px", borderStyle:"none", margin:"2px", padding:"2px", color: "#7c7b7b", fontSize:"13px"}}>Completed</button>
                <button style={{width:"98%", height: "30px", borderWidth: "0px", borderStyle:"none", margin:"2px", padding:"2px", color: "#7c7b7b", fontSize:"13px"}}>Cancelled</button>
                <button style={{width:"98%", height: "30px", borderWidth: "0px", borderStyle:"none", margin:"2px", padding:"2px", color: "#7c7b7b", fontSize:"13px"}}>Return</button>
            </div>
            
            <div style={{width: "85%", backgroundColor: "#faf9f9"}}>

            { status==="pending" ? <div><ProgressBar 
                            visible={true}
                            height="80"
                            width="80"
                            color="#097fee"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{}}
                            wrapperClass="" /> 
                        </div> : 
                data?.pages.map ? 
                    data.pages.map((page, k) => (
                        page.orders.map((orderItem, i) => (
                            <div key={i} style={{width: "100%", borderWidth: "1px", borderStyle:"solid", borderColor:"#eeeaea", padding: "2px", margin:"2px 0px 8px 0px", backgroundColor:"white",
                                                display: "flex", flexDirection: "row", flexWrap: "wrap"}}> 
                                <div style={{width: "50%", fontSize:"10px", color: "gray", textAlign:"left", padding:"5px", }}> Order ID: {orderItem._id} </div>
                                <div style={{width: "50%", fontSize:"13px", color: "#faa297", textAlign:"right", padding:"5px",}}> {orderItem.status}</div> 
                                <div style={{width: "100%", textAlign: "left", padding: "5px", }}>
                                {
                                        orderItem?.orderdetails?.map((orderdetailsItem, j) => (
                                            <div key={j} style={{width:"100%", display: "flex", flexDirection: "row"}}>
                                                <div style={{width: "55%"}}> 
                                                    {orderdetailsItem._type} - {orderdetailsItem.filename} <br />
                                                    <span style={{fontSize: "12px", color:"gray"}}>Size: {orderdetailsItem.width} x {orderdetailsItem.height} {orderdetailsItem.unit} - 
                                                        [{orderdetailsItem.totalsqft} sq. ft] 
                                                    </span>
                                                </div>
                                                <div style={{width: "15%", textAlign: "center", color:"gray", fontSize:"12px"}}>
                                                    {orderdetailsItem.quantity}
                                                </div>
                                                <div style={{width: "15%", textAlign: "center", color:"gray", fontSize:"12px"}}>
                                                    {orderdetailsItem.unitprice}
                                                </div>
                                                <div style={{width: "15%", textAlign: "right", fontSize:"12px"}}>
                                                    {orderdetailsItem.total===undefined ? "" : getFormattedPrice(orderdetailsItem.total)}
                                                </div>
                                            </div>

                                            )
                                        )
                                }
                                </div>     
                                <div style={{width: "100%", fontSize:"18px", fontWeight: "500", color: "#f75f5f", textAlign:"right", padding:"5px", backgroundColor:"#f5f2f2"}}>
                                <span style={{fontSize:"12px", fontWeight: "300", color: "black"}}>Order Total</span> &nbsp; {getFormattedPrice(orderItem.total)}
                                </div> 
                                <div style={{width: "100%", fontSize:"12px", fontWeight: "300", textAlign:"right", padding:"5px", backgroundColor:"#f5f2f2"}}>
                                <button style={{backgroundColor: "cornflowerblue", color: "white", borderRadius: "8px", fontSize: "15px", cursor: "pointer", borderWidth: "0px"}}>Cancel Order</button> &nbsp;
                                <button style={{backgroundColor: "cornflowerblue", color: "white", borderRadius: "8px", fontSize: "15px", cursor: "pointer", borderWidth: "0px"}}>Receive Order</button>
                                </div> 
                            
                            </div>
                        ))
                    ))
                : "No data"
            }
                <div style={{color:"gray", fontSize:"12px"}}>
                    {isFetchingNextPage ? 'Loading more...' : 'No more records.' }
                </div>
            </div>

        </div>
        <div ref={ref}> &nbsp;</div> {/* OBSERVER */}
    </div>
  )
}

export default Orders
import { useState } from "react";
import { Navbar, Nav, Container, Form, Button} from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from './hooks/useAuth';
import useLogout from "./hooks/useLogout";

import IconSearch from '../images/icons/search.png'
//import NavigationMenuAd  from "./NavigationMenuAd";
//import CartMain from "./cart/CartButton";
import iconCart from '../images/icons/cart2.png'
import iconOrders from '../images/icons/orders.png';
import iconProfile from '../images/icons/account.png';

import PrintBitLogo from "../images/printbit_logo_300.png";

const NavigationMain = () => {
    const { auth } = useAuth();
    const [expanded, setExpanded] = useState(false); //collapsible breadcrum
    const { pathname } = useLocation();
    const logout = useLogout();
    const navigate = useNavigate();

    const signOut = async () => {
        //navigate('/');
        setExpanded(false);
        await logout();
        navigate("/");
    }

    const renderTooltipCart = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Cart
        </Tooltip>
    );

    const renderTooltipOrders = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Orders
        </Tooltip>
    );

    const renderTooltipProfile = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Profile
        </Tooltip>
    );


    if (pathname === "/editor") return null;
    if (pathname === "/editornew") return null;

    return (
      <Navbar collapseOnSelect bg="light" expand="lg" className='user_nav-bg' expanded={expanded}>
        <Container>
            <Navbar.Brand as={Link} to="/"><img src={PrintBitLogo} width="150" className="d-inline-block align-top" alt="PrintBit"/></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="m-auto">
                        {/* <Nav.Link as={Link} to="/studio" onClick={() => setExpanded(false)}><h4 className="user_nav-link">STUDIO</h4></Nav.Link> */}
                        <Nav.Link as={Link} to="/editor" onClick={() => setExpanded(false)}><h4 className="user_nav-link">EDITOR</h4></Nav.Link>
                        <Nav.Link as={Link} to="/shop" onClick={() => setExpanded(false)}><h4 className="user_nav-link">SHOP</h4></Nav.Link> 
                        {/* <Nav.Link as={Link} to="https://www.yearbookville.com" target="_blank" onClick={() => setExpanded(false)}><h4 className="user_nav-link">YEARBOOKVILLE</h4></Nav.Link>  */}
                        {/* <Nav.Link as={Link} to="https://coin.printbit.com" target="_blank" onClick={() => setExpanded(false)}><h4 className="user_nav-link">PRINTBIT COIN</h4></Nav.Link>  */}
                        
                    </Nav>
                    <Form className="d-flex">
                        <Form.Control type="search" placeholder="Search Products" className="me-2" style={{width: "420px", fontSize:"12px"}} aria-label="Search Products"/>
                        <Button><img src={IconSearch} alt="search" width="15" /></Button>
                    </Form>
                    {/*<div style={{padding: "10px"}}><NavigationMenuAd /></div>*/}
                    {/* <div style={{padding: "10px"}}><CartMain /></div> */}
                    <div style={{width: "10%"}}>&nbsp;</div> 
                    <Nav> 
                        <div style={{padding: "2px"}}> 
                            <Nav.Link as={Link} to="/cart" onClick={() => setExpanded(false)}> 
                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltipCart}>
                                    <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} ><img src={iconCart} width="24px" alt="Cart"/> </div>
                                </OverlayTrigger>
                            </Nav.Link>
                        </div>
                        <div style={{padding: "2px"}}> 
                            <Nav.Link as={Link} to="/orders" onClick={() => setExpanded(false)}> 
                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltipOrders}>
                                    <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} ><img src={iconOrders} width="24px" alt="Orders"/> </div>
                                </OverlayTrigger>
                            </Nav.Link>
                        </div>
                        <div style={{padding: "2px"}}> 
                            <Nav.Link as={Link} to="/profile" onClick={() => setExpanded(false)}> 
                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltipProfile}>
                                    <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"30px"}} ><img src={iconProfile} width="24px" alt="Profile"/> </div>
                                </OverlayTrigger>
                            </Nav.Link>
                        </div>
                        <div style={{padding: "2px"}}> 
                            { auth?.accessToken ? 
                                <Nav.Link as={Link} onClick={signOut}>  
                                        <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"60px"}} >Logout</div>  
                                </Nav.Link>
                                : 
                                <Nav.Link as={Link} to="/login" onClick={() => setExpanded(false)}> 
                                        <div className='buttonShapes' style={{margin:"0px", padding:"3px", height:"30px", width:"50px"}} >Login </div>
                                </Nav.Link> 
                            }
                        </div>
                    </Nav>
                    
                </Navbar.Collapse>
        </Container> 
      </Navbar>  
    )
}

export default NavigationMain;